import React, { Fragment } from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AddBooking = ({ translationModel, hour, resource, onAddBooking }) => {
  const { t } = useTranslation("common");
  const addBookingHandler = (hour, resourceId) => {
    onAddBooking(hour, resourceId);
  };

  return (
    <Fragment key={format(new Date(hour), "HHmm") + "_" + resource.id}>
      <Tooltip
        key={format(new Date(hour), "HHmm") + "_" + resource.id}
        title={t(translationModel + ".buttons.addSession")}
        placement="top"
      >
        <AddCircleIcon
          key={format(new Date(hour), "HHmm") + "_" + resource.id}
          id={format(new Date(hour), "HHmm") + "_" + resource.id}
          color="primary"
          sx={{ float: "right" }}
          onClick={() => addBookingHandler(hour, resource.id)}
        />
      </Tooltip>
    </Fragment>
  );
};

export default AddBooking;
