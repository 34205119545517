import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Card, Typography, Alert } from "@mui/material";
import styles from "./styles.module.css";

const OnHoldModelRows = ({ onHoldModelRows, action, module, onSelect }) => {
  const { t } = useTranslation("common");
  const currentSaleId = useSelector((state) => state.ui.saleId);
  const currentPurchaseId = useSelector((state) => state.ui.purchaseId);
  const currentModelId =
    module === ("sales" || "posnew_sale") ? currentSaleId : currentPurchaseId;
  const selectOnHoldSaleHandler = (id) => {
    onSelect(id);
  };

  const renderCard = (item, type) =>
    item.id !== currentModelId && (
      <Card
        className={`${styles["onHoldModelRowsCards"]}`}
        key={item.id}
        onClick={() => selectOnHoldSaleHandler(item.id)}
        disabled={action === "view" ? true : false}
        style={{ backgroundColor: "#035fc0", color: "white" }}
      >
        <Typography variant="h6">{`${t("sales.columns.id")} ${
          item.id
        } `}</Typography>
        <Typography variant="h6">{`${t("sales.columns.totalItems")} ${
          item[`${type}TotalItems`] ? item[`${type}TotalItems`] : "0"
        }`}</Typography>
        <Typography variant="h6">{`${t("sales.columns.totalPayments")} ${
          item[`${type}TotalPayments`]
            ? parseFloat(item[`${type}TotalPayments`], 2).toFixed(2)
            : "$0.00"
        }`}</Typography>
        <Typography variant="h6">{`${t("sales.columns.amountDue")} ${
          !item[`${type}AmountDue`] && !item[`${type}TotalItems`]
            ? "$0.00"
            : item[`${type}AmountDue`]
            ? "$" + item[`${type}AmountDue`]
            : item[`${type}TotalAmount`]
            ? "$" + parseFloat(item[`${type}TotalAmount`], 2).toFixed(2)
            : ""
        }`}</Typography>
      </Card>
    );

  return (
    <Grid container sx={{ minWidth: "600px" }}>
      <Grid item xs={12} s={12} md={12} lg={12}>
        {onHoldModelRows.Sales?.length <= 1 && (
          <Alert severity="warning">{t("errors.noContent")}</Alert>
        )}
        {onHoldModelRows.Purchases?.length <= 1 && (
          <Alert severity="warning">{t("errors.noContent")}</Alert>
        )}
        {onHoldModelRows && (
          <Fragment>
            {onHoldModelRows.Sales?.map((sale) => renderCard(sale, "sal"))}
            {onHoldModelRows.Purchases?.map((purchase) =>
              renderCard(purchase, "pur")
            )}
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default OnHoldModelRows;
