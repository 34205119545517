import React, { Fragment, useEffect } from "react";
import useHttp from "../../../hooks/use-http";
import useInput from "../../../hooks/use-input";
import useFormField from "../../../hooks/use-form-field";
import { Stack, Fab, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import styles from "../styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ClinicHistoryModelForm = ({
  token,
  model,
  translationModel,
  action,
  recordType,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  let loadForm = true;
  let formIsValid = false;
  const formSubmitHandler = async (event) => {
    event.preventDefault();
    if (action === "add") {
      onCreate(formFields);
    }

    if (action === "edit") {
      onUpdate(formFields);
    }
  };

  const cancelHandler = () => {
    onCancel();
  };

  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const clientChangeHandler = (e) => {
    setClient(e.target.value);
  };

  const { enteredValue: id, setEnteredValue: setId } = useInput();
  const { enteredValue: client, setEnteredValue: setClient } = useInput();
  const { enteredValue: date, setEnteredValue: setDate } = useInput();

  const {
    enteredValue: inheritanceBackground,
    setEnteredValue: setInheritanceBackground,
    valueChangeHandler: inheritanceBackgroundChangeHandler,
    reset: resetInheritanceBackground,
  } = useInput();

  const {
    enteredValue: familyHistory,
    setEnteredValue: setFamilyHistory,
    valueChangeHandler: familyHistoryChangeHandler,
    reset: resetFamilyHistory,
  } = useInput();

  const {
    enteredValue: pathologicalHistory,
    setEnteredValue: setPathologicalHistory,
    valueChangeHandler: pathologicalHistoryChangeHandler,
    reset: resetPathologicalHistory,
  } = useInput();

  const {
    enteredValue: nonPathologicalHistory,
    setEnteredValue: setNonPathologicalHistory,
    valueChangeHandler: nonPathologicalHistoryChangeHandler,
    reset: resetNonPathologicalHistory,
  } = useInput();

  const {
    enteredValue: externalHabitat,
    setEnteredValue: setExternalHabitat,
    valueChangeHandler: externalHabitatChangeHandler,
    reset: resetExternalHabitat,
  } = useInput();

  const {
    enteredValue: currentConditions,
    setEnteredValue: setCurrentConditions,
    valueChangeHandler: currentConditionsChangeHandler,
    reset: resetCurrentConditions,
  } = useInput();

  const { enteredValue: interrogation, setEnteredValue: setInterrogation } =
    useInput();

  const interrogationChangeHandler = () => {
    setInterrogation(!interrogation);
  };

  const { enteredValue: labResults, setEnteredValue: setLabResults } =
    useInput();

  const {
    enteredValue: implementedTherapyAndResults,
    setEnteredValue: setImplementedTherapyAndResults,
    valueChangeHandler: implementedTherapyAndResultsChangeHandler,
    reset: resetImplementedTherapyAndResults,
  } = useInput();

  const {
    enteredValue: diagnosticsOrClinicProblems,
    setEnteredValue: setDiagnosticsOrClinicProblems,
    valueChangeHandler: diagnosticsOrClinicProblemsChangeHandler,
    reset: resetDiagnosticsOrClinicProblems,
  } = useInput();

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    reset: resetObservations,
  } = useInput();

  const { enteredValue: captured, setEnteredValue: setCaptured } = useInput();

  const formConfig = [
    {
      type: "text",
      action,
      disabled: true,
      id: "id",
      multiline: false,
      name: "id",
      sx: { display: "none" },
      value: id,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "recordType",
      multiline: false,
      name: "cliRecType",
      sx: { display: "none" },
      value: recordType,
    },
    {
      type: "select",
      action,
      disabled: action === "view",
      errorMessage: t("errors.fieldError"),
      id: "client",
      label: t(translationModel + ".form.clinicHistory.client") + "*",
      name: "cliRecClientID",
      sx: { background: "white" },
      value: clients?.clients && !clientsAreLoading ? client : "",
      menuItems: clients && !clientsAreLoading && clients.clients,
      menuItemValue: ["id", "cliLastName", "cliFirstName"],
      customOnChange: clientChangeHandler,
      validationFn: (value) => value > 0,
    },
    {
      type: "date",
      action,
      disabled: action === "view",
      id: "date",
      label: t(translationModel + ".form.clinicHistory.date"),
      name: "cliRecRecognitionDate",
      sx: { width: 220 },
      value: date ? date : format(new Date(), "yyyy-MM-dd"),
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "inheritanceBackground",
      label: t(translationModel + ".form.clinicHistory.inheritanceBackground"),
      multiline: true,
      name: "cliRecHeritageConditions",
      sx: { background: "white" },
      value: inheritanceBackground,
      customOnChange: inheritanceBackgroundChangeHandler,
      customOnClear: resetInheritanceBackground,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "familyHistory",
      label: t(translationModel + ".form.clinicHistory.familyHistory"),
      multiline: true,
      name: "cliRecFamiliarConditions",
      sx: { background: "white" },
      value: familyHistory,
      customOnChange: familyHistoryChangeHandler,
      customOnClear: resetFamilyHistory,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "pathologicalHistory",
      label: t(translationModel + ".form.clinicHistory.pathologicalHistory"),
      multiline: true,
      name: "cliRecPathologicalConditions",
      sx: { background: "white" },
      value: pathologicalHistory,
      customOnChange: pathologicalHistoryChangeHandler,
      customOnClear: resetPathologicalHistory,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "nonPathologicalHistory",
      label: t(translationModel + ".form.clinicHistory.nonPathologicalHistory"),
      multiline: true,
      name: "cliRecNonPathologicalConditions",
      sx: { background: "white" },
      value: nonPathologicalHistory,
      customOnChange: nonPathologicalHistoryChangeHandler,
      customOnClear: resetNonPathologicalHistory,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "currentConditions",
      label: t(translationModel + ".form.clinicHistory.currentConditions"),
      multiline: true,
      name: "cliRecActualConditions",
      sx: { background: "white" },
      value: currentConditions,
      customOnChange: currentConditionsChangeHandler,
      customOnClear: resetCurrentConditions,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "interrogationByDevicesAndSystems",
      label: t(
        translationModel +
          ".form.clinicHistory.interrogationByDevicesAndSystems"
      ),
      name: "cliRecInterrogation",
      sx: { background: "white" },
      value: interrogation ? true : false,
      checked: interrogation === 1 || interrogation === true ? true : false,
      customOnChange: interrogationChangeHandler,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "externalHabitat",
      label: t(translationModel + ".form.clinicHistory.externalHabitat"),
      multiline: true,
      name: "cliRecExteriorHabitus",
      sx: { background: "white" },
      value: externalHabitat,
      customOnChange: externalHabitatChangeHandler,
      customOnClear: resetExternalHabitat,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "document",
      disabled: action === "view",
      id: "labResults",
      label: t(translationModel + ".form.clinicHistory.labResultsDownload"),
      name: "cliRecLabResults",
      token,
      value: labResults,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "implementedTherapyAndResults",
      label: t(
        translationModel + ".form.clinicHistory.implementedTherapyAndResults"
      ),
      multiline: true,
      name: "cliRecTherapyResults",
      sx: { background: "white" },
      value: implementedTherapyAndResults,
      customOnChange: implementedTherapyAndResultsChangeHandler,
      customOnClear: resetImplementedTherapyAndResults,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "diagnosticsOrClinicProblems",
      label: t(
        translationModel + ".form.clinicHistory.diagnosticsOrClinicProblems"
      ),
      multiline: true,
      name: "cliRecDiagnostics",
      sx: { background: "white" },
      value: diagnosticsOrClinicProblems,
      customOnChange: diagnosticsOrClinicProblemsChangeHandler,
      customOnClear: resetDiagnosticsOrClinicProblems,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "observations",
      label: t(translationModel + ".form.clinicHistory.observations"),
      multiline: true,
      name: "cliRecObservations",
      sx: { background: "white" },
      value: observations,
      customOnChange: observationsChangeHandler,
      customOnClear: resetObservations,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "captured",
      label: action === "view" && t(translationModel + ".form.captured"),
      multiline: false,
      name: "captured",
      sx: action !== "view" ? { display: "none" } : {},
      value: captured,
    },
  ];

  const { formFields, renderFormFields } = useFormField(formConfig);

  if (client) {
    formIsValid = true;
  }

  useEffect(() => {
    if (loadForm) {
      getClients();
    }
    if (model && action) {
      setId(model.clientRecord.id ? model.clientRecord.id : null);
      setClient(
        model.clientRecord.cliRecClientID
          ? model.clientRecord.cliRecClientID
          : false
      );

      setDate(
        model.clientRecord.cliRecRecognitionDate
          ? new Date(model.clientRecord.cliRecRecognitionDate)
              .toISOString()
              .split("T")[0]
          : ""
      );

      setInheritanceBackground(
        model.clientRecord?.cliRecHeritageConditions
          ? model.clientRecord.cliRecHeritageConditions
          : ""
      );

      setFamilyHistory(
        model.clientRecord?.cliRecFamiliarConditions
          ? model.clientRecord.cliRecFamiliarConditions
          : ""
      );

      setPathologicalHistory(
        model.clientRecord?.cliRecPathologicalConditions
          ? model.clientRecord.cliRecPathologicalConditions
          : ""
      );

      setNonPathologicalHistory(
        model.clientRecord?.cliRecNonPathologicalConditions
          ? model.clientRecord.cliRecNonPathologicalConditions
          : ""
      );

      setExternalHabitat(
        model.clientRecord?.cliRecPathologicalConditions
          ? model.clientRecord.cliRecPathologicalConditions
          : ""
      );

      setCurrentConditions(
        model.clientRecord?.cliRecActualConditions
          ? model.clientRecord.cliRecActualConditions
          : ""
      );

      setInterrogation(model.clientRecord?.cliRecInterrogation ? true : false);

      setLabResults(
        model.clientRecord?.cliRecLabResults
          ? model.clientRecord.cliRecLabResults
          : ""
      );

      setImplementedTherapyAndResults(
        model.clientRecord?.cliRecTherapyResults
          ? model.clientRecord.cliRecTherapyResults
          : ""
      );

      setDiagnosticsOrClinicProblems(
        model.clientRecord?.cliRecDiagnostics
          ? model.clientRecord.cliRecDiagnostics
          : ""
      );

      setObservations(
        model.clientRecord?.cliRecObservations
          ? model.clientRecord.cliRecObservations
          : ""
      );
      setCaptured(
        model.clientRecord?.cliRecCaptured
          ? model.clientRecord.cliRecCaptured
          : ""
      );
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {renderFormFields()}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ClinicHistoryModelForm;
