import React, { useState, useEffect, Fragment } from "react";
import useHttp from "../../hooks/use-http";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { uiActions } from "../../store/ui-slice";
import { posActions } from "../../store/pos-slice";
import { Container, Grid, CircularProgress, Alert } from "@mui/material";

import Categories from "./Categories";
import FooterFunctions from "./FooterFunctions";
import Header from "./Header";
import PosDialog from "./Dialog";
import Products from "./Products";
import ModelDetails from "./ModelDetails";
import StatusAlerts from "./StatusAlerts";
import Totals from "./Totals";

const POSGrid = ({ action: actionProp, token, module, clientId, onCancel }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [action, setAction] = useState("");
  const [activeDialog, setActiveDialog] = useState(null);
  const [addNote, setAddNote] = useState(false);
  const [cashOperationMovementSuccess, setCashOperationMovementSuccess] =
    useState("");
  const [productCategory, setProductCategory] = useState("all");
  const [productId, setProductId] = useState("");
  const [modelAction, setModelAction] = useState("");
  const saleId = useSelector((state) => state.ui.saleId);
  const purchaseId = useSelector((state) => state.ui.purchaseId);
  const editable = useSelector((state) => state.pos.editable);
  const [selectedOnHoldModelId, setSelectedOnHoldModelId] = useState(null);
  const [modelDetailAction, setModelDetailAction] = useState();
  const [modelDetailResourceId, setModelDetailResourceId] = useState(null);
  const [modelDetailProduct, setModelDetailProduct] = useState();
  const [modelDetailProductQuantity, setModelDetailProductQuantity] =
    useState();
  const [modelDetailProductPrice, setModelDetailProductPrice] = useState();
  const [confirmAction, setConfirmAction] = useState(false);
  const [modelOnHoldDirection, setModelOnHoldDirection] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState("");
  const selectedCashDrawerId = useSelector(
    (state) => state.pos.selectedCashDrawer
  );
  const defaultCashDrawer = useSelector(
    (state) => state.auth.defaultCashDrawer
  );

  const translationModel = "pos";
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const addModelDetail = async () => {
    setModelDetailAction(action);
  };

  const {
    data: cashDrawerLastOperation,
    sendRequest: cashDrawerLastOperationGetRequest,
  } = useHttp();

  const getCashDrawerLastOperation = async (cashDrawerId) => {
    await cashDrawerLastOperationGetRequest({
      url:
        process.env.REACT_APP_API_SERVER +
        "/cash_operations/last_operation/" +
        cashDrawerId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: modelData,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const getModelHandler = async () => {
    const moduleUrl =
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
        ? "/sales"
        : "/purchases";
    const id = saleId || purchaseId;
    const requestUrl = `${process.env.REACT_APP_API_SERVER}${moduleUrl}/${id}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    };

    await sendModelGetRequest({ url: requestUrl, headers });
  };

  const { data: modelOnHoldData, sendRequest: sendModelOnHoldGetRequest } =
    useHttp();

  const getModelOnHoldHandler = async () => {
    const moduleUrl =
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
        ? "/sales/on_hold"
        : "/purchases/on_hold";
    const requestUrl = process.env.REACT_APP_API_SERVER + moduleUrl;
    await sendModelOnHoldGetRequest({
      url: requestUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const { error: fetchUpdateModelError, sendRequest: sendUpdateModelRequest } =
    useHttp();

  const updateModelHandler = async (data) => {
    setAction("update");
    const moduleUrl =
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
        ? "/sales/"
        : "/purchases/";
    const requestUrl = process.env.REACT_APP_API_SERVER + moduleUrl;
    await sendUpdateModelRequest({
      url: requestUrl,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: data,
    });
    if (!fetchUpdateModelError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    setAction("");
  };

  const categoryChangeHandler = (categoryId) => {
    setProductCategory(categoryId);
  };

  const changeSaleOnHoldHandler = (direction) => {
    setActiveDialog("changeModelConfirmation");
    setModelOnHoldDirection(direction);
  };

  const closeDialogHandler = () => {
    setActiveDialog("");
  };

  const confirmEditModel = (data) => {
    let objectData = {};
    if (data && activeDialog === "editModel") {
      objectData = {
        id: data.id,
        salClientID: data.salClientID,
        salExecutiveID: data.salExecutiveID,
        salCancel: data.salCancel,
        saleCancelDate: data.salCancel && new Date(),
        saleObservations: data.saleObservations,
        salCaptured: data.salCaptured,
        salLastUpdate: new Date(),
        salWarOutput: data.salWarOutput,
        salPoiCarNumber: data.salPoiCarNumber,
      };
      if (isPurchasesModule()) {
        objectData = {
          id: data.id,
          purProviderID: data.purProviderID,
          purCanceled: data.purCanceled,
          purObservations: data.purObservations,
          purCaptured: data.purCaptured,
          purLastUpdate: new Date(),
          purWarInput: data.purWarInput,
        };
      }
      updateModelHandler(objectData);
    }
    if (activeDialog === "alertDeleteModelDetail") {
      setConfirmAction(true);
      setTimeout(() => {
        setConfirmAction(false);
      }, 10);
    }
    setActiveDialog("");
  };

  const confirmResponseHandler = (response) => {
    if (response === "addSuccess") {
      setModelDetailAction(null);
      setModelDetailResourceId(null);
      setModelDetailProduct(null);
      setModelDetailProductPrice(null);
      setModelDetailProductQuantity(null);
    }
    setUpdateSuccess(true);
    setTimeout(() => {
      setUpdateSuccess(false);
    }, process.env.REACT_APP_ALERTS_TIMEOUT);
  };

  const createCashOperationSuccess = () => {
    getCashDrawerLastOperation(selectedCashDrawerId);
    setCashOperationMovementSuccess(true);
    setTimeout(() => {
      setCashOperationMovementSuccess(false);
    }, process.env.REACT_APP_ALERTS_TIMEOUT);
  };

  const createChargeSuccess = () => {
    setAction("Update");
    setTimeout(() => {
      setUpdateSuccess(false);
    }, process.env.REACT_APP_ALERTS_TIMEOUT);
    setAction("");
    closeDialogHandler();
  };

  const confirmChangeModelOnHold = () => {
    setActiveDialog("");
    if (!selectedOnHoldModelId && !modelAction) {
      if (modelOnHoldData) {
        const findPreviousModel = (modelRows, modelId) => {
          return modelRows
            .sort((a, b) => b.id - a.id)
            .find((sale) => sale.id < modelId);
        };

        const findNextModel = (modelRows, modelId) => {
          return modelRows
            .sort((a, b) => a.id - b.id)
            .find((sale) => sale.id > modelId);
        };

        if (modelOnHoldDirection === "previous") {
          let previousModel = findPreviousModel(modelOnHoldData.Sales, saleId);
          if (isPurchasesModule()) {
            previousModel = findPreviousModel(
              modelOnHoldData.Purchases,
              purchaseId
            );
          }
          previousModel && dispatch(uiActions.setSaleId(previousModel.id));
        }

        if (modelOnHoldDirection === "next") {
          let nextModel = findNextModel(modelOnHoldData.Sales, saleId);
          if (isPurchasesModule()) {
            nextModel = findNextModel(modelOnHoldData.Purchases, purchaseId);
          }
          nextModel && dispatch(uiActions.setSaleId(nextModel.id));
        }
      }
    }
    if (selectedOnHoldModelId && !modelAction) {
      if (isSalesModule()) {
        dispatch(uiActions.setSaleId(selectedOnHoldModelId));
      }
      if (isPurchasesModule()) {
        dispatch(uiActions.setPurchaseId(selectedOnHoldModelId));
      }
      setActiveDialog("");
    }
    if (modelAction === "setModelOnHold") {
      if (
        module === "sales" ||
        module === "salesclient" ||
        module === "salespending_charges" ||
        module === "purchases" ||
        module === "purchasespending_payments"
      ) {
        onCancel();
      }
      if (isSalesModule()) {
        dispatch(uiActions.setSaleId(""));
        (module === "sales" || module === "posnew_sale") && navigate("/sales");
        module === "posnew_saleclient" &&
          clientId &&
          navigate(`/sales/client/${clientId}`);
        module === "salespending_charges" && navigate("/sales/pending_charges");
      }
      if (isPurchasesModule()) {
        dispatch(uiActions.setPurchaseId(""));
        (module === "purchases" || module === "posnew_purchase") &&
          navigate("/purchases");
        module === "purchases/pending_payments" &&
          navigate("/purchases/pending_payments");
      }
    }
    if (modelAction === "cancelModel") {
      let cancelData = {
        id: saleId,
        salCancel: true,
        salLastUpdate: new Date(),
      };
      if (isPurchasesModule()) {
        cancelData = {
          id: purchaseId,
          purCanceled: true,
          purLastUpdate: new Date(),
        };
      }
      updateModelHandler(cancelData);
    }
  };

  const getModelId = (saleId, purchaseId) => {
    return saleId || purchaseId;
  };

  const getAmountDue = (modelData) => {
    return modelData?.sale?.salAmountDue || modelData?.purchase?.purAmountDue;
  };

  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };

  const isPurchasesModule = () => {
    return (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    );
  };

  const posDialogTitle = (dialogType) => {
    switch (dialogType) {
      case "alertDeleteModelDetail":
        return t(translationModel + ".confirmations.deleteDetail.title");
      case "cashDrawer":
        return t(translationModel + ".confirmations.selectCashDrawer.title");
      case "cashMovement":
        return t(
          translationModel + ".confirmations.cashRegisterMovement.title"
        );
      case "charges":
        return t(translationModel + ".confirmations.addCharge.title");
      case "cancelModelConfirmation":
        return isSalesModule()
          ? t(translationModel + ".confirmations.cancelModel.title", {
              model: t("sales.model"),
            })
          : t(translationModel + ".confirmations.cancelModel.title", {
              model: t("purchases.model"),
            });
      case "changeModelConfirmation":
        return isSalesModule()
          ? t(translationModel + ".confirmations.changeSaleOnHold.title")
          : t(translationModel + ".confirmations.changePurchaseOnHold.title");
      case "editModel":
        return isSalesModule()
          ? t(translationModel + ".confirmations.editSale.title")
          : t(translationModel + ".confirmations.editPurchase.title");
      case "exitModelConfirmation":
        return t(translationModel + ".confirmations.exitModel.title");
      case "productInfo":
        return t(translationModel + ".confirmations.productInfo.title");
      case "onHoldModelRows":
        return isSalesModule()
          ? t(translationModel + ".confirmations.selectOnHoldSale.title")
          : t(translationModel + ".confirmations.selectOnHoldPurchase.title");
      case "resources":
        return t(translationModel + ".confirmations.selectResource.title");
      case "setModelOnHoldConfirmation":
        return isSalesModule()
          ? t(translationModel + ".confirmations.setModelOnHold.title", {
              model: t("sales.model"),
            })
          : t(translationModel + ".confirmations.setModelOnHold.title", {
              model: t("purchases.model"),
            });
      default:
        return "";
    }
  };

  const posDialogDescription = (dialogType) => {
    switch (dialogType) {
      case "alertDeleteModelDetail":
        return t(translationModel + ".confirmations.deleteDetail.description");
      case "cancelModelConfirmation":
        return isSalesModule()
          ? t(translationModel + ".confirmations.cancelModel.description", {
              model: t("sales.model"),
            })
          : t(translationModel + ".confirmations.cancelModel.description", {
              model: t("purchases.model"),
            });
      case "changeModelConfirmation":
        return isSalesModule()
          ? t(translationModel + ".confirmations.changeSaleOnHold.description")
          : t(
              translationModel +
                ".confirmations.changePurchaseOnHold.description"
            );
      case "exitModelConfirmation":
        return t(translationModel + ".confirmations.exitModel.description");
      case "setModelOnHoldConfirmation":
        return isSalesModule()
          ? t(translationModel + ".confirmations.setModelOnHold.description", {
              model: t("sales.model"),
            })
          : t(translationModel + ".confirmations.setModelOnHold.description", {
              model: t("purchases.model"),
            });
      default:
        return "";
    }
  };

  const selectHandler = (type, id, quantity, price) => {
    if (id && type === "onHoldModelRows") {
      setSelectedOnHoldModelId(id);
      setActiveDialog("changeModelConfirmation");
    }
    if ((id || quantity || price) && type === "productDetails") {
      setModelDetailProductQuantity(quantity);
      setModelDetailProductPrice(price);
      setModelDetailResourceId(id);
      addModelDetail(id);
    }
    type !== "onHoldModelRows" && setActiveDialog(null);
  };

  const closeHandler = () => {
    setActiveDialog(null);
  };

  const searchProductHandler = (searchCriteria) => {
    setProductCategory("all");
    setSearchCriteria(searchCriteria);
  };
  //TODO: Test if action is really needed
  const selectProductHandler = (action, product) => {
    (isSalesModule() || isPurchasesModule()) &&
      setActiveDialog("productDetails");
    setModelDetailProduct(product);
    isPurchasesModule();
  };

  const setModelOnHold = () => {
    if (action === "edit" || action === "create") {
      if (getAmountDue(modelData) > 0) {
        setActiveDialog("setModelOnHoldConfirmation");
      }
      if (!getAmountDue(modelData) || getAmountDue(modelData) === 0) {
        setActiveDialog("exitModelConfirmation");
      }
      setModelAction("setModelOnHold");
    }
    if (action === "view") {
      if (isSalesModule() || isPurchasesModule()) {
        onCancel();
      }
      if (isSalesModule()) {
        dispatch(uiActions.setSaleId(""));
        (module === "sales" || module === "posnew_sale") && navigate("/sales");
        module === "posnew_saleclient" &&
          clientId &&
          navigate(`/sales/client/${clientId}`);
        module === "sales/pending_charges" &&
          navigate("/sales/pending_charges");
      }
      if (isPurchasesModule()) {
        dispatch(uiActions.setPurchaseId(""));
        module === "purchases" ||
          (module === "posnew_purchase" && navigate("/purchases"));
        module === "purchases/pending_payments" &&
          navigate("/purchases/pending_payments");
      }
    }
  };

  const updateActiveDialogHandler = (type, extraParam) => {
    setActiveDialog(type);
    type === "editModel" && setAddNote(false);
    extraParam && setAddNote(true);
  };

  const viewProductHandler = (productId) => {
    updateActiveDialogHandler("productInfo");
    setProductId(productId);
  };

  useEffect(() => {
    getModelHandler();
    getModelOnHoldHandler();
    dispatch(posActions.getModelIsEditable());
    !selectedCashDrawerId && dispatch(posActions.getSelectedCashDrawer());
    if (!selectedCashDrawerId && defaultCashDrawer) {
      dispatch(posActions.setSelectedCashDrawer(defaultCashDrawer));
    }
    if (actionProp) {
      setAction(actionProp);
    }
    if (selectedCashDrawerId || cashOperationMovementSuccess) {
      getCashDrawerLastOperation(selectedCashDrawerId);
    }
    // eslint-disable-next-line
  }, [
    action,
    saleId,
    purchaseId,
    productCategory,
    selectedCashDrawerId,
    defaultCashDrawer,
    updateSuccess,
  ]);

  return (
    <Fragment>
      <Container maxWidth="xlg">
        {activeDialog && (
          <PosDialog
            action={action}
            addNote={addNote}
            cashDrawerLastOperation={
              cashDrawerLastOperation && cashDrawerLastOperation
            }
            description={posDialogDescription(activeDialog)}
            productId={productId || modelDetailProduct}
            selectedCashDrawerId={selectedCashDrawerId}
            modelData={modelData}
            module={module}
            title={posDialogTitle(activeDialog)}
            token={token}
            type={activeDialog}
            onHoldModelRows={modelOnHoldData && modelOnHoldData}
            onSelect={selectHandler}
            onClose={closeHandler}
            onCreateCashOperationSuccess={createCashOperationSuccess}
            onCreateChargeSuccessHandler={createChargeSuccess}
            onConfirmChangeModelOnHold={confirmChangeModelOnHold}
            onConfirmEditModel={confirmEditModel}
          />
        )}
        <StatusAlerts
          module={module}
          cashOperationMovementSuccess={cashOperationMovementSuccess}
          updateSuccess={updateSuccess}
        />
        {modelOnHoldData && (
          <Header
            action={action}
            clientId={clientId}
            modelData={modelData && modelData}
            modelId={getModelId(saleId, purchaseId)}
            modelOnHold={modelOnHoldData}
            module={module}
            translationModel={translationModel}
            selectedCashDrawerId={selectedCashDrawerId}
            onEditModelHandler={() => updateActiveDialogHandler("editModel")}
            onChangeModelOnHoldHandler={changeSaleOnHoldHandler}
            onSearchProductHandler={searchProductHandler}
            onSetModelOnHold={setModelOnHold}
            onShowCashRegisterDialog={() =>
              updateActiveDialogHandler("cashDrawers")
            }
            onShowOnHoldModelDialog={() =>
              updateActiveDialogHandler("onHoldModelRows")
            }
          />
        )}
        <Grid container spacing={2} pb="100px">
          <Grid item xs={6} s={6} md={6} lg={6}>
            {modelIsLoading && <CircularProgress color="inherit" />}
            {fetchModelError && (
              <Alert severity="error">{t("errors.defaultError")}</Alert>
            )}
            {modelData && (
              <ModelDetails
                action={action}
                confirmAction={confirmAction}
                addModelDetailAction={modelDetailAction}
                addModelDetailResourceId={modelDetailResourceId}
                addModelDetailProduct={modelDetailProduct}
                addModelDetailProductQuantity={modelDetailProductQuantity}
                addModelDetailProductPrice={modelDetailProductPrice}
                modelData={modelData && modelData}
                modelId={getModelId(saleId, purchaseId)}
                module={module}
                token={token}
                translationModel={translationModel}
                onAlertDeleteModelDetail={() =>
                  setActiveDialog("alertDeleteModelDetail")
                }
                onConfirmResponse={confirmResponseHandler}
                onViewProduct={viewProductHandler}
              />
            )}
            {modelIsLoading && <CircularProgress color="inherit" />}
            {fetchModelError && (
              <Alert severity="error">{t("errors.defaultError")}</Alert>
            )}
            <Totals
              action={action}
              modelId={getModelId(saleId, purchaseId)}
              module={module}
              token={token}
              translationModel={translationModel}
              updateSuccess={updateSuccess}
            />
            {modelData && (
              <FooterFunctions
                action={action}
                cashDrawerLastOperation={
                  cashDrawerLastOperation &&
                  cashDrawerLastOperation.CashOperation &&
                  cashDrawerLastOperation
                }
                modelData={modelData && modelData}
                module={module}
                translationModel={translationModel}
                onAddNote={() =>
                  updateActiveDialogHandler("editModel", "addNote")
                }
                onPayModel={updateActiveDialogHandler}
                onPrintModel={() => setActiveDialog("ticket")}
              />
            )}
          </Grid>
          <Grid item xs={6} s={6} md={6} lg={6}>
            <Categories
              action={action}
              editable={editable}
              category={productCategory}
              modelData={modelData && modelData}
              module={module}
              token={token}
              translationModel={translationModel}
              onCategoryChange={categoryChangeHandler}
            />
            <Products
              action={action}
              editable={editable}
              modelData={modelData && modelData}
              module={module}
              productCategory={productCategory && productCategory}
              searchCriteria={searchCriteria && searchCriteria}
              token={token}
              onSelectProductHandler={selectProductHandler}
            />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};
export default POSGrid;
