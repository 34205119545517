import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  model,
  translationModel,
  action,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const [inactive, setInactive] = useState(false);
  const titles = ["arch", "mr", "mrs", "miss", "dr", "drFemale", "eng", "lic"];
  let loadForm = true;

  const {
    enteredValue: initials,
    setEnteredValue: setInitials,
    valueChangeHandler: initialsChangeHandler,
    hasError: initialsHaveError,
    inputBlurHandler: initialsBlurHandler,
    reset: resetInitials,
  } = useInput((value) => value.toString().trim() !== "");

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:clients.form.titles.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`clients.form.titles.${key}`])}
        </MenuItem>
      );
    }
  };

  const {
    enteredValue: title,
    setEnteredValue: setTitle,
    valueChangeHandler: titleChangeHandler,
    inputBlurHandler: titleBlurHandler,
    reset: resetTitle,
  } = useInput();

  const {
    enteredValue: name,
    setEnteredValue: setName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: lastName,
    setEnteredValue: setLastName,
    valueChangeHandler: lastNameChangeHandler,
    hasError: lastNameHasError,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: secondLastName,
    setEnteredValue: setSecondLastName,
    valueChangeHandler: secondLastNameChangeHandler,
    hasError: secondLastNameHasError,
    inputBlurHandler: secondLastNameBlurHandler,
    reset: resetSecondLastName,
  } = useInput();

  const {
    enteredValue: birthday,
    setEnteredValue: setBirthday,
    valueChangeHandler: birthdayChangeHandler,
    reset: resetBirthday,
  } = useInput();

  const {
    enteredValue: mainEmail,
    setEnteredValue: setMainEmail,
    valueChangeHandler: mainEmailChangeHandler,
    inputBlurHandler: mainEmailBlurHandler,
    hasError: mainEmailHasError,
    reset: resetMainEmail,
  } = useInput();

  const {
    enteredValue: secondaryEmail,
    setEnteredValue: setSecondaryEmail,
    valueChangeHandler: secondaryEmailChangeHandler,
    inputBlurHandler: secondaryEmailBlurHandler,
    hasError: secondaryEmailHasError,
    reset: resetSecondaryEmail,
  } = useInput();

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueChangeHandler: descriptionChangeHandler,
    hasError: descriptionHasError,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput();

  const inactiveChangeHandler = () => {
    setInactive(!inactive);
  };

  const resetInactive = () => {
    setInactive("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (nameIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let inactiveValue = null;
    if (inactive === true) {
      inactiveValue = 1;
    }
    if (inactive === false) {
      inactiveValue = null;
    }

    if (action === "add") {
      const modelData = {
        exeInitials: initials,
        exeTitle: title,
        exeFirstName: name,
        exeLastName: lastName,
        exeMotherLastName: secondLastName,
        exeBirthdate: birthday ? birthday : null,
        exeMainEmail: mainEmail,
        exeAltEmail: secondaryEmail,
        exeObservations: description,
        exeInactive: inactiveValue,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.executives.id,
        exeInitials: initials,
        exeTitle: title,
        exeFirstName: name,
        exeLastName: lastName,
        exeMotherLastName: secondLastName,
        exeBirthdate: birthday ? birthday : null,
        exeMainEmail: mainEmail,
        exeAltEmail: secondaryEmail,
        exeObservations: description,
        exeInactive: inactiveValue,
      };
      onUpdate(modelData);
    }
    resetInitials();
    resetTitle();
    resetName();
    resetLastName();
    resetSecondLastName();
    resetBirthday();
    resetMainEmail();
    resetSecondaryEmail();
    resetDescription();
    resetInactive();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      if (model && action) {
        setInitials(
          model.executives.exeInitials ? model.executives.exeInitials : ""
        );
        setTitle(model.executives.exeTitle ? model.executives.exeTitle : "");
        setName(
          model.executives.exeFirstName ? model.executives.exeFirstName : ""
        );
        setLastName(
          model.executives.exeFirstName ? model.executives.exeLastName : ""
        );
        setSecondLastName(
          model.executives.exeMotherLastName
            ? model.executives.exeMotherLastName
            : ""
        );
        setBirthday(
          model.executives.exeBirthdate ? model.executives.exeBirthdate : ""
        );
        setMainEmail(
          model.executives.exeMainEmail ? model.executives.exeMainEmail : ""
        );
        setSecondaryEmail(
          model.executives.exeAltEmail ? model.executives.exeAltEmail : ""
        );
        setDescription(
          model.executives.exeObservations
            ? model.executives.exeObservations
            : ""
        );
        if (
          model.executives.exeInactive === 1 ||
          model.executives.exeInactive === true
        ) {
          setInactive(true);
        }
        if (!model.executives.exeInactive) {
          setInactive(false);
        }
        setCaptured(
          model.executives.exeCaptured ? model.executives.exeCaptured : ""
        );
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl
          error={initialsHaveError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="initials">
            {t(translationModel + ".form.initials")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="initials"
            onChange={initialsChangeHandler}
            onBlur={initialsBlurHandler}
            value={initials}
            endAdornment={
              initials !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.initials")}
            disabled={action === "view"}
          />
          {initialsHaveError && (
            <FormHelperText id="initials-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <InputLabel id="title_label">{t("clients.form.title")}</InputLabel>
          <Select
            id="title"
            labelId="title_label"
            value={title}
            label={t("clients.form.title")}
            onChange={titleChangeHandler}
            onBlur={titleBlurHandler}
            disabled={action === "view"}
          >
            {titles.map((title, index) => findTranslation(title, index))}
          </Select>
        </FormControl>
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={lastNameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="lastName">
            {t(translationModel + ".form.lastname")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="lastName"
            onChange={lastNameChangeHandler}
            onBlur={lastNameBlurHandler}
            value={lastName}
            endAdornment={
              lastName !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.lastname")}
            disabled={action === "view"}
          />
          {lastNameHasError && (
            <FormHelperText id="lastName-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          error={secondLastNameHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="secondLastName">
            {t(translationModel + ".form.secondLastname")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="secondLastName"
            onChange={secondLastNameChangeHandler}
            onBlur={secondLastNameBlurHandler}
            value={secondLastName}
            endAdornment={
              secondLastName !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.secondLastname")}
            disabled={action === "view"}
          />
          {secondLastNameHasError && (
            <FormHelperText id="secondLastName-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <TextField
          id="birthday"
          label={t(translationModel + ".form.birthday")}
          type="date"
          value={birthday}
          onChange={birthdayChangeHandler}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={action === "view"}
        />
        <FormControl
          error={mainEmailHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="mainEmail">
            {t(translationModel + ".form.mainEmail")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="mainEmail"
            onChange={mainEmailChangeHandler}
            onBlur={mainEmailBlurHandler}
            value={mainEmail}
            endAdornment={
              mainEmail !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.mainEmail")}
            disabled={action === "view"}
          />
          {mainEmailHasError && (
            <FormHelperText id="mainEmail-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          error={secondaryEmailHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="secondaryEmail">
            {t(translationModel + ".form.secondaryEmail")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="secondaryEmail"
            onChange={secondaryEmailChangeHandler}
            onBlur={secondaryEmailBlurHandler}
            value={secondaryEmail}
            endAdornment={
              secondaryEmail !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.secondaryEmail")}
            disabled={action === "view"}
          />
          {secondaryEmailHasError && (
            <FormHelperText id="secondaryEmail-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          error={descriptionHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
          {descriptionHasError && (
            <FormHelperText id="description-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              id="inactive"
              checked={inactive === 1 || inactive === true ? true : false}
              onChange={inactiveChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.inactive")}
        />
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
