import React from "react";
import {
  Alert,
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const CompanyCabinDemand = ({ companyCabinDemand, translationModel }) => {
  const { t } = useTranslation("common");

  const createGraphData = (data) => {
    if (data.length > 0) {
      const labels = data.map((item) => item?.room?.resName);
      const dataValues = data.map((item) => parseInt(item.countByCabin, 10));
      const getRandomColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return "#" + "0".repeat(6 - randomColor.length) + randomColor;
      };
      const colors = data.map(() => getRandomColor());

      return {
        labels: labels,
        datasets: [
          {
            label: t(translationModel + ".form.graphs.cabinDemand.label"),
            data: dataValues,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      };
    }
  };

  return (
    <Grid container>
      <Grid item sx={{ marginTop: "30px" }} xs={12} s={12} md={12} lg={12}>
        <Card variant="outlined" sx={{ maxWidth: 360 }}>
          <Box sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography gutterBottom variant="h5" component="div">
                {t(translationModel + ".form.graphs.cabinDemand.title")}
              </Typography>
            </Stack>
            <Typography color="text.secondary" variant="body2">
              {t(translationModel + ".form.graphs.cabinDemand.description")}
            </Typography>
            <Divider sx={{ paddingTop: "10px", marginBottom: "10px" }} />
            {companyCabinDemand?.Bookings?.length === 0 && (
              <Alert severity="warning">{t("errors.noContent")}</Alert>
            )}
            {companyCabinDemand && (
              <Pie
                data={
                  companyCabinDemand?.Bookings &&
                  createGraphData(companyCabinDemand.Bookings)
                }
              />
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyCabinDemand;
