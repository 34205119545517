import React, { useEffect, Fragment } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  FormHelperText,
  Stack,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import useInput from "../../hooks/use-input";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

const ProductDetails = ({ action, isSalesModule, onSelect, onCancel }) => {
  const { t } = useTranslation("common");
  let refreshGrid = true;

  const {
    enteredValue: quantity,
    setEnteredValue: setQuantity,
    valueIsValid: quantityIsValid,
    valueChangeHandler: quantityChangeHandler,
    hasError: quantityHasError,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value.toString().trim() > 0);

  const {
    enteredValue: price,
    setEnteredValue: setPrice,
    valueChangeHandler: priceChangeHandler,
    hasError: priceHasError,
    inputBlurHandler: priceBlurHandler,
    reset: resetPrice,
  } = useInput();

  let formIsValid = false;

  if (quantityIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    onSelect("", quantity, price);
    setQuantity("");
    setPrice("");
  };
  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (refreshGrid === true) {
      setQuantity(1);
    }
    // eslint-disable-next-line
  }, [refreshGrid]);

  return (
    <Grid sx={{ minWidth: "600px" }} container>
      <Grid
        className={styles.Resources}
        item
        sx={{ marginTop: "30px" }}
        xs={12}
        s={12}
        md={12}
        lg={12}
      >
        <Fragment>
          <Stack
            className={`${styles["client-form-stack"]}`}
            spacing={1}
            justifyContent="center"
            sx={{ paddingTop: "20px", maxWidth: "90%" }}
          >
            <FormControl
              error={quantityHasError ? true : false}
              variant="outlined"
            >
              <InputLabel htmlFor="quantity">
                {t("salesDetails.columns.quantity")}*
              </InputLabel>
              <FilledInput
                sx={{ background: "white" }}
                id="quantity"
                onChange={quantityChangeHandler}
                onBlur={quantityBlurHandler}
                value={quantity}
                endAdornment={
                  quantity !== "" &&
                  action !== "view" && (
                    <InputAdornment position="start">
                      <Clear onClick={resetQuantity} />
                    </InputAdornment>
                  )
                }
                type="number"
                label={t("salesDetails.columns.quantity")}
                disabled={action === "view"}
              />
              {quantityHasError && (
                <FormHelperText id="quantity-helper-text">
                  {t("errors.fieldError")}
                </FormHelperText>
              )}
            </FormControl>
            {!isSalesModule && (
              <FormControl
                error={priceHasError ? true : false}
                variant="outlined"
              >
                <InputLabel htmlFor="price">
                  {t("salesDetails.columns.unitPrice")}
                </InputLabel>
                <FilledInput
                  sx={{ background: "white" }}
                  id="price"
                  onChange={priceChangeHandler}
                  onBlur={priceBlurHandler}
                  value={price}
                  endAdornment={
                    price !== "" &&
                    action !== "view" && (
                      <InputAdornment position="start">
                        <Clear onClick={resetPrice} />
                      </InputAdornment>
                    )
                  }
                  type="number"
                  label={t("salesDetails.columns.unitPrice")}
                  disabled={action === "view"}
                />
                {priceHasError && (
                  <FormHelperText id="price-helper-text">
                    {t("errors.fieldError")}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            {action !== "view" && (
              <Button
                id="submit_form"
                variant="contained"
                onClick={formSubmitHandler}
                disabled={!formIsValid}
              >
                {t("buttons.add", {
                  model: t("products.model"),
                })}
              </Button>
            )}
            <Button
              id="cancel_form"
              variant="contained"
              color={action === "view" ? "inherit" : "warning"}
              onClick={cancelHandler}
            >
              {action === "view" && t("buttons.back")}
              {action !== "view" && t("buttons.cancel")}
            </Button>
          </Stack>
        </Fragment>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
