import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import i18next from "i18next";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const categories = [1, 2, 3];

const ModelForm = ({
  model,
  translationModel,
  action,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const [inactive, setInactive] = useState(false);
  let loadForm = true;

  const {
    enteredValue: category,
    setEnteredValue: setCategory,
    hasError: categoryHasError,
    valueChangeHandler: categoryChangeHandler,
    inputBlurHandler: categoryBlurHandler,
    reset: resetCategory,
  } = useInput((value) => value.toString().trim() !== "");

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:resourceCategories.form.categories.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`resourceCategories.form.categories.${key}`])}
        </MenuItem>
      );
    }
  };

  const {
    enteredValue: image,
    setEnteredValue: setImage,
    valueChangeHandler: imageChangeHandler,
    inputBlurHandler: imageBlurHandler,
    reset: resetImage,
  } = useInput();

  const {
    enteredValue: name,
    setEnteredValue: setName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput();

  const inactiveChangeHandler = () => {
    setInactive(!inactive);
  };

  const resetInactive = () => {
    setInactive("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (nameIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let inactiveValue = null;
    if (inactive === true) {
      inactiveValue = 1;
    }
    if (inactive === false) {
      inactiveValue = null;
    }

    if (action === "add") {
      const modelData = {
        resTypCategory: category,
        resTypName: image,
        resTypImage: name,
        resTypDescription: description,
        resTypInactive: inactiveValue,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.resourceType.id,
        resTypCategory: category,
        resTypName: image,
        resTypImage: name,
        resTypDescription: description,
        resTypInactive: inactiveValue,
      };
      onUpdate(modelData);
    }
    resetCategory();
    resetImage();
    resetName();
    resetDescription();
    resetInactive();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      if (model && action) {
        setCategory(
          model.resourceType.resTypCategory
            ? model.resourceType.resTypCategory
            : ""
        );
        setImage(
          model.resourceType.resTypImage ? model.resourceType.resTypImage : ""
        );
        setName(
          model.resourceType.resTypName ? model.resourceType.resTypName : ""
        );
        setDescription(
          model.resourceType.resTypDescription
            ? model.resourceType.resTypDescription
            : ""
        );
        if (
          model.resourceType.resTypInactive === 1 ||
          model.resourceType.resTypInactive === true
        ) {
          setInactive(true);
        }
        if (!model.resourceType.resTypInactive) {
          setInactive(false);
        }
        setCaptured(
          model.resourceType.resTypCaptured
            ? model.resourceType.resTypCaptured
            : ""
        );
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl error={categoryHasError ? true : false}>
          <InputLabel id="category_label">
            {t(translationModel + ".form.category")}
          </InputLabel>
          <Select
            id="category"
            labelId="category_label"
            value={category}
            label={t(translationModel + ".form.category")}
            onChange={categoryChangeHandler}
            onBlur={categoryBlurHandler}
            disabled={action === "view"}
          >
            {categories.map((category, index) =>
              findTranslation(category, index)
            )}
          </Select>
          {categoryHasError && (
            <FormHelperText id="description-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="image">
            {t(translationModel + ".form.image")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="image"
            onChange={imageChangeHandler}
            onBlur={imageBlurHandler}
            value={image}
            endAdornment={
              image !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetImage} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.image")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              id="inactive"
              checked={inactive === 1 || inactive === true ? true : false}
              onChange={inactiveChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.inactive")}
        />
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
