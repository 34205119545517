import React from "react";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { CalendarMonth, WhatsApp, Cabin } from "@mui/icons-material";
const LoginUpdatesContainer = () => {
  const { t } = useTranslation("common");
  return (
    <Stack
      sx={{
        background: "#f5f5f5",
        padding: "20px 25px 30px 25px",
        border: "1px solid #ddd",
        borderRadius: "10px",
        marginBottom: "15%",
        maxWidth: "500px",
        minWidth: "450px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      spacing={1}
      justifyContent="center"
    >
      <Box m={1} p={1}>
        <Typography variant="h5">
          {t("login.updates.title")} {process.env.REACT_APP_VERSION}
        </Typography>
        <Typography variant="subtitle1">
          {t("login.updates.subtitle")}
        </Typography>
      </Box>
      <List sx={{ background: "#fcf8e3" }}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <CalendarMonth />
            </ListItemIcon>
            <ListItemText primary={t("login.updates.updatesBookings")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <WhatsApp />
            </ListItemIcon>
            <ListItemText primary={t("login.updates.updatesWhatsapp")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Cabin />
            </ListItemIcon>
            <ListItemText primary={t("login.updates.updatesBookingsByCabin")} />
          </ListItemButton>
        </ListItem>
      </List>
      <List sx={{ background: "#d9edf7" }}>
        <ListItem disablePadding>
          <Box m={1} p={1}>
            <Typography variant="h5">{t("login.loginHelp.title")}</Typography>
            <Typography variant="body2">
              {t("login.loginHelp.description")}
            </Typography>
          </Box>
          <ListItemButton>
            <ListItemText></ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );
};
export default LoginUpdatesContainer;
