import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = (props) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const model = props.model;
  const translationModel = props.translationModel;
  const action = props.action;
  const [showProductField, setShowProductField] = useState(false);
  let loadForm = true;

  const {
    data: products,
    isLoading: productsAreLoading,
    sendRequest: fetchProducts,
  } = useHttp();

  const getProducts = (warehouseId) => {
    let url = "/products/missing_in_warehouse?warehouseId=" + warehouseId;
    if (action === "edit" || action === "view") {
      url = "/products/";
    }
    fetchProducts({
      url: process.env.REACT_APP_API_SERVER + url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: product,
    setEnteredValue: setProduct,
    valueIsValid: productIsValid,
    valueChangeHandler: productChangeHandler,
    hasError: productHasError,
    inputBlurHandler: productBlurHandler,
    reset: resetProduct,
  } = useInput((value) => value.toString().trim() !== "");

  const showProductFieldHandler = () => {
    setShowProductField(false);
    if (warehouse || (warehouse && product)) {
      setShowProductField(true);
    }
  };

  const {
    data: warehouses,
    isLoading: warehousesAreLoading,
    sendRequest: fetchWarehouses,
  } = useHttp();

  const getWarehouses = () => {
    fetchWarehouses({
      url: process.env.REACT_APP_API_SERVER + "/warehouses",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: warehouse,
    setEnteredValue: setWarehouse,
    valueIsValid: warehouseIsValid,
    valueChangeHandler: warehouseChangeHandler,
    hasError: warehouseHasError,
    inputBlurHandler: warehouseBlurHandler,
    reset: resetWarehouse,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: location,
    setEnteredValue: setLocation,
    valueChangeHandler: locationChangeHandler,
    inputBlurHandler: locationBlurHandler,
    reset: resetLocation,
  } = useInput();

  const {
    enteredValue: stock,
    setEnteredValue: setStock,
    valueChangeHandler: stockChangeHandler,
    inputBlurHandler: stockBlurHandler,
    reset: resetStock,
  } = useInput();

  const {
    enteredValue: minStock,
    setEnteredValue: setMinStock,
    valueChangeHandler: minStockChangeHandler,
    inputBlurHandler: minStockBlurHandler,
    reset: resetMinStock,
  } = useInput();

  const {
    enteredValue: maxStock,
    setEnteredValue: setMaxStock,
    valueChangeHandler: maxStockChangeHandler,
    inputBlurHandler: maxStockBlurHandler,
    reset: resetMaxStock,
  } = useInput();

  const {
    enteredValue: reorderPoint,
    setEnteredValue: setReorderPoint,
    valueChangeHandler: reorderPointChangeHandler,
    inputBlurHandler: reorderPointBlurHandler,
    reset: resetReorderPoint,
  } = useInput();

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (warehouseIsValid && productIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (action === "add") {
      const modelData = {
        stoWarID: warehouse,
        stoProductID: product,
        stoProductLocation: location ? location : null,
        stoStock: stock ? stock : 0,
        stoMinimumStock: minStock ? minStock : null,
        stoMaximumStock: maxStock ? maxStock : null,
        stoReorderPoint: reorderPoint ? reorderPoint : null,
      };
      props.onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.Stock.id,
        stoWarID: warehouse,
        stoProductID: product,
        stoProductLocation: location ? location : null,
        stoStock: stock ? stock : 0,
        stoMinimumStock: minStock ? minStock : null,
        stoMaximumStock: maxStock ? maxStock : null,
        stoReorderPoint: reorderPoint ? reorderPoint : null,
      };
      props.onUpdate(modelData);
    }
    resetProduct();
    resetWarehouse();
    resetLocation();
    resetStock();
    resetMinStock();
    resetMaxStock();
    resetReorderPoint();
    resetCaptured();
  };

  const cancelHandler = () => {
    props.onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      getWarehouses();
      showProductFieldHandler();
      if (warehouse || action === "add") {
        getProducts(warehouse);
        showProductFieldHandler();
      }
      if (action === "edit" || action === "view") {
        getProducts();
      }
      if (model && action) {
        setProduct(model.Stock.stoProductID ? model.Stock.stoProductID : "");
        setWarehouse(model.Stock.stoWarID ? model.Stock.stoWarID : "");
        setLocation(
          model.Stock.stoProductLocation ? model.Stock.stoProductLocation : ""
        );
        setStock(
          model.Stock.stoStock || model.Stock.stoStock >= 0
            ? model.Stock.stoStock
            : ""
        );
        setMinStock(
          model.Stock.stoMinimumStock || model.Stock.stoMinimumStock >= 0
            ? model.Stock.stoMinimumStock
            : ""
        );
        setMaxStock(
          model.Stock.stoMaximumStock || model.Stock.stoMaximumStock >= 0
            ? model.Stock.stoMaximumStock
            : ""
        );
        setReorderPoint(
          model.Stock.stoReorderPoint || model.Stock.stoReorderPoint >= 0
            ? model.Stock.stoReorderPoint
            : ""
        );
        setCaptured(model.Stock.stoCaptured ? model.Stock.stoCaptured : "");
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model, warehouse]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {warehousesAreLoading && <CircularProgress color="inherit" />}
        {warehouses && (
          <FormControl error={warehouseHasError ? true : false}>
            <InputLabel id="warehouse_label">
              {t(translationModel + ".form.warehouse")}*
            </InputLabel>
            <Select
              id="warehouse"
              labelId="warehouse_label"
              value={warehouse}
              label={t(translationModel + ".form.warehouse")}
              onChange={warehouseChangeHandler}
              onBlur={warehouseBlurHandler}
              disabled={action === "view" || action === "edit"}
            >
              {warehouses &&
                warehouses.Warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.warName}
                  </MenuItem>
                ))}
            </Select>
            {warehouseHasError && (
              <FormHelperText id="type-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {productsAreLoading && <CircularProgress color="inherit" />}
        {showProductField && products && (
          <FormControl error={productHasError ? true : false}>
            <InputLabel id="product_label">
              {t(translationModel + ".form.product")}*
            </InputLabel>
            <Select
              id="product"
              labelId="product_label"
              value={product}
              label={t(translationModel + ".form.product")}
              onChange={productChangeHandler}
              onBlur={productBlurHandler}
              disabled={action === "view" || action === "edit"}
            >
              {products &&
                products.Products.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {`${product.id} - ${product.proCommercialName}`}
                  </MenuItem>
                ))}
            </Select>
            {productHasError && (
              <FormHelperText id="type-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel htmlFor="location">
            {t(translationModel + ".form.location")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="location"
            onChange={locationChangeHandler}
            onBlur={locationBlurHandler}
            value={location}
            endAdornment={
              location !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetLocation} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.location")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="stock">
            {t(translationModel + ".form.stock")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="stock"
            onChange={stockChangeHandler}
            onBlur={stockBlurHandler}
            value={stock}
            endAdornment={
              stock !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetStock} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.stock")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="minStock">
            {t(translationModel + ".form.minStock")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="minStock"
            onChange={minStockChangeHandler}
            onBlur={minStockBlurHandler}
            value={minStock}
            endAdornment={
              minStock !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetMinStock} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.minStock")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="maxStock">
            {t(translationModel + ".form.maxStock")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="maxStock"
            onChange={maxStockChangeHandler}
            onBlur={maxStockBlurHandler}
            value={maxStock}
            endAdornment={
              maxStock !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetMaxStock} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.maxStock")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="reorderPoint">
            {t(translationModel + ".form.reorderPoint")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="reorderPoint"
            onChange={reorderPointChangeHandler}
            onBlur={reorderPointBlurHandler}
            value={reorderPoint}
            endAdornment={
              reorderPoint !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetReorderPoint} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.reorderPoint")}
            disabled={action === "view"}
          />
        </FormControl>
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
