import React from "react";
import { Card, Tooltip, Typography } from "@mui/material";
import { CheckCircle, HelpOutlined } from "@mui/icons-material/";
import { formatDistanceStrict, format } from "date-fns";

const Booking = ({ booking, onViewBooking }) => {
  const bookingDuration =
    booking?.start && booking?.end
      ? formatDistanceStrict(new Date(booking.start), new Date(booking.end), {
          unit: "minute",
        })
      : null;
  const bookingHeight = (bookingDuration.split(" ")[0] / 60) * 80;
  const bookingStyle = (backgroundColor) => {
    return {
      color: "white",
      background: backgroundColor ? backgroundColor : "#035fc0",
      marginTop: "-7px",
      marginLeft: "50px",
      paddingLeft: "3px",
      paddingRight: "3px",
      height: `${bookingHeight}px`,
      position: "absolute",
      border: "none",
      width: "115px",
      overflow: "hidden",
    };
  };
  const viewBookingHandler = (bookingId) => {
    onViewBooking(bookingId);
  };
  const bookingTooltip = (bookingInfo) => {
    const tooltip = (
      <>
        <Typography color="inherit">
          {bookingInfo?.id} {bookingInfo?.Product?.proCommercialName}
        </Typography>
        <Typography color="inherit">
          {bookingInfo?.Client?.cliFirstName} {bookingInfo?.Client?.cliLastName}
        </Typography>
        <Typography color="inherit">{bookingInfo?.room?.resName}</Typography>
        <Typography color="inherit">{`(${format(
          new Date(bookingInfo.start),
          "HH:mm - "
        )} ${format(new Date(bookingInfo?.end), "HH:mm")})`}</Typography>
        <Typography color="inherit">{bookingDuration}</Typography>
      </>
    );
    return tooltip;
  };
  return (
    <Tooltip title={bookingTooltip(booking)} placement="top">
      <Card
        id={"booking_" + booking.id}
        data-testid="BookingCard"
        sx={bookingStyle(booking?.Product?.proCatColor)}
        variant="outlined"
        onClick={() => booking && viewBookingHandler(booking.id)}
      >
        {booking?.status_name?.sesStaName &&
        booking?.status_name?.sesStaName === "Confirmada" ? (
          <Typography mt="3px" mb="-12px" align="right">
            <CheckCircle fontSize="small" />
          </Typography>
        ) : (
          booking?.status_name?.sesStaName &&
          booking?.status_name?.sesStaName !== "Confirmada" && (
            <Typography mt="3px" mb="-12px" align="right">
              <HelpOutlined fontSize="small" />
            </Typography>
          )
        )}
        {booking?.Client?.cliFirstName &&
          booking?.Client?.cliLastName &&
          `${booking.Client.cliFirstName} ${booking.Client.cliLastName}`}
        {booking?.Product?.proCommercialName &&
          ` - ${booking.Product.proCommercialName}`}
      </Card>
    </Tooltip>
  );
};

export default Booking;
