import React, { useState, useEffect, Fragment } from "react";
import useHttp from "../../../hooks/use-http";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Tooltip,
  Fab,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Search, Delete, Add } from "@mui/icons-material";
import styles from "./styles.module.css";
import FormDialog from "../Dialogs/Dialog";

const Details = (props) => {
  const { t } = useTranslation("common");
  const token = props.token;
  const stockMovementId = props.stockMovementId;
  const translationModel = props.translationModel;
  const action = props.action;
  const createIsLoading = props.createIsLoading;
  const createHasError = props.createHasError;
  const disabled = props.disabled;
  const [formDialog, setFormDialog] = useState("");
  const [stockMovementDetail, setStockMovementDetail] = useState(null);
  const [product, setProduct] = useState(null);
  const [productQuantity, setProductQuantity] = useState(null);
  const [stockMovement, setStockMovement] = useState(null);

  const {
    isLoading: stockMovementDetailsAreLoading,
    error: fetchStockMovementDetailsError,
    data: stockMovementDetails,
    sendRequest: sendStockMovementDetailsGetRequest,
  } = useHttp();

  const getStockMovementDetailsHandler = async () => {
    await sendStockMovementDetailsGetRequest({
      url:
        process.env.REACT_APP_API_SERVER +
        "/" +
        "stock_updates/" +
        stockMovementId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    error: fetchDeleteStockMovementDetailError,
    sendRequest: sendDeleteStockMovementDetailRequest,
  } = useHttp();

  const deleteStockMovementDetailHandler = async () => {
    await sendDeleteStockMovementDetailRequest({
      url: process.env.REACT_APP_API_SERVER + "/stock_update_details/",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: stockMovementDetail },
    });

    if (!fetchDeleteStockMovementDetailError) {
      props.onDeleteRow(product, productQuantity, stockMovement);
    }
  };

  const dialogHandler = (
    type,
    stockMovementDetailId,
    productId,
    productQuantity,
    stockMovementId
  ) => {
    setFormDialog(type);
    setStockMovementDetail(stockMovementDetailId);
    setProduct(productId);
    setProductQuantity(productQuantity);
    setStockMovement(stockMovementId);
  };

  const dialogConfirmHandler = () => {
    deleteStockMovementDetailHandler();
    setFormDialog("");
  };

  const viewHandler = (productId) => {
    props.onViewProduct(productId);
  };

  const updateProductQuantityHandler = (
    productId,
    operation,
    stockMovementDetailId
  ) => {
    props.onUpdateProductQuantity(productId, operation, stockMovementDetailId);
  };

  const columns = [
    { title: t(translationModel + ".form.columns.StockMovementDetails.name") },
    {
      title: t(
        translationModel + ".form.columns.StockMovementDetails.quantity"
      ),
    },
    { title: "" },
  ];

  const rows = [
    {
      content: (params) => (
        <Stack direction="row" spacing={2}>
          <Tooltip
            key={params.id}
            title={
              <Typography variant="h5">{params.stoUpdDetProdName}</Typography>
            }
            placement="top"
          >
            <Typography
              variant="h7"
              sx={{
                maxWidth: "150px",
                paddingRight: "5px",
              }}
            >
              {params.stoUpdDetProdName.length > 60
                ? params.stoUpdDetProdName.slice(0, 60) + "..."
                : params.stoUpdDetProdName}
            </Typography>
          </Tooltip>
          <Fab
            id={"stockMovement_details_view_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{
              display: "flex",
              justifyContent: "center",
              minWidth: "40px",
              zIndex: "1",
            }}
            size="small"
            aria-label="view"
            onClick={() => viewHandler(params.stoUpdDetProdID)}
          >
            <Search fontSize="small" />
          </Fab>
        </Stack>
      ),
    },
    {
      content: (params) => (
        <Stack direction="row">
          <Fab
            id={"stockMovement_details_subtract_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{ marginRight: "5px", zIndex: "1" }}
            size="small"
            aria-label="view"
            onClick={() =>
              updateProductQuantityHandler(
                params.stoUpdDetProdID,
                "subtract",
                params.id
              )
            }
            color="warning"
            disabled={action === "view" || disabled ? true : false}
          >
            <Typography variant="h5">{"-"}</Typography>
          </Fab>
          <TextField
            id={"stockMovement_detail_quantity_" + params.id}
            value={params.stoUpdDetQuantity}
            sx={{ width: "50px", paddingRight: "5px" }}
            disabled
          />
          <Fab
            id={"stockMovement_details_add_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{ marginRight: "5px", zIndex: "1" }}
            size="small"
            aria-label="view"
            onClick={() =>
              updateProductQuantityHandler(
                params.stoUpdDetProdID,
                "add",
                params.id
              )
            }
            color="success"
            disabled={action === "view" || disabled ? true : false}
          >
            <Add fontSize="small" />
          </Fab>
        </Stack>
      ),
    },
    {
      content: (params) => (
        <Fragment>
          <Fab
            id={"stockMovement_details_delete_action_" + params.id}
            className={`${styles["action-buttons"]}`}
            sx={{ marginRight: "10px", zIndex: "1" }}
            size="small"
            aria-label="view"
            onClick={() =>
              dialogHandler(
                "deleteDetail",
                params.id,
                params.stoUpdDetProdID,
                params.stoUpdDetQuantity,
                params.stoUpdDetStoUpdID
              )
            }
            color="error"
            disabled={action === "view" || disabled ? true : false}
          >
            <Delete fontSize="small" />
          </Fab>
        </Fragment>
      ),
    },
  ];

  useEffect(() => {
    getStockMovementDetailsHandler();
    // eslint-disable-next-line
  }, [action]);

  return (
    <Fragment>
      {formDialog && (
        <FormDialog
          translationModel={translationModel}
          formDialog={formDialog}
          onCancel={dialogHandler}
          onConfirm={dialogConfirmHandler}
        />
      )}
      {(stockMovementDetailsAreLoading || createIsLoading) && (
        <CircularProgress color="inherit" />
      )}
      {(fetchStockMovementDetailsError || createHasError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      <Paper id="stockMovement_details_paper" className={styles.datagrid}>
        {!stockMovementDetailsAreLoading &&
          !createIsLoading &&
          stockMovementDetails &&
          stockMovementDetails.StockUpdate.StockUpdateDetails &&
          stockMovementDetails.StockUpdate.StockUpdateDetails.length <= 0 &&
          !stockMovementDetailsAreLoading && (
            <Alert
              id="stockMovement_details_no_content_alert"
              severity="warning"
            >
              {t("errors.noContent")}
            </Alert>
          )}
        {stockMovementDetails &&
          !stockMovementDetailsAreLoading &&
          stockMovementDetails.StockUpdate.StockUpdateDetails.length && (
            <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        sx={{ fontWeight: "bold" }}
                        key={index}
                        align="center"
                      >
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockMovementDetails.StockUpdate.StockUpdateDetails.map(
                    (stockMovementDetail) => (
                      <TableRow key={stockMovementDetail.id}>
                        {rows.map((row, index) => (
                          <TableCell key={index} align="center">
                            {row.content(stockMovementDetail)}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </Paper>
    </Fragment>
  );
};

export default Details;
