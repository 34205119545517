import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import {
  Alert,
  Button,
  Box,
  CircularProgress,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  CalendarMonth,
  Clear,
  MonetizationOn,
  PersonPin,
  PointOfSale,
  Savings,
  ShoppingCartCheckout,
} from "@mui/icons-material/";
import { format } from "date-fns";
import Totals from "./Totals";
import CompanyClientsByCategory from "./Graphs/CompanyClientsByCategory";
import CompanyPersonnelDemand from "./Graphs/CompanyPersonnelDemand";
import CompanySalesByProductType from "./Graphs/CompanySalesByProductType";
import CompanyChargesByPaymentType from "./Graphs/CompanyChargesByPaymentType";
import CompanyCabinDemand from "./Graphs/CompanyCabinDemand";
import CompanyBookingsStatus from "./Graphs/CompanyBookingsStatus";

const Company = ({ token, onSetPageTitle }) => {
  const { t } = useTranslation("common");
  const modelName = "dashboard";
  const translationModel = "dashboard";
  const [updateSuccess, setUpdateSuccess] = useState(false);
  let loadForm = true;

  const setPageTitleHandler = () => {
    onSetPageTitle(t(`${translationModel}.title`));
  };

  const {
    enteredValue: year,
    setEnteredValue: setYear,
    valueIsValid: yearIsValid,
    valueChangeHandler: yearChangeHandler,
    hasError: yearHasError,
    inputBlurHandler: yearBlurHandler,
    reset: resetYear,
  } = useInput((value) => value <= format(new Date(), "yyyy"));

  let formIsValid = false;

  if (yearIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    setTimeout(() => {
      getCompanyTotalSales();
    }, 500);
    setTimeout(() => {
      getCompanyTotalPurchases();
    }, 1000);
    setTimeout(() => {
      getCompanyTotalClients();
    }, 1500);
    setTimeout(() => {
      getCompanyTotalCharges();
    }, 2000);
    setTimeout(() => {
      getCompanyTotalPayments();
    }, 2500);
    setTimeout(() => {
      getCompanyTotalBookings();
    }, 3000);
    setTimeout(() => {
      getCompanyClientsByCategory();
    }, 3500);
    setTimeout(() => {
      getCompanyPersonnelDemand();
    }, 4000);
    setTimeout(() => {
      getCompanyCabinDemand();
    }, 4500);
    setTimeout(() => {
      getCompanyBookingsStatus();
    }, 5000);
    setTimeout(() => {
      getCompanySalesByProductType();
    }, 5500);
    setTimeout(() => {
      getCompanySalesByServiceType();
    }, 6000);
    setTimeout(() => {
      getCompanyChargesByPaymentType();
    }, 6500);
    setUpdateSuccess(true);
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 7000);
  };

  const convertToMoney = (val) => {
    return (
      "$" +
      Number(val)?.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  const {
    data: companyTotalSales,
    isLoading: companyTotalSalesAreLoading,
    error: fetchCompanyTotalSalesError,
    sendRequest: fetchCompanyTotalSales,
  } = useHttp();

  const getCompanyTotalSales = () => {
    const url = `${process.env.REACT_APP_API_SERVER}/dashboard/total_sales?year=${year}`;
    fetchCompanyTotalSales({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyTotalPurchases,
    isLoading: companyTotalPurchasesAreLoading,
    error: fetchCompanyTotalPurchasesError,
    sendRequest: fetchCompanyTotalPurchases,
  } = useHttp();

  const getCompanyTotalPurchases = () => {
    const url = `${process.env.REACT_APP_API_SERVER}/dashboard/total_Purchases?year=${year}`;
    fetchCompanyTotalPurchases({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyTotalClients,
    isLoading: companyTotalClientsAreLoading,
    error: fetchCompanyTotalClientsError,
    sendRequest: fetchCompanyTotalClients,
  } = useHttp();

  const getCompanyTotalClients = () => {
    fetchCompanyTotalClients({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/total_clients?year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyTotalCharges,
    isLoading: companyTotalChargesAreLoading,
    error: fetchCompanyTotalChargesError,
    sendRequest: fetchCompanyTotalCharges,
  } = useHttp();

  const getCompanyTotalCharges = () => {
    const url = `${process.env.REACT_APP_API_SERVER}/dashboard/total_charges?year=${year}`;
    fetchCompanyTotalCharges({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyTotalPayments,
    isLoading: companyTotalPaymentsAreLoading,
    error: fetchCompanyTotalPaymentsError,
    sendRequest: fetchCompanyTotalPayments,
  } = useHttp();

  const getCompanyTotalPayments = () => {
    const url = `${process.env.REACT_APP_API_SERVER}/dashboard/total_payments?year=${year}`;
    fetchCompanyTotalPayments({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyTotalBookings,
    isLoading: companyTotalBookingsAreLoading,
    error: fetchCompanyTotalBookingsError,
    sendRequest: fetchCompanyTotalBookings,
  } = useHttp();

  const getCompanyTotalBookings = () => {
    fetchCompanyTotalBookings({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/total_bookings?year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyClientsByCategory,
    isLoading: companyClientsByCategoryAreLoading,
    error: fetchCompanyClientsByCategoryError,
    sendRequest: fetchCompanyClientsByCategory,
  } = useHttp();

  const getCompanyClientsByCategory = () => {
    fetchCompanyClientsByCategory({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/clients_category?year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyPersonnelDemand,
    isLoading: companyPersonnelDemandAreLoading,
    error: fetchCompanyPersonnelDemandError,
    sendRequest: fetchCompanyPersonnelDemand,
  } = useHttp();

  const getCompanyPersonnelDemand = () => {
    fetchCompanyPersonnelDemand({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/bookings_by_therapist?year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyCabinDemand,
    isLoading: companyCabinDemandAreLoading,
    error: fetchCompanyCabinDemandError,
    sendRequest: fetchCompanyCabinDemand,
  } = useHttp();

  const getCompanyCabinDemand = () => {
    fetchCompanyCabinDemand({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/bookings_by_cabin?year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyBookingsStatus,
    isLoading: companyBookingsStatusAreLoading,
    error: fetchCompanyBookingsStatusError,
    sendRequest: fetchCompanyBookingsStatus,
  } = useHttp();

  const getCompanyBookingsStatus = () => {
    fetchCompanyBookingsStatus({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/bookings_by_status?year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companySalesByProductType,
    isLoading: companySalesByProductTypeAreLoading,
    error: fetchCompanySalesByProductTypeError,
    sendRequest: fetchCompanySalesByProductType,
  } = useHttp();

  const getCompanySalesByProductType = () => {
    fetchCompanySalesByProductType({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/product_sales?type=product&year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companySalesByServiceType,
    isLoading: companySalesByServiceTypeAreLoading,
    error: fetchCompanySalesByServiceTypeError,
    sendRequest: fetchCompanySalesByServiceType,
  } = useHttp();

  const getCompanySalesByServiceType = () => {
    fetchCompanySalesByServiceType({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/product_sales?type=service&year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: companyChargesByPaymentType,
    isLoading: companyChargesByPaymentTypeAreLoading,
    error: fetchCompanyChargesByPaymentTypeError,
    sendRequest: fetchCompanyChargesByPaymentType,
  } = useHttp();

  const getCompanyChargesByPaymentType = () => {
    fetchCompanyChargesByPaymentType({
      url: `${process.env.REACT_APP_API_SERVER}/dashboard/total_charges/payment_type/?year=${year}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  useEffect(() => {
    if (loadForm) {
      setYear(format(new Date(), "yyyy"));
      setPageTitleHandler();
      getCompanyTotalSales();
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm]);

  useEffect(() => {
    if (
      companyTotalSales &&
      !companyTotalSalesAreLoading &&
      !companyTotalPurchases
    ) {
      setTimeout(() => {
        getCompanyTotalPurchases();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [companyTotalSales, companyTotalSalesAreLoading, companyTotalPurchases]);

  useEffect(() => {
    if (
      companyTotalPurchases &&
      !companyTotalPurchasesAreLoading &&
      !companyTotalClients
    ) {
      setTimeout(() => {
        getCompanyTotalClients();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companyTotalPurchases,
    companyTotalPurchasesAreLoading,
    companyTotalClients,
  ]);

  useEffect(() => {
    if (
      companyTotalClients &&
      !companyTotalClientsAreLoading &&
      !companyTotalCharges
    ) {
      setTimeout(() => {
        getCompanyTotalCharges();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [companyTotalClients, companyTotalClientsAreLoading, companyTotalCharges]);

  useEffect(() => {
    if (
      companyTotalCharges &&
      !companyTotalChargesAreLoading &&
      !companyTotalPayments
    ) {
      setTimeout(() => {
        getCompanyTotalPayments();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companyTotalCharges,
    companyTotalChargesAreLoading,
    companyTotalPayments,
  ]);

  useEffect(() => {
    if (
      companyTotalPayments &&
      !companyTotalPaymentsAreLoading &&
      !companyTotalBookings
    ) {
      setTimeout(() => {
        getCompanyTotalBookings();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companyTotalPayments,
    companyTotalPaymentsAreLoading,
    companyTotalBookings,
  ]);

  useEffect(() => {
    if (
      companyTotalBookings &&
      !companyTotalBookingsAreLoading &&
      !companyClientsByCategory
    ) {
      setTimeout(() => {
        getCompanyClientsByCategory();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companyTotalBookings,
    companyTotalBookingsAreLoading,
    companyClientsByCategory,
  ]);

  useEffect(() => {
    if (
      companyClientsByCategory &&
      !companyClientsByCategoryAreLoading &&
      !companyPersonnelDemand
    ) {
      setTimeout(() => {
        getCompanyPersonnelDemand();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companyClientsByCategory,
    companyClientsByCategoryAreLoading,
    companyPersonnelDemand,
  ]);

  useEffect(() => {
    if (
      companyPersonnelDemand &&
      !companyPersonnelDemandAreLoading &&
      !companyCabinDemand
    ) {
      setTimeout(() => {
        getCompanyCabinDemand();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companyPersonnelDemand,
    companyPersonnelDemandAreLoading,
    companyCabinDemand,
  ]);

  useEffect(() => {
    if (
      companyCabinDemand &&
      !companyCabinDemandAreLoading &&
      !companyBookingsStatus
    ) {
      setTimeout(() => {
        getCompanyBookingsStatus();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [companyCabinDemand, companyCabinDemandAreLoading, companyBookingsStatus]);

  useEffect(() => {
    if (
      companyBookingsStatus &&
      !companyBookingsStatusAreLoading &&
      !companySalesByProductType
    ) {
      setTimeout(() => {
        getCompanySalesByProductType();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companyBookingsStatus,
    companyBookingsStatusAreLoading,
    companyBookingsStatus,
  ]);

  useEffect(() => {
    if (
      companySalesByProductType &&
      !companySalesByProductTypeAreLoading &&
      !companySalesByServiceType
    ) {
      setTimeout(() => {
        getCompanySalesByServiceType();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companySalesByProductType,
    companySalesByProductTypeAreLoading,
    companySalesByServiceType,
  ]);

  useEffect(() => {
    if (
      companySalesByServiceType &&
      !companySalesByServiceTypeAreLoading &&
      !companyChargesByPaymentType
    ) {
      setTimeout(() => {
        getCompanyChargesByPaymentType();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    companySalesByServiceType,
    companySalesByServiceTypeAreLoading,
    companyChargesByPaymentType,
  ]);

  return (
    <Fragment>
      <Typography id={modelName + "_title"} mt="100px" variant="h4">
        {t(translationModel + ".title")}{" "}
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} s={12} md={12} lg={12} sx={{ textAlign: "right" }}>
          {updateSuccess && (
            <Alert
              id="alert_update_success"
              severity="success"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              {t("confirmations.updateSuccess", {
                model: t(translationModel + ".model"),
              })}
            </Alert>
          )}
          <FormControl error={yearHasError ? true : false} variant="outlined">
            <InputLabel htmlFor="year">
              {t(translationModel + ".form.year")}
            </InputLabel>
            <FilledInput
              maxRows={4}
              sx={{ background: "white", marginRight: "30px" }}
              id="year"
              onChange={yearChangeHandler}
              onBlur={yearBlurHandler}
              value={year}
              endAdornment={
                year !== "" && (
                  <InputAdornment position="start">
                    <Clear onClick={resetYear} />
                  </InputAdornment>
                )
              }
              type="number"
              label={t(translationModel + ".form.year")}
            />
            {yearHasError && (
              <FormHelperText id="year-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            sx={{ marginTop: "15px", marginRight: "70px" }}
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {t("buttons.update")}
          </Button>
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={3}>
          {companyTotalSalesAreLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyTotalSales && !companyTotalSalesAreLoading && (
            <Totals
              background="#9139db"
              description={t(
                translationModel + ".form.totals.totalSales.description"
              )}
              error={fetchCompanyTotalSalesError}
              icon={<Savings sx={{ width: "40px", height: "40px" }} />}
              loading={companyTotalSalesAreLoading}
              title={t(translationModel + ".form.totals.totalSales.title")}
              total={convertToMoney(companyTotalSales?.Sales?.dasTotalSales)}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={3}>
          {companyTotalPurchasesAreLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyTotalPurchases && !companyTotalPurchasesAreLoading && (
            <Totals
              background="#db9839"
              description={t(
                translationModel + ".form.totals.totalPurchases.description"
              )}
              error={fetchCompanyTotalPurchasesError}
              icon={
                <ShoppingCartCheckout sx={{ width: "40px", height: "40px" }} />
              }
              loading={companyTotalPurchasesAreLoading}
              title={t(translationModel + ".form.totals.totalPurchases.title")}
              total={convertToMoney(
                companyTotalPurchases?.Purchases?.dasTotalPurchases
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={3}>
          {!companyTotalCharges && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyTotalCharges && !companyTotalChargesAreLoading && (
            <Totals
              background="#9bb121"
              description={t(
                translationModel + ".form.totals.totalCharges.description"
              )}
              error={fetchCompanyTotalChargesError}
              icon={<PointOfSale sx={{ width: "40px", height: "40px" }} />}
              loading={companyTotalChargesAreLoading}
              title={t(translationModel + ".form.totals.totalCharges.title")}
              total={convertToMoney(
                companyTotalCharges?.Charges?.dasTotalCharges
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={3}>
          {!companyTotalPayments && <CircularProgress color="inherit" />}
          {companyTotalPayments && !companyTotalPaymentsAreLoading && (
            <Totals
              background="#dbc339"
              description={t(
                translationModel + ".form.totals.totalPayments.description"
              )}
              error={fetchCompanyTotalPaymentsError}
              icon={<MonetizationOn sx={{ width: "40px", height: "40px" }} />}
              loading={companyTotalPaymentsAreLoading}
              title={t(translationModel + ".form.totals.totalPayments.title")}
              total={convertToMoney(
                companyTotalPayments?.Payments?.dasTotalPayments
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={3}></Grid>
        <Grid item xs={12} s={12} md={6} lg={3} sx={{ marginBottom: "30px" }}>
          {!companyTotalClients && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyTotalClients && !companyTotalClientsAreLoading && (
            <Totals
              background="#3991db"
              description={t(
                translationModel + ".form.totals.totalClients.description"
              )}
              error={fetchCompanyTotalClientsError}
              icon={<PersonPin sx={{ width: "40px", height: "40px" }} />}
              loading={companyTotalClientsAreLoading}
              title={t(translationModel + ".form.totals.totalClients.title")}
              total={companyTotalClients?.Clients?.dasTotalClients}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={3} sx={{ marginBottom: "30px" }}>
          {!companyTotalBookings && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyTotalBookings && !companyTotalBookingsAreLoading && (
            <Totals
              background="#555"
              description={t(
                translationModel + ".form.totals.totalBookings.description"
              )}
              error={fetchCompanyTotalBookingsError}
              icon={<CalendarMonth sx={{ width: "40px", height: "40px" }} />}
              loading={companyTotalBookingsAreLoading}
              title={t(translationModel + ".form.totals.totalBookings.title")}
              total={companyTotalBookings?.Bookings?.dasTotalBookings}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={3}></Grid>
        <Grid item xs={12} s={12} md={6} lg={4}>
          {fetchCompanyClientsByCategoryError && (
            <Alert severity="error">{t("errors.defaultError")}</Alert>
          )}
          {!companyClientsByCategory && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyClientsByCategory && !companyClientsByCategoryAreLoading && (
            <CompanyClientsByCategory
              companyClientsByCategory={companyClientsByCategory}
              translationModel={translationModel}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={4}>
          {fetchCompanyPersonnelDemandError && (
            <Alert severity="error">{t("errors.defaultError")}</Alert>
          )}
          {!companyPersonnelDemand && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyPersonnelDemand && !companyPersonnelDemandAreLoading && (
            <CompanyPersonnelDemand
              companyPersonnelDemand={companyPersonnelDemand}
              translationModel={translationModel}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={4}>
          {fetchCompanyCabinDemandError && (
            <Alert severity="error">{t("errors.defaultError")}</Alert>
          )}
          {!companyCabinDemand && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyCabinDemand && !companyCabinDemandAreLoading && (
            <CompanyCabinDemand
              companyCabinDemand={companyCabinDemand}
              translationModel={translationModel}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={4}>
          {fetchCompanyBookingsStatusError && (
            <Alert severity="error">{t("errors.defaultError")}</Alert>
          )}
          {!companyBookingsStatus && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyBookingsStatus && !companyBookingsStatusAreLoading && (
            <CompanyBookingsStatus
              companyBookingsStatus={companyBookingsStatus}
              translationModel={translationModel}
            />
          )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={4}>
          {fetchCompanySalesByProductTypeError && (
            <Alert severity="error">{t("errors.defaultError")}</Alert>
          )}
          {!companySalesByProductType && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companySalesByProductType &&
            !companySalesByProductTypeAreLoading && (
              <CompanySalesByProductType
                companySalesByProductType={companySalesByProductType}
                translationModel={translationModel}
                type="product"
              />
            )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={4}>
          {fetchCompanySalesByServiceTypeError && (
            <Alert severity="error">{t("errors.defaultError")}</Alert>
          )}
          {!companySalesByServiceType && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companySalesByServiceType &&
            !companySalesByServiceTypeAreLoading && (
              <CompanySalesByProductType
                companySalesByProductType={companySalesByServiceType}
                translationModel={translationModel}
                type="service"
              />
            )}
        </Grid>
        <Grid item xs={12} s={12} md={6} lg={4} sx={{ marginBottom: "150px" }}>
          {fetchCompanyChargesByPaymentTypeError && (
            <Alert severity="error">{t("errors.defaultError")}</Alert>
          )}
          {!companyChargesByPaymentType && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {companyChargesByPaymentType &&
            !companyChargesByPaymentTypeAreLoading && (
              <CompanyChargesByPaymentType
                companyChargesByPaymentType={companyChargesByPaymentType}
                translationModel={translationModel}
              />
            )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default Company;
