import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoginForm from "./LoginForm";
import LoginUpdatesContainer from "./LoginUpdatesContainer";
import { Grid } from "@mui/material";

const Login = ({ onSetPageTitle }) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation("common");
  const translationModel = "login";
  const setPageTitleHandler = () => {
    onSetPageTitle(t(translationModel + ".title"));
  };

  useEffect(() => {
    setPageTitleHandler();
  });

  return (
    <Grid
      container
      mt={1}
      mb={3}
      rowSpacing={6}
      columnSpacing={4}
      justifyContent="center"
    >
      {mediumScreen ? <Grid item xs={12} s={12} md={12} lg={12}></Grid> : ""}
      <Grid item xs={12} s={1} md={1} lg={1}></Grid>
      <Grid item xs={12} s={5} md={5} lg={5}>
        <LoginForm />
      </Grid>
      <Grid item xs={12} s={5} md={5} lg={5}>
        <LoginUpdatesContainer />
      </Grid>
      <Grid item xs={12} s={1} md={1} lg={1}></Grid>
    </Grid>
  );
};
export default Login;
