import React, { Fragment } from "react";
import { Fab, Tooltip } from "@mui/material";
import { Refresh } from "@mui/icons-material/";
import { useTranslation } from "react-i18next";

const RefreshModelButton = ({ onRefreshGrid }) => {
  const { t } = useTranslation("common");
  const refreshModelHandler = () => {
    onRefreshGrid();
  };

  return (
    <Fragment>
      <Tooltip title={t("buttons.update")} placement="top">
        <Fab
          sx={{ marginLeft: "5px", marginRight: "5px", float: "right" }}
          size="small"
          aria-label="edit"
          onClick={() => refreshModelHandler()}
        >
          <Refresh fontSize="small" />
        </Fab>
      </Tooltip>
    </Fragment>
  );
};

export default RefreshModelButton;
