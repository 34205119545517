import { useState, useEffect } from "react";
import {
  Switch,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import FileUploader from "../components/UI/FileUploader";

const useFormField = (formConfig) => {
  const [formFields, setFormFields] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    // Set initial values from formConfig when the component mounts
    const initialValues = {};
    formConfig.forEach((fieldConfig) => {
      if (fieldConfig.value !== undefined && !formFields[fieldConfig.name]) {
        initialValues[fieldConfig.name] = fieldConfig.value;
      }
    });

    // Only update the formFields state if there are new initial values
    if (Object.keys(initialValues).length > 0) {
      setFormFields((prevFormFields) => ({
        ...prevFormFields,
        ...initialValues,
      }));
    }
  }, [formConfig, formFields]);

  const handleFieldChange = (name, value, validationFn, errorMessage) => {
    const error = validateField(value, validationFn, errorMessage);
    setFieldErrors((prevFieldErrors) => ({
      ...prevFieldErrors,
      [name]: error,
    }));
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [name]: value,
    }));
  };

  const validateField = (value, validationFn, errorMessage) => {
    if (validationFn) {
      const isValid = validationFn(value);
      return isValid ? "" : errorMessage;
    }
    return "";
  };

  const handleFieldBlur = (name, value, validationFn, errorMessage) => {
    const error = validateField(value, validationFn, errorMessage);
    setFieldErrors((prevFieldErrors) => ({
      ...prevFieldErrors,
      [name]: error,
    }));
  };

  const renderFormFields = () => {
    return formConfig.map((fieldConfig) => {
      const {
        action,
        checked,
        disabled,
        file,
        fileType,
        id,
        label,
        multiline,
        menuItems,
        menuItemValue,
        name,
        sx,
        token,
        type,
        customOnChange,
        customOnBlur,
        customOnClear,
        validationFn,
        errorMessage,
      } = fieldConfig;

      const commonProps = {
        action,
        checked,
        disabled,
        id,
        key: name,
        label,
        name,
        sx,
        type,
        multiline,
        value: formFields[name] || "",
        onChange: (e) => {
          if (e.target.type === "checkbox") {
            handleFieldChange(
              name,
              e.target.checked,
              validationFn,
              errorMessage
            );
            if (customOnChange) {
              customOnChange(e);
            }
          } else {
            handleFieldChange(name, e.target.value, validationFn, errorMessage);
            if (customOnChange) {
              customOnChange(e);
            }
          }
        },
        onBlur: (e) => {
          handleFieldBlur(name, e.target.value, validationFn, errorMessage);
          if (customOnBlur) {
            customOnBlur(e);
          }
        },
      };

      const fileProps = {
        file,
        fileType,
        token,
        uploadHandler: (fieldName, fileName) => {
          setFormFields((prevFormFields) => ({
            ...prevFormFields,
            [fieldName]: fileName,
          }));
        },
      };

      const clearFieldProps = {
        onClick: () => {
          if (customOnClear) {
            customOnClear();
            setFormFields((prevFormFields) => ({
              ...prevFormFields,
              [name]: "",
            }));
          }
        },
      };

      switch (type) {
        case "text":
          return (
            <FormControl
              error={fieldErrors[name] ? true : false}
              key={`formControl_${commonProps.name}`}
              variant="outlined"
            >
              <InputLabel
                key={`inputLabel${commonProps.name}`}
                htmlFor={commonProps.name}
              >
                {commonProps.label}
              </InputLabel>
              <FilledInput
                {...commonProps}
                endAdornment={
                  formFields[name] !== "" &&
                  commonProps.action !== "view" &&
                  !commonProps.disabled && (
                    <InputAdornment position="start">
                      <Clear onClick={clearFieldProps.onClick} />
                    </InputAdornment>
                  )
                }
              />
              {fieldErrors[name] && (
                <FormHelperText id={`${formFields[name]}-helper-text`}>
                  {fieldErrors[name] && fieldErrors[name]}
                </FormHelperText>
              )}
            </FormControl>
          );

        case "date":
          return (
            <TextField
              {...commonProps}
              InputLabelProps={{
                shrink: true,
              }}
            />
          );

        case "select":
          return (
            <FormControl
              key={`formControl_${commonProps.name}`}
              error={fieldErrors[name] ? true : false}
              variant="outlined"
            >
              <InputLabel
                key={`inputLabel${commonProps.name}`}
                htmlFor={commonProps.name}
              >
                {commonProps.label}
              </InputLabel>
              <Select {...commonProps}>
                {menuItems &&
                  menuItems.map((menuItem) => (
                    <MenuItem key={menuItem.id} value={menuItem.id}>
                      {menuItemValue.map((key) => menuItem[key]).join(" ")}
                    </MenuItem>
                  ))}
              </Select>
              {fieldErrors[name] && (
                <FormHelperText
                  key={`FormHelperText_${commonProps.name}`}
                  id={`${formFields[name]}-helper-text`}
                >
                  {fieldErrors[name] && fieldErrors[name]}
                </FormHelperText>
              )}
            </FormControl>
          );
        case "switch":
          return (
            <FormControlLabel
              key={`FormControlLabel_${commonProps.name}`}
              control={
                <Switch
                  id={commonProps.id}
                  key={`checkbox_${commonProps.name}`}
                  disabled={commonProps.disabled}
                  checked={commonProps.checked}
                  onChange={commonProps.onChange}
                />
              }
              label={commonProps.label}
            />
          );
        case "file":
          return (
            <FileUploader
              key={commonProps.key}
              action={commonProps.action}
              currentFile={formFields[name]}
              fileType={fileProps.fileType}
              inputId={commonProps.id}
              inputLabel={commonProps.label}
              token={fileProps.token}
              onUploadSuccess={(filename) =>
                fileProps.uploadHandler(commonProps.name, filename)
              }
            ></FileUploader>
          );

        default:
          return null;
      }
    });
  };

  return { formFields, renderFormFields };
};

export default useFormField;
