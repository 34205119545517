import React, { Fragment, useEffect } from "react";
import useHttp from "../../../hooks/use-http";
import useInput from "../../../hooks/use-input";
import useFormField from "../../../hooks/use-form-field";
import { Stack, Fab, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import styles from "../styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const PrescriptionsModelForm = ({
  token,
  model,
  translationModel,
  action,
  recordType,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  let loadForm = true;
  let formIsValid = false;
  const formSubmitHandler = async (event) => {
    event.preventDefault();
    if (action === "add") {
      onCreate(formFields);
    }

    if (action === "edit") {
      onUpdate(formFields);
    }
  };

  const cancelHandler = () => {
    onCancel();
  };

  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const clientChangeHandler = (e) => {
    setClient(e.target.value);
  };

  const { enteredValue: id, setEnteredValue: setId } = useInput();
  const { enteredValue: client, setEnteredValue: setClient } = useInput();
  const { enteredValue: date, setEnteredValue: setDate } = useInput();

  const {
    enteredValue: prescription,
    setEnteredValue: setPrescription,
    valueChangeHandler: prescriptionChangeHandler,
    reset: resetPrescription,
  } = useInput();

  const { enteredValue: recordFile, setEnteredValue: setRecordFile } =
    useInput();

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    reset: resetObservations,
  } = useInput();

  const { enteredValue: captured, setEnteredValue: setCaptured } = useInput();

  const formConfig = [
    {
      type: "text",
      action,
      disabled: true,
      id: "id",
      multiline: false,
      name: "id",
      sx: { display: "none" },
      value: id,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "recordType",
      multiline: false,
      name: "cliRecType",
      sx: { display: "none" },
      value: recordType,
    },
    {
      type: "select",
      action,
      disabled: action === "view",
      errorMessage: t("errors.fieldError"),
      id: "client",
      label: t(translationModel + ".form.prescriptions.client") + "*",
      name: "cliRecClientID",
      sx: { background: "white" },
      value: clients?.clients && !clientsAreLoading ? client : "",
      menuItems: clients && !clientsAreLoading && clients.clients,
      menuItemValue: ["id", "cliLastName", "cliFirstName"],
      customOnChange: clientChangeHandler,
      validationFn: (value) => value > 0,
    },
    {
      type: "date",
      action,
      disabled: action === "view",
      id: "date",
      label: t(translationModel + ".form.prescriptions.date"),
      name: "cliRecRecognitionDate",
      sx: { width: 220 },
      value: date ? date : format(new Date(), "yyyy-MM-dd"),
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "prescription",
      label: t(translationModel + ".form.prescriptions.prescription"),
      multiline: true,
      name: "cliRecPrescription",
      sx: { background: "white" },
      value: prescription,
      customOnChange: prescriptionChangeHandler,
      customOnClear: resetPrescription,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "document",
      disabled: action === "view",
      id: "recordFile",
      label: t(translationModel + ".form.prescriptions.document"),
      name: "cliRecFile",
      token,
      value: recordFile,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "observations",
      label: t(translationModel + ".form.prescriptions.observations"),
      multiline: true,
      name: "cliRecObservations",
      sx: { background: "white" },
      value: observations,
      customOnChange: observationsChangeHandler,
      customOnClear: resetObservations,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "captured",
      label: action === "view" && t(translationModel + ".form.captured"),
      multiline: false,
      name: "captured",
      sx: action !== "view" ? { display: "none" } : {},
      value: captured,
    },
  ];

  const { formFields, renderFormFields } = useFormField(formConfig);

  if (client) {
    formIsValid = true;
  }

  useEffect(() => {
    if (loadForm) {
      getClients();
    }
    if (model && action) {
      setId(model.clientRecord.id ? model.clientRecord.id : null);
      setClient(
        model.clientRecord.cliRecClientID
          ? model.clientRecord.cliRecClientID
          : false
      );
      setDate(
        model.clientRecord.cliRecRecognitionDate
          ? new Date(model.clientRecord.cliRecRecognitionDate)
              .toISOString()
              .split("T")[0]
          : ""
      );
      setPrescription(
        model.clientRecord?.cliRecPrescription
          ? model.clientRecord.cliRecPrescription
          : ""
      );
      setRecordFile(
        model.clientRecord?.cliRecFile ? model.clientRecord.cliRecFile : ""
      );
      setObservations(
        model.clientRecord?.cliRecObservations
          ? model.clientRecord.cliRecObservations
          : ""
      );
      setCaptured(
        model.clientRecord?.cliRecCaptured
          ? model.clientRecord.cliRecCaptured
          : ""
      );
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {renderFormFields()}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default PrescriptionsModelForm;
