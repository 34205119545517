import React from "react";
import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

const Totals = ({
  background,
  description,
  error,
  icon,
  loading,
  title,
  total,
}) => {
  return (
    <Grid container>
      <Grid item sx={{ marginTop: "30px" }} xs={12} s={12} md={12} lg={12}>
        <Card
          variant="outlined"
          sx={{
            maxWidth: 360,
            background: background && background,
            color: "white",
          }}
        >
          {error && <Alert severity="error">{error}</Alert>}
          {loading && <CircularProgress color="inherit" />}
          {!error && !loading && total && (
            <Box sx={{ p: 2, height: "150px" }}>
              {icon && icon}
              <Typography gutterBottom variant="h6" component="div">
                {title}
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {total}
              </Typography>
              <Typography variant="body2">{description}</Typography>
            </Box>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Totals;
