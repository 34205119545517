import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import {
  Stack,
  Fab,
  FormControl,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  model,
  translationModel,
  action,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const [inactive, setInactive] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  let loadForm = true;

  const {
    enteredValue: name,
    setEnteredValue: setName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: percentage,
    setEnteredValue: setPercentage,
    valueIsValid: percentageIsValid,
    valueChangeHandler: percentageChangeHandler,
    hasError: percentageHasError,
    inputBlurHandler: percentageBlurHandler,
    reset: resetPercentage,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput((value) => value.toString().trim() !== "");

  const inactiveChangeHandler = () => {
    setInactive(!inactive);
  };

  const resetInactive = () => {
    setInactive("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  const isDefaultChangeHandler = () => {
    setIsDefault(!isDefault);
  };

  const resetIsDefault = () => {
    setIsDefault("");
  };

  if (nameIsValid && percentageIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let inactiveValue = null;
    if (inactive === true) {
      inactiveValue = 1;
    }
    if (inactive === false) {
      inactiveValue = null;
    }

    let isDefaultValue = null;
    if (isDefault === true) {
      isDefaultValue = 1;
    }
    if (isDefault === false) {
      isDefaultValue = null;
    }

    if (action === "add") {
      const modelData = {
        taxName: name,
        taxPercentage: percentage,
        taxDescription: description,
        taxDefault: isDefaultValue,
        taxInactive: inactiveValue,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.tax.id,
        taxName: name,
        taxPercentage: percentage,
        taxDescription: description,
        taxDefault: isDefaultValue,
        taxInactive: inactiveValue,
      };
      onUpdate(modelData);
    }
    resetName();
    resetPercentage();
    resetDescription();
    resetIsDefault();
    resetInactive();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      if (model && action) {
        setName(model.tax.taxName ? model.tax.taxName : "");
        setPercentage(model.tax.taxPercentage ? model.tax.taxPercentage : "");
        setDescription(
          model.tax.taxDescription ? model.tax.taxDescription : ""
        );
        if (model.tax.taxInactive === 1 || model.tax.taxInactive === true) {
          setInactive(true);
        }
        if (!model.tax.resTypInactive) {
          setInactive(false);
        }
        if (model.tax.taxDefault === 1 || model.tax.taxDefault === true) {
          setIsDefault(true);
        }
        if (!model.tax.taxDefault) {
          setIsDefault(false);
        }
        setCaptured(model.tax.taxCaptured ? model.tax.taxCaptured : "");
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          error={percentageHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="percentage">
            {t(translationModel + ".form.percentage")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="percentage"
            onChange={percentageChangeHandler}
            onBlur={percentageBlurHandler}
            value={percentage}
            endAdornment={
              percentage !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetPercentage} />
                </InputAdornment>
              )
            }
            type="number"
            label={t(translationModel + ".form.percentage")}
            disabled={action === "view"}
          />
          {percentageHasError && (
            <FormHelperText id="percentage-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              id="isDefault"
              checked={isDefault === 1 || isDefault === true ? true : false}
              onChange={isDefaultChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.predefined")}
        />
        <FormControlLabel
          control={
            <Switch
              id="inactive"
              checked={inactive === 1 || inactive === true ? true : false}
              onChange={inactiveChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.inactive")}
        />
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
