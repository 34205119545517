import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useHttp from "../../hooks/use-http";
import { Grid, Backdrop, CircularProgress, Alert } from "@mui/material";
import { format, addDays, subDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { bookingActions } from "../../store/booking-slice";
import Header from "./Header";
import Resource from "./Resource";
import BookingsSheetDialog from "./Dialogs/BookingsSheetDialog";

const BookingSheets = ({ date, token, onSetPageTitle }) => {
  const { resourceType } = useParams();
  const [action, setAction] = useState("");
  const [dialog, setDialog] = useState("");
  const [bookingHour, setBookingHour] = useState("");
  const [bookingResource, setBookingResource] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [resourceBookings, setResourceBookings] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const modelName = "bookings";
  const translationModel = "bookingSheets";
  const companyWorkingHours = [7, 22];

  const changeDate = (direction) => {
    let newDate = new Date(date);
    if (direction === "previous") {
      newDate = subDays(new Date(newDate), 1);
      dispatch(bookingActions.setDate(JSON.stringify(newDate)));
    }
    if (direction === "current") {
      newDate = new Date();
      dispatch(bookingActions.setDate(JSON.stringify(newDate)));
    }
    if (direction === "next") {
      newDate = addDays(new Date(newDate), 1);
      dispatch(bookingActions.setDate(JSON.stringify(newDate)));
    }
    getBookingsHandler(newDate);
  };

  const {
    isLoading: bookingsAreLoading,
    error: fetchBookingsError,
    data: bookings,
    sendRequest: sendBookingsGetRequest,
  } = useHttp();

  const getBookingsHandler = async (date) => {
    await sendBookingsGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: {
        start: format(new Date(date), "yyyy-MM-dd"),
        end: format(addDays(new Date(date), 1), "yyyy-MM-dd"),
      },
    });
  };

  const {
    isLoading: resourcesAreLoading,
    error: fetchResourcesError,
    data: resources,
    sendRequest: sendResourcesGetRequest,
  } = useHttp();

  const getResourcesHandler = async () => {
    let category = 1;
    if (resourceType === "cabin") {
      category = 3;
    }
    await sendResourcesGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/resources?category=" + category,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const addBookingHandler = (hour, resource, resourceBookings) => {
    setAction("add");
    setDialog("create");
    setBookingHour(hour);
    setBookingResource(resource);
    setResourceBookings(resourceBookings);
  };

  const closeDialogHandler = () => {
    setAction("");
    setDialog("");
    setBookingHour("");
    setBookingResource("");
    setBookingId("");
    setResourceBookings("");
    getBookingsHandler(date);
  };

  const viewBookingHandler = (bookingId) => {
    setAction("view");
    setDialog("view");
    setBookingId(bookingId);
    setResourceBookings(resourceBookings);
  };

  const editBookingHandler = () => {
    setAction("");
    setResourceBookings(resourceBookings);
    setTimeout(() => {
      setAction("edit");
    }, 1);
    setDialog("edit");
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(
      t(translationModel + ".title", {
        resourceType: t(translationModel + ".resourceType." + resourceType),
      })
    );
  };

  useEffect(() => {
    setPageTitleHandler();
    getBookingsHandler(date);
    getResourcesHandler();
    // eslint-disable-next-line
  }, [resourceType, date]);

  return (
    <Grid container spacing={2} sx={{ paddingBottom: "20px" }}>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Header
          resourceType={resourceType}
          modelName={modelName}
          translationModel={translationModel}
          date={date}
          onRefresh={() => getBookingsHandler(date)}
          onChangeDate={changeDate}
        />
      </Grid>
      <Grid
        item
        xs={12}
        s={12}
        md={12}
        lg={12}
        // sx={{ overflow: "auto", whiteSpace: "nowrap" }} TODO: fix floating horizontally
      >
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={resourcesAreLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {fetchBookingsError ||
            (fetchResourcesError && (
              <Alert severity="error">
                {t("errors.defaultError")}{" "}
                {fetchBookingsError && fetchBookingsError}{" "}
                {fetchResourcesError && fetchResourcesError}
              </Alert>
            ))}
          {!resourcesAreLoading &&
            !bookingsAreLoading &&
            resources &&
            resources.resources &&
            resources.resources.map((resource) => (
              <Resource
                key={resource.id}
                companyWorkingHours={companyWorkingHours}
                date={date}
                resource={resource}
                resourceType={resourceType}
                bookings={bookings}
                translationModel={translationModel}
                onAddBooking={addBookingHandler}
                onViewBooking={viewBookingHandler}
              />
            ))}
        </div>
      </Grid>
      {action && (
        <BookingsSheetDialog
          token={token}
          dialog={dialog}
          action={action}
          dialogTitle={t(translationModel + ".dialogs." + action + ".title")}
          bookingHour={bookingHour}
          bookingResource={bookingResource}
          resourceType={resourceType}
          bookingId={bookingId}
          resourceBookings={resourceBookings}
          onCloseDialog={closeDialogHandler}
          onEdit={editBookingHandler}
        />
      )}
    </Grid>
  );
};

export default BookingSheets;
