import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import i18next from "i18next";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const types = ["1", "2", "3"];

const ModelForm = (props) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const [inactive, setInactive] = useState(false);
  const [manufacturer, setManufacturer] = useState(false);
  const model = props.model;
  const translationModel = props.translationModel;
  const action = props.action;
  let loadForm = true;

  const {
    enteredValue: type,
    setEnteredValue: setType,
    valueIsValid: typeIsValid,
    valueChangeHandler: typeChangeHandler,
    inputBlurHandler: typeBlurHandler,
    reset: resetType,
  } = useInput((value) => value.toString().trim() !== "");

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:providers.form.types.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`providers.form.types.${key}`])}
        </MenuItem>
      );
    }
  };

  const {
    enteredValue: name,
    setEnteredValue: setName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: category,
    setEnteredValue: setCategory,
    valueIsValid: categoryIsValid,
    valueChangeHandler: categoryChangeHandler,
    hasError: categoryHasError,
    inputBlurHandler: categoryBlurHandler,
    reset: resetCategory,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: categories,
    isLoading: categoriesAreLoading,
    sendRequest: fetchCategories,
  } = useHttp();

  const getCategories = () => {
    fetchCategories({
      url: process.env.REACT_APP_API_SERVER + "/provider_categories",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: contact,
    setEnteredValue: setContact,
    valueChangeHandler: contactChangeHandler,
    hasError: contactHasError,
    inputBlurHandler: contactBlurHandler,
    reset: resetContact,
  } = useInput();

  const manufacturerChangeHandler = () => {
    setManufacturer(!manufacturer);
  };

  const resetManufacturer = () => {
    setManufacturer("");
  };

  const {
    enteredValue: address1,
    setEnteredValue: setAddress1,
    valueChangeHandler: address1ChangeHandler,
    inputBlurHandler: address1BlurHandler,
    reset: resetAddress1,
  } = useInput();

  const {
    enteredValue: address2,
    setEnteredValue: setAddress2,
    valueChangeHandler: address2ChangeHandler,
    inputBlurHandler: address2BlurHandler,
    reset: resetAddress2,
  } = useInput();

  const {
    enteredValue: mainPhone,
    setEnteredValue: setMainPhone,
    valueChangeHandler: mainPhoneChangeHandler,
    inputBlurHandler: mainPhoneBlurHandler,
    reset: resetMainPhone,
  } = useInput();

  const {
    enteredValue: otherPhone,
    setEnteredValue: setOtherPhone,
    valueChangeHandler: otherPhoneChangeHandler,
    inputBlurHandler: otherPhoneBlurHandler,
    reset: resetOtherPhone,
  } = useInput();

  const {
    enteredValue: email,
    setEnteredValue: setEmail,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput();

  const {
    enteredValue: secondaryEmail,
    setEnteredValue: setSecondaryEmail,
    valueChangeHandler: secondaryEmailChangeHandler,
    inputBlurHandler: secondaryEmailBlurHandler,
    reset: resetSecondaryEmail,
  } = useInput();

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueChangeHandler: descriptionChangeHandler,
    hasError: descriptionHasError,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput();

  const inactiveChangeHandler = () => {
    setInactive(!inactive);
  };

  const resetInactive = () => {
    setInactive("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if ((typeIsValid, nameIsValid, categoryIsValid)) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let inactiveValue = null;
    if (inactive === true) {
      inactiveValue = 1;
    }
    if (inactive === false) {
      inactiveValue = null;
    }

    let isManufacturer = null;
    if (manufacturer === true) {
      isManufacturer = 1;
    }
    if (manufacturer === false) {
      isManufacturer = null;
    }

    if (action === "add") {
      const modelData = {
        proType: type,
        proCoCommercialName: name,
        proCategory: category,
        proContactName: contact,
        proManufactures: isManufacturer,
        proCoMainAddr1: address1,
        proCoMainAddr2: address2,
        proMainPhone: mainPhone,
        proOtherPhones: otherPhone,
        proMainEmail: email,
        proAltEmail: secondaryEmail,
        proObservations: description,
        proInactive: inactiveValue,
      };
      props.onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.provider.id,
        proType: type,
        proCoCommercialName: name,
        proCategory: category,
        proContactName: contact,
        proManufactures: isManufacturer,
        proCoMainAddr1: address1,
        proCoMainAddr2: address2,
        proMainPhone: mainPhone,
        proOtherPhones: otherPhone,
        proMainEmail: email,
        proAltEmail: secondaryEmail,
        proObservations: description,
        proInactive: inactiveValue,
      };
      props.onUpdate(modelData);
    }
    resetType();
    resetName();
    resetCategory();
    resetContact();
    resetManufacturer();
    resetAddress1();
    resetAddress2();
    resetMainPhone();
    resetOtherPhone();
    resetEmail();
    resetSecondaryEmail();
    resetDescription();
    resetInactive();
    resetCaptured();
  };

  const cancelHandler = () => {
    props.onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      getCategories();

      if (model && action) {
        setType(model.provider.proType ? model.provider.proType : "");
        setName(
          model.provider.proCoCommercialName
            ? model.provider.proCoCommercialName
            : ""
        );
        setCategory(
          model.provider.proCategory ? model.provider.proCategory : ""
        );
        setContact(
          model.provider.proContactName ? model.provider.proContactName : ""
        );
        setManufacturer(
          model.provider.proManufactures ? model.provider.proManufactures : ""
        );
        setAddress1(
          model.provider.proCoMainAddr1 ? model.provider.proCoMainAddr1 : ""
        );
        setAddress2(
          model.provider.proCoMainAddr2 ? model.provider.proCoMainAddr2 : ""
        );
        setMainPhone(
          model.provider.proMainPhone ? model.provider.proMainPhone : ""
        );
        setOtherPhone(
          model.provider.proOtherPhones ? model.provider.proOtherPhones : ""
        );
        setEmail(
          model.provider.proMainEmail ? model.provider.proMainEmail : ""
        );
        setSecondaryEmail(
          model.provider.proAltEmail ? model.provider.proAltEmail : ""
        );
        setDescription(
          model.provider.proObservations ? model.provider.proObservations : ""
        );
        if (
          model.provider.proInactive === 1 ||
          model.provider.proInactive === true
        ) {
          setInactive(true);
        }
        if (!model.provider.proInactive) {
          setInactive(false);
        }
        setCaptured(
          model.provider.proCaptured ? model.provider.proCaptured : ""
        );
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl>
          <InputLabel id="type_label">
            {t(translationModel + ".form.type")}*
          </InputLabel>
          <Select
            id="type"
            labelId="type_label"
            value={type}
            label={t(translationModel + ".form.type")}
            onChange={typeChangeHandler}
            onBlur={typeBlurHandler}
            disabled={action === "view"}
          >
            {types.map((type, index) => findTranslation(type, index))}
          </Select>
        </FormControl>
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {categoriesAreLoading && <CircularProgress color="inherit" />}
        {!categoriesAreLoading && (
          <FormControl error={categoryHasError ? true : false}>
            <InputLabel id="category_label">
              {t(translationModel + ".form.category")}*
            </InputLabel>
            <Select
              id="category"
              labelId="category_label"
              value={category}
              label={t(translationModel + ".form.category")}
              onChange={categoryChangeHandler}
              onBlur={categoryBlurHandler}
              disabled={action === "view"}
            >
              {categories &&
                categories.ProviderCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.proCatName}
                  </MenuItem>
                ))}
            </Select>
            {categoryHasError && (
              <FormHelperText id="category-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl error={contactHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="contact">
            {t(translationModel + ".form.contact")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="contact"
            onChange={contactChangeHandler}
            onBlur={contactBlurHandler}
            value={contact}
            endAdornment={
              contact !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetContact} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.contact")}
            disabled={action === "view"}
          />
          {contactHasError && (
            <FormHelperText id="contact-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              id="manufacturer"
              checked={
                manufacturer === 1 || manufacturer === true ? true : false
              }
              onChange={manufacturerChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.manufacturer")}
        />
        <FormControl variant="outlined">
          <InputLabel htmlFor="address1">
            {t(translationModel + ".form.address1")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="address1"
            onChange={address1ChangeHandler}
            onBlur={address1BlurHandler}
            value={address1}
            endAdornment={
              address1 !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetAddress1} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.address1")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="address2">
            {t(translationModel + ".form.address2")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="address2"
            onChange={address2ChangeHandler}
            onBlur={address2BlurHandler}
            value={address2}
            endAdornment={
              address2 !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetAddress2} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.address2")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="mainPhone">
            {t(translationModel + ".form.mainPhone")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="mainPhone"
            onChange={mainPhoneChangeHandler}
            onBlur={mainPhoneBlurHandler}
            value={mainPhone}
            endAdornment={
              mainPhone !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetMainPhone} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.mainPhone")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="otherPhone">
            {t(translationModel + ".form.otherPhone")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="otherPhone"
            onChange={otherPhoneChangeHandler}
            onBlur={otherPhoneBlurHandler}
            value={otherPhone}
            endAdornment={
              otherPhone !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetOtherPhone} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.otherPhone")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="email">
            {t(translationModel + ".form.email")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="email"
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={email}
            endAdornment={
              email !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetEmail} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.email")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="secondaryEmail">
            {t(translationModel + ".form.secondaryEmail")}
          </InputLabel>
          <FilledInput
            multiline
            sx={{ background: "white" }}
            id="secondaryEmail"
            onChange={secondaryEmailChangeHandler}
            onBlur={secondaryEmailBlurHandler}
            value={secondaryEmail}
            endAdornment={
              secondaryEmail !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetSecondaryEmail} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.secondaryEmail")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl
          error={descriptionHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
          {descriptionHasError && (
            <FormHelperText id="description-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              id="inactive"
              checked={inactive === 1 || inactive === true ? true : false}
              onChange={inactiveChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.inactive")}
        />

        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
