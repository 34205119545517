import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  action,
  model,
  modelData,
  modelName,
  redirect,
  selectedCashDrawerId,
  translationModel,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const isSalesModule = () => {
    return modelName === "Charges";
  };
  const isPurchasesModule = () => {
    return modelName === "Payments";
  };
  const [canceled, setCanceled] = useState(false);
  let loadForm = true;
  const {
    enteredValue: modelOnHold,
    setEnteredValue: setModelOnHold,
    valueIsValid: modelOnHoldIsValid,
    hasError: modelOnHoldHasError,
    valueChangeHandler: modelOnHoldChangeHandler,
    inputBlurHandler: modelOnHoldBlurHandler,
    reset: resetModelOnHold,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: modeOnHold,
    isLoading: modeOnHoldAreLoading,
    sendRequest: fetchModeOnHold,
  } = useHttp();

  const getModeOnHold = () => {
    const endpoint = isPurchasesModule()
      ? "purchases?paymentPending=true"
      : "sales?chargePending=true";
    const url = `${process.env.REACT_APP_API_SERVER}/${endpoint}`;
    fetchModeOnHold({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: paymentType,
    setEnteredValue: setPaymentType,
    hasError: paymentTypeHasError,
    valueChangeHandler: paymentTypeChangeHandler,
    inputBlurHandler: paymentTypeBlurHandler,
    reset: resetPaymentType,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: paymentTypes,
    isLoading: paymentTypesAreLoading,
    sendRequest: fetchPaymentTypes,
  } = useHttp();

  const getPaymentTypes = () => {
    fetchPaymentTypes({
      url: process.env.REACT_APP_API_SERVER + "/payment_types",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: date,
    setEnteredValue: setDate,
    valueChangeHandler: dateChangeHandler,
    reset: resetDate,
  } = useInput((value) => value.toString().trim() !== "");

  if (action === "add" && !date) {
    setDate(format(new Date(), "yyyy-MM-dd HH:mm"));
  }

  const {
    enteredValue: amount,
    setEnteredValue: setAmount,
    valueChangeHandler: amountChangeHandler,
    reset: resetAmount,
  } = useInput();

  const {
    enteredValue: amountReceived,
    valueChangeHandler: amountReceivedChangeHandler,
    reset: resetAmountReceived,
  } = useInput();

  const {
    enteredValue: tip,
    setEnteredValue: setTip,
    valueChangeHandler: tipChangeHandler,
    reset: resetTip,
  } = useInput();

  const {
    enteredValue: cashDrawer,
    setEnteredValue: setCashDrawer,
    valueChangeHandler: cashDrawerChangeHandler,
    inputBlurHandler: cashDrawerBlurHandler,
    valueIsValid: cashDrawerIsValid,
    hasError: cashDrawerHasError,
    reset: resetCashDrawer,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: cashDrawers,
    isLoading: cashDrawersAreLoading,
    sendRequest: fetchCashDrawers,
  } = useHttp();

  const getCashDrawers = () => {
    fetchCashDrawers({
      url: process.env.REACT_APP_API_SERVER + "/cash_drawers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: document,
    setEnteredValue: setDocument,
    valueChangeHandler: documentChangeHandler,
    reset: resetDocument,
  } = useInput();

  const canceledChangeHandler = () => {
    setCanceled(!canceled);
  };

  const resetCanceled = () => {
    setCanceled("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (modelOnHoldIsValid && cashDrawerIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let canceledValue = null;
    if (canceled === true) {
      canceledValue = 1;
    }
    if (canceled === false) {
      canceledValue = null;
    }

    if (action === "add") {
      let modelData = {
        chaSalID: modelOnHold,
        chaDateTime: date,
        chaPaymentType: paymentType,
        chaAmount: amount,
        chaTip: tip ? tip : null,
        chaDraID: cashDrawer,
        chaFile: document,
        chaCanceled: canceledValue,
      };
      if (isPurchasesModule()) {
        modelData = {
          payPurID: modelOnHold,
          payDateTime: date,
          payPaymentType: paymentType,
          payAmount: amount,
          payDraID: cashDrawer,
          payFile: document,
          payCanceled: canceledValue,
        };
      }
      onCreate(modelData);
    }

    if (action === "edit") {
      let modelData = {
        id: model.Charge.id,
        chaSalID: modelOnHold,
        chaDateTime: date,
        chaPaymentType: paymentType,
        chaAmount: amount,
        chaTip: tip ? tip : null,
        chaDraID: cashDrawer,
        chaFile: document,
        chaCanceled: canceledValue,
      };
      if (isPurchasesModule()) {
        modelData = {
          id: model.Payment.id,
          payPurID: modelOnHold,
          payDateTime: date,
          payPaymentType: paymentType,
          payAmount: amount,
          payDraID: cashDrawer,
          payFile: document,
          payCanceled: canceledValue,
        };
      }
      onUpdate(modelData);
    }
    resetAmount();
    isSalesModule() && resetAmountReceived();
    resetCanceled();
    resetCashDrawer();
    resetDate();
    resetDocument();
    resetModelOnHold();
    resetPaymentType();
    resetCaptured();
    isSalesModule() && resetTip();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const formatDate = (date) => {
    return format(new Date(date), "yyyy-MM-dd HH:mm");
  };

  const updateChangeAmount = () => {
    const change = amountReceived - amount;
    return change > 0 ? parseFloat(change, 2).toFixed(2) : 0;
  };

  const getModelId = () => {
    if (isSalesModule()) {
      return model.Charge.chaSalID ? model.Charge.chaSalID : "";
    }
    if (isPurchasesModule()) {
      return model.Payment.payPurID ? model.Payment.payPurID : "";
    }
  };

  const getDate = () => {
    if (isSalesModule()) {
      return model.Charge.chaDateTime
        ? formatDate(model.Charge.chaDateTime)
        : "";
    }
    if (isPurchasesModule()) {
      return model.Payment.payDateTime
        ? formatDate(model.Payment.payDateTime)
        : "";
    }
  };

  const getPaymentType = () => {
    if (isSalesModule()) {
      return model.Charge.chaPaymentType ? model.Charge.chaPaymentType : "";
    }
    if (isPurchasesModule()) {
      return model.Payment.payPaymentType ? model.Payment.payPaymentType : "";
    }
  };

  const getAmount = () => {
    if (isSalesModule()) {
      return model.Charge.chaAmount
        ? parseFloat(model.Charge.chaAmount).toFixed(2)
        : "";
    }
    if (isPurchasesModule()) {
      return model.Payment.payAmount
        ? parseFloat(model.Payment.payAmount).toFixed(2)
        : "";
    }
  };

  const getCashDrawer = () => {
    if (isSalesModule()) {
      return model.Charge.chaDraID ? model.Charge.chaDraID : "";
    }
    if (isPurchasesModule()) {
      return model.Payment.payDraID ? model.Payment.payDraID : "";
    }
  };

  const getDocument = () => {
    if (isSalesModule()) {
      return model.Charge.chaFile ? model.Charge.chaFile : "";
    }
    if (isPurchasesModule()) {
      return model.Payment.payFile ? model.Payment.payFile : "";
    }
  };

  const getCanceled = () => {
    if (isSalesModule()) {
      if (model.Charge.chaCanceled === 1 || model.Charge.chaCanceled === true) {
        return true;
      }
      if (!model.Charge.chaCanceled) {
        return false;
      }
    }
    if (isPurchasesModule()) {
      if (
        model.Payment.payCanceled === 1 ||
        model.Payment.payCanceled === true
      ) {
        return true;
      }
      if (!model.Payment.payCanceled) {
        return false;
      }
    }
  };

  const getAmountDue = () => {
    if (isSalesModule()) {
      return modelData.sale.salAmountDue
        ? parseFloat(modelData.sale.salAmountDue).toFixed(2)
        : "";
    }
    if (isPurchasesModule()) {
      return modelData.purchase.purAmountDue
        ? parseFloat(modelData.purchase.purAmountDue).toFixed(2)
        : "";
    }
  };

  useEffect(() => {
    if (loadForm) {
      getModeOnHold();
      getPaymentTypes();
      getCashDrawers();
      if (model && action) {
        setModelOnHold(getModelId());
        setDate(getDate());
        setPaymentType(getPaymentType());
        setAmount(getAmount());
        isSalesModule() &&
          setTip(model.Charge.chaTip ? model.Charge.chaTip : "");
        setCashDrawer(getCashDrawer());
        setDocument(getDocument());
        if (getCanceled() === 1 || getCanceled() === true) {
          setCanceled(true);
        }
        if (!getCanceled()) {
          setCanceled(false);
        }
        setCaptured(
          model.Charge?.executive ? model.Charge.executive.exeInitials : ""
        );
      }
      // if (saleData) {
      //   setSaleOnHold(saleData.sale.id);
      // }
    }
    //TODO: Get default values from company configuration
    if (action === "add") {
      !paymentType && setPaymentType(6);
      if (selectedCashDrawerId) {
        setCashDrawer(selectedCashDrawerId);
      }
      !amount && modelData && setAmount(getAmountDue());
      !modelOnHold &&
        modelData &&
        setModelOnHold(modelData.sale?.id || modelData.purchase?.id);
      if (!amount && !modelData && modelOnHold && modeOnHold) {
        let amountDueValue = 0;
        if (isSalesModule()) {
          amountDueValue = modeOnHold.Sales.find(
            (sale) => sale.id === modelOnHold
          ).salAmountDue;
        }
        if (isPurchasesModule()) {
          amountDueValue = modeOnHold.Purchases.find(
            (purchase) => purchase.id === modelOnHold
          ).purAmountDue;
        }
        amountDueValue && setAmount(parseFloat(amountDueValue).toFixed(2));
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model, modelOnHold]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={2}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {!redirect && (
          <TextField
            id="date"
            label={t(translationModel + ".form.date")}
            type="datetime-local"
            value={date}
            onChange={dateChangeHandler}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={action === "view"}
          />
        )}
        {modeOnHoldAreLoading &&
          action === "add" &&
          !redirect &&
          !modelData && <CircularProgress color="inherit" />}
        {!modeOnHoldAreLoading && action === "add" && !modelData && (
          <FormControl error={modelOnHoldHasError ? true : false}>
            <InputLabel id="modelId_label">
              {isSalesModule() && t(translationModel + ".form.saleId") + "*"}
              {isPurchasesModule() &&
                t(translationModel + ".form.purchaseId") + "*"}
            </InputLabel>
            <Select
              id="modelId"
              labelId="modelId_label"
              value={modelOnHold}
              label={t(translationModel + ".form.saleId")}
              onChange={modelOnHoldChangeHandler}
              onBlur={modelOnHoldBlurHandler}
              disabled={action === "view"}
            >
              {modeOnHold &&
                modeOnHold.Sales?.map((sale) => (
                  <MenuItem key={sale.id} value={sale.id}>
                    {sale.id}
                  </MenuItem>
                ))}
              {modeOnHold &&
                modeOnHold.Purchases?.map((purchase) => (
                  <MenuItem key={purchase.id} value={purchase.id}>
                    {purchase.id}
                  </MenuItem>
                ))}
            </Select>
            {modelOnHoldHasError && (
              <FormHelperText id="model-on-hold-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {action !== "add" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="modelId">
              {t(translationModel + ".form.saleId")}*
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="modelId"
              value={modelOnHold}
              type="number"
              label={t(translationModel + ".form.saleId")}
              disabled
            />
          </FormControl>
        )}
        {paymentTypesAreLoading && <CircularProgress color="inherit" />}
        {!paymentTypesAreLoading && (
          <FormControl error={paymentTypeHasError ? true : false}>
            <InputLabel id="paymentType_label">
              {t(translationModel + ".form.paymentType")}*
            </InputLabel>
            <Select
              id="paymentType"
              labelId="paymentType_label"
              value={paymentType}
              label={t(translationModel + ".form.paymentType")}
              onChange={paymentTypeChangeHandler}
              onBlur={paymentTypeBlurHandler}
              disabled={action !== "add"}
            >
              {paymentTypes &&
                paymentTypes.PaymentTypes.map((paymentType) => (
                  <MenuItem key={paymentType.id} value={paymentType.id}>
                    {paymentType.payTypName}
                  </MenuItem>
                ))}
            </Select>
            {paymentTypeHasError && (
              <FormHelperText id="payment-type-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {cashDrawersAreLoading && !selectedCashDrawerId && (
          <CircularProgress color="inherit" />
        )}
        {!cashDrawersAreLoading && !selectedCashDrawerId && (
          <FormControl error={cashDrawerHasError ? true : false}>
            <InputLabel id="cashDrawer_label">
              {t(translationModel + ".form.cashDrawer")}*
            </InputLabel>
            <Select
              id="cashDrawer"
              labelId="cashDrawer_label"
              value={cashDrawer}
              label={t(translationModel + ".form.cashDrawer")}
              onChange={cashDrawerChangeHandler}
              onBlur={cashDrawerBlurHandler}
              disabled={action !== "add"}
            >
              {cashDrawers &&
                cashDrawers.CashDrawers.map((cashDrawer) => (
                  <MenuItem key={cashDrawer.id} value={cashDrawer.id}>
                    {cashDrawer.casDraName}
                  </MenuItem>
                ))}
            </Select>
            {cashDrawerHasError && (
              <FormHelperText id="cashDrawer-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel
            htmlFor="amount"
            sx={{ fontWeight: "bold", marginTop: "-5px" }}
          >
            {t(translationModel + ".form.amount")}
          </InputLabel>
          <FilledInput
            className={`${styles["charge-amount-field"]}`}
            id="amount"
            onChange={amountChangeHandler}
            value={amount}
            endAdornment={
              amount !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetAmount} />
                </InputAdornment>
              )
            }
            type="number"
            label={t(translationModel + ".form.amount")}
            disabled={action !== "add"}
          />
        </FormControl>
        {isSalesModule() && action === "add" && (
          <FormControl variant="outlined">
            <InputLabel
              htmlFor="amountReceived"
              sx={{ fontWeight: "bold", marginTop: "-5px" }}
            >
              {t(translationModel + ".form.amountReceived")}
            </InputLabel>
            <FilledInput
              className={`${styles["charge-amount-received-field"]}`}
              id="amountReceived"
              onChange={amountReceivedChangeHandler}
              value={amountReceived}
              endAdornment={
                amountReceived !== "" &&
                action !== "view" && (
                  <InputAdornment position="start">
                    <Clear onClick={resetAmountReceived} />
                  </InputAdornment>
                )
              }
              type="number"
              label={t(translationModel + ".form.amountReceived")}
              disabled={action === "view"}
            />
          </FormControl>
        )}
        {isSalesModule() && action === "add" && (
          <FormControl variant="outlined">
            <InputLabel
              htmlFor="change"
              sx={{ fontWeight: "bold", marginTop: "-5px" }}
            >
              {t(translationModel + ".form.change")}
            </InputLabel>
            <FilledInput
              className={`${styles["charge-change-field"]}`}
              id="change"
              value={amountReceived ? updateChangeAmount() : ""}
              type="number"
              label={t(translationModel + ".form.change")}
            />
          </FormControl>
        )}
        {isSalesModule() && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="tip">
              {t(translationModel + ".form.tip")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="tip"
              onChange={tipChangeHandler}
              value={tip}
              endAdornment={
                tip !== "" &&
                action !== "view" && (
                  <InputAdornment position="start">
                    <Clear onClick={resetTip} />
                  </InputAdornment>
                )
              }
              type="number"
              label={t(translationModel + ".form.tip")}
              disabled={action !== "add"}
            />
          </FormControl>
        )}
        {(!redirect || action === "view") && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="document">
              {t(translationModel + ".form.document")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="document"
              onChange={documentChangeHandler}
              value={document}
              endAdornment={
                document !== "" &&
                action !== "view" && (
                  <InputAdornment position="start">
                    <Clear onClick={resetDocument} />
                  </InputAdornment>
                )
              }
              type="text"
              label={t(translationModel + ".form.document")}
              disabled={action === "view"}
            />
          </FormControl>
        )}
        <FormControlLabel
          control={
            <Switch
              id="canceled"
              checked={canceled === 1 || canceled === true ? true : false}
              onChange={canceledChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={t(translationModel + ".form.canceled")}
          disabled={action === "view"}
        />
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
