import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

const StatusAlerts = ({
  module,
  updateSuccess,
  cashOperationMovementSuccess,
}) => {
  const { t } = useTranslation("common");
  const translationModel = "pos";

  return (
    <Fragment>
      {updateSuccess && (
        <Alert
          sx={{ marginTop: "20px" }}
          id="alert_update_success"
          severity="success"
        >
          {t("confirmations.updateSuccess", {
            model:
              module === "purchases"
                ? t(translationModel + ".model.purchases")
                : t(translationModel + ".model.sales"),
          })}
        </Alert>
      )}
      {cashOperationMovementSuccess && (
        <Alert
          sx={{ marginTop: "20px" }}
          id="alert_cash_operation_success"
          severity="success"
        >
          {t("confirmations.createSuccess", {
            model: t("cashOperations.model"),
          })}
        </Alert>
      )}
    </Fragment>
  );
};

export default StatusAlerts;
