import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useInput from "../../../hooks/use-input";
import {
  Stack,
  FormControl,
  InputLabel,
  InputAdornment,
  FilledInput,
  FormHelperText,
  Button,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "../Form/styles.module.css";

const Observations = (props) => {
  const { t } = useTranslation("common");
  const translationModel = props.translationModel;
  const stockMovement = props.stockMovement;
  const action = props.action;

  let loadForm = true;

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    valueIsValid: observationsAreValid,
    hasError: observationsHaveError,
    inputBlurHandler: observationsBlurHandler,
    reset: resetObservations,
  } = useInput((value) => value.toString().trim() !== "");

  let formIsValid = false;

  if (observationsAreValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    const modelData = {
      stoUpdObservations: observations ? observations : null,
    };
    props.onSaveObservationsClick("", modelData);
    resetObservations();
  };

  useEffect(() => {
    if (loadForm) {
      if (stockMovement) {
        setObservations(
          stockMovement.stoUpdObservations
            ? stockMovement.stoUpdObservations
            : ""
        );
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, stockMovement]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <FormControl
          error={observationsHaveError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="observations">
            {t(translationModel + ".form.fields.observations")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white", height: "150px" }}
            id="observations"
            onChange={observationsChangeHandler}
            onBlur={observationsBlurHandler}
            value={observations}
            endAdornment={
              observations !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetObservations} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.observations")}
            disabled={action === "view"}
          />
          {observationsHaveError && (
            <FormHelperText id="observations-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          id="submit_form"
          variant="contained"
          onClick={formSubmitHandler}
          disabled={!observations || action === "view" || !formIsValid}
        >
          {action === "add" &&
            t("buttons.add", { model: t(translationModel + ".model") })}
          {action !== "add" && t("buttons.save")}
        </Button>
      </Stack>
    </Fragment>
  );
};
export default Observations;
