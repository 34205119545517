import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Fab, Tooltip } from "@mui/material";
import { Edit, Search, Delete } from "@mui/icons-material";
import GridActionsClientsMenu from "./GridActionsClientsMenu";
import styles from "./grid.actions.module.css";

const GridActions = ({
  amountDue,
  canceled,
  data: modelData,
  id,
  modelName,
  status,
  total,
  onEditModelHandler,
  onDeleteModelHandler,
  onViewModelHandler,
}) => {
  const { t } = useTranslation("common");
  const crudRights = useSelector((state) => state.auth.accessRights);
  let disableAction = false;

  if ((total > 0 && amountDue === 0) || status === false || canceled) {
    disableAction = true;
  }

  const openTabHandler = (Id, action) => {
    const url =
      window.location.origin + `/${modelName}?id=${Id}&action=${action}`;
    window.open(url, "_blank");
  };

  const viewHandler = (event, Id, model) => {
    if (event.metaKey) {
      openTabHandler(Id, "view");
    } else {
      onViewModelHandler(Id, model);
    }
  };

  const editHandler = (event, Id, model) => {
    if (event.metaKey) {
      openTabHandler(Id, "edit");
    } else {
      onEditModelHandler(Id, model);
    }
  };

  const deleteHandler = (Id) => {
    onDeleteModelHandler(Id);
  };

  const isActionAllowed = (action, userRights) => {
    return userRights.includes(action);
  };

  return (
    <Fragment>
      {crudRights && isActionAllowed("R", crudRights) && (
        <Tooltip title={t("buttons.view")} placement="top">
          <Fab
            id={modelName.replace(/\//g, "_") + "_view_action_" + id}
            className={`${styles["action-buttons"]}`}
            sx={{ marginRight: "10px" }}
            size="small"
            aria-label="view"
            onClick={(event) => viewHandler(event, id, modelData)}
          >
            <Search fontSize="small" />
          </Fab>
        </Tooltip>
      )}
      {crudRights && isActionAllowed("U", crudRights) && (
        <Fragment>
          {!disableAction ? (
            <Tooltip title={t("buttons.edit")} placement="top">
              <Fab
                id={modelName.replace(/\//g, "_") + "_edit_action_" + id}
                className={`${styles["action-buttons"]}`}
                sx={{ marginRight: "10px" }}
                size="small"
                aria-label="edit"
                disabled={disableAction}
                onClick={(event) => editHandler(event, id, modelData)}
              >
                <Edit fontSize="small" />
              </Fab>
            </Tooltip>
          ) : (
            <Fab
              id={modelName.replace(/\//g, "_") + "_edit_action_" + id}
              className={`${styles["action-buttons"]}`}
              sx={{ marginRight: "10px" }}
              size="small"
              aria-label="edit"
              disabled={disableAction}
              onClick={(event) => editHandler(event, id, modelData)}
            >
              <Edit fontSize="small" />
            </Fab>
          )}
        </Fragment>
      )}
      {crudRights && isActionAllowed("D", crudRights) && (
        <Fragment>
          {!disableAction ? (
            <Tooltip title={t("buttons.delete")} placement="top">
              <Fab
                id={modelName.replace(/\//g, "_") + "_delete_action_" + id}
                className={`${styles["action-buttons"]}`}
                sx={modelName === "clients" ? { marginRight: "10px" } : {}}
                size="small"
                aria-label="delete"
                disabled={disableAction}
                onClick={() => deleteHandler(id)}
              >
                <Delete fontSize="small" />
              </Fab>
            </Tooltip>
          ) : (
            <Fab
              id={modelName.replace(/\//g, "_") + "_delete_action_" + id}
              className={`${styles["action-buttons"]}`}
              sx={modelName === "clients" ? { marginRight: "10px" } : {}}
              size="small"
              aria-label="delete"
              disabled={disableAction}
              onClick={() => deleteHandler(id)}
            >
              <Delete fontSize="small" />
            </Fab>
          )}
        </Fragment>
      )}
      {modelName === "clients" && (
        <GridActionsClientsMenu id={id} modelName={modelName} />
      )}
    </Fragment>
  );
};

export default GridActions;
