import React, { Fragment, useEffect } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  FormControl,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { format } from "date-fns";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const ModelForm = ({ translationModel, onSubmitForm, onChangeForm }) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  let loadForm = true;

  const {
    enteredValue: date,
    setEnteredValue: setDate,
    reset: resetDate,
  } = useInput();

  const dateChangeHandler = (event) => {
    resetDate();
    resetCashOperation();
    const dateValue = event.target.value;
    setDate(dateValue);
    onChangeForm();
  };

  const {
    enteredValue: cashDrawer,
    setEnteredValue: setCashDrawer,
    hasError: cashDrawerHasError,
    inputBlurHandler: cashDrawerBlurHandler,
    reset: resetCashDrawer,
  } = useInput((value) => value && value.toString().trim() !== "");

  const cashDrawerChangeHandler = (event) => {
    resetCashDrawer();
    resetCashOperation();
    const cashDrawerValue = event.target.value;
    setCashDrawer(cashDrawerValue);
    onChangeForm();
  };

  const {
    data: cashDrawers,
    isLoading: cashDrawersAreLoading,
    sendRequest: fetchCashDrawers,
  } = useHttp();

  const getCashDrawers = () => {
    fetchCashDrawers({
      url: process.env.REACT_APP_API_SERVER + "/cash_drawers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: cashOperation,
    setEnteredValue: setCashOperation,
    hasError: cashOperationHasError,
    inputBlurHandler: cashOperationBlurHandler,
    reset: resetCashOperation,
  } = useInput((value) => value && value.toString().trim() !== "");

  const cashOperationChangeHandler = (event) => {
    resetCashOperation();
    const cashOperationValue = event.target.value;
    setCashOperation(cashOperationValue);
  };

  const {
    data: cashOperations,
    isLoading: cashOperationsAreLoading,
    sendRequest: fetchCashOperations,
  } = useHttp();

  let formIsValid = false;

  if (date && cashDrawer && cashOperation) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    const cashOperationData = cashOperations.CashOperations.find(
      (operation) => operation.id === cashOperation
    );
    cashOperationData && onSubmitForm(cashOperationData);
  };

  useEffect(() => {
    if (loadForm) {
      !date && setDate(format(new Date(), "yyyy-MM-dd"));
      getCashDrawers();
    }
    const getCashOperations = () => {
      fetchCashOperations({
        url: `${process.env.REACT_APP_API_SERVER}/cash_operations/${cashDrawer}/${date}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      });
    };
    cashDrawer && date && getCashOperations(cashDrawer, date);
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, cashDrawer, date]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <TextField
          id="date"
          label={t(translationModel + ".form.date")}
          type="date"
          value={date}
          onChange={dateChangeHandler}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {cashDrawersAreLoading && <CircularProgress color="inherit" />}
        {!cashDrawersAreLoading && (
          <FormControl error={cashDrawerHasError ? true : false}>
            <InputLabel id="cashDrawer_label">
              {t(translationModel + ".form.cashDrawer")}*
            </InputLabel>
            <Select
              id="cashDrawer"
              labelId="cashDrawer_label"
              value={cashDrawer}
              label={t(translationModel + ".form.cashDrawer")}
              onChange={cashDrawerChangeHandler}
              onBlur={cashDrawerBlurHandler}
              disabled={!date}
            >
              {cashDrawers &&
                cashDrawers.CashDrawers.map((cashDrawer) => (
                  <MenuItem key={cashDrawer.id} value={cashDrawer.id}>
                    {cashDrawer.casDraName}
                  </MenuItem>
                ))}
            </Select>
            {cashDrawerHasError && (
              <FormHelperText id="cashDrawer-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {cashOperationsAreLoading && <CircularProgress color="inherit" />}
        {!cashOperationsAreLoading &&
          cashOperations?.CashOperations?.length > 0 && (
            <FormControl error={cashOperationHasError ? true : false}>
              <InputLabel id="cashOperation_label">
                {t(translationModel + ".form.operation")}*
              </InputLabel>
              <Select
                id="cashOperation"
                labelId="cashOperation_label"
                value={cashOperation}
                label={t(translationModel + ".form.operation")}
                onChange={cashOperationChangeHandler}
                onBlur={cashOperationBlurHandler}
                disabled={!cashDrawer}
              >
                {cashOperations &&
                  cashOperations.CashOperations.map((cashOperation) => (
                    <MenuItem key={cashOperation.id} value={cashOperation.id}>
                      {format(new Date(cashOperation.casOpeCloseDate), "HH:mm")}
                    </MenuItem>
                  ))}
              </Select>
              {cashOperationHasError && (
                <FormHelperText id="cashOperation-helper-text">
                  {t("errors.fieldError")}
                </FormHelperText>
              )}
            </FormControl>
          )}
        <Button
          id="submit_form"
          variant="contained"
          onClick={formSubmitHandler}
          disabled={!formIsValid}
        >
          {t("buttons.accept")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
