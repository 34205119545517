import React, { useEffect } from "react";
import { Grid, Button, Alert, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { posActions } from "../../store/pos-slice";
import useHttp from "../../hooks/use-http";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

const CashDrawers = ({
  token,
  type,
  action,
  saleIsCanceled,
  selectedCashDrawerId,
  onSelect,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const selectCashDrawerHandler = (cashDrawerId) => {
    onSelect();
    dispatch(posActions.setSelectedCashDrawer(cashDrawerId));
  };

  const {
    data: cashDrawers,
    isLoading: cashDrawersAreLoading,
    error: fetchCashDrawersError,
    sendRequest: fetchCashDrawers,
  } = useHttp();

  const getCashDrawers = () => {
    fetchCashDrawers({
      url: process.env.REACT_APP_API_SERVER + "/cash_drawers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  useEffect(() => {
    dispatch(posActions.getSelectedCashDrawer());
    getCashDrawers();
    // eslint-disable-next-line
  }, [type]);

  return (
    <Grid sx={{ paddingBottom: "200px", minWidth: "600px" }} container>
      {fetchCashDrawersError && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {cashDrawersAreLoading && <CircularProgress color="inherit" />}
      <Grid
        className={styles.cashDrawers}
        item
        sx={{ marginTop: "30px" }}
        xs={12}
        s={12}
        md={12}
        lg={12}
      >
        {!cashDrawersAreLoading &&
          cashDrawers &&
          cashDrawers.CashDrawers.map(
            (cashDrawer) =>
              cashDrawer.casDraName && (
                <Button
                  id={`cash_drawer_${cashDrawer.id}`}
                  sx={{
                    marginLeft: "5px",
                    marginTop: "10px",
                    width: "180px",
                    height: "80px",
                  }}
                  size="large"
                  color="primary"
                  key={cashDrawer.id}
                  variant="contained"
                  onClick={() => selectCashDrawerHandler(cashDrawer.id)}
                  disabled={
                    (selectedCashDrawerId &&
                      selectedCashDrawerId === cashDrawer.id) ||
                    action === "view" ||
                    saleIsCanceled
                      ? true
                      : false
                  }
                >
                  {cashDrawer.casDraName && cashDrawer.casDraName}
                </Button>
              )
          )}
      </Grid>
    </Grid>
  );
};

export default CashDrawers;
