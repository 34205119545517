import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  tableRowClasses,
  TableCell,
  Chip,
} from "@mui/material";
import {
  eachMinuteOfInterval,
  setHours,
  setMinutes,
  areIntervalsOverlapping,
  addMinutes,
  subMinutes,
  isSameHour,
  isSameMinute,
  format,
  getHours,
} from "date-fns";
import Hour from "./Hour";
import Booking from "./Booking";
import AddBooking from "./AddBooking";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "40px",
  },
}));

const Resource = ({
  resource,
  companyWorkingHours,
  bookings,
  date,
  resourceType,
  translationModel,
  onViewBooking,
  onAddBooking,
}) => {
  const uniqueBookings = [];
  const timeStep = 30; //TODO: Configure in company
  const minBookingSpace = 10;
  const shortestServiceDuration = 30; //TODO: Update Automatically from services
  const printedHours = [];

  const resourceBookings =
    bookings?.bookings
      ?.filter((booking) => {
        if (resourceType === "personnel") {
          return booking.resourceType1 === resource.id;
        }
        if (resourceType === "cabin") {
          return booking.resourceType3 === resource.id;
        }
        return false;
      })
      .sort(
        (a, b) =>
          format(new Date(a.start), "HHmm") - format(new Date(b.start), "HHmm")
      ) || [];

  const startDateTime = setMinutes(
    setHours(new Date(date), companyWorkingHours[0]),
    0
  );

  const endDateTime = setMinutes(
    setHours(new Date(date), companyWorkingHours[1]),
    0
  );

  const createHourIntervals = (dateStart, dateEnd, step) => {
    return eachMinuteOfInterval(
      {
        start: dateStart,
        end: dateEnd,
      },
      { step: step }
    );
  };

  const workingHours = createHourIntervals(
    startDateTime,
    endDateTime,
    timeStep
  );

  const viewBookingHandler = (bookingId) => {
    onViewBooking(bookingId, resourceBookings);
  };

  const addBookingHandler = (hour, resource) => {
    onAddBooking(hour, resource, resourceBookings);
  };

  const isUnique = (booking) => {
    const findBooking = uniqueBookings.find(
      (bookingId) => bookingId === Number(booking.id)
    );
    if (findBooking) {
      return false;
    }
    uniqueBookings.push(Number(booking.id));
    return true;
  };

  const bookingOverlaps = (booking, hour) => {
    if (
      areIntervalsOverlapping(
        { start: new Date(booking.start), end: new Date(booking.end) },
        { start: new Date(hour), end: new Date(addMinutes(new Date(hour), 30)) }
      ) &&
      isUnique(booking)
    ) {
      const bookedHours = createHourIntervals(
        new Date(booking.start),
        new Date(subMinutes(new Date(booking.end), minBookingSpace)),
        timeStep
      );
      bookedHours.forEach((hour) => {
        printedHours.push({
          date: new Date(hour),
        });
      });
      return true;
    }
    return false;
  };

  const isHourSlotBooked = (dateTime) => {
    const isAlreadyPrinted = printedHours.some(
      (printedHour) =>
        isSameHour(new Date(printedHour.date), new Date(dateTime)) &&
        isSameMinute(new Date(printedHour.date), new Date(dateTime))
    );
    if (!isAlreadyPrinted) {
      printedHours.push({
        date: new Date(dateTime),
      });
    }
    return isAlreadyPrinted;
  };

  const hasBookingSpace = (dateTime) => {
    const isOverlapping = resourceBookings.some((booking) =>
      areIntervalsOverlapping(
        {
          start: addMinutes(new Date(dateTime), shortestServiceDuration),
          end: addMinutes(new Date(dateTime), shortestServiceDuration),
        },
        { start: new Date(booking.start), end: new Date(booking.end) }
      )
    );
    return !isOverlapping;
  };

  const isNotLastHour = (hour) =>
    shortestServiceDuration <= 30 && getHours(hour) !== companyWorkingHours[1];

  return (
    <TableContainer
      component={Paper}
      sx={{
        display: "inline-block",
        maxWidth: 250,
        marginRight: "10px",
        border: "1px solid rgb(198, 196, 196)",
        borderCollapse: "collapse",
        marginBottom: "150px",
      }}
      elevation={3}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Chip
                sx={{ marginTop: "10px", marginBottom: "10px" }}
                label={resource.resName}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workingHours &&
            workingHours.map((hour, index) => (
              <StyledTableRow key={index}>
                <TableCell align="left">
                  <Hour hour={hour} />
                  {resourceBookings &&
                    resourceBookings.map((booking) => {
                      return (
                        <Fragment key={booking.id}>
                          {bookingOverlaps(booking, hour) && (
                            <Booking
                              key={booking.id}
                              booking={booking}
                              onViewBooking={viewBookingHandler}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  {!isHourSlotBooked(hour) &&
                    hasBookingSpace(hour) &&
                    isNotLastHour(hour) && (
                      <AddBooking
                        hour={hour}
                        resource={resource}
                        translationModel={translationModel}
                        onAddBooking={addBookingHandler}
                      />
                    )}
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Resource;
