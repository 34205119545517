import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button, Grid, Typography } from "@mui/material";
import { WarningAmber } from "@mui/icons-material/";
import { useTranslation } from "react-i18next";

const ErrorPage = ({ onSetPageTitle, hasAccessData }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-2);
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(t(`errorPage.title`));
  };

  useEffect(() => {
    setPageTitleHandler();
  });

  return (
    <Container sx={{ height: "600px" }}>
      {hasAccessData?.accessRight === false && (
        <Grid container spacing={2} mt="100px">
          <Grid item xs={12} s={12} md={12} lg={12}></Grid>
          <Grid item xs={1} s={1} md={1} lg={1} sx={{ marginRight: "30px" }}>
            <WarningAmber sx={{ fontSize: "100px" }} />
          </Grid>
          <Grid item xs={6} s={6} md={6} lg={6}>
            <Typography variant="h4">{t("errorPage.title")}</Typography>
            <Typography variant="subtitle1">
              {t("errorPage.subtitle")}
            </Typography>
            <Typography variant="body1">
              {t("errorPage.description")}
            </Typography>
          </Grid>
          <Grid item xs={12} s={12} md={12} lg={12}>
            <Button id="go_back" variant="contained" onClick={goBack}>
              {t("buttons.back")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default ErrorPage;
