import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  model,
  translationModel,
  action,
  addSaleNote,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const defaultWarehouse = useSelector((state) => state.auth.defaultWarehouse);
  const [cancel, setCancel] = useState(false);
  let loadForm = true;
  const { enteredValue: date, reset: resetDate } = useInput();
  const {
    enteredValue: client,
    setEnteredValue: setClient,
    valueChangeHandler: clientChangeHandler,
    inputBlurHandler: clientBlurHandler,
    reset: resetClient,
  } = useInput();

  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: warehouse,
    setEnteredValue: setWarehouse,
    valueChangeHandler: warehouseChangeHandler,
    valueIsValid: warehouseIsValid,
    hasError: warehouseHasError,
    inputBlurHandler: warehouseBlurHandler,
    reset: resetWarehouse,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: warehouses,
    isLoading: warehousesAreLoading,
    sendRequest: fetchWarehouses,
  } = useHttp();

  const getWarehouses = () => {
    fetchWarehouses({
      url: process.env.REACT_APP_API_SERVER + "/warehouses",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: pointsCard,
    setEnteredValue: setPointsCard,
    reset: resetPointsCard,
  } = useInput();

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    inputBlurHandler: observationsBlurHandler,
    reset: resetObservations,
  } = useInput();

  const cancelChangeHandler = () => {
    setCancel(!cancel);
  };

  const resetCancel = () => {
    setCancel("");
  };

  let formIsValid = false;

  if (warehouseIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let cancelValue = null;
    if (cancel === true) {
      cancelValue = 1;
    }
    if (cancel === false) {
      cancelValue = null;
    }

    if (action === "add") {
      const modelData = {
        id: 1,
        salClientID: client ? client : null,
        salCancel: cancelValue,
        saleObservations: observations ? observations : null,
        salDate: date ? date : null,
        salLastUpdate: new Date(),
        salWarOutput: warehouse,
        salPoiCarNumber: pointsCard ? pointsCard : null,
        salTotalTaxes: 0,
        salTotalItems: 0,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.sale.id,
        salClientID: client ? client : null,
        salCancel: cancelValue,
        saleObservations: observations ? observations : null,
        salDate: date ? date : null,
        salLastUpdate: new Date(),
        salWarOutput: warehouse,
        salPoiCarNumber: pointsCard ? pointsCard : null,
      };
      onUpdate(modelData);
    }
    resetDate();
    resetClient();
    resetWarehouse();
    resetPointsCard();
    resetObservations();
    resetCancel();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      dispatch(authActions.getDefaultWarehouse());
      getClients();
      getWarehouses();
      if (model && action) {
        setClient(model.sale.salClientID ? model.sale.salClientID : "");
        setWarehouse(model.sale.salWarOutput ? model.sale.salWarOutput : "");
        setPointsCard(
          model.sale.salPoiCarNumber ? model.sale.salPoiCarNumber : ""
        );
        setObservations(
          model.sale.saleObservations ? model.sale.saleObservations : ""
        );
        if (model.sale.salCancel === 1 || model.sale.salCancel === true) {
          setCancel(true);
        }
        if (!model.sale.salCancel) {
          setCancel(false);
        }
      }
      if (action === "add") {
        setWarehouse(defaultWarehouse);
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {clientsAreLoading && <CircularProgress color="inherit" />}
        {!clientsAreLoading && !addSaleNote && (
          <FormControl>
            <InputLabel id="type_label">
              {t(translationModel + ".form.client")}
            </InputLabel>
            <Select
              id="client"
              labelId="client_label"
              value={client}
              label={t(translationModel + ".form.client")}
              onChange={clientChangeHandler}
              onBlur={clientBlurHandler}
              disabled={action === "view"}
            >
              {clients &&
                clients.clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.cliLastName} {client.cliFirstName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {warehousesAreLoading && <CircularProgress color="inherit" />}
        {!warehousesAreLoading && !addSaleNote && (
          <FormControl error={warehouseHasError ? true : false}>
            <InputLabel id="type_label">
              {t(translationModel + ".form.warehouse")}*
            </InputLabel>
            <Select
              id="warehouse"
              labelId="warehouse_label"
              value={warehouse}
              label={t(translationModel + ".form.warehouse")}
              onChange={warehouseChangeHandler}
              onBlur={warehouseBlurHandler}
              disabled={
                action === "view" ||
                (model?.sale?.SaleDetails?.length > 0 &&
                  model?.sale?.salWarOutput !== null)
              }
            >
              {warehouses &&
                warehouses.Warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.id} {warehouse.warName}
                  </MenuItem>
                ))}
            </Select>
            {warehouseHasError && (
              <FormHelperText id="firstName-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel htmlFor="observations">
            {t(translationModel + ".form.observations")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white", height: "150px" }}
            id="observations"
            onChange={observationsChangeHandler}
            onBlur={observationsBlurHandler}
            value={observations}
            endAdornment={
              observations !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetObservations} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.observations")}
            disabled={action === "view"}
          />
        </FormControl>
        {action === "edit" && !addSaleNote && (
          <FormControlLabel
            control={
              <Switch
                id="cancel"
                checked={cancel === 1 || cancel === true ? true : false}
                onChange={cancelChangeHandler}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t(translationModel + ".form.cancel")}
          />
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
