import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";
import GridActions from "../UI/GridActions";

const ModelGrid = ({
  action,
  modelRows,
  isLoading,
  isReport,
  smallScreen,
  modelName,
  translationModel,
  onDeleteModel,
  onViewModel,
  onEditModel,
  onRefreshGrid,
  onPageChange,
  onPageSizeChange,
  onSearch,
}) => {
  const [alertDelete, setAlertDelete] = useState(false);
  const [Id, setId] = useState(null);
  const [rowCount, setRowCount] = useState(null);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [search, setSearch] = useState(null);
  const { t } = useTranslation("common");
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (Id) => {
    setAlertDelete(true);
    setId(Id);
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    onDeleteModel(Id);
  };

  const viewHandler = (Id) => {
    onViewModel(Id);
  };

  const editHandler = (Id) => {
    onEditModel(Id);
  };

  const refreshGridHandler = () => {
    onRefreshGrid();
  };

  const searchHandler = (searchText) => {
    onSearch(searchText);
  };

  const pageChangeHandler = (newPage, size, search) => {
    onPageChange(newPage, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    onPageSizeChange(size, search);
  };

  const columns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          total={params.row.salTotalAmount}
          amountDue={params.row.salAmountDue}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={modelName}
        />
      ),
    },
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "number",
      filterable: true,
      sortable: false,
    },
    {
      field: "proClient",
      headerName: t(translationModel + ".columns.client"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.Client && `${params.row.Client.salClientName || ""}`,
    },
    {
      field: "salCreationDate",
      headerName: t(translationModel + ".columns.creationDate"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        format(new Date(params.row.salCreationDate), "dd/MM/yyyy HH:mm"),
    },
    {
      field: "salTotalItems",
      headerName: t(translationModel + ".columns.totalItems"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "salTotalAmount",
      headerName: t(translationModel + ".columns.total"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.salTotalAmount &&
        "$" + parseFloat(params.row.salTotalAmount, 2).toFixed(2),
    },
    {
      field: "salTotalPayments",
      headerName: t(translationModel + ".columns.totalPayments"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.salTotalPayments &&
        "$" + parseFloat(params.row.salTotalPayments, 2).toFixed(2),
    },
    {
      field: "salAmountDue",
      headerName: t(translationModel + ".columns.amountDue"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.salAmountDue &&
        "$" + parseFloat(params.row.salAmountDue, 2).toFixed(2),
    },
    {
      field: "salBookings",
      headerName: t(translationModel + ".columns.bookings"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "salCancel",
      headerName: t(translationModel + ".columns.canceled"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Switch
          id={`canceled_${params.row.id}`}
          checked={
            params.row.salCancel === 1 || params.row.salCancel === true
              ? true
              : false
          }
          disabled
        />
      ),
    },
  ];

  isReport && columns.shift();
  isReport && columns.splice(7, 1);

  const CustomToolbar = () => {
    return (
      <GridToolbar
        action={action}
        isServerPagination={true}
        search={search}
        isReport={isReport}
        modelName={modelName}
        translationModel={translationModel}
        smallScreen={smallScreen}
        onRefreshGrid={refreshGridHandler}
        onSearch={searchHandler}
      />
    );
  };

  useEffect(() => {
    modelRows?.totalItems && setRowCount(modelRows?.totalItems);
    if (modelRows?.page) {
      modelRows?.page > 0
        ? setPage(parseInt(modelRows?.page - 1), 10)
        : setPage(parseInt(0, 10));
    }
    modelRows?.pageSize > 0
      ? setPageSize(parseInt(modelRows?.pageSize, 10))
      : setPageSize(5);
    modelRows?.search && setSearch(modelRows.search);
  }, [modelRows]);

  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", {
            model: t(translationModel + ".model"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t(translationModel + ".model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {modelRows.Sales !== undefined &&
        modelRows.Sales.length <= 0 &&
        !isLoading && <Alert severity="warning">{t("errors.noContent")}</Alert>}
      <DataGrid
        paginationMode={rowCount > 0 ? "server" : "client"}
        rows={modelRows?.Sales}
        columns={columns}
        rowCount={rowCount > 0 ? rowCount : 0}
        page={page && page}
        pageSize={pageSize ? pageSize : 5}
        onPageSizeChange={(newPageSize) =>
          pageSizeChangeHandler(newPageSize, search && search)
        }
        onPageChange={(newPage) =>
          pageChangeHandler(
            newPage,
            pageSize > 0 ? pageSize : 5,
            search && search
          )
        }
        rowsPerPageOptions={
          rowsPerPage.length > 0 ? rowsPerPage.map(Number) : [5]
        }
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          sorting: {},
        }}
      />
    </Fragment>
  );
};

export default ModelGrid;
