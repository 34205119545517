import React from "react";
import {
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import RefreshModelButton from "../UI/RefreshModelButton";
import SearchField from "./SearchField";

const GridToolbar = ({
  action,
  isReport,
  isServerPagination,
  modelName,
  smallScreen,
  search,
  onRefreshGrid,
  onSearch,
}) => {
  const refreshGridHandler = () => {
    onRefreshGrid();
  };
  const searchHandler = (searchText) => {
    onSearch(searchText);
  };

  return (
    <GridToolbarContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} s={4} md={6} lg={6}>
          {smallScreen && isReport && (
            <GridToolbarExport
              csvOptions={{
                fileName: "controlspa_" + modelName,
                delimiter: ";",
                utf8WithBom: true,
                allColumns: true,
              }}
            />
          )}
          {smallScreen && <GridToolbarColumnsButton />}
        </Grid>
        <Grid
          item
          xs={12}
          s={4}
          md={6}
          lg={6}
          display="flex"
          justifyContent="right"
        >
          {!isServerPagination && (
            <GridToolbarQuickFilter id={`${modelName}_quick_filter`} />
          )}
          {isServerPagination && (
            <SearchField
              id={`${modelName}_quick_filter`}
              search={search}
              onSearch={searchHandler}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          s={12}
          md={12}
          lg={12}
          display="flex"
          justifyContent="right"
        >
          {!action && <RefreshModelButton onRefreshGrid={refreshGridHandler} />}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default GridToolbar;
