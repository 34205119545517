import React, { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import styles from "./styles.module.css";
import ModelGrid from "./ModelGrid";

const StockMovementDetails = ({ action, token, onSetPageTitle }) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const { type } = useParams();
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const modelName = "stock_update_details";
  const translationModel = "stockMovementDetails";
  const modelTypeValues = {
    output: 2,
    exchange: 3,
    default: 1,
  };
  let modelType = modelTypeValues[type] || modelTypeValues["default"];
  let refreshGrid = true;

  const isReport = () => {
    return location.pathname.includes("/reports") ? true : false;
  };

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    await sendModelRowsGetRequest({
      url: `${
        process.env.REACT_APP_API_SERVER
      }/${modelName}?type=${modelType}&page=${reqPage}&pageSize=${reqSize}&&search=${
        searchText ?? ""
      }`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(
      t(translationModel + ".title", {
        movementType: t(translationModel + ".movementType." + type),
      })
    );
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    if (refreshGrid) {
      setPageTitleHandler();
      !modelRows && getModelRowsHandler(0);
      // eslint-disable-next-line
      refreshGrid = false;
    }
  }, [sendModelRowsGetRequest, refreshGrid, type, modelRows]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={modelRowsAreLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography id={modelName + "_title"} mt="100px" variant="h4">
        {t(translationModel + ".title", {
          movementType: t(translationModel + ".movementType." + type),
        })}{" "}
        {action && "- " + t("actions." + action)}
      </Typography>
      {fetchModelRowsError && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!modelRowsAreLoading && modelRows && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            isLoading={modelRowsAreLoading}
            isReport={isReport()}
            modelRows={modelRows}
            smallScreen={smallScreen}
            modelName={modelName}
            translationModel={translationModel}
            movementType={type}
            onRefreshGrid={getModelRowsHandler}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onSearch={searchHandler}
          />
        </Paper>
      )}
    </Fragment>
  );
};
export default StockMovementDetails;
