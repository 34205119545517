import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import AddModelButton from "../UI/AddModelButton";
import styles from "./styles.module.css";
import ModelGrid from "./ModelGrid";
import ModelForm from "./ModelForm";

const BookingStatus = ({ token, action: propAction, onSetPageTitle }) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const modelName = "session_status";
  const translationModel = "bookingStatus";
  let refreshGrid = true;

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: model,
    setData: setModel,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const getModelHandler = async (modelId) => {
    await sendModelGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/" + modelId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getModelRowsHandler = async () => {
    await sendModelRowsGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const createdHandler = async (modelData) => {
    await sendCreateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/create",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchCreateError) {
      setCreateSuccess(true);
      setTimeout(() => {
        setCreateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler();
    setModel("");
    setAction("");
  };

  const updateHandler = async (modelData) => {
    await sendUpdateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler();
    setModel("");
    setAction("");
  };

  const deleteHandler = async (modelId) => {
    await sendDeleteRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: modelId },
    });

    if (!fetchDeleteError) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler();
  };

  const viewHandler = (modelId) => {
    setAction("view");
    getModelHandler(modelId);
  };

  const editHandler = (modelId) => {
    setAction("edit");
    getModelHandler(modelId);
  };

  const addHandler = () => {
    setModel("");
    setAction("add");
  };

  const cancelHandler = () => {
    setModel("");
    setAction("");
    actionParam && window.close();
  };

  const formTitle = () => {
    let title =
      t(translationModel + ".title") +
      (action ? " - " + t("actions." + action) : "");
    return title;
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(t(`${translationModel}.title`));
  };

  useEffect(() => {
    actionParam && setAction(actionParam);

    id && getModelHandler(id);
    setPageTitleHandler();
    propAction && setAction(propAction);
    getModelRowsHandler();
    // eslint-disable-next-line
    refreshGrid = false;
  }, [
    sendModelGetRequest,
    sendModelRowsGetRequest,
    sendDeleteRequest,
    refreshGrid,
    propAction,
  ]);

  return (
    <Fragment>
      {modelIsLoading ||
        modelRowsAreLoading ||
        deleteIsLoading ||
        updateIsLoading ||
        (createIsLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={
              modelIsLoading ||
              modelRowsAreLoading ||
              deleteIsLoading ||
              updateIsLoading ||
              createIsLoading
            }
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ))}

      {formTitle() && (
        <Typography id={modelName + "_title"} mt="100px" variant="h4">
          {formTitle()}
          {!action && (
            <AddModelButton
              modelName={"booking_status"}
              translationModel={translationModel}
              onAdd={addHandler}
            />
          )}
        </Typography>
      )}
      {(fetchModelError ||
        fetchModelRowsError ||
        fetchCreateError ||
        fetchUpdateError ||
        fetchDeleteError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {(!modelRowsAreLoading || deleteIsLoading) && modelRows && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            modelRows={modelRows}
            isLoading={modelRowsAreLoading}
            smallScreen={smallScreen}
            modelName={modelName}
            translationModel={translationModel}
            onDeleteModel={deleteHandler}
            onViewModel={viewHandler}
            onEditModel={editHandler}
            onRefreshGrid={getModelRowsHandler}
          />
        </Paper>
      )}
      {action && (
        <ModelForm
          action={action}
          model={model}
          modelRows={modelRows}
          translationModel={translationModel}
          onCancel={cancelHandler}
          onCreate={createdHandler}
          onUpdate={updateHandler}
        />
      )}
    </Fragment>
  );
};
export default BookingStatus;
