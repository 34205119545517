import React, { useEffect, Fragment } from "react";
import useHttp from "../../hooks/use-http";
import { useTranslation } from "react-i18next";
import { CircularProgress, Alert, Typography, Box } from "@mui/material";

const Totals = ({
  action,
  redirect,
  modelId,
  module,
  token,
  translationModel,
  updateSuccess,
}) => {
  const { t } = useTranslation("common");
  const formatCurrency = (amount) => {
    return `$${parseFloat(amount, 2).toFixed(2)}`;
  };
  const getAmountDueColor = (amountDue) => {
    if (amountDue === 0) {
      return "green";
    }
    if (amountDue > 0) {
      return "red";
    }
    if (amountDue < 0) {
      return "black";
    }
  };

  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };

  const isPurchasesModule = () => {
    return (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    );
  };

  const getSubtotalAmount = () => {
    if (!modelData) return 0;
    const salSubtotalAmount = modelData.sale?.salSubTotalAmount;
    const purSubtotalAmount = modelData.purchase?.purSubTotalAmount;
    if (
      (isSalesModule() && salSubtotalAmount > 0) ||
      (isPurchasesModule() && purSubtotalAmount > 0)
    ) {
      return isSalesModule() ? salSubtotalAmount : purSubtotalAmount;
    } else {
      return 0;
    }
  };

  const getTotalAmount = () => {
    if (!modelData) return 0;
    const salTotalAmount = modelData.sale?.salTotalAmount;
    const purTotalAmount = modelData.purchase?.purTotalAmount;
    if (
      (isSalesModule() && salTotalAmount > 0) ||
      (isPurchasesModule() && purTotalAmount > 0)
    ) {
      return isSalesModule() ? salTotalAmount : purTotalAmount;
    } else {
      return 0;
    }
  };

  const getDiscount = () => {
    if (!modelData) return 0;
    const salDiscount = modelData.sale?.salDiscount;
    const purDiscount = modelData.purchase?.purDiscount;
    if (
      (isSalesModule() && salDiscount > 0) ||
      (isPurchasesModule() && purDiscount > 0)
    ) {
      return isSalesModule() ? salDiscount : purDiscount;
    } else {
      return 0;
    }
  };

  const getTotalTaxes = () => {
    if (!modelData) return 0;
    const salTotalTaxes = modelData.sale?.salTotalTaxes;
    const purTotalTaxes = modelData.purchase?.purTotalTaxes;
    if (
      (isSalesModule() && salTotalTaxes > 0) ||
      (isPurchasesModule() && purTotalTaxes > 0)
    ) {
      return isSalesModule() ? salTotalTaxes : purTotalTaxes;
    } else {
      return 0;
    }
  };

  const getAmountDue = () => {
    if (!modelData) return 0;
    const salTotalPayments = modelData.sale?.salTotalPayments;
    const purTotalPayments = modelData.purchase?.purTotalPayments;
    const salTotalAmount = modelData.sale?.salTotalAmount;
    const purTotalAmount = modelData.purchase?.purTotalAmount;

    if (
      (isSalesModule() && salTotalPayments > 0) ||
      (isPurchasesModule() && purTotalPayments > 0)
    ) {
      return isSalesModule()
        ? salTotalAmount - salTotalPayments
        : purTotalAmount - purTotalPayments;
    } else if (
      (isSalesModule() && !salTotalPayments && salTotalAmount > 0) ||
      (isPurchasesModule() && !purTotalPayments && purTotalAmount > 0)
    ) {
      return isSalesModule() ? salTotalAmount : purTotalAmount;
    } else {
      return 0;
    }
  };

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: modelData,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const getModelHandler = async () => {
    let url = `${process.env.REACT_APP_API_SERVER}/sales/${modelId}`;
    if (isPurchasesModule()) {
      url = `${process.env.REACT_APP_API_SERVER}/purchases/${modelId}`;
    }
    await sendModelGetRequest({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  useEffect(() => {
    modelId && getModelHandler();
    // eslint-disable-next-line
  }, [modelId, action, updateSuccess]);

  return (
    <Fragment>
      {modelIsLoading && <CircularProgress color="inherit" />}
      {fetchModelError && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      <table style={{ paddingLeft: "40px" }}>
        <tbody>
          <tr>
            <th align="right">
              {!redirect && (
                <Box
                  id="model_details_totals_subtotal"
                  pt={2}
                  sx={{ typography: "h6", fontWeight: "bold" }}
                >
                  {t(translationModel + ".columns.saleDetails.subtotal") + ":"}
                </Box>
              )}
              {redirect && (
                <Fragment key="model_details_totals_subtotal">
                  {t(translationModel + ".columns.saleDetails.subtotal") + ":"}
                </Fragment>
              )}
            </th>
            <td>
              <Box
                id="model_details_totals_subtotal_amount"
                pt={!redirect ? 2 : 0}
                sx={{ typography: !redirect ? "h6" : "" }}
              >
                {formatCurrency(getSubtotalAmount())}
              </Box>
            </td>
            <th>
              {!redirect && (
                <Box
                  id="model_details_totals_total"
                  pt={2}
                  pl={2}
                  sx={{ typography: "h6", fontWeight: "bold" }}
                >
                  {t(translationModel + ".columns.saleDetails.total") + ":"}
                </Box>
              )}
              {redirect && (
                <Fragment key="model_details_totals_total">
                  {t(translationModel + ".columns.saleDetails.total") + ":"}
                </Fragment>
              )}
            </th>
            <td>
              <Box
                id="model_details_totals_total_amount"
                pt={!redirect ? 2 : 0}
                sx={{ typography: !redirect ? "h6" : "" }}
              >
                {formatCurrency(getTotalAmount())}
              </Box>
            </td>
          </tr>
          <tr>
            {isSalesModule() && (
              <Fragment>
                <th align="right">
                  {redirect ? (
                    t(translationModel + ".columns.saleDetails.discount") + ":"
                  ) : (
                    <Box
                      id="model_details_totals_discount"
                      pt={1}
                      sx={{ typography: "h6", fontWeight: "bold" }}
                    >
                      {t(translationModel + ".columns.saleDetails.discount") +
                        ":"}
                    </Box>
                  )}
                </th>
                <td>
                  <Box
                    id="model_details_totals_discount_amount"
                    pt={redirect ? 0 : 1}
                    sx={{ typography: redirect ? "" : "h6" }}
                  >
                    {formatCurrency(getDiscount())}
                  </Box>
                </td>
              </Fragment>
            )}
            {isPurchasesModule() && (
              <Fragment>
                <th align="right">
                  {redirect ? (
                    t(translationModel + ".columns.saleDetails.tax") + ":"
                  ) : (
                    <Box
                      id="model_details_totals_tax"
                      pt={1}
                      sx={{ typography: "h6", fontWeight: "bold" }}
                    >
                      {t(translationModel + ".columns.saleDetails.tax") + ":"}
                    </Box>
                  )}
                </th>
                <td>
                  <Box
                    id="model_details_totals_tax_amount"
                    pt={redirect ? 0 : 1}
                    sx={{ typography: redirect ? "" : "h6" }}
                  >
                    {formatCurrency(getTotalTaxes())}
                  </Box>
                </td>
              </Fragment>
            )}
            <th>
              {!redirect && (
                <Box
                  id="model_details_totals_amount_due"
                  pt={1}
                  pl={2}
                  sx={{ typography: "h6", fontWeight: "bold" }}
                >
                  {t(translationModel + ".columns.saleDetails.amountDue") + ":"}
                </Box>
              )}
              {redirect && (
                <Fragment key="model_details_totals_amount_due">
                  {t(translationModel + ".columns.saleDetails.amountDue") + ":"}
                </Fragment>
              )}
            </th>
            <td>
              <Typography
                id="model_details_totals_amount_due_amount"
                pt={1}
                variant={redirect ? "h7" : "h6"}
                sx={{
                  color:
                    modelData && getAmountDueColor(getAmountDue(modelData)),
                }}
              >
                <Fragment key="model_details_totals_amount_due">
                  {formatCurrency(getAmountDue(modelData))}
                </Fragment>
              </Typography>
            </td>
          </tr>
          {isSalesModule() && (
            <tr>
              <th align="right">
                {!redirect && (
                  <Box
                    id="model_details_totals_tax"
                    pt={1}
                    sx={{ typography: "h6", fontWeight: "bold" }}
                  >
                    {t(translationModel + ".columns.saleDetails.tax") + ":"}
                  </Box>
                )}
                {redirect && (
                  <Fragment key="model_details_totals_tax">
                    {t(translationModel + ".columns.saleDetails.tax") + ":"}
                  </Fragment>
                )}
              </th>
              <td>
                <Typography
                  id="model_details_totals_tax_amount"
                  pt={1}
                  variant={redirect ? "h7" : "h6"}
                >
                  {formatCurrency(getTotalTaxes())}
                </Typography>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default Totals;
