import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { stockActions } from "../../../store/stock-slice";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../../hooks/use-http";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import styles from "./styles.module.css";
import ModelGrid from "./ModelGrid";
import Form from "../Form/Form";
import AddModelButton from "../../UI/AddModelButton";

const StockMovements = ({ token, onSetPageTitle }) => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const { t } = useTranslation("common");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [createSuccess, setCreateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const modelName = "stock_updates";
  const translationModel = "stockMovements";
  const stockMovementId = useSelector((state) => state.stock.stockMovementId);
  let refreshGrid = true;

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    const modelTypeValues = {
      output: 2,
      exchange: 3,
      default: 1,
    };

    let modelType = modelTypeValues[type] || modelTypeValues["default"];

    await sendModelRowsGetRequest({
      url: `${
        process.env.REACT_APP_API_SERVER
      }/${modelName}?type=${modelType}&page=${reqPage}&pageSize=${reqSize}&search=${
        searchText ?? ""
      }`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const [modelRow, setModelRow] = useState(null);
  const [modelRowIsLoading, setModelRowIsLoading] = useState(false);
  const [fetchModelRowError, setFetchModelRowError] = useState(null);

  const getModelRowHandler = async (modelId) => {
    try {
      setModelRowIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/${modelName}/${modelId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (!response.ok) {
        setFetchModelRowError(true);
      }
      const data = await response.json();
      setModelRow(data);
    } catch (error) {
      setFetchModelRowError(true);
    }
  };

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    data: stockMovementCreateData,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const createStockMovementHandler = async () => {
    let stockUpdateType = 1;

    if (type === "output") {
      stockUpdateType = 2;
    }
    if (type === "exchange") {
      stockUpdateType = 3;
    }

    const requestBody = {
      ...{ stoUpdType: stockUpdateType },
      ...{ stoUpdDate: new Date() },
      ...((stockUpdateType === 1 || stockUpdateType === 3) && {
        stoUpdWarInID: 1,
      }),
      ...((stockUpdateType === 2 || stockUpdateType === 3) && {
        stoUpdWarOutID: 2,
      }),
      ...{ stoUpdCaptured: 1 }, //TODO: Assign current user's executive
      ...{ stoUpdCanceled: 0 },
      ...{ stoUpdStatus: 1 },
    };

    await sendCreateRequest({
      method: "POST",
      url: process.env.REACT_APP_API_SERVER + "/stock_updates/create/",
      body: requestBody,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
    if (!fetchCreateError) {
      setAction("add");
      setCreateSuccess(true);
      setTimeout(() => {
        setCreateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
  };

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const deleteHandler = async (modelId) => {
    await sendDeleteRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: modelId },
    });
    if (!fetchDeleteError) {
      getModelRowsHandler(0);
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const viewHandler = (modelId) => {
    setAction("view");
    dispatch(stockActions.setStockMovementId(modelId));
    getModelRowHandler(modelId);
  };

  const editHandler = (modelId) => {
    setAction("");
    setTimeout(() => {
      setAction("edit");
    }, 100);
    dispatch(stockActions.setStockMovementId(modelId));
    getModelRowHandler(modelId);
  };

  const addHandler = () => {
    setAction("add");
    dispatch(stockActions.setStockMovementId(""));
    createStockMovementHandler();
  };

  const cancelHandler = () => {
    setAction("");
    getModelRowsHandler(0);
    dispatch(stockActions.setStockMovementId(""));
    actionParam && window.close();
  };

  const updateStockMovementData = (modelId) => {
    dispatch(stockActions.setStockMovementId(modelId));
    getModelRowHandler(modelId);
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(
      t(translationModel + ".title", {
        movementType: t(translationModel + ".movementType." + type),
      })
    );
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    setPageTitleHandler();
    let newStockMovementId = 0;
    if (refreshGrid === true) {
      if (id && !modelRow && actionParam === "view") {
        viewHandler(id);
      }
      if (id && !modelRow && actionParam === "edit") {
        editHandler(id);
      }
      if (!id && !modelRow && !actionParam) {
        getModelRowsHandler(0);
      }
    }
    if (
      stockMovementCreateData &&
      stockMovementCreateData.StockUpdate.id !== stockMovementId
    ) {
      newStockMovementId = stockMovementCreateData?.StockUpdate?.id;
      getModelRowHandler(stockMovementCreateData?.StockUpdate?.id);
    }
    if (modelRow && modelRow?.StockUpdate?.id === newStockMovementId) {
      editHandler(modelRow.StockUpdate.id, modelRow.StockUpdate);
    }
    // eslint-disable-next-line
    refreshGrid = false;
  }, [
    sendModelRowsGetRequest,
    sendDeleteRequest,
    refreshGrid,
    stockMovementCreateData,
    modelRow,
    type,
  ]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          (modelRowIsLoading && modelRowsAreLoading) ||
          deleteIsLoading ||
          createIsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(!action || action === "add") && (
        <Typography id={modelName + "_title"} mt="100px" pb="0px" variant="h4">
          {t(translationModel + ".title", {
            movementType: t(translationModel + ".movementType." + type),
          })}{" "}
          {action && "- " + t("actions." + action)}
          {!action && (
            <AddModelButton
              modelName={modelName}
              translationModel={translationModel}
              movementType={type}
              onAdd={addHandler}
            />
          )}
        </Typography>
      )}
      {(fetchModelRowError ||
        fetchModelRowsError ||
        fetchCreateError ||
        fetchDeleteError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".movementType." + type),
          })}
        </Alert>
      )}
      {(!modelRowsAreLoading || deleteIsLoading) && modelRows && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            modelRows={modelRows && modelRows}
            isLoading={modelRowsAreLoading}
            smallScreen={smallScreen}
            modelName={modelName}
            translationModel={translationModel}
            type={type}
            onDeleteModel={deleteHandler}
            onViewModel={viewHandler}
            onEditModel={editHandler}
            onRefreshGrid={getModelRowsHandler}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onSearch={searchHandler}
          />
        </Paper>
      )}
      {stockMovementId &&
        action &&
        modelRow &&
        (action === "edit" || action === "view") && (
          <Form
            token={token}
            action={action}
            translationModel={translationModel}
            type={type}
            stockMovementId={stockMovementId}
            movementData={modelRow}
            onCancel={cancelHandler}
            onUpdateStockMovement={updateStockMovementData}
            onStockMovementChange={editHandler}
          />
        )}
    </Fragment>
  );
};
export default StockMovements;
