import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import styles from "./Clients.module.css";
import ModelGrid from "./ModelGrid";
import ModelForm from "./ModelForm";
import AddModelButton from "../UI/AddModelButton";

const Clients = ({ token, onSetPageTitle }) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const modelName = "clients";
  const translationModel = "clients";
  let refreshGrid = true;

  const isReport = () => {
    return location.pathname.includes("/reports") ? true : false;
  };

  const {
    isLoading: clientIsLoading,
    error: fetchClientError,
    data: client,
    setData: setClient,
    sendRequest: sendClientGetRequest,
  } = useHttp();

  const {
    isLoading: clientsAreLoading,
    error: fetchClientsError,
    data: clients,
    sendRequest: sendClientsGetRequest,
  } = useHttp();

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const getClientHandler = async (clientId) => {
    await sendClientGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/clients/" + clientId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getClientsHandler = async (page, size, searchText) => {
    await sendClientsGetRequest({
      url: `${process.env.REACT_APP_API_SERVER}/clients?page=${
        page > 0 ? page : 1
      }&pageSize=${size > 0 ? size : pageSize}&search=${searchText ?? ""}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const createdHandler = async (clientData) => {
    await sendCreateRequest({
      url: process.env.REACT_APP_API_SERVER + "/clients/create",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: clientData,
    });

    if (!fetchCreateError) {
      setCreateSuccess(true);
      setTimeout(() => {
        setCreateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getClientsHandler();
    setClient("");
    setAction("");
  };

  const updateHandler = async (clientData) => {
    await sendUpdateRequest({
      url: process.env.REACT_APP_API_SERVER + "/clients/",
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: clientData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getClientsHandler();
    setClient("");
    setAction("");
  };

  const deleteHandler = async (clientID) => {
    await sendDeleteRequest({
      url: process.env.REACT_APP_API_SERVER + "/clients/",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: clientID },
    });

    if (!fetchDeleteError) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getClientsHandler();
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getClientsHandler(1, pageSize, search);
  };

  const viewHandler = (clientID) => {
    setAction("view");
    getClientHandler(clientID);
  };

  const editHandler = (clientID) => {
    setAction("edit");
    getClientHandler(clientID);
  };

  const addHandler = () => {
    setClient("");
    setAction("add");
  };

  const cancelHandler = () => {
    setClient("");
    setAction("");
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(t(`${translationModel}.title`));
  };

  const pageChangeHandler = (page, size, search) => {
    getClientsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getClientsHandler(1, size, search);
  };

  useEffect(() => {
    if (refreshGrid) {
      actionParam && setAction(actionParam);

      id && getClientHandler(id);
      setPageTitleHandler();
      getClientsHandler(0);
    }
    // eslint-disable-next-line
    refreshGrid = false;
  }, [
    sendClientGetRequest,
    sendClientsGetRequest,
    sendDeleteRequest,
    refreshGrid,
  ]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          clientIsLoading ||
          clientsAreLoading ||
          deleteIsLoading ||
          updateIsLoading ||
          createIsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography id="clients_title" mt="100px" variant="h4">
        {t("clients.title")} {action && "-" + t("actions." + action)}
        {!action && (
          <AddModelButton
            modelName={modelName}
            translationModel={translationModel}
            onAdd={addHandler}
          />
        )}
      </Typography>
      {(fetchClientError ||
        fetchClientsError ||
        fetchCreateError ||
        fetchUpdateError ||
        fetchDeleteError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", { model: t("clients.model") })}
        </Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", { model: t("clients.model") })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", { model: t("clients.model") })}
        </Alert>
      )}
      {(!clientsAreLoading || deleteIsLoading) && clients && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            clients={clients}
            isLoading={clientsAreLoading}
            isReport={isReport()}
            smallScreen={smallScreen}
            modelName={modelName}
            pageSize={pageSize}
            onPageSizeChange={pageSizeChangeHandler}
            onDeleteClient={deleteHandler}
            onViewClient={viewHandler}
            onEditClient={editHandler}
            onRefreshGrid={getClientsHandler}
            onPageChange={pageChangeHandler}
            onSearch={searchHandler}
          />
        </Paper>
      )}
      {action && (
        <ModelForm
          updateIsLoading={updateIsLoading}
          createIsLoading={createIsLoading}
          onCancel={cancelHandler}
          onCreate={createdHandler}
          onUpdate={updateHandler}
          action={action}
          client={client}
        />
      )}
    </Fragment>
  );
};
export default Clients;
