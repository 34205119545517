import React, { Fragment } from "react";
import Header from "./Header";
import TicketDetails from "./TicketDetails";
import Totals from "../Totals";
import ModelCharges from "./ModelCharges";

const Ticket = ({ module, modelData, token, translationModel }) => {
  const getModelId = (model) => {
    return model.sale?.id || model.purchase?.id;
  };
  return (
    <Fragment>
      <Header token={token} />
      <TicketDetails
        module={module}
        modelData={modelData}
        translationModel={translationModel}
      />
      <Totals
        token={token}
        modelId={modelData && getModelId(modelData)}
        module={module}
        translationModel={translationModel}
        redirect="pos"
      />
      <ModelCharges model={modelData} module={module} />
    </Fragment>
  );
};
export default Ticket;
