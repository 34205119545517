import React, { useState, useEffect, Fragment } from "react";
import useHttp from "../../../hooks/use-http";
import { Container, Grid, CircularProgress, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Categories from "../../POS/Categories";
import Products from "../../POS/Products";
import Details from "./Details";
import FooterFunctions from "./FooterFunctions";
import Notifications from "./Notifications";
import FormDialog from "../Dialogs/Dialog";

const Form = (props) => {
  const token = props.token;
  const translationModel = props.translationModel;
  const type = props.type;
  const stockMovementId = props.stockMovementId;
  const movementData = props.movementData;
  const { t } = useTranslation("common");
  const [action, setAction] = useState(props.action);
  const [category, setCategory] = useState("all");
  const [product, setProduct] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [formDialog, setFormDialog] = useState("");
  const [stockMovementsOnHold, setStockMovementsOnHold] = useState(null);
  const [viewProductId, setViewProductId] = useState(null);
  const selectedInputWarehouse =
    movementData?.StockUpdate?.stoUpdWarInID !== ""
      ? movementData?.StockUpdate?.stoUpdWarInID
      : localStorage.getItem("default_input_warehouse");
  const selectedOutputWarehouse =
    movementData?.StockUpdate?.stoUpdWarOutID !== ""
      ? movementData?.StockUpdate?.stoUpdWarOutID
      : localStorage.getItem("default_output_warehouse");

  const categoryChangeHandler = (categoryId) => {
    setCategory(categoryId);
  };

  const selectProductHandler = (operation, productId) => {
    setProduct(productId);
    setAction("");
    setFormDialog("addProduct");
  };

  const searchProductHandler = (searchCriteria) => {
    setCategory("all");
    setSearchCriteria(searchCriteria);
  };

  const {
    isLoading: stockMovementsOnHoldAreLoading,
    error: stockMovementsOnHoldFetchError,
    data: stockMovementsOnHoldData,
    sendRequest: stockMovementsOnHoldGetRequest,
  } = useHttp();

  const getStockMovementsOnHoldHandler = async () => {
    const modelTypeValues = {
      output: 2,
      exchange: 3,
      default: 1,
    };

    let modelType = modelTypeValues[type] || modelTypeValues["default"];

    await stockMovementsOnHoldGetRequest({
      url:
        process.env.REACT_APP_API_SERVER +
        "/" +
        "stock_updates/on_hold?type=" +
        modelType,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const { error: stockUpdateFetchError, sendRequest: stockUpdateRequest } =
    useHttp();

  const updateStockHandler = async (
    warehouseId,
    productId,
    operation,
    quantity
  ) => {
    await stockUpdateRequest({
      method: "PATCH",
      url: process.env.REACT_APP_API_SERVER + "/stock/update_stock_update",
      body: {
        salDetType: type,
        salDetWarehouseId: warehouseId,
        salDetProductID: productId,
        salDetAction: operation,
        salDetQuantity: quantity,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
    if (!stockUpdateFetchError) {
      setAction("update");
      setTimeout(() => {
        setAction("");
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
      props.onUpdateStockMovement(stockMovementId);
    }
  };

  const { sendRequest: stockMovementDetailsGetRequest } = useHttp();

  const stockMovementDetailsHandler = async (stockUpdateId) => {
    await stockMovementDetailsGetRequest({
      url:
        process.env.REACT_APP_API_SERVER +
        "/" +
        "stock_updates/" +
        stockUpdateId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    isLoading: stockMovementDetailCreateIsLoading,
    error: fetchStockMovementDetailCreateError,
    sendRequest: stockMovementDetailCreateRequest,
  } = useHttp();

  const createStockMovementDetailHandler = async (
    product,
    operation,
    stockMovementDetailId,
    amount
  ) => {
    await stockMovementDetailCreateRequest({
      method: "POST",
      url: process.env.REACT_APP_API_SERVER + "/stock_update_details/create/",
      body: {
        id: stockMovementDetailId,
        stoUpdDetOperation: operation,
        stoUpdDetStoUpdID: stockMovementId,
        stoUpdDetProdID: product.id,
        stoUpdDetQuantity: amount > 0 ? amount : 1,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
    if (!fetchStockMovementDetailCreateError) {
      const { stoUpdWarInID, stoUpdWarOutID } = movementData?.StockUpdate;
      const productId = product && !product.id ? product : product.id;
      let quantity = 1;

      if (amount > 0) {
        quantity = amount;
      }

      if (type === "input") {
        updateStockHandler(stoUpdWarInID, productId, operation, quantity);
      }

      if (type === "output" || type === "exchange") {
        updateStockHandler(stoUpdWarOutID, productId, operation, quantity);
      }

      if (type === "exchange") {
        updateStockHandler(stoUpdWarInID, productId, operation, quantity);
      }
      setAction("update");
      setTimeout(() => {
        setAction("");
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
  };

  const {
    isLoading: stockMovementUpdateLoading,
    error: fetchStockMovementUpdateError,
    sendRequest: stockMovementUpdateRequest,
  } = useHttp();

  const updateStockMovementHandler = async (
    stockMovementId,
    inputWarehouse,
    outputWarehouse,
    cancel,
    observations,
    status
  ) => {
    const requestBody = {
      ...{ id: stockMovementId },
      ...(inputWarehouse && { stoUpdWarInID: inputWarehouse }),
      ...(outputWarehouse && { stoUpdWarOutID: outputWarehouse }),
      ...(cancel && { stoUpdCanceled: cancel, stoUpdStatus: 0 }),
      ...(observations && { stoUpdObservations: observations }),
      ...(status === 0 && { stoUpdStatus: status }),
    };
    await stockMovementUpdateRequest({
      method: "PATCH",
      url: process.env.REACT_APP_API_SERVER + "/stock_updates/",
      body: requestBody,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });

    if (!fetchStockMovementUpdateError) {
      setAction("update");
      setTimeout(() => {
        setAction("");
      }, process.env.REACT_APP_ALERTS_TIMEOUT);

      props.onUpdateStockMovement(stockMovementId);
    }
  };

  const viewProductHandler = (productId) => {
    setFormDialog("viewProduct");
    setViewProductId(productId);
  };

  const deleteHandler = (product, productQuantity) => {
    let warehouseId = null;
    if (type === "input") {
      warehouseId = selectedInputWarehouse;
    }
    if (type === "output") {
      warehouseId = selectedOutputWarehouse;
    }
    updateStockHandler(
      warehouseId,
      product && !product.id ? product : product.id,
      "delete",
      productQuantity
    );
    setAction("delete");
    setTimeout(() => {
      setAction("");
    }, process.env.REACT_APP_ALERTS_TIMEOUT);
  };

  const selectWarehouseHandler = (warehouseId, type) => {
    const inputWarehouseId = type === "input" ? warehouseId : "";
    const outputWarehouseId = type === "output" ? warehouseId : "";
    updateStockMovementHandler(
      stockMovementId,
      inputWarehouseId,
      outputWarehouseId
    );
  };

  const confirmCloseHandler = () => {
    props.onCancel();
  };

  const confirmCancelHandler = () => {
    if (type === "input") {
      updateStockMovementHandler(
        stockMovementId,
        selectedInputWarehouse,
        "",
        1
      );
    }
    if (type === "output") {
      updateStockMovementHandler(
        stockMovementId,
        selectedOutputWarehouse,
        "",
        1
      );
    }
    if (type === "exchange") {
      updateStockMovementHandler(
        stockMovementId,
        selectedInputWarehouse,
        "",
        1
      );
      updateStockMovementHandler(
        stockMovementId,
        selectedOutputWarehouse,
        "",
        1
      );
    }
  };

  const closeMovementHandler = () => {
    updateStockMovementHandler(stockMovementId, "", "", "", "", 0);
  };

  const dialogHandler = (type) => {
    setFormDialog(type);
  };

  const dialogConfirmHandler = (
    warehouseId,
    observations,
    movementId,
    stockMovementData
  ) => {
    const handleStockMovementChange = (direction) => {
      if (stockMovementsOnHoldData.StockUpdatesOnHold.length > 1) {
        const currentStockMovementOnHoldPosition =
          stockMovementsOnHoldData.StockUpdatesOnHold.findIndex(
            (stockUpdateOnHold) => stockUpdateOnHold.id === stockMovementId
          );
        const newStockMovementOnHoldPosition =
          currentStockMovementOnHoldPosition + direction;
        if (
          newStockMovementOnHoldPosition >= 0 &&
          newStockMovementOnHoldPosition <
            stockMovementsOnHoldData.StockUpdatesOnHold.length
        ) {
          const newStockMovementData =
            stockMovementsOnHoldData.StockUpdatesOnHold[
              newStockMovementOnHoldPosition
            ];
          props.onStockMovementChange(
            newStockMovementData.id,
            newStockMovementData
          );
        }
      }
    };

    switch (formDialog) {
      case "onHold":
        confirmCloseHandler();
        break;
      case "cancel":
        confirmCancelHandler();
        break;
      case "close":
        closeMovementHandler();
        break;
      case "inputWarehouses":
      case "inputExchangeWarehouses":
        selectWarehouseHandler(warehouseId, "input");
        break;
      case "outputWarehouses":
      case "outputExchangeWarehouses":
        selectWarehouseHandler(warehouseId, "output");
        break;
      case "observations":
        saveObservationsHandler(observations);
        break;
      case "stockMovementsOnHold":
        props.onStockMovementChange(movementId, stockMovementData);
        break;
      case "changeStockMovementOnHoldPrevious":
        handleStockMovementChange(-1);
        break;
      case "changeStockMovementOnHoldNext":
        handleStockMovementChange(1);
        break;
      default:
        break;
    }

    setFormDialog("");
  };

  const dialogConfirmQuantityHandler = (quantity, product) => {
    createStockMovementDetailHandler(product, "add", "", quantity);
    setFormDialog("");
  };

  const saveObservationsHandler = (formData) => {
    updateStockMovementHandler(
      stockMovementId,
      "",
      "",
      "",
      formData.stoUpdObservations
    );
  };

  useEffect(() => {
    !stockMovementsOnHold &&
      !stockMovementsOnHoldData &&
      getStockMovementsOnHoldHandler();
    !stockMovementsOnHold &&
      stockMovementsOnHoldData &&
      setStockMovementsOnHold(stockMovementsOnHoldData);
    stockMovementDetailsHandler(stockMovementId);
    // eslint-disable-next-line
  }, [
    product,
    category,
    action,
    stockMovementId,
    selectedInputWarehouse,
    selectedOutputWarehouse,
  ]);

  return (
    <Fragment>
      <Container maxWidth="xlg">
        {(stockMovementsOnHoldAreLoading || stockMovementUpdateLoading) && (
          <CircularProgress color="inherit" />
        )}
        {(stockMovementsOnHoldFetchError || fetchStockMovementUpdateError) && (
          <Alert severity="error">{t("errors.defaultError")}</Alert>
        )}
        <Header
          translationModel={translationModel}
          action={action}
          movementType={type}
          movementData={movementData}
          stockMovementId={stockMovementId}
          selectedInputWarehouse={selectedInputWarehouse}
          selectedOutputWarehouse={selectedOutputWarehouse}
          disabled={
            movementData?.StockUpdate?.stoUpdCanceled ||
            !movementData?.StockUpdate?.stoUpdStatus
          }
          stockMovementsOnHold={
            stockMovementsOnHoldData && stockMovementsOnHoldData
          }
          onStockMovementOnHold={dialogHandler}
          onShowWarehousesDialog={dialogHandler}
          onShowStockMovementsOnHold={dialogHandler}
          onChangeStockMovementOnHold={dialogHandler}
          onSearchProductHandler={searchProductHandler}
        />
        {(action === "update" || action === "delete") && (
          <Notifications
            translationModel={translationModel}
            notificationType={action}
            type={type}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={5} s={5} md={5} lg={5}>
            {fetchStockMovementDetailCreateError && (
              <Alert severity="error">{t("errors.defaultError")}</Alert>
            )}
            <Details
              token={token}
              action={action}
              translationModel={translationModel}
              stockMovementId={stockMovementId}
              createIsLoading={stockMovementDetailCreateIsLoading}
              createHasError={fetchStockMovementDetailCreateError}
              disabled={
                movementData?.StockUpdate?.stoUpdCanceled ||
                !movementData?.StockUpdate.stoUpdStatus
              }
              onViewProduct={viewProductHandler}
              onDeleteRow={deleteHandler}
              onUpdateProductQuantity={createStockMovementDetailHandler}
            />
            <FooterFunctions
              translationModel={translationModel}
              action={action}
              disabled={movementData?.StockUpdate?.stoUpdCanceled}
              isClosed={!movementData?.StockUpdate?.stoUpdStatus}
              onSetSaleOnHoldClick={dialogHandler}
              onCancelClick={dialogHandler}
              onCloseMovementClick={dialogHandler}
              onObservationsClick={dialogHandler}
              onPrintClick={dialogHandler}
            />
          </Grid>
          <Grid item xs={7} s={7} md={7} lg={7}>
            <Categories
              token={token}
              category={category}
              action={action}
              module="purchases"
              translationModel={translationModel}
              editable={
                movementData?.StockUpdate?.stoUpdCanceled ||
                !movementData?.StockUpdate?.stoUpdStatus
                  ? false
                  : true
              }
              onCategoryChange={categoryChangeHandler}
            />
            <Products
              token={token}
              action={action}
              module="purchases"
              productCategory={category && category}
              searchCriteria={searchCriteria && searchCriteria}
              editable={
                movementData?.StockUpdate?.stoUpdCanceled ||
                !movementData?.StockUpdate?.stoUpdStatus
                  ? false
                  : true
              }
              onSelectProductHandler={selectProductHandler}
            />
          </Grid>
        </Grid>
        {formDialog && (
          <FormDialog
            token={token}
            action={action}
            translationModel={translationModel}
            formDialog={formDialog}
            type={type}
            isCanceled={movementData?.StockUpdate?.stoUpdCanceled}
            movementData={movementData?.StockUpdate}
            stockMovementsOnHoldData={
              stockMovementsOnHoldData && stockMovementsOnHoldData
            }
            productId={viewProductId}
            product={product}
            onCancel={dialogHandler}
            onConfirm={dialogConfirmHandler}
            onConfirmQuantity={dialogConfirmQuantityHandler}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default Form;
