import React from "react";
import {
  Alert,
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const CompanyClientsByCategory = ({
  companyClientsByCategory,
  translationModel,
}) => {
  const { t } = useTranslation("common");
  const createGraphData = (data) => {
    if (data.length > 0) {
      const labels = data.map((item) => item.category.clieCatName);
      const dataValues = data.map((item) => parseInt(item.countByCategory, 10));
      const colors = data.map((item) => item.category.clieCatColor);
      return {
        labels: labels,
        datasets: [
          {
            label: t(translationModel + ".form.graphs.clientsByCategory.label"),
            data: dataValues,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      };
    }
  };

  return (
    <Grid container>
      <Grid item sx={{ marginTop: "30px" }} xs={12} s={12} md={12} lg={12}>
        <Card variant="outlined" sx={{ maxWidth: 360 }}>
          <Box sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography gutterBottom variant="h5" component="div">
                {t(translationModel + ".form.graphs.clientsByCategory.title")}
              </Typography>
            </Stack>
            <Typography color="text.secondary" variant="body2">
              {t(
                translationModel + ".form.graphs.clientsByCategory.description"
              )}
            </Typography>
            <Divider sx={{ paddingTop: "10px", marginBottom: "10px" }} />
            {companyClientsByCategory?.ClientsByCategory?.length === 0 && (
              <Alert severity="warning">{t("errors.noContent")}</Alert>
            )}
            {companyClientsByCategory && (
              <Pie
                data={
                  companyClientsByCategory?.ClientsByCategory &&
                  createGraphData(companyClientsByCategory.ClientsByCategory)
                }
              />
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClientsByCategory;
