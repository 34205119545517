import React, { useState, useRef, Fragment } from "react";
import useHttp from "../../hooks/use-http";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  FormControl,
  Button,
  OutlinedInput,
  IconButton,
  InputLabel,
  InputAdornment,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  AccountCircle,
  Clear,
} from "@mui/icons-material";
import { useEffect } from "react";

const LoginForm = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  const [values, setValues] = useState({});
  const [usernameDeleteAction, setUsernameDeleteAction] = useState(false);
  const [passwordDeleteAction, setPasswordDeleteAction] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const { isLoading, error, data, sendRequest: fetchLogin } = useHttp();
  const lastPage = useSelector((state) => state.auth.lastPage);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const usernameChangeHandler = () => {
    usernameInputRef.current?.value !== "" && setUsernameDeleteAction(true);
  };

  const passwordChangeHandler = () => {
    passwordInputRef.current?.value !== "" && setPasswordDeleteAction(true);
  };

  const clearUsername = () => {
    usernameInputRef.current.value = "";
    setUsernameDeleteAction(false);
  };

  const clearPassword = () => {
    passwordInputRef.current.value = "";
    setPasswordDeleteAction(false);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    const username = usernameInputRef.current.value;
    const password = passwordInputRef.current.value;
    const credentials = { username, password };

    await fetchLogin({
      url: process.env.REACT_APP_API_SERVER + "/users/login",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: credentials,
    });
  };

  const loginUser = () => {
    dispatch(authActions.setToken(data.access_token));
    dispatch(authActions.setUser(data.username));
    dispatch(authActions.setDefaultCashDrawer(data.user.defaultCashDrawer));
    dispatch(authActions.setDefaultWarehouse(data.user.defaultWarehouse));
    setRedirect(lastPage ? lastPage : data.redirect);
    clearUsername();
    clearPassword();
    if (location.pathname !== "/login" && location.pathname !== "/") {
      navigate(location.pathname);
    } else {
      navigate(redirect);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitFormHandler(event);
    }
  };

  useEffect(() => {
    setRedirect(null);
    if (data) {
      loginUser();
    }
    // eslint-disable-next-line
  }, [submitFormHandler, data]);

  return (
    <Fragment>
      {error && (
        <Alert id="error-alert" sx={{ marginBottom: "20px" }} severity="error">
          {error}
        </Alert>
      )}
      <Stack
        sx={{
          background: "#f5f5f5",
          padding: "20px 25px 30px 25px",
          border: "1px solid #ddd",
          borderRadius: "10px",
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        spacing={1}
        justifyContent="center"
        onKeyUp={handleKeyPress}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box m={1} p={1}>
          <Typography variant="h5">{t("login.title")}</Typography>
          <Typography variant="subtitle1">{t("login.subtitle")}</Typography>
        </Box>

        <FormControl variant="outlined" onSubmit={submitFormHandler}>
          <InputLabel htmlFor="username">
            {t("login.fields.username")}
          </InputLabel>
          <OutlinedInput
            sx={{ background: "white" }}
            id="username"
            type="text"
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            }
            endAdornment={
              usernameDeleteAction && (
                <InputAdornment position="start">
                  <Clear onClick={clearUsername} />
                </InputAdornment>
              )
            }
            label={t("login.fields.username")}
            inputRef={usernameInputRef}
            onChange={usernameChangeHandler}
            autoComplete="off"
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="password">
            {t("login.fields.password")}
          </InputLabel>
          <OutlinedInput
            sx={{ background: "white" }}
            id="password"
            type={values.showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                {passwordDeleteAction && (
                  <InputAdornment position="end">
                    <Clear onClick={clearPassword} />
                  </InputAdornment>
                )}
              </InputAdornment>
            }
            label={t("login.fields.password")}
            inputRef={passwordInputRef}
            onChange={passwordChangeHandler}
            autoComplete="off"
          />
        </FormControl>
        <Button
          id="login"
          variant="contained"
          onClick={submitFormHandler}
          disabled={usernameDeleteAction && passwordDeleteAction ? false : true}
        >
          {t("login.fields.login")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default LoginForm;
