import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
  Fab,
} from "@mui/material";
import { Add } from "@mui/icons-material/";
import styles from "./styles.module.css";
import ClinicHistoryModelGrid from "./grids/ClinicHistoryModelGrid";
import ClinicHistoryModelForm from "./forms/ClinicHistoryModelForm";
import BackgroundModelGrid from "./grids/BackgroundModelGrid";
import BackgroundModelForm from "./forms/BackgroundModelForm";
import ConsultationsModelGrid from "./grids/ConsultationsModelGrid";
import ConsultationsModelForm from "./forms/ConsultationsModelForm";
import PrescriptionsModelGrid from "./grids/PrescriptionsModelGrid";
import PrescriptionsModelForm from "./forms/PrescriptionsModelForm";
import GenericModelGrid from "./grids/GenericModelGrid";

const ClientRecords = ({ token, onSetPageTitle, recordType }) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const [genericRecordType, setGenericRecordType] = useState(null);
  const modelName = "client_records";
  const translationModel = "clientRecords";
  const { clientId } = useParams();
  let refreshGrid = true;

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: model,
    setData: setModel,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const getModelHandler = async (modelId) => {
    await sendModelGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/" + modelId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    const baseUrl = `${process.env.REACT_APP_API_SERVER}/${modelName}`;
    let url = clientId
      ? `${baseUrl}/client/${clientId}?page=${reqPage}&pageSize=${reqSize}&search=${
          searchText ?? ""
        }`
      : `${baseUrl}?type=${recordType}&page=${reqPage}&pageSize=${reqSize}&search=${
          searchText ?? ""
        }`;
    await sendModelRowsGetRequest({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const createdHandler = async (modelData) => {
    const {
      cliRecBirthDate,
      cliRecRHFactor,
      cliRecWeight,
      cliRecHeight,
      cliRecSex,
      ...rest
    } = modelData;
    const updatedModelData = {
      ...rest,
      cliRecBirthDate: cliRecBirthDate === "" ? null : cliRecBirthDate,
      cliRecRHFactor: cliRecRHFactor === "" ? null : cliRecRHFactor,
      cliRecWeight: cliRecWeight === "" ? null : cliRecWeight,
      cliRecHeight: cliRecHeight === "" ? null : cliRecHeight,
      cliRecSex: cliRecSex === "" ? null : cliRecSex,
    };

    await sendCreateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/create",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: updatedModelData,
    });

    if (!fetchCreateError) {
      setCreateSuccess(true);
      setTimeout(() => {
        setCreateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const updateHandler = async (modelData) => {
    const url = `${process.env.REACT_APP_API_SERVER}/${modelName}/`;
    await sendUpdateRequest({
      url,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const deleteHandler = async (modelId) => {
    await sendDeleteRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: modelId },
    });

    if (!fetchDeleteError) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const viewHandler = (modelId, modelData) => {
    recordType === 5 && setGenericRecordType(modelData.cliRecType);
    setAction("view");
    getModelHandler(modelId);
  };

  const editHandler = (modelId, modelData) => {
    recordType === 5 && setGenericRecordType(modelData.cliRecType);
    setAction("edit");
    getModelHandler(modelId);
  };

  const addHandler = () => {
    setModel("");
    setAction("add");
  };

  const cancelHandler = () => {
    setGenericRecordType(null);
    setModel("");
    setAction("");
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(
      t(translationModel + ".title", {
        recordType: t(translationModel + ".recordTypes." + recordType),
      })
    );
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    if (refreshGrid) {
      actionParam && setAction(actionParam);

      id && getModelHandler(id);
      setPageTitleHandler();
      !modelRows && getModelRowsHandler(0);
      // eslint-disable-next-line
      refreshGrid = false;
    }
  }, [
    sendModelGetRequest,
    sendModelRowsGetRequest,
    sendDeleteRequest,
    refreshGrid,
    modelRows,
  ]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          modelIsLoading ||
          modelRowsAreLoading ||
          deleteIsLoading ||
          updateIsLoading ||
          createIsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography id={modelName + "_title"} mt="100px" variant="h4">
        {t(translationModel + ".title", {
          recordType: t(translationModel + ".recordTypes." + recordType),
        })}{" "}
        {action && "- " + t("actions." + action)}
        {!action && !clientId && (
          <Fab
            id={modelName + "_action_add"}
            className={`${styles["action-buttons"]} ${styles["add-button"]}`}
            color="primary"
            size="medium"
            aria-label={t("buttons.add", {
              model: t(translationModel + ".model"),
            })}
            onClick={() => addHandler()}
          >
            <Add fontSize="medium" />
          </Fab>
        )}
      </Typography>
      {(fetchModelError ||
        fetchModelRowsError ||
        fetchCreateError ||
        fetchUpdateError ||
        fetchDeleteError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {(!modelRowsAreLoading || deleteIsLoading) && modelRows && !action && (
        <Paper className={styles.datagrid}>
          {recordType === 1 && (
            <BackgroundModelGrid
              modelRows={modelRows}
              isLoading={modelRowsAreLoading}
              smallScreen={smallScreen}
              modelName={modelName}
              translationModel={translationModel}
              recordType={recordType}
              onDeleteModel={deleteHandler}
              onViewModel={viewHandler}
              onEditModel={editHandler}
              onGetModelRowsHandler={getModelRowsHandler}
              onPageChange={pageChangeHandler}
              onPageSizeChange={pageSizeChangeHandler}
              onRefreshGrid={getModelRowsHandler}
              onSearch={searchHandler}
            />
          )}
          {recordType === 2 && (
            <ConsultationsModelGrid
              modelRows={modelRows}
              isLoading={modelRowsAreLoading}
              smallScreen={smallScreen}
              modelName={modelName}
              translationModel={translationModel}
              token={token}
              recordType={recordType}
              onDeleteModel={deleteHandler}
              onViewModel={viewHandler}
              onEditModel={editHandler}
              onGetModelRowsHandler={getModelRowsHandler}
              onPageChange={pageChangeHandler}
              onPageSizeChange={pageSizeChangeHandler}
              onRefreshGrid={getModelRowsHandler}
              onSearch={searchHandler}
            />
          )}
          {recordType === 3 && (
            <ClinicHistoryModelGrid
              modelRows={modelRows}
              isLoading={modelRowsAreLoading}
              smallScreen={smallScreen}
              modelName={modelName}
              translationModel={translationModel}
              recordType={recordType}
              onDeleteModel={deleteHandler}
              onViewModel={viewHandler}
              onEditModel={editHandler}
              onGetModelRowsHandler={getModelRowsHandler}
              onPageChange={pageChangeHandler}
              onPageSizeChange={pageSizeChangeHandler}
              onRefreshGrid={getModelRowsHandler}
              onSearch={searchHandler}
            />
          )}
          {recordType === 4 && (
            <PrescriptionsModelGrid
              modelRows={modelRows}
              isLoading={modelRowsAreLoading}
              smallScreen={smallScreen}
              modelName={modelName}
              translationModel={translationModel}
              recordType={recordType}
              onDeleteModel={deleteHandler}
              onViewModel={viewHandler}
              onEditModel={editHandler}
              onGetModelRowsHandler={getModelRowsHandler}
              onPageChange={pageChangeHandler}
              onPageSizeChange={pageSizeChangeHandler}
              onRefreshGrid={getModelRowsHandler}
              onSearch={searchHandler}
            />
          )}
          {recordType === 5 && (
            <GenericModelGrid
              modelRows={modelRows}
              isLoading={modelRowsAreLoading}
              smallScreen={smallScreen}
              modelName={modelName}
              translationModel={translationModel}
              recordType={recordType}
              onDeleteModel={deleteHandler}
              onViewModel={viewHandler}
              onEditModel={editHandler}
              onGetModelRowsHandler={getModelRowsHandler}
              onPageChange={pageChangeHandler}
              onPageSizeChange={pageSizeChangeHandler}
              onRefreshGrid={getModelRowsHandler}
              onSearch={searchHandler}
            />
          )}
        </Paper>
      )}
      {action && (
        <Fragment>
          {(recordType === 1 ||
            (recordType === 5 && genericRecordType === 1)) && (
            <BackgroundModelForm
              onCancel={cancelHandler}
              onCreate={createdHandler}
              onUpdate={updateHandler}
              action={action}
              model={model}
              token={token}
              translationModel={translationModel}
              recordType={recordType}
            />
          )}
          {(recordType === 2 ||
            (recordType === 5 && genericRecordType === 2)) && (
            <ConsultationsModelForm
              onCancel={cancelHandler}
              onCreate={createdHandler}
              onUpdate={updateHandler}
              action={action}
              model={model}
              token={token}
              translationModel={translationModel}
              recordType={recordType}
            />
          )}
          {(recordType === 3 ||
            (recordType === 5 && genericRecordType === 3)) && (
            <ClinicHistoryModelForm
              onCancel={cancelHandler}
              onCreate={createdHandler}
              onUpdate={updateHandler}
              action={action}
              model={model}
              token={token}
              translationModel={translationModel}
              recordType={recordType}
            />
          )}
          {(recordType === 4 ||
            (recordType === 5 && genericRecordType === 4)) && (
            <PrescriptionsModelForm
              onCancel={cancelHandler}
              onCreate={createdHandler}
              onUpdate={updateHandler}
              action={action}
              model={model}
              token={token}
              translationModel={translationModel}
              recordType={recordType}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
export default ClientRecords;
