import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import styles from "./styles.module.css";
import AddModelButton from "../UI/AddModelButton";
import ModelGrid from "./ModelGrid";
import ModelForm from "./ModelForm";

const Products = (props) => {
  const { t } = useTranslation("common");
  const token = props.token;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const modelName = "products";
  const translationModel = "products";
  const promotions = props.promotions;
  const pricesUpdate = props.pricesUpdate;
  const onSetPageTitle = props.onSetPageTitle;
  let refreshGrid = true;

  const isReport = () => {
    return location.pathname.includes("/reports") ? true : false;
  };

  const isServiceReport = () => {
    return location.pathname.includes("/services") ? true : false;
  };

  const getPageTitle = () => {
    let pageTitle = "";

    if (promotions) {
      pageTitle += t(`${translationModel}.promotionsTitle`);
    }

    if (pricesUpdate) {
      pageTitle += t(`${translationModel}.priceUpdateTitle`);
    }

    if (isReport()) {
      pageTitle += t("reports.model") + "- ";
    }

    if (!promotions && !pricesUpdate) {
      pageTitle += t(`${translationModel}.productsTitle`);
    }

    if (action) {
      pageTitle += "- " + t("actions." + action);
    }

    return pageTitle;
  };

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: model,
    setData: setModel,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const getModelHandler = async (modelId) => {
    await sendModelGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/" + modelId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    const baseUrl = `${process.env.REACT_APP_API_SERVER}/${modelName}/`;
    let typeParam = "";

    if (isReport()) {
      typeParam = isServiceReport() ? "service" : "product";
    } else {
      typeParam = "productAndService";
    }

    const url = `${baseUrl}?type=${typeParam}&page=${reqPage}&pageSize=${reqSize}&search=${
      searchText ?? ""
    }`;

    await sendModelRowsGetRequest({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const createdHandler = async (modelData) => {
    await sendCreateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/create",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchCreateError) {
      setCreateSuccess(true);
      setTimeout(() => {
        setCreateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const updateHandler = async (modelData) => {
    await sendUpdateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const priceUpdateHandler = async (id, price) => {
    await sendUpdateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/price_update",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: {
        id: id,
        proSalePrice: price,
      },
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const utilityUpdateHandler = async (id, utility) => {
    await sendUpdateRequest({
      url:
        process.env.REACT_APP_API_SERVER + "/" + modelName + "/utility_update",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: {
        id: id,
        proUtilityPercent: utility,
      },
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const deleteHandler = async (modelId) => {
    await sendDeleteRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: modelId },
    });

    if (!fetchDeleteError) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const viewHandler = (modelId) => {
    setAction("view");
    getModelHandler(modelId);
  };

  const editHandler = (modelId) => {
    setAction("edit");
    getModelHandler(modelId);
  };

  const addHandler = () => {
    setModel("");
    setAction("add");
  };

  const cancelHandler = () => {
    setModel("");
    setAction("");
    if (props.action) {
      props.onClose();
    }
  };

  const formTitle = () => {
    let title = t(translationModel + ".productsTitle");
    if (promotions) {
      title = t(translationModel + ".promotionsTitle");
    }
    if (pricesUpdate) {
      title = t(translationModel + ".priceUpdateTitle");
    }
    return title;
  };

  const setPageTitleHandler = () => {
    if (
      location.pathname !== "/cash_drawers" &&
      location.pathname !== "/sales" &&
      location.pathname !== "/purchases" &&
      location.pathname !== "/stock_movements/output" &&
      location.pathname !== "/stock_movements/input"
    ) {
      onSetPageTitle(formTitle());
    }
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    setPageTitleHandler();
    if (props.action) {
      setAction(props.action);
      if (props.productId) {
        getModelHandler(props.productId);
      }
    }
    if (refreshGrid) {
      actionParam && setAction(actionParam);

      id && getModelHandler(id);
      getModelRowsHandler(0);
    }
    // eslint-disable-next-line
    refreshGrid = false;
  }, [
    sendModelGetRequest,
    sendModelRowsGetRequest,
    sendDeleteRequest,
    refreshGrid,
  ]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          modelIsLoading ||
          modelRowsAreLoading ||
          deleteIsLoading ||
          updateIsLoading ||
          createIsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!props.action && (
        <Typography id={modelName + "_title"} mt="100px" variant="h4">
          {getPageTitle()}
          {!action && !pricesUpdate && (
            <AddModelButton
              modelName={modelName}
              translationModel={translationModel}
              onAdd={addHandler}
            />
          )}
        </Typography>
      )}
      {(fetchModelError ||
        fetchModelRowsError ||
        fetchCreateError ||
        fetchUpdateError ||
        fetchDeleteError) && (
        <Alert id="alert_error" severity="error">
          {t("errors.defaultError")}
        </Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {(!modelRowsAreLoading || deleteIsLoading) && modelRows && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            isLoading={modelRowsAreLoading}
            isReport={isReport()}
            isServiceReport={isServiceReport()}
            modelName={modelName}
            modelRows={modelRows}
            pricesUpdate={pricesUpdate}
            promotions={promotions}
            smallScreen={smallScreen}
            token={token}
            translationModel={translationModel}
            onDeleteModel={deleteHandler}
            onEditModel={editHandler}
            onPriceUpdate={priceUpdateHandler}
            onRefreshGrid={getModelRowsHandler}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onUtilityUpdate={utilityUpdateHandler}
            onSearch={searchHandler}
            onViewModel={viewHandler}
          />
        </Paper>
      )}
      {action && (
        <ModelForm
          action={action}
          model={model}
          translationModel={translationModel}
          onCancel={cancelHandler}
          onCreate={createdHandler}
          onUpdate={updateHandler}
        />
      )}
    </Fragment>
  );
};
export default Products;
