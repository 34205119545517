import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { posActions } from "../../store/pos-slice";
import { authActions } from "../../store/auth-slice";
import useInput from "../../hooks/use-input";
import {
  Grid,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Fab,
  Tooltip,
  Box,
} from "@mui/material";
import { Search, Clear, Close, Error } from "@mui/icons-material";
import styles from "./styles.module.css";

const Header = ({
  translationModel,
  clientId,
  modelId,
  modelOnHold: modelOnHoldProp,
  module,
  action,
  modelData,
  selectedCashDrawerId,
  onSearchProductHandler,
  onChangeModelOnHoldHandler,
  onEditModelHandler,
  onShowOnHoldModelDialog,
  onSetModelOnHold,
  onShowCashRegisterDialog,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [hasCharges, setHasCharges] = useState(false);
  const getAmountDue = (model) => {
    return model?.sale?.salAmountDue || model?.Purchase?.purAmountDue;
  };
  const getClient = (model) => {
    return model?.sale?.Client || model?.purchase?.Provider;
  };
  const getModelIsCancelled = (model) => {
    return model?.sale?.salCancel || model?.Purchase?.purCancel;
  };
  const amountDue = modelData && getAmountDue(modelData);
  const client = modelData && getClient(modelData);
  const modelIsCanceled = modelData && getModelIsCancelled(modelData);
  const modelOnHold = modelOnHoldProp.Sales
    ? modelOnHoldProp.Sales.sort((a, b) => {
        return a.id - b.id;
      })
    : modelOnHoldProp.Purchases.sort((a, b) => {
        return a.id - b.id;
      });
  const firstSale = modelOnHold.length > 0 && modelOnHold[0].id;
  const lastSale =
    modelOnHold.length > 0 && modelOnHold[modelOnHold.length - 1].id;

  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };

  const {
    enteredValue: searchCriteria,
    valueChangeHandler: searchCriteriaChangeHandler,
    inputBlurHandler: searchCriteriaBlurHandler,
    reset: resetSearchCriteria,
  } = useInput();

  const searchChangeHandler = (criteria) => {
    onSearchProductHandler(criteria);
  };
  const changeSaleOnHoldHandler = (direction) => {
    onChangeModelOnHoldHandler(direction);
  };
  const editModelHandler = () => {
    onEditModelHandler();
  };
  const showModelOnHoldHandler = () => {
    if (modelOnHold) {
      onShowOnHoldModelDialog();
    }
  };
  const setSaleOnHold = () => {
    onSetModelOnHold();
  };

  const showCashRegisterDialogHandler = () => {
    onShowCashRegisterDialog();
  };

  const posHeaderFunctions = () => {
    return (
      <Stack spacing={1} direction="row">
        <Box
          id="pos_title"
          pt={1}
          sx={{ typography: "h6", fontWeight: "bold" }}
        >
          {isSalesModule()
            ? t(translationModel + ".sales.header.title")
            : t(translationModel + ".purchases.header.title")}
        </Box>
        <Button
          id="previous_model_on_hold_button"
          variant="contained"
          onClick={() => changeSaleOnHoldHandler("previous")}
          disabled={
            !modelOnHold.length ||
            modelId === firstSale ||
            action === "view" ||
            !editable
          }
        >
          {t(translationModel + ".buttons.previous")}
        </Button>
        <Box
          id="order_number"
          pt={1}
          sx={{ typography: "h6", fontWeight: "bold" }}
        >
          {isSalesModule()
            ? t(translationModel + ".sales.header.orderNumber")
            : t(translationModel + ".purchases.header.orderNumber")}
        </Box>
        <Box pt={1} sx={{ typography: "h6" }}>
          {modelId}
        </Box>
        <Button
          id="next_model_on_hold_button"
          variant="contained"
          onClick={() => changeSaleOnHoldHandler("next")}
          disabled={
            !modelOnHold.length ||
            modelId === lastSale ||
            action === "view" ||
            !editable
          }
        >
          {t(translationModel + ".buttons.next")}
        </Button>
        <Button
          id="cash_register_dialog_button"
          variant="contained"
          onClick={() => showCashRegisterDialogHandler()}
          disabled={
            action === "view" || modelIsCanceled || !editable || hasCharges
          }
        >
          {t(translationModel + ".buttons.registerId.title")}
          {selectedCashDrawerId && ` ${selectedCashDrawerId}`}
          {!selectedCashDrawerId && (
            <Tooltip
              title={
                <Typography>
                  {t(translationModel + ".buttons.registerId.warning")}
                </Typography>
              }
              placement="top"
            >
              <Error fontSize="small" color="warning" />
            </Tooltip>
          )}
        </Button>
        <Button
          id="models_on_hold_button"
          variant="contained"
          onClick={() => showModelOnHoldHandler()}
          disabled={action === "view" || !editable}
        >
          {isSalesModule()
            ? t(translationModel + ".buttons.sales.searchOrder")
            : t(translationModel + ".buttons.purchases.searchOrder")}
        </Button>
        <Button
          id="edit_model_button"
          variant="contained"
          onClick={() => editModelHandler()}
          disabled={action === "view" || amountDue === 0 || !editable}
        >
          {isSalesModule()
            ? t(translationModel + ".buttons.editSale")
            : t(translationModel + ".buttons.editPurchase")}
        </Button>
        <FormControl variant="outlined">
          <InputLabel htmlFor="search">
            {t(translationModel + ".form.searchItem")}
          </InputLabel>
          <OutlinedInput
            fullWidth={true}
            sx={{ background: "white", minWidth: "250px" }}
            id="search"
            onChange={searchCriteriaChangeHandler}
            onBlur={searchCriteriaBlurHandler}
            value={searchCriteria}
            endAdornment={
              <InputAdornment position="start">
                {searchCriteria !== "" && action !== "view" && (
                  <Clear id="clearSearchItem" onClick={resetSearchCriteria} />
                )}
                <Search id="searchItemIcon" />
              </InputAdornment>
            }
            type="text"
            label={t(translationModel + ".form.observations")}
            disabled={action === "view" || modelIsCanceled || !editable}
          />
        </FormControl>
      </Stack>
    );
  };

  const posClientHeaderFunctions = () => {
    return (
      <Fragment>
        <Stack spacing={1} direction="row">
          <Box
            id="pos_title"
            pt={1}
            sx={{ typography: "h6", fontWeight: "bold" }}
          >
            {isSalesModule()
              ? t(translationModel + ".sales.header.title")
              : t(translationModel + ".purchases.header.title")}
          </Box>
        </Stack>
        <Stack spacing={1} direction="row">
          <Box
            id="order_number"
            pt={1}
            sx={{ typography: "h6", fontWeight: "bold" }}
          >
            {isSalesModule()
              ? t(translationModel + ".sales.header.orderNumber")
              : t(translationModel + ".purchases.header.orderNumber")}
          </Box>
          <Box pt={1} sx={{ typography: "h6" }}>
            {modelId}
          </Box>
        </Stack>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <FormControl variant="outlined">
            <InputLabel htmlFor="search">
              {t(translationModel + ".form.searchItem")}
            </InputLabel>
            <OutlinedInput
              fullWidth={true}
              sx={{ background: "white", minWidth: "250px" }}
              id="search"
              onChange={searchCriteriaChangeHandler}
              onBlur={searchCriteriaBlurHandler}
              value={searchCriteria}
              endAdornment={
                <InputAdornment position="start">
                  {searchCriteria !== "" && action !== "view" && (
                    <Clear id="clearSearchItem" onClick={resetSearchCriteria} />
                  )}
                  <Search id="searchItemIcon" />
                </InputAdornment>
              }
              type="text"
              label={t(translationModel + ".form.observations")}
              disabled={action === "view" || modelIsCanceled}
            />
          </FormControl>
        </Stack>
      </Fragment>
    );
  };

  useEffect(() => {
    if (modelData) {
      dispatch(authActions.getDefaultCashDrawer());
      const hasDetails =
        modelData?.sale?.SaleDetails?.length > 0 ||
        modelData?.purchase?.PurchaseDetails?.length > 0;
      const hasAmountDue =
        modelData?.sale?.salAmountDue > 0 ||
        modelData?.purchase?.purAmountDue > 0;
      const hasCharges =
        modelData?.sale?.Charges?.length > 0 ||
        modelData?.purchase?.Payments?.length > 0;
      hasCharges && setHasCharges(true);

      const isEditable =
        (hasDetails && hasAmountDue) || (!hasDetails && !hasAmountDue);

      setEditable(isEditable);
      dispatch(posActions.setModelIsEditable(isEditable));
    }
    searchChangeHandler(searchCriteria);
    // eslint-disable-next-line
  }, [searchCriteria, modelData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Fab
          id="cancel_form"
          className={`${styles["action-buttons"]} ${styles["back-button"]}`}
          size="small"
          aria-label="back"
          onClick={() => setSaleOnHold()}
        >
          <Close fontSize="small" />
        </Fab>
      </Grid>
      <Grid item xs={12} s={12} md={12} lg={12}>
        {!clientId ? posHeaderFunctions() : posClientHeaderFunctions()}
      </Grid>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Stack direction="row">
          <Box
            id="model_title"
            pt={1}
            pb={0}
            sx={{ typography: "h6", fontWeight: "bold" }}
            onClick={editModelHandler}
          >
            {isSalesModule()
              ? t(translationModel + ".columns.saleDetails.client") + ":"
              : t(translationModel + ".columns.purchaseDetails.provider") + ":"}
          </Box>
          <Box
            id={client ? "client_name_" + client.id : "client_name"}
            pt={1}
            pl={1}
            sx={{ typography: "h6" }}
          >
            {client && (
              <Fragment>
                {isSalesModule()
                  ? client.salClientName
                  : client.proCoCommercialName}
              </Fragment>
            )}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default Header;
