import React, { Fragment, useEffect } from "react";
import useHttp from "../../../hooks/use-http";
import useInput from "../../../hooks/use-input";
import useFormField from "../../../hooks/use-form-field";
import { Stack, Fab, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import styles from "../styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ConsultationsModelForm = ({
  token,
  model,
  translationModel,
  action,
  recordType,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  let loadForm = true;
  let formIsValid = false;
  const formSubmitHandler = async (event) => {
    event.preventDefault();
    if (action === "add") {
      onCreate(formFields);
    }

    if (action === "edit") {
      onUpdate(formFields);
    }
  };

  const cancelHandler = () => {
    onCancel();
  };

  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const clientChangeHandler = (e) => {
    setClient(e.target.value);
  };

  const { enteredValue: id, setEnteredValue: setId } = useInput();
  const { enteredValue: client, setEnteredValue: setClient } = useInput();
  const { enteredValue: date, setEnteredValue: setDate } = useInput();

  const {
    enteredValue: symptoms,
    setEnteredValue: setSymptoms,
    valueChangeHandler: symptomsChangeHandler,
    reset: resetSymptoms,
  } = useInput();

  const {
    enteredValue: weight,
    setEnteredValue: setWeight,
    valueChangeHandler: weightChangeHandler,
    reset: resetWeight,
  } = useInput();

  const {
    enteredValue: height,
    setEnteredValue: setHeight,
    valueChangeHandler: heightChangeHandler,
    reset: resetHeight,
  } = useInput();

  const {
    enteredValue: bloodPressure,
    setEnteredValue: setBloodPressure,
    valueChangeHandler: bloodPressureChangeHandler,
    reset: resetBloodPressure,
  } = useInput();

  const {
    enteredValue: otherMeasures,
    setEnteredValue: setOtherMeasures,
    valueChangeHandler: otherMeasuresChangeHandler,
    reset: resetOtherMeasures,
  } = useInput();

  const {
    enteredValue: diagnosticsOrClinicProblems,
    setEnteredValue: setDiagnosticsOrClinicProblems,
    valueChangeHandler: diagnosticsOrClinicProblemsChangeHandler,
    reset: resetDiagnosticsOrClinicProblems,
  } = useInput();

  const {
    enteredValue: treatment,
    setEnteredValue: setTreatment,
    valueChangeHandler: treatmentChangeHandler,
    reset: resetTreatment,
  } = useInput();

  const { enteredValue: recordFile, setEnteredValue: setRecordFile } =
    useInput();

  const { enteredValue: image1, setEnteredValue: setImage1 } = useInput();

  const { enteredValue: image2, setEnteredValue: setImage2 } = useInput();

  const { enteredValue: image3, setEnteredValue: setImage3 } = useInput();

  const { enteredValue: image4, setEnteredValue: setImage4 } = useInput();

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    reset: resetObservations,
  } = useInput();

  const { enteredValue: captured, setEnteredValue: setCaptured } = useInput();

  const formConfig = [
    {
      type: "text",
      action,
      disabled: true,
      id: "id",
      multiline: false,
      name: "id",
      sx: { display: "none" },
      value: id,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "recordType",
      multiline: false,
      name: "cliRecType",
      sx: { display: "none" },
      value: recordType,
    },
    {
      type: "select",
      action,
      disabled: action === "view",
      errorMessage: t("errors.fieldError"),
      id: "client",
      label: t(translationModel + ".form.consultations.client") + "*",
      name: "cliRecClientID",
      sx: { background: "white" },
      value: clients?.clients && !clientsAreLoading ? client : "",
      menuItems: clients && !clientsAreLoading && clients.clients,
      menuItemValue: ["id", "cliLastName", "cliFirstName"],
      customOnChange: clientChangeHandler,
      validationFn: (value) => value > 0,
    },
    {
      type: "date",
      action,
      disabled: action === "view",
      id: "date",
      label: t(translationModel + ".form.consultations.date"),
      name: "cliRecRecognitionDate",
      sx: { width: 220 },
      value: date ? date : format(new Date(), "yyyy-MM-dd"),
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "symptoms",
      label: t(translationModel + ".form.consultations.symptoms"),
      multiline: true,
      name: "cliRecSymthoms",
      sx: { background: "white" },
      value: symptoms,
      customOnChange: symptomsChangeHandler,
      customOnClear: resetSymptoms,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "weight",
      label: t(translationModel + ".form.consultations.weight"),
      multiline: false,
      name: "cliRecWeight",
      sx: { background: "white" },
      value: weight,
      customOnChange: weightChangeHandler,
      customOnClear: resetWeight,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "height",
      label: t(translationModel + ".form.consultations.height"),
      multiline: false,
      name: "cliRecHeight",
      sx: { background: "white" },
      value: height,
      customOnChange: heightChangeHandler,
      customOnClear: resetHeight,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "bloodPressure",
      label: t(translationModel + ".form.consultations.bloodPressure"),
      multiline: false,
      name: "cliRecBloodPressure",
      sx: { background: "white" },
      value: bloodPressure,
      customOnChange: bloodPressureChangeHandler,
      customOnClear: resetBloodPressure,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "otherMeasures",
      label: t(translationModel + ".form.consultations.otherMeasures"),
      multiline: true,
      name: "cliRecOtherMeasures",
      sx: { background: "white" },
      value: otherMeasures,
      customOnChange: otherMeasuresChangeHandler,
      customOnClear: resetOtherMeasures,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "diagnosticsOrClinicProblems",
      label: t(translationModel + ".form.consultations.diagnostics"),
      multiline: true,
      name: "cliRecDiagnostics",
      sx: { background: "white" },
      value: diagnosticsOrClinicProblems,
      customOnChange: diagnosticsOrClinicProblemsChangeHandler,
      customOnClear: resetDiagnosticsOrClinicProblems,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "treatment",
      label: t(translationModel + ".form.consultations.treatment"),
      multiline: true,
      name: "cliRecTreatment",
      sx: { background: "white" },
      value: treatment,
      customOnChange: treatmentChangeHandler,
      customOnClear: resetTreatment,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "document",
      disabled: action === "view",
      id: "recordFile",
      label: t(translationModel + ".form.consultations.document"),
      name: "cliRecFile",
      token,
      value: recordFile,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "clientRecords",
      disabled: action === "view",
      id: "image1",
      label: t(translationModel + ".form.consultations.image1"),
      name: "cliRecImage1",
      token,
      value: image1,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "clientRecords",
      disabled: action === "view",
      id: "image2",
      label: t(translationModel + ".form.consultations.image2"),
      name: "cliRecImage2",
      token,
      value: image2,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "clientRecords",
      disabled: action === "view",
      id: "image3",
      label: t(translationModel + ".form.consultations.image3"),
      name: "cliRecImage3",
      token,
      value: image3,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "clientRecords",
      disabled: action === "view",
      id: "image4",
      label: t(translationModel + ".form.consultations.image4"),
      name: "cliRecImage4",
      token,
      value: image4,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "observations",
      label: t(translationModel + ".form.consultations.observations"),
      multiline: true,
      name: "cliRecObservations",
      sx: { background: "white" },
      value: observations,
      customOnChange: observationsChangeHandler,
      customOnClear: resetObservations,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "captured",
      label: action === "view" && t(translationModel + ".form.captured"),
      multiline: false,
      name: "captured",
      sx: action !== "view" ? { display: "none" } : {},
      value: captured,
    },
  ];

  const { formFields, renderFormFields } = useFormField(formConfig);

  if (client) {
    formIsValid = true;
  }

  useEffect(() => {
    if (loadForm) {
      getClients();
    }
    if (model && action) {
      setId(model.clientRecord.id ? model.clientRecord.id : null);
      setClient(
        model.clientRecord.cliRecClientID
          ? model.clientRecord.cliRecClientID
          : false
      );
      setDate(
        model.clientRecord.cliRecRecognitionDate
          ? new Date(model.clientRecord.cliRecRecognitionDate)
              .toISOString()
              .split("T")[0]
          : ""
      );
      setSymptoms(
        model.clientRecord?.cliRecSymthoms
          ? model.clientRecord.cliRecSymthoms
          : ""
      );
      setWeight(
        model.clientRecord?.cliRecWeight ? model.clientRecord.cliRecWeight : ""
      );
      setHeight(
        model.clientRecord?.cliRecHeight ? model.clientRecord.cliRecHeight : ""
      );
      setBloodPressure(
        model.clientRecord?.cliRecBloodPressure
          ? model.clientRecord.cliRecBloodPressure
          : ""
      );
      setOtherMeasures(
        model.clientRecord?.cliRecOtherMeasures
          ? model.clientRecord.cliRecOtherMeasures
          : ""
      );
      setDiagnosticsOrClinicProblems(
        model.clientRecord?.cliRecDiagnostics
          ? model.clientRecord.cliRecDiagnostics
          : ""
      );
      setTreatment(
        model.clientRecord?.cliRecTreatment
          ? model.clientRecord.cliRecTreatment
          : ""
      );
      setRecordFile(
        model.clientRecord?.cliRecFile ? model.clientRecord.cliRecFile : ""
      );
      setImage1(
        model.clientRecord?.cliRecImage1 ? model.clientRecord.cliRecImage1 : ""
      );
      setImage2(
        model.clientRecord?.cliRecImage2 ? model.clientRecord.cliRecImage2 : ""
      );
      setImage3(
        model.clientRecord?.cliRecImage3 ? model.clientRecord.cliRecImage3 : ""
      );
      setImage4(
        model.clientRecord?.cliRecImage4 ? model.clientRecord.cliRecImage4 : ""
      );
      setObservations(
        model.clientRecord?.cliRecObservations
          ? model.clientRecord.cliRecObservations
          : ""
      );
      setCaptured(
        model.clientRecord?.cliRecCaptured
          ? model.clientRecord.cliRecCaptured
          : ""
      );
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {renderFormFields()}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ConsultationsModelForm;
