import React from "react";
import {
  AddPhotoAlternateRounded,
  CalendarViewMonthRounded,
  CategoryRounded,
  CabinRounded,
  ChecklistRtlRounded,
  CurrencyExchangeRounded,
  DownloadRounded,
  DvrRounded,
  EventRounded,
  GroupsRounded,
  InventoryRounded,
  LocalOfferRounded,
  LocalShippingRounded,
  LockOpenRounded,
  LockRounded,
  Link,
  ManageHistoryRounded,
  ManageSearchRounded,
  MedicationRounded,
  MedicationLiquidRounded,
  PaymentsRounded,
  PeopleAltRounded,
  PermContactCalendarRounded,
  PointOfSaleRounded,
  ReceiptLongOutlined,
  RecentActorsRounded,
  SettingsRounded,
  ShoppingCartRounded,
  SpeedRounded,
  UploadRounded,
  TuneRounded,
  WarningAmberRounded,
} from "@mui/icons-material";

const KeysToComponentMap = {
  AddPhotoAlternateRounded,
  CalendarViewMonthRounded,
  CategoryRounded,
  CabinRounded,
  ChecklistRtlRounded,
  CurrencyExchangeRounded,
  DownloadRounded,
  DvrRounded,
  EventRounded,
  GroupsRounded,
  InventoryRounded,
  LocalOfferRounded,
  LocalShippingRounded,
  LockOpenRounded,
  LockRounded,
  Link,
  ManageHistoryRounded,
  ManageSearchRounded,
  MedicationRounded,
  MedicationLiquidRounded,
  PaymentsRounded,
  PeopleAltRounded,
  PermContactCalendarRounded,
  PointOfSaleRounded,
  ReceiptLongOutlined,
  RecentActorsRounded,
  SettingsRounded,
  ShoppingCartRounded,
  SpeedRounded,
  UploadRounded,
  TuneRounded,
  WarningAmberRounded,
};

const iconRenderer = (icon) => {
  if (typeof KeysToComponentMap[icon] !== "undefined") {
    return React.createElement(KeysToComponentMap[icon], {
      color: "primary",
    });
  }
};

export default iconRenderer;
