import React, { useEffect, Fragment } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  FormHelperText,
  Stack,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import useInput from "../../../hooks/use-input";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

const ProductDetails = ({ action, translationModel, onSelect, onCancel }) => {
  const { t } = useTranslation("common");
  let refreshGrid = true;

  const {
    enteredValue: quantity,
    setEnteredValue: setQuantity,
    valueIsValid: quantityIsValid,
    valueChangeHandler: quantityChangeHandler,
    hasError: quantityHasError,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value.toString().trim() > 0);

  let formIsValid = true;

  if (quantityIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    onSelect(quantity);
    setQuantity("");
  };
  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (refreshGrid === true) {
      setQuantity(1);
    }
    // eslint-disable-next-line
  }, [refreshGrid]);

  return (
    <Grid sx={{ minWidth: "600px" }} container>
      <Grid item sx={{ marginTop: "30px" }} xs={12} s={12} md={12} lg={12}>
        <Fragment>
          <Stack
            className={`${styles["form-stack"]}`}
            spacing={1}
            justifyContent="center"
            sx={{ paddingTop: "20px", maxWidth: "90%" }}
          >
            <FormControl
              error={quantityHasError ? true : false}
              variant="outlined"
            >
              <InputLabel htmlFor="quantity">
                {t(translationModel + ".columns.quantity")}*
              </InputLabel>
              <FilledInput
                sx={{ background: "white" }}
                id="quantity"
                onChange={quantityChangeHandler}
                onBlur={quantityBlurHandler}
                value={quantity}
                endAdornment={
                  quantity !== "" &&
                  action !== "view" && (
                    <InputAdornment position="start">
                      <Clear onClick={resetQuantity} />
                    </InputAdornment>
                  )
                }
                type="number"
                label={t(translationModel + ".columns.quantity")}
                disabled={action === "view"}
              />
              {quantityHasError && (
                <FormHelperText id="quantity-helper-text">
                  {t("errors.fieldError")}
                </FormHelperText>
              )}
            </FormControl>
            {action !== "view" && (
              <Button
                id="submit_form"
                variant="contained"
                onClick={formSubmitHandler}
                disabled={!formIsValid}
              >
                {t("buttons.add", {
                  model: t(translationModel + ".columns.product"),
                })}
              </Button>
            )}
            <Button
              id="cancel_form"
              variant="contained"
              color={action === "view" ? "inherit" : "warning"}
              onClick={cancelHandler}
            >
              {action === "view" && t("buttons.back")}
              {action !== "view" && t("buttons.cancel")}
            </Button>
          </Stack>
        </Fragment>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
