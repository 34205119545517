import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";

const CashOperations = ({ cashOperations, translationModel }) => {
  const { t } = useTranslation("common");

  const formatMoney = (amount) => {
    return (
      "$" +
      Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
  };

  const getTotalCash = () => {
    let totalCash = 0;
    cashOperations?.CashOperations?.forEach((operation) => {
      if (
        operation.casOpeType === "1" ||
        operation.casOpeType === "3" ||
        operation.casOpeType === "6"
      ) {
        totalCash += Number(operation.casOpeAmount);
      } else {
        totalCash -= Number(operation.casOpeAmount);
      }
    });
    return totalCash;
  };

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography
          id={t(translationModel + ".columns.cashOperations.title") + "_title"}
          mt="100px"
          pb="20px"
          variant="h5"
        >
          {t(`${translationModel}.columns.cashOperations.title`)}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <Fragment>
                <TableRow key="header">
                  <TableCell align="center" key={"type_cell_title"}>
                    {t(
                      translationModel + `.columns.cashOperations.movementType`
                    )}
                  </TableCell>
                  <TableCell key={"amount_cell_title"} align="center">
                    {t(translationModel + `.columns.cashOperations.amount`)}
                  </TableCell>
                </TableRow>
                {cashOperations?.CashOperations?.length > 0 &&
                  cashOperations.CashOperations.map((operation) => (
                    <TableRow key={"row_" + operation.id}>
                      <TableCell
                        key={"type_cell_value_" + operation.id}
                        align="center"
                      >
                        {t(
                          `${translationModel}.columns.cashOperations.movementTypes.${operation?.casOpeType}`
                        )}
                      </TableCell>
                      <TableCell
                        key={"amount_cell_value_" + operation.id}
                        align="center"
                      >
                        {formatMoney(operation.casOpeAmount)}
                      </TableCell>
                    </TableRow>
                  ))}
              </Fragment>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          id={t(translationModel + ".columns.cashOperations.total") + "_title"}
          pt="10px"
          pr="30px"
          variant="h6"
          textAlign="right"
        >
          {t(`${translationModel}.columns.cashOperations.total`) +
            " " +
            formatMoney(getTotalCash())}
        </Typography>
      </Stack>
    </Fragment>
  );
};
export default CashOperations;
