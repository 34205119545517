import React, { Fragment, useState } from "react";
import { Fab, Menu, MenuItem, ListItemText, Tooltip } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import styles from "./grid.actions.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const GridActionsClientsMenu = ({ id, modelName }) => {
  const { t } = useTranslation("common");
  const [activeMenu, setActiveMenu] = useState(null);
  const openClientsFunctions = Boolean(activeMenu);

  const menuHandler = (event) => {
    setActiveMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setActiveMenu(null);
  };

  return (
    <Fragment>
      <Tooltip title={t("buttons.moreFunctions")} placement="top">
        <Fab
          id={modelName + "_functions_button_" + id}
          className={`${styles["action-buttons"]}`}
          size="small"
          aria-controls={
            openClientsFunctions
              ? modelName + "_functions_button_menu" + id
              : undefined
          }
          aria-haspopup="true"
          aria-expanded={openClientsFunctions ? "true" : undefined}
          onClick={menuHandler}
        >
          <MenuIcon />
        </Fab>
      </Tooltip>
      <Menu
        id={modelName + "_functions_button_menu" + id}
        anchorEl={activeMenu}
        open={openClientsFunctions}
        onClose={() => closeMenu()}
        MenuListProps={{
          "aria-labelledby": modelName + "_functions_button" + id,
        }}
      >
        <MenuItem
          id={modelName + "_functions_button_menu_sales_" + id}
          component={Link}
          to={`/sales/client/${id}`}
          target="_blank"
        >
          <ListItemText>{t(`${modelName}.gridMenu.sales`)}</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/sales_details/client/${id}`}
          target="_blank"
        >
          <ListItemText>{t(`${modelName}.gridMenu.salesDetails`)}</ListItemText>
        </MenuItem>
        <MenuItem
          id={modelName + "_functions_button_menu_bookings_" + id}
          component={Link}
          to={`/bookings/client/${id}`}
          target="_blank"
        >
          <ListItemText>{t(`${modelName}.gridMenu.bookings`)}</ListItemText>
        </MenuItem>
        <MenuItem
          id={modelName + "_functions_button_menu_tasks_" + id}
          component={Link}
          to={`/tasks/client/${id}`}
          target="_blank"
        >
          <ListItemText>{t(`${modelName}.gridMenu.tasks`)}</ListItemText>
        </MenuItem>
        <MenuItem
          id={modelName + "_functions_button_menu_photo_" + id}
          component={Link}
          to={`/photo_catalog/client/${id}`}
          target="_blank"
        >
          <ListItemText>{t(`${modelName}.gridMenu.photoCatalog`)}</ListItemText>
        </MenuItem>
        <MenuItem
          id={modelName + "_functions_button_menu_records_" + id}
          component={Link}
          to={`/client_records/client/${id}`}
          target="_blank"
        >
          <ListItemText>{t(`${modelName}.gridMenu.records`)}</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default GridActionsClientsMenu;
