import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Fab, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

const AddModelButton = ({
  modelName,
  translationModel,
  movementType,
  onAdd,
}) => {
  const { t } = useTranslation("common");
  const crudActions = useSelector((state) => state.auth.accessRights);
  const isCreateAllowed = (actions) => {
    return actions.includes("C");
  };

  const openTabHandler = (action) => {
    const url = window.location.origin + `/${modelName}?action=${action}`;
    window.open(url, "_blank");
  };

  const addHandler = (event) => {
    if (event.metaKey) {
      openTabHandler("add");
    } else {
      onAdd();
    }
  };

  return (
    <Fragment>
      {crudActions && isCreateAllowed(crudActions) && (
        <Tooltip
          title={
            movementType
              ? t("buttons.add", {
                  model: t(translationModel + ".movementType." + movementType),
                })
              : t("buttons.add", { model: t(translationModel + ".model") })
          }
          placement="top"
        >
          <Fab
            id={modelName + "_action_add"}
            sx={{ float: "right" }}
            color="primary"
            size="medium"
            aria-label={t("buttons.add", {
              model: t(translationModel + ".model"),
            })}
            onClick={(event) => addHandler(event)}
          >
            <Add fontSize="medium" />
          </Fab>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default AddModelButton;
