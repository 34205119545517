import React from "react";
import { Card } from "@mui/material";
import { format } from "date-fns";

const Hour = ({ hour }) => {
  const hourStyle = {
    float: "left",
    paddingRight: "7px",
  };
  return (
    <Card sx={hourStyle} elevation={0}>
      {format(new Date(hour), "HH:mm")}
    </Card>
  );
};

export default Hour;
