import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Switch,
} from "@mui/material";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";
import GridActions from "../UI/GridActions";
import ImageAvatar from "../UI/ImageAvatar";

const ModelGrid = ({
  action,
  isLoading,
  isReport,
  isServiceReport,
  modelName,
  modelRows,
  pricesUpdate,
  promotions,
  smallScreen,
  token,
  translationModel,
  onDeleteModel,
  onEditModel,
  onPriceUpdate,
  onRefreshGrid,
  onUtilityUpdate,
  onViewModel,
  onPageChange,
  onPageSizeChange,
  onSearch,
}) => {
  const [alertDelete, setAlertDelete] = useState(false);
  const [Id, setId] = useState(null);
  const [rowCount, setRowCount] = useState(null);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [search, setSearch] = useState(null);
  const { t } = useTranslation("common");
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (Id) => {
    setAlertDelete(true);
    setId(Id);
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    onDeleteModel(Id);
  };

  const viewHandler = (Id) => {
    onViewModel(Id);
  };

  const editHandler = (Id) => {
    onEditModel(Id);
  };

  const refreshGridHandler = () => {
    onRefreshGrid();
  };

  const searchHandler = (searchText) => {
    onSearch(searchText);
  };

  const pageChangeHandler = (newPage, size, search) => {
    onPageChange(newPage, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    onPageSizeChange(size, search);
  };

  const editStopHandler = (params, event) => {
    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
      event.defaultMuiPrevented = true;
    }
    if (params.reason === GridCellEditStopReasons.escapeKeyDown) {
      return;
    } else {
      if (params.field === "proSalePrice") {
        onPriceUpdate(params.row.id, event.target.value);
      }
      if (params.field === "proUtilityPercent") {
        onUtilityUpdate(params.row.id, event.target.value);
      }
    }
  };

  const productColumns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={modelName}
        />
      ),
    },
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "proType",
      headerName: t(translationModel + ".columns.type"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${t(translationModel + ".columns.types." + params.row.proType) || ""}`,
    },
    {
      field: "proImage1",
      headerName: t(translationModel + ".columns.image"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params?.row?.proImage1 && (
          <ImageAvatar
            currentFile={params?.row?.proImage1}
            inputId="proImage1"
            token={token}
            fileType="product"
          ></ImageAvatar>
        ),
    },
    {
      field: "proCommercialName",
      headerName: t(translationModel + ".columns.name"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.proCommercialName && (
          <Tooltip title={params.row.proCommercialName} placement="top">
            <div>{params.row.proCommercialName}</div>
          </Tooltip>
        ),
    },
    {
      field: "category",
      headerName: t(translationModel + ".columns.category"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.category.proCatName &&
        `${params.row.category.proCatName || ""}`,
    },
    {
      field: "provider",
      headerName: t(translationModel + ".columns.provider"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.provider && params.row.provider.proCoCommercialName,
    },
    {
      field: "proUnit",
      headerName: t(translationModel + ".columns.unit"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "proSalePrice",
      headerName: t(translationModel + ".columns.netPrice"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "number",
      editable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.proSalePrice &&
        "$" +
          params.row.proSalePrice
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
    {
      field: "proTax",
      headerName: t(translationModel + ".columns.tax"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.proTax * 100 + "%",
    },
    {
      field: "proPromoDiscount",
      headerName: t(translationModel + ".columns.discount"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.proPromoDiscount * 100 + "%",
    },
    {
      field: "grossPrice",
      headerName: t(translationModel + ".columns.price"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.proSaleGrossPrice &&
        "$" +
          params.row.proSaleGrossPrice
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
  ];

  const productReportColumns = [
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "proCommercialName",
      headerName: t(translationModel + ".columns.name"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.proCommercialName && (
          <Tooltip title={params.row.proCommercialName} placement="top">
            <div>{params.row.proCommercialName}</div>
          </Tooltip>
        ),
    },
    {
      field: "proCode",
      headerName: t(translationModel + ".columns.barCode"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "proImage1",
      headerName: t(translationModel + ".columns.image"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params?.row?.proImage1 && (
          <ImageAvatar
            currentFile={params?.row?.proImage1}
            inputId="proImage1"
            token={token}
            fileType="product"
          ></ImageAvatar>
        ),
    },
    {
      field: "category",
      headerName: t(translationModel + ".columns.category"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.category.proCatName &&
        `${params.row.category.proCatName || ""}`,
    },
    {
      field: "provider",
      headerName: t(translationModel + ".columns.provider"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.provider && params.row.provider.proCoCommercialName,
    },
    {
      field: "proUnit",
      headerName: t(translationModel + ".columns.unit"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "proSalePrice",
      headerName: t(translationModel + ".columns.netPrice"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "number",
      filterable: false,
      sortable: false,
      editable: false,
      renderCell: (params) =>
        params.row.proSalePrice &&
        "$" +
          params.row.proSalePrice
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
  ];

  const promotionColumns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={modelName}
        />
      ),
    },
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "proType",
      headerName: t(translationModel + ".columns.type"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${t(translationModel + ".columns.types." + params.row.proType) || ""}`,
    },
    {
      field: "proImage1",
      headerName: t(translationModel + ".columns.image"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "proCommercialName",
      headerName: t(translationModel + ".columns.name"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.proCommercialName && (
          <Tooltip title={params.row.proCommercialName} placement="top">
            <div>{params.row.proCommercialName}</div>
          </Tooltip>
        ),
    },
    {
      field: "category",
      headerName: t(translationModel + ".columns.category"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.category.proCatName &&
        `${params.row.category.proCatName || ""}`,
    },
    {
      field: "proPoints",
      headerName: t(translationModel + ".columns.points"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) => `${params.row.proPoints || ""}`,
    },
    {
      field: "proPromoDiscount",
      headerName: t(translationModel + ".columns.discount"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) => `${params.row.proPromoDiscount || ""}`,
    },
    {
      field: "proSalePrice",
      headerName: t(translationModel + ".columns.price"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.proSalePrice &&
        "$" +
          params.row.proSalePrice
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },

    {
      field: "netPrice",
      headerName: t(translationModel + ".columns.netPrice"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.proSaleGrossPrice &&
        "$" +
          params.row.proSaleGrossPrice
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
    {
      field: "proUnit",
      headerName: t(translationModel + ".columns.unit"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "provider",
      headerName: t(translationModel + ".columns.provider"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.provider && params.row.provider.proCoCommercialName,
    },
  ];

  const priceUpdateColumns = [
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "proType",
      headerName: t(translationModel + ".columns.type"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${t(translationModel + ".columns.types." + params.row.proType) || ""}`,
    },
    {
      field: "proCommercialName",
      headerName: t(translationModel + ".columns.name"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params.row.proCommercialName && (
          <Tooltip title={params.row.proCommercialName} placement="top">
            <div>{params.row.proCommercialName}</div>
          </Tooltip>
        ),
    },
    {
      field: "category",
      headerName: t(translationModel + ".columns.category"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.category.proCatName &&
        `${params.row.category.proCatName || ""}`,
    },
    {
      field: "provider",
      headerName: t(translationModel + ".columns.provider"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.provider && params.row.provider.proCoCommercialName,
    },
    {
      field: "proUnit",
      headerName: t(translationModel + ".columns.unit"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "proSalePrice",
      headerName: t(translationModel + ".columns.price"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "number",
      editable: true,
      filterable: false,
      sortable: false,
      valueParser: (value) => {
        if (!isNaN(value)) {
          return value;
        }
      },
      renderCell: (params) =>
        params.row.proSalePrice && "$" + params.row.proSalePrice,
    },
    {
      field: "netPrice",
      headerName: t(translationModel + ".columns.netPrice"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 80,
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row.proSaleGrossPrice &&
        "$" +
          params.row.proSaleGrossPrice
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
    },
  ];

  let columns = productColumns;

  if (promotions) {
    columns = promotionColumns;
  }
  if (pricesUpdate) {
    columns = priceUpdateColumns;
  }
  if (isReport) {
    columns = productReportColumns;
  }

  if (isServiceReport) {
    productReportColumns.push({
      field: "proReservation",
      headerName: t(translationModel + ".columns.canBeBooked"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      renderCell: (params) => (
        <Switch
          id={`can_book_${params.row.proReservation}`}
          checked={
            params.row.proReservation === 1 ||
            params.row.proReservation === true
              ? true
              : false
          }
          disabled
        />
      ),
    });
  }

  const CustomToolbar = () => {
    return (
      <GridToolbar
        action={action}
        isServerPagination={true}
        search={search}
        isReport={isReport}
        modelName={modelName}
        translationModel={translationModel}
        smallScreen={smallScreen}
        onRefreshGrid={refreshGridHandler}
        onSearch={searchHandler}
      />
    );
  };

  useEffect(() => {
    modelRows?.totalItems && setRowCount(modelRows?.totalItems);
    if (modelRows?.page) {
      modelRows?.page > 0
        ? setPage(parseInt(modelRows?.page - 1), 10)
        : setPage(parseInt(0, 10));
    }
    modelRows?.pageSize > 0
      ? setPageSize(parseInt(modelRows?.pageSize, 10))
      : setPageSize(5);
    modelRows?.search && setSearch(modelRows.search);
  }, [modelRows]);

  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", {
            model: t(translationModel + ".model"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t(translationModel + ".model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {modelRows.Products !== undefined &&
        modelRows.Products.length <= 0 &&
        !isLoading && <Alert severity="warning">{t("errors.noContent")}</Alert>}
      <DataGrid
        paginationMode={rowCount > 0 ? "server" : "client"}
        sx={{
          "& .MuiDataGrid-cell--editable": {
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#376331" : "rgb(217 243 190)",
          },
        }}
        rows={modelRows?.Products}
        columns={columns}
        rowCount={rowCount > 0 ? rowCount : 0}
        page={page && page}
        pageSize={pageSize ? pageSize : 5}
        onPageSizeChange={(newPageSize) =>
          pageSizeChangeHandler(newPageSize, search && search)
        }
        onPageChange={(newPage) =>
          pageChangeHandler(
            newPage,
            pageSize > 0 ? pageSize : 5,
            search && search
          )
        }
        rowsPerPageOptions={
          rowsPerPage.length > 0 ? rowsPerPage.map(Number) : [5]
        }
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          columns: {},
        }}
        onCellEditStop={editStopHandler}
      />
    </Fragment>
  );
};

export default ModelGrid;
