import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  CircularProgress,
  Alert,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";

const Categories = ({
  token,
  action,
  editable,
  category: selectedCategory,
  modelData,
  module,
  translationModel,
  onCategoryChange,
}) => {
  const { t } = useTranslation("common");
  const getAmountDue = (model) => {
    if (
      model?.sale?.salAmountDue !== undefined &&
      model?.sale?.Charges?.length > 0
    ) {
      return model.sale.salAmountDue;
    } else if (
      model?.sale?.salAmountDue === 0 &&
      model?.sale?.Charges?.length === 0
    ) {
      return undefined;
    } else if (
      model?.purchase?.purAmountDue !== undefined &&
      model?.purchase?.Payments?.length > 0
    ) {
      return model.purchase.purAmountDue;
    } else if (
      model?.purchase?.purAmountDue === 0 &&
      model?.purchase?.Payments?.length === 0
    ) {
      return undefined;
    } else {
      return undefined;
    }
  };
  const getModelIsCancelled = (model) => {
    return model?.sale?.salCancel || model?.purchase?.purCancel;
  };
  const amountDue = modelData && getAmountDue(modelData);
  const disabled = (modelData && getModelIsCancelled(modelData)) || !editable;
  let refreshGrid = true;

  const categoryHandler = (id) => {
    onCategoryChange(id);
  };

  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };
  const isPurchasesModule = () => {
    return (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    );
  };

  const {
    isLoading: categoriesAreLoading,
    error: fetchCategoriesError,
    data: categories,
    sendRequest: sendCategoriesGetRequest,
  } = useHttp();

  const getCategoriesHandler = async () => {
    let requestUrl = "/product_categories?orderCol=proCatName&orderType=ASC";
    if (isPurchasesModule()) {
      requestUrl =
        "/product_categories?orderCol=proCatName&orderType=ASC&type=product";
    }
    await sendCategoriesGetRequest({
      url: process.env.REACT_APP_API_SERVER + requestUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  if (categories) {
    const allCategory = categories.ProductCategories.find(
      (category) => category.id === "all"
    );
    if (!allCategory) {
      categories.ProductCategories.unshift({
        id: "all",
        proCatName:
          isSalesModule() || isPurchasesModule()
            ? t(translationModel + ".buttons.allProducts")
            : t(translationModel + ".form.buttons.allProducts"),
      });
    }
  }

  useEffect(() => {
    if (refreshGrid) {
      !categories && getCategoriesHandler();
      // eslint-disable-next-line
      refreshGrid = false;
    }
  }, [categories, action]);

  return (
    <Grid container sx={{ minWidth: "400px" }}>
      <Grid
        className={styles.categories}
        item
        sx={{ marginTop: "10px" }}
        xs={12}
        s={12}
        md={12}
        lg={12}
      >
        {categoriesAreLoading && !categories && (
          <CircularProgress color="inherit" />
        )}
        {fetchCategoriesError && (
          <Alert severity="error">{t("errors.defaultError")}</Alert>
        )}
        {categories &&
          categories.ProductCategories.map(
            (category) =>
              category.proCatName &&
              category.proCatName.length > 0 && (
                <Tooltip
                  key={category.id}
                  title={
                    <Typography variant="h5">{category.proCatName}</Typography>
                  }
                  placement="top"
                >
                  <span>
                    <Button
                      sx={{
                        marginLeft: "5px",
                        marginTop: "10px",
                        width: "180px",
                        height: "80px",
                      }}
                      size="large"
                      color={
                        selectedCategory === category.id ? "info" : "inherit"
                      }
                      key={category.id}
                      id={`product_category_${category.id}`}
                      variant="contained"
                      onClick={() => categoryHandler(category.id)}
                      disabled={
                        action === "view" || disabled || amountDue === 0
                          ? true
                          : false
                      }
                    >
                      {category.proCatName &&
                        category.proCatName.length > 20 &&
                        `${category.proCatName.slice(0, 15)} (${
                          category.Products && category.Products.length
                        })...`}
                      {category.proCatName &&
                        category.proCatName.length <= 20 &&
                        category.id !== "all" &&
                        `${category.proCatName} (${
                          category.Products && category.Products.length
                        })`}
                      {category.id === "all" && category.proCatName}
                    </Button>
                  </span>
                </Tooltip>
              )
          )}
      </Grid>
    </Grid>
  );
};

export default Categories;
