import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import AddModelButton from "../UI/AddModelButton";
import styles from "./styles.module.css";
import ModelGrid from "./ModelGrid";
import ModelForm from "./ModelForm";

const Charges = ({
  token,
  redirect,
  modelData,
  modelName,
  selectedCashDrawerId,
  onClose,
  onCreateSuccess,
  onSetPageTitle,
}) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const translationModel = modelName && modelName.toLowerCase();
  let refreshGrid = true;

  const isReport = () => {
    return location.pathname.includes("/reports") ? true : false;
  };

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: model,
    setData: setModel,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const getModelHandler = async (modelId) => {
    await sendModelGetRequest({
      url: `${process.env.REACT_APP_API_SERVER}/${modelName}/${modelId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    await sendModelRowsGetRequest({
      url: `${
        process.env.REACT_APP_API_SERVER
      }/${modelName}?page=${reqPage}&pageSize=${reqSize}&search=${
        searchText ?? ""
      }`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const createdHandler = async (modelData) => {
    await sendCreateRequest({
      url: `${
        process.env.REACT_APP_API_SERVER
      }/${modelName.toLowerCase()}/create`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchCreateError) {
      setCreateSuccess(true);
      setTimeout(
        () => {
          setCreateSuccess(false);
          onCreateSuccess();
        },
        redirect ? 2000 : 5000
      );
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const updateHandler = async (modelData) => {
    await sendUpdateRequest({
      url: `${process.env.REACT_APP_API_SERVER}/${modelName}/`,
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const deleteHandler = async (modelId) => {
    await sendDeleteRequest({
      url: `${process.env.REACT_APP_API_SERVER}/${modelName}/`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: modelId },
    });

    if (!fetchDeleteError) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const viewHandler = (modelId) => {
    setAction("view");
    getModelHandler(modelId);
  };

  const editHandler = (modelId) => {
    setAction("edit");
    getModelHandler(modelId);
  };

  const addHandler = () => {
    setModel("");
    setAction("add");
  };

  const cancelHandler = () => {
    setModel("");
    setAction("");
    if (redirect) {
      onClose();
    }
  };

  const setPageTitleHandler = () => {
    if (location.pathname === "/charges" || location.pathname === "/payments") {
      onSetPageTitle(t(`${translationModel}.title`));
    }
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    setPageTitleHandler();
    if (!redirect && refreshGrid) {
      !modelRows && getModelRowsHandler(0);
      actionParam && setAction(actionParam);

      id && getModelHandler(id);
      // eslint-disable-next-line
      refreshGrid = false;
    }
    if (!action && redirect) {
      setAction("add");
    }
  }, [
    sendModelGetRequest,
    sendModelRowsGetRequest,
    sendDeleteRequest,
    refreshGrid,
    modelRows,
  ]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          modelIsLoading ||
          modelRowsAreLoading ||
          deleteIsLoading ||
          updateIsLoading ||
          createIsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!redirect && (
        <Typography id={modelName + "_title"} mt="100px" variant="h4">
          {t(translationModel + ".title")}{" "}
          {action && "- " + t("actions." + action)}
          {!action && (
            <AddModelButton
              modelName={modelName}
              translationModel={translationModel}
              onAdd={addHandler}
            />
          )}
        </Typography>
      )}
      {(fetchModelError ||
        fetchModelRowsError ||
        fetchCreateError ||
        fetchUpdateError ||
        fetchDeleteError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {(!modelRowsAreLoading || deleteIsLoading) &&
        modelRows &&
        !action &&
        !redirect && (
          <Paper className={styles.datagrid}>
            <ModelGrid
              isLoading={modelRowsAreLoading}
              isReport={isReport()}
              modelRows={modelRows}
              modelName={modelName}
              smallScreen={smallScreen}
              translationModel={translationModel}
              onDeleteModel={deleteHandler}
              onEditModel={editHandler}
              onRefreshGrid={getModelRowsHandler}
              onPageChange={pageChangeHandler}
              onPageSizeChange={pageSizeChangeHandler}
              onSearch={searchHandler}
              onViewModel={viewHandler}
            />
          </Paper>
        )}
      {action && !createIsLoading && !createSuccess && (
        <ModelForm
          onCancel={cancelHandler}
          onCreate={createdHandler}
          onUpdate={updateHandler}
          action={action}
          model={model}
          modelName={modelName}
          translationModel={translationModel}
          modelData={modelData}
          redirect="pos"
          selectedCashDrawerId={selectedCashDrawerId}
        />
      )}
    </Fragment>
  );
};
export default Charges;
