import { useState } from "react";

const useForm = (validateFunction, defaultValue) => {
  const [enteredValue, setEnteredValue] = useState(
    defaultValue ? defaultValue : ""
  );
  const [isTouched, setIsTouched] = useState(false);

  const valueIsValid = validateFunction ? validateFunction(enteredValue) : true;
  const hasError = !valueIsValid && isTouched;

  const valueChangeHandler = (event) => {
    setEnteredValue(event.target.value);
  };

  const booleanValueChangeHandler = (event) => {
    if (event.target && event.target.checked) {
      if (event.target.checked === true) {
        setEnteredValue(false);
      }
      if (event.target.checked === false) {
        setEnteredValue(true);
      }
    } else {
      setEnteredValue(event);
    }
  };

  const inputBlurHandler = (event) => {
    setIsTouched(true);
  };

  const reset = () => {
    setEnteredValue("");
    setIsTouched(false);
  };

  return {
    enteredValue,
    setEnteredValue,
    valueIsValid,
    hasError,
    valueChangeHandler,
    booleanValueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useForm;
