import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Warehouses from "./Warehouses";
import Observations from "./Observations";
import Report from "./Report/Report";
import StockMovementsOnHold from "./StockMovementsOnHold";
import Products from "../../Products/Products";
import ProductDetails from "./ProductDetails";

const StockMovementDialog = (props) => {
  const { t } = useTranslation("common");
  const [dialogDescription, setDialogDescription] = useState(true);
  const token = props.token;
  const action = props.action;
  const translationModel = props.translationModel;
  const formDialog = props.formDialog;
  const type = props.type;
  const isCanceled = props.isCanceled;
  const isClosed = props.isClosed;
  const movementData = props.movementData;
  const stockMovementsOnHoldData = props.stockMovementsOnHoldData;
  const productId = props.productId;
  const product = props.product;

  let dialog = "";

  switch (formDialog) {
    case "onHold":
      dialog = "closeStockMovement";
      break;
    case "cancel":
      dialog = "cancelStockMovement";
      break;
    case "close":
      dialog = "changeStatus";
      break;
    case "deleteDetail":
      dialog = "deleteDetail";
      break;
    case "inputWarehouses":
    case "inputExchangeWarehouses":
      dialog = "inputWarehouseDialog";
      break;
    case "outputWarehouses":
    case "outputExchangeWarehouses":
      dialog = "outputWarehouseDialog";
      break;
    case "observations":
      dialog = "observations";
      break;
    case "report":
      dialog = "printReport";
      break;
    case "stockMovementsOnHold":
      dialog = "stockMovementsOnHold";
      break;
    case "changeStockMovementOnHoldPrevious":
    case "changeStockMovementOnHoldNext":
      dialog = "changeStockMovementOnHold";
      break;
    case "viewProduct":
      dialog = "productDetails";
      break;
    case "addProduct":
      dialog = "addProduct";
      break;
    default:
      dialog = "";
      break;
  }

  const dialogDescriptionHandler = () => {
    setDialogDescription(true);
    (isCanceled || isClosed) &&
      formDialog === "onHold" &&
      setDialogDescription(false);
    (formDialog === "inputWarehouses" ||
      formDialog === "inputExchangeWarehouses" ||
      formDialog === "outputWarehouses" ||
      formDialog === "outputExchangeWarehouses" ||
      formDialog === "observations" ||
      formDialog === "report" ||
      formDialog === "stockMovementsOnHold" ||
      formDialog === "viewProduct" ||
      formDialog === "addProduct") &&
      setDialogDescription(false);
  };

  const printReport = () => {
    let divContents = document.getElementById("printableArea").innerHTML;
    let a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    a.document.write("<body >");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  const cancelHandler = () => {
    props.onCancel("");
  };

  const confirmHandler = (
    warehouseId,
    observations,
    stockMovementId,
    stockMovementData
  ) => {
    if (
      formDialog === "changeStockMovementOnHoldPrevious" ||
      formDialog === "changeStockMovementOnHoldNext"
    ) {
      props.onConfirm("", "", "", "");
    } else {
      props.onConfirm(
        warehouseId,
        observations,
        stockMovementId,
        stockMovementData
      );
    }
  };

  const confirmQuantityHandler = (quantity) => {
    props.onConfirmQuantity(quantity, product);
  };

  useEffect(() => {
    dialogDescriptionHandler();
  });

  return (
    <Dialog
      id="stock_movement_dialog"
      open={formDialog ? true : false}
      onCancel={cancelHandler}
      aria-labelledby="stock_movement_title"
      aria-describedby="stock_movement_description"
      fullWidth={
        (formDialog === "observations" || formDialog === "viewProduct") && true
      }
      maxWidth={
        (formDialog === "observations" || formDialog === "viewProduct") && "sm"
      }
    >
      <DialogTitle id="stock_movement_title">
        {t(translationModel + ".form.confirmations." + dialog + ".title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="stock_movement_description">
          {dialogDescription &&
            t(
              translationModel +
                ".form.confirmations." +
                dialog +
                ".description"
            )}
        </DialogContentText>
        {(formDialog === "inputWarehouses" ||
          formDialog === "inputExchangeWarehouses" ||
          formDialog === "outputWarehouses" ||
          formDialog === "outputExchangeWarehouses") && (
          <Warehouses
            token={token}
            action={action}
            warehouseType={formDialog}
            movementData={movementData}
            translationModel={translationModel}
            onSelectWarehouseHandler={confirmHandler}
          />
        )}
        {formDialog === "observations" && (
          <Observations
            token={token}
            action={action}
            translationModel={translationModel}
            onSaveObservationsClick={confirmHandler}
            stockMovement={movementData}
          />
        )}
        {formDialog === "report" && (
          <div id="printableArea">
            <Report
              stockMovementData={movementData}
              translationModel={translationModel}
              type={type}
            />
          </div>
        )}
        {formDialog === "stockMovementsOnHold" && (
          <StockMovementsOnHold
            selectedStockMovementId={movementData && movementData.id}
            stockMovementsOnHold={stockMovementsOnHoldData}
            translationModel={translationModel}
            type={type}
            onSelectStockMovementsOnHoldHandler={confirmHandler}
          />
        )}
        {formDialog === "viewProduct" && (
          <Products
            token={token}
            action={"view"}
            productId={productId}
            onClose={cancelHandler}
          />
        )}
        {formDialog === "addProduct" && (
          <ProductDetails
            action={"add"}
            translationModel={translationModel}
            onSelect={confirmQuantityHandler}
            onCancel={cancelHandler}
          />
        )}
      </DialogContent>
      <DialogActions>
        {formDialog !== "addProduct" && (
          <Button id="stock_movement_cancel_button" onClick={cancelHandler}>
            {t("buttons.cancel")}
          </Button>
        )}
        {formDialog !== "inputWarehouses" &&
          formDialog !== "inputExchangeWarehouses" &&
          formDialog !== "outputWarehouses" &&
          formDialog !== "outputExchangeWarehouses" &&
          formDialog !== "observations" &&
          formDialog !== "report" &&
          formDialog !== "stockMovementsOnHold" &&
          formDialog !== "viewProduct" &&
          formDialog !== "addProduct" && (
            <Button
              id="stock_movement_confirm_button"
              onClick={confirmHandler}
              autoFocus
            >
              {t("buttons.accept")}
            </Button>
          )}
        {formDialog === "report" && (
          <Button id="alert_print_button" onClick={printReport}>
            {t("pos.buttons.print")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default StockMovementDialog;
