import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth-slice";
import uiReducer from "./ui-slice";
import posReducer from "./pos-slice";
import stockReducer from "./stock-slice";
import bookingReducer from "./booking-slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    pos: posReducer,
    stock: stockReducer,
    booking: bookingReducer,
  },
});

export default store;
