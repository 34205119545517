import React, { Fragment, useEffect } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Button,
  CircularProgress,
  Fab,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear, Help } from "@mui/icons-material";
import styles from "./Clients.module.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Close } from "@mui/icons-material";
import FileUploader from "../UI/FileUploader";

const ClientsForm = ({
  client,
  action,
  updateIsLoading,
  createIsLoading,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const titles = ["arch", "mr", "mrs", "miss", "dr", "drFemale", "eng", "lic"];
  let loadForm = true;

  const {
    data: categories,
    isLoading: categoriesAreLoading,
    sendRequest: fetchCategories,
  } = useHttp();

  const {
    data: mediaLists,
    isLoading: mediaListsAreLoading,
    sendRequest: fetchMediaLists,
  } = useHttp();

  const {
    data: executives,
    isLoading: executivesAreLoading,
    sendRequest: fetchExecutives,
  } = useHttp();

  const getCategories = () => {
    fetchCategories({
      url: process.env.REACT_APP_API_SERVER + "/client_categories",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getMediaLists = () => {
    fetchMediaLists({
      url: process.env.REACT_APP_API_SERVER + "/media_lists",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getExecutives = () => {
    fetchExecutives({
      url: process.env.REACT_APP_API_SERVER + "/executives",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: title,
    setEnteredValue: setTitle,
    valueChangeHandler: titleChangeHandler,
    inputBlurHandler: titleBlurHandler,
    reset: resetTitle,
  } = useInput();

  const {
    enteredValue: firstName,
    setEnteredValue: setFirstName,
    valueIsValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: lastName,
    setEnteredValue: setLastName,
    valueIsValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: secondLastName,
    setEnteredValue: setSecondLastName,
    valueChangeHandler: secondLastNameChangeHandler,
    reset: resetSecondLastName,
  } = useInput();

  const {
    enteredValue: mainPhone,
    setEnteredValue: setMainPhone,
    valueIsValid: mainPhoneIsValid,
    valueChangeHandler: mainPhoneChangeHandler,
    hasError: mainPhoneHasError,
    inputBlurHandler: mainPhoneBlurHandler,
    reset: resetMainPhone,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: mainEmail,
    setEnteredValue: setMainEmail,
    valueChangeHandler: mainEmailChangeHandler,
    reset: resetMainEmail,
  } = useInput();

  const {
    enteredValue: whatsapp,
    setEnteredValue: setWhatsapp,
    valueChangeHandler: whatsappChangeHandler,
    reset: resetWhatsapp,
  } = useInput();

  const {
    enteredValue: instagram,
    setEnteredValue: setInstagram,
    valueChangeHandler: instagramChangeHandler,
    reset: resetInstagram,
  } = useInput();

  const {
    enteredValue: facebook,
    setEnteredValue: setFacebook,
    valueChangeHandler: facebookChangeHandler,
    reset: resetFacebook,
  } = useInput();

  const {
    enteredValue: category,
    setEnteredValue: setCategory,
    valueIsValid: categoryIsValid,
    valueChangeHandler: categoryChangeHandler,
    inputBlurHandler: categoryBlurHandler,
    reset: resetCategory,
  } = useInput();

  const {
    enteredValue: mediaList,
    setEnteredValue: setMediaList,
    valueIsValid: mediaListIsValid,
    valueChangeHandler: mediaListChangeHandler,
    inputBlurHandler: mediaListBlurHandler,
    reset: resetMediaList,
  } = useInput();

  const {
    enteredValue: birthday,
    setEnteredValue: setBirthday,
    valueChangeHandler: birthdayChangeHandler,
    reset: resetBirthday,
  } = useInput();

  const {
    enteredValue: executive,
    setEnteredValue: setExecutive,
    valueIsValid: executiveIsValid,
    valueChangeHandler: executiveChangeHandler,
    hasError: executiveHasError,
    inputBlurHandler: executiveBlurHandler,
    reset: resetExecutive,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: picture,
    setEnteredValue: setPicture,
    reset: resetPicture,
  } = useInput();

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (
    firstNameIsValid &&
    lastNameIsValid &&
    mainPhoneIsValid &&
    categoryIsValid &&
    mediaListIsValid &&
    executiveIsValid
  ) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (action === "add") {
      const clientData = {
        cliTitle: title,
        cliFirstName: firstName,
        cliLastName: lastName,
        cliMothersLastName: secondLastName,
        cliMainPhone: mainPhone,
        cliMainEmail: mainEmail,
        cliWhatsapp: whatsapp,
        cliInstagram: instagram,
        cliFacebook: facebook,
        cliBirthdate: birthday !== "" ? JSON.stringify(birthday) : null,
        cliCategory: category === "" ? null : category,
        cliMediaID: mediaList === "" ? null : mediaList,
        cliExecutiveID: executive,
        cliPhoto: picture,
      };
      onCreate(clientData);
    }

    if (action === "edit") {
      const clientData = {
        id: client.client.id,
        cliTitle: title,
        cliFirstName: firstName,
        cliLastName: lastName,
        cliMothersLastName: secondLastName,
        cliMainPhone: mainPhone,
        cliMainEmail: mainEmail,
        cliWhatsapp: whatsapp,
        cliInstagram: instagram,
        cliFacebook: facebook,
        cliBirthdate: birthday !== "" ? JSON.stringify(birthday) : null,
        cliCategory: category === "" ? null : category,
        cliMediaID: mediaList === "" ? null : mediaList,
        cliExecutiveID: executive,
        cliPhoto: picture,
      };
      onUpdate(clientData);
    }

    resetTitle();
    resetFirstName();
    resetLastName();
    resetSecondLastName();
    resetMainPhone();
    resetMainEmail();
    resetWhatsapp();
    resetInstagram();
    resetFacebook();
    resetCategory();
    resetMediaList();
    resetBirthday();
    resetExecutive();
    resetPicture();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      getCategories();
      getMediaLists();
      getExecutives();
      if (client && action) {
        setTitle(client.client.cliTitle ? client.client.cliTitle : "");
        setFirstName(
          client.client.cliFirstName ? client.client.cliFirstName : ""
        );
        setLastName(client.client.cliLastName ? client.client.cliLastName : "");
        setSecondLastName(
          client.client.cliMothersLastName
            ? client.client.cliMothersLastName
            : ""
        );
        setMainPhone(
          client.client.cliMainPhone ? client.client.cliMainPhone : ""
        );
        setMainEmail(
          client.client.cliMainEmail ? client.client.cliMainEmail : ""
        );
        setWhatsapp(client.client.cliWhatsapp ? client.client.cliWhatsapp : "");
        setInstagram(
          client.client.cliInstagram ? client.client.cliInstagram : ""
        );
        setFacebook(client.client.cliFacebook ? client.client.cliFacebook : "");
        setCategory(client.client.cliCategory ? client.client.cliCategory : "");
        setMediaList(client.client.cliMediaID ? client.client.cliMediaID : "");
        setBirthday(
          client.client.cliBirthdate ? client.client.cliBirthdate : ""
        );
        setExecutive(
          client.client.cliExecutiveID ? client.client.cliExecutiveID : ""
        );
        setPicture(client.client.cliPhoto ? client.client.cliPhoto : "");
        setCaptured(
          client.client?.executive ? client.client?.executive?.exeInitials : ""
        );
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, client]);

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:clients.form.titles.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`clients.form.titles.${key}`])}
        </MenuItem>
      );
    }
  };

  const uploadSuccessHandler = (fileName) => {
    setPicture(fileName);
  };

  return (
    <Fragment>
      <Stack
        className={`${styles["client-form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={createIsLoading || updateIsLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl>
          <InputLabel id="title_label">{t("clients.form.title")}</InputLabel>
          <Select
            id="title"
            labelId="title_label"
            value={title}
            label={t("clients.form.title")}
            onChange={titleChangeHandler}
            onBlur={titleBlurHandler}
            disabled={action === "view"}
          >
            {titles.map((title, index) => findTranslation(title, index))}
          </Select>
        </FormControl>
        <FormControl
          error={firstNameHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="firstName">
            {t("clients.form.firstName")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="firstName"
            onChange={firstNameChangeHandler}
            onBlur={firstNameBlurHandler}
            value={firstName}
            endAdornment={
              firstName !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetFirstName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t("clients.form.firstName")}
            disabled={action === "view"}
          />
          {firstNameHasError && (
            <FormHelperText id="firstName-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={lastNameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="lastName">
            {t("clients.form.lastName")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="lastName"
            onChange={lastNameChangeHandler}
            onBlur={lastNameBlurHandler}
            value={lastName}
            endAdornment={
              lastName !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetLastName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t("clients.form.lastName")}
            disabled={action === "view"}
          />
          {lastNameHasError && (
            <FormHelperText id="lastName-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="secondLastName">
            {t("clients.form.secondLastName")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="secondLastName"
            onChange={secondLastNameChangeHandler}
            value={secondLastName}
            endAdornment={
              secondLastName !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetSecondLastName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t("clients.form.secondLastName")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl
          error={mainPhoneHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="mainPhone">
            {t("clients.form.mainPhone")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="mainPhone"
            onChange={mainPhoneChangeHandler}
            onBlur={mainPhoneBlurHandler}
            value={mainPhone}
            endAdornment={
              mainPhone !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetMainPhone} />
                </InputAdornment>
              )
            }
            type="tel"
            label={t("clients.form.mainPhone")}
            disabled={action === "view"}
          />
          {mainPhoneHasError && (
            <FormHelperText id="mainPhone-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {categoriesAreLoading && <CircularProgress color="inherit" />}
        {!categoriesAreLoading && (
          <FormControl>
            <InputLabel id="category_label">
              {t("clients.form.category")}
            </InputLabel>
            <Select
              id="category"
              labelId="category_label"
              value={category}
              label={t("clients.form.category")}
              onChange={categoryChangeHandler}
              onBlur={categoryBlurHandler}
              disabled={action === "view"}
            >
              {categories &&
                categories.ClientCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.clieCatName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {mediaListsAreLoading && <CircularProgress color="inherit" />}
        {!mediaListsAreLoading && (
          <FormControl>
            <InputLabel id="media_label">
              {t("clients.form.mediaList")}
            </InputLabel>
            <Select
              id="mediaList"
              labelId="media_label"
              value={mediaList}
              label={t("clients.form.mediaList")}
              onChange={mediaListChangeHandler}
              onBlur={mediaListBlurHandler}
              disabled={action === "view"}
            >
              {mediaLists &&
                mediaLists.mediaLists.map((mediaList) => (
                  <MenuItem key={mediaList.id} value={mediaList.id}>
                    {mediaList.medName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {executivesAreLoading && <CircularProgress color="inherit" />}
        {!executivesAreLoading && (
          <FormControl error={executiveHasError ? true : false}>
            <InputLabel id="executive_label">
              {t("clients.form.executive")}*
            </InputLabel>
            <Select
              id="executive"
              labelId="executive_label"
              value={executive}
              label={t("clients.form.executive")}
              onChange={executiveChangeHandler}
              onBlur={executiveBlurHandler}
              disabled={action === "view"}
            >
              {executives &&
                executives.executives.map((executive) => (
                  <MenuItem key={executive.id} value={executive.id}>
                    {executive.exeInitials}
                  </MenuItem>
                ))}
            </Select>
            {executiveHasError && (
              <FormHelperText id="executive-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel htmlFor="mainEmail">
            {t("clients.form.mainEmail")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="mainEmail"
            onChange={mainEmailChangeHandler}
            value={mainEmail}
            endAdornment={
              mainEmail !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetMainEmail} />
                </InputAdornment>
              )
            }
            type="email"
            label={t("clients.form.mainEmail")}
            disabled={action === "view"}
          />
        </FormControl>
        <Tooltip title={t("clients.form.whatsapp.tooltip")} placement="top">
          <FormControl variant="outlined">
            <InputLabel htmlFor="whatsapp">
              {t("clients.form.whatsapp.title")}
              <Help color="primary" sx={{ with: "20px", height: "20px" }} />
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="whatsapp"
              onChange={whatsappChangeHandler}
              value={whatsapp}
              endAdornment={
                whatsapp !== "" &&
                action !== "view" && (
                  <InputAdornment position="start">
                    <Clear onClick={resetWhatsapp} />
                  </InputAdornment>
                )
              }
              type="tel"
              label={t("clients.form.whatsapp.title")}
              disabled={action === "view"}
            />
          </FormControl>
        </Tooltip>
        <FormControl variant="outlined">
          <InputLabel htmlFor="instagram">
            {t("clients.form.instagram")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="instagram"
            onChange={instagramChangeHandler}
            value={instagram}
            endAdornment={
              instagram !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetInstagram} />
                </InputAdornment>
              )
            }
            type="text"
            label={t("clients.form.instagram")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="facebook">
            {t("clients.form.facebook")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="facebook"
            onChange={facebookChangeHandler}
            value={facebook}
            endAdornment={
              facebook !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetFacebook} />
                </InputAdornment>
              )
            }
            type="text"
            label={t("clients.form.facebook")}
            disabled={action === "view"}
          />
        </FormControl>
        <TextField
          id="birthday"
          label={t("clients.form.birthday")}
          type="date"
          value={birthday}
          onChange={birthdayChangeHandler}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={action === "view"}
        />
        <FileUploader
          action={action}
          currentFile={picture}
          fileType="image"
          inputId="clientPicture"
          inputLabel={t("clients.form.picture")}
          token={token}
          onUploadSuccess={uploadSuccessHandler}
        ></FileUploader>
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t("clients.form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t("clients.form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t("clients.model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ClientsForm;
