import React, { Fragment, useEffect } from "react";
import useHttp from "../../../hooks/use-http";
import useInput from "../../../hooks/use-input";
import useFormField from "../../../hooks/use-form-field";
import { Stack, Fab, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import styles from "../styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const BackgroundModelForm = ({
  token,
  model,
  translationModel,
  action,
  recordType,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  let loadForm = true;
  let formIsValid = false;
  const formSubmitHandler = async (event) => {
    event.preventDefault();
    if (action === "add") {
      onCreate(formFields);
    }

    if (action === "edit") {
      onUpdate(formFields);
    }
  };

  const cancelHandler = () => {
    onCancel();
  };

  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const clientChangeHandler = (e) => {
    setClient(e.target.value);
  };

  const { enteredValue: id, setEnteredValue: setId } = useInput();
  const { enteredValue: client, setEnteredValue: setClient } = useInput();
  const { enteredValue: date, setEnteredValue: setDate } = useInput();

  const {
    enteredValue: gender,
    setEnteredValue: setGender,
    valueChangeHandler: genderChangeHandler,
  } = useInput();

  const genders = [
    { id: 1, type: t(translationModel + ".form.background.genders.1") },
    { id: 2, type: t(translationModel + ".form.background.genders.2") },
  ];

  const { enteredValue: birthDate, setEnteredValue: setBirthDate } = useInput();

  const {
    enteredValue: bloodType,
    setEnteredValue: setBloodType,
    valueChangeHandler: bloodTypeChangeHandler,
  } = useInput();

  const bloodTypes = [
    { id: 1, type: t(translationModel + ".form.background.bloodTypes.1") },
    { id: 2, type: t(translationModel + ".form.background.bloodTypes.2") },
    { id: 3, type: t(translationModel + ".form.background.bloodTypes.3") },
    { id: 4, type: t(translationModel + ".form.background.bloodTypes.4") },
    { id: 5, type: t(translationModel + ".form.background.bloodTypes.5") },
    { id: 6, type: t(translationModel + ".form.background.bloodTypes.6") },
    { id: 7, type: t(translationModel + ".form.background.bloodTypes.7") },
    { id: 8, type: t(translationModel + ".form.background.bloodTypes.8") },
  ];

  const {
    enteredValue: rhFactor,
    setEnteredValue: setRhFactor,
    valueChangeHandler: rhFactorChangeHandler,
    reset: resetRhFactor,
  } = useInput();

  const {
    enteredValue: weight,
    setEnteredValue: setWeight,
    valueChangeHandler: weightChangeHandler,
    reset: resetWeight,
  } = useInput();

  const {
    enteredValue: height,
    setEnteredValue: setHeight,
    valueChangeHandler: heightChangeHandler,
    reset: resetHeight,
  } = useInput();

  const {
    enteredValue: medicineTaken,
    setEnteredValue: setMedicineTaken,
    valueChangeHandler: medicineTakenChangeHandler,
    reset: resetMedicineTaken,
  } = useInput();

  const {
    enteredValue: penicillinAllergy,
    setEnteredValue: setPenicillinAllergy,
  } = useInput();

  const penicillinAllergyChangeHandler = () => {
    setPenicillinAllergy(!penicillinAllergy);
  };

  const { enteredValue: iodineAllergy, setEnteredValue: setIodineAllergy } =
    useInput();

  const iodineAllergyChangeHandler = () => {
    setIodineAllergy(!iodineAllergy);
  };

  const { enteredValue: polenAllergy, setEnteredValue: setPolenAllergy } =
    useInput();

  const polenAllergyChangeHandler = () => {
    setPolenAllergy(!polenAllergy);
  };

  const {
    enteredValue: otherAllergies,
    setEnteredValue: setOtherAllergies,
    valueChangeHandler: otherAllergiesChangeHandler,
    reset: resetOtherAllergies,
  } = useInput();

  const { enteredValue: smoker, setEnteredValue: setSmoker } = useInput();

  const smokerChangeHandler = () => {
    setSmoker(!smoker);
  };

  const { enteredValue: drinker, setEnteredValue: setDrinker } = useInput();

  const drinkerChangeHandler = () => {
    setDrinker(!drinker);
  };

  const {
    enteredValue: highBloodPressure,
    setEnteredValue: setHighBloodPressure,
  } = useInput();

  const highBloodPressureChangeHandler = () => {
    setHighBloodPressure(!highBloodPressure);
  };

  const { enteredValue: diabetes, setEnteredValue: setDiabetes } = useInput();

  const diabetesChangeHandler = () => {
    setDiabetes(!diabetes);
  };

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    reset: resetObservations,
  } = useInput();

  const {
    enteredValue: otherConditions,
    setEnteredValue: setOtherConditions,
    valueChangeHandler: otherConditionsChangeHandler,
    reset: resetOtherConditions,
  } = useInput();

  const {
    enteredValue: surgicalInterventions,
    setEnteredValue: setSurgicalInterventions,
    valueChangeHandler: surgicalInterventionsChangeHandler,
    reset: resetSurgicalInterventions,
  } = useInput();

  const { enteredValue: familyDiabetes, setEnteredValue: setFamilyDiabetes } =
    useInput();

  const familyDiabetesChangeHandler = () => {
    setFamilyDiabetes(!familyDiabetes);
  };

  const { enteredValue: familyCancer, setEnteredValue: setFamilyCancer } =
    useInput();

  const familyCancerChangeHandler = () => {
    setFamilyCancer(!familyCancer);
  };

  const { enteredValue: recordFile, setEnteredValue: setRecordFile } =
    useInput();

  const { enteredValue: captured, setEnteredValue: setCaptured } = useInput();

  const formConfig = [
    {
      type: "text",
      action,
      disabled: true,
      id: "id",
      multiline: false,
      name: "id",
      sx: { display: "none" },
      value: id,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "recordType",
      multiline: false,
      name: "cliRecType",
      sx: { display: "none" },
      value: recordType,
    },
    {
      type: "select",
      action,
      disabled: action === "view",
      errorMessage: t("errors.fieldError"),
      id: "client",
      label: t(translationModel + ".form.background.client") + "*",
      name: "cliRecClientID",
      sx: { background: "white" },
      value: clients?.clients && !clientsAreLoading ? client : "",
      menuItems: clients && !clientsAreLoading && clients.clients,
      menuItemValue: ["id", "cliLastName", "cliFirstName"],
      customOnChange: clientChangeHandler,
      validationFn: (value) => value > 0,
    },
    {
      type: "date",
      action,
      disabled: action === "view",
      id: "date",
      label: t(translationModel + ".form.background.date"),
      name: "cliRecRecognitionDate",
      sx: { width: 220 },
      value: date ? date : format(new Date(), "yyyy-MM-dd"),
    },
    {
      type: "select",
      action,
      disabled: action === "view",
      id: "gender",
      label: t(translationModel + ".form.background.gender"),
      name: "cliRecSex",
      sx: { background: "white" },
      value: gender,
      menuItems: genders,
      menuItemValue: ["type"],
      customOnChange: genderChangeHandler,
    },
    {
      type: "date",
      action,
      disabled: action === "view",
      id: "birthDate",
      label: t(translationModel + ".form.background.birthDate"),
      name: "cliRecBirthDate",
      sx: { width: 220 },
      value: birthDate,
    },
    {
      type: "select",
      action,
      disabled: action === "view",
      id: "bloodType",
      label: t(translationModel + ".form.background.bloodType"),
      name: "cliRecBloodType",
      sx: { background: "white" },
      value: bloodType,
      menuItems: bloodTypes,
      menuItemValue: ["type"],
      customOnChange: bloodTypeChangeHandler,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "rhFactor",
      label: t(translationModel + ".form.background.rhFactor"),
      multiline: false,
      name: "cliRecRHFactor",
      sx: { background: "white" },
      value: rhFactor,
      customOnChange: rhFactorChangeHandler,
      customOnClear: resetRhFactor,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "weight",
      label: t(translationModel + ".form.background.weight"),
      multiline: false,
      name: "cliRecWeight",
      sx: { background: "white" },
      value: weight,
      customOnChange: weightChangeHandler,
      customOnClear: resetWeight,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "height",
      label: t(translationModel + ".form.background.height"),
      multiline: false,
      name: "cliRecHeight",
      sx: { background: "white" },
      value: height,
      customOnChange: heightChangeHandler,
      customOnClear: resetHeight,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "medicineTaken",
      label: t(translationModel + ".form.background.medicineTaken"),
      multiline: true,
      name: "cliRecMedicineTaken",
      sx: { background: "white" },
      value: medicineTaken,
      customOnChange: medicineTakenChangeHandler,
      customOnClear: resetMedicineTaken,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "penicillinAllergy",
      label: t(translationModel + ".form.background.penicillinAllergy"),
      name: "cliRecPenicilinAlergy",
      sx: { background: "white" },
      value: penicillinAllergy ? true : false,
      checked:
        penicillinAllergy === 1 || penicillinAllergy === true ? true : false,
      customOnChange: penicillinAllergyChangeHandler,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "iodineAllergy",
      label: t(translationModel + ".form.background.iodineAllergy"),
      name: "cliRecIodineAlergy",
      sx: { background: "white" },
      value: iodineAllergy ? true : false,
      checked: iodineAllergy === 1 || iodineAllergy === true ? true : false,
      customOnChange: iodineAllergyChangeHandler,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "polenAllergy",
      label: t(translationModel + ".form.background.polenAllergy"),
      name: "cliRecPollenAlergy",
      sx: { background: "white" },
      value: polenAllergy ? true : false,
      checked: polenAllergy === 1 || polenAllergy === true ? true : false,
      customOnChange: polenAllergyChangeHandler,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "otherAllergies",
      label: t(translationModel + ".form.background.otherAllergies"),
      multiline: true,
      name: "cliRecOtherAlergies",
      sx: { background: "white" },
      value: otherAllergies,
      customOnChange: otherAllergiesChangeHandler,
      customOnClear: resetOtherAllergies,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "smoker",
      label: t(translationModel + ".form.background.smoker"),
      name: "cliRecSmokes",
      sx: { background: "white" },
      value: smoker ? true : false,
      checked: smoker === 1 || smoker === true ? true : false,
      customOnChange: smokerChangeHandler,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "drinker",
      label: t(translationModel + ".form.background.drinker"),
      name: "cliRecDrinks",
      sx: { background: "white" },
      value: drinker ? true : false,
      checked: drinker === 1 || drinker === true ? true : false,
      customOnChange: drinkerChangeHandler,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "highBloodPressure",
      label: t(translationModel + ".form.background.highBloodPressure"),
      name: "cliRecHighPresure",
      sx: { background: "white" },
      value: highBloodPressure ? true : false,
      checked:
        highBloodPressure === 1 || highBloodPressure === true ? true : false,
      customOnChange: highBloodPressureChangeHandler,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "diabetes",
      label: t(translationModel + ".form.background.diabetes"),
      name: "cliRecDiabetes",
      sx: { background: "white" },
      value: diabetes ? true : false,
      checked: diabetes === 1 || diabetes === true ? true : false,
      customOnChange: diabetesChangeHandler,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "otherConditions",
      label: t(translationModel + ".form.background.otherConditions"),
      multiline: true,
      name: "cliRecOtherConditions",
      sx: { background: "white" },
      value: otherConditions,
      customOnChange: otherConditionsChangeHandler,
      customOnClear: resetOtherConditions,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "surgicalInterventions",
      label: t(translationModel + ".form.background.surgicalInterventions"),
      multiline: true,
      name: "cliRecSurgeries",
      sx: { background: "white" },
      value: surgicalInterventions,
      customOnChange: surgicalInterventionsChangeHandler,
      customOnClear: resetSurgicalInterventions,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "familyDiabetes",
      label: t(translationModel + ".form.background.familyDiabetes"),
      name: "cliRecFamilyDiabetes",
      sx: { background: "white" },
      value: familyDiabetes ? true : false,
      checked: familyDiabetes === 1 || familyDiabetes === true ? true : false,
      customOnChange: familyDiabetesChangeHandler,
    },
    {
      type: "switch",
      action,
      disabled: action === "view",
      id: "familyCancer",
      label: t(translationModel + ".form.background.familyCancer"),
      name: "cliRecFamilyCancer",
      sx: { background: "white" },
      value: familyCancer ? true : false,
      checked: familyCancer === 1 || familyCancer === true ? true : false,
      customOnChange: familyCancerChangeHandler,
    },
    {
      type: "file",
      action,
      currentFile: "",
      fileType: "document",
      disabled: action === "view",
      id: "recordFile",
      label: t(translationModel + ".form.background.document"),
      name: "cliRecFile",
      token,
      value: recordFile,
    },
    {
      type: "text",
      action,
      disabled: action === "view",
      id: "observations",
      label: t(translationModel + ".form.background.observations"),
      multiline: true,
      name: "cliRecObservations",
      sx: { background: "white" },
      value: observations,
      customOnChange: observationsChangeHandler,
      customOnClear: resetObservations,
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "captured",
      multiline: false,
      name: "cliRecCaptured",
      sx: { display: "none" },
      value: 1, //TODO: Assign user id
    },
    {
      type: "text",
      action,
      disabled: true,
      id: "captured",
      label: action === "view" ? t(translationModel + ".form.captured") : "",
      multiline: false,
      name: "captured",
      sx: action !== "view" ? { display: "none" } : { display: true },
      value: captured,
    },
  ];

  const { formFields, renderFormFields } = useFormField(formConfig);

  if (client) {
    formIsValid = true;
  }

  useEffect(() => {
    if (loadForm) {
      getClients();
    }
    if (model && action) {
      setId(model.clientRecord.id ? model.clientRecord.id : null);

      setClient(
        model.clientRecord.cliRecClientID
          ? model.clientRecord.cliRecClientID
          : false
      );

      setDate(
        model.clientRecord.cliRecCreationDate
          ? new Date(model.clientRecord.cliRecCreationDate)
              .toISOString()
              .split("T")[0]
          : ""
      );

      setGender(
        model.clientRecord?.cliRecSex ? model.clientRecord.cliRecSex : ""
      );

      setBirthDate(
        model.clientRecord.cliRecBirthDate
          ? new Date(model.clientRecord.cliRecBirthDate)
              .toISOString()
              .split("T")[0]
          : ""
      );

      setBloodType(
        model.clientRecord?.cliRecBloodType
          ? model.clientRecord.cliRecBloodType
          : ""
      );

      setRhFactor(
        model.clientRecord?.cliRecRHFactor
          ? model.clientRecord.cliRecRHFactor
          : 0
      );

      setWeight(
        model.clientRecord?.cliRecWeight ? model.clientRecord.cliRecWeight : ""
      );

      setHeight(
        model.clientRecord?.cliRecHeight ? model.clientRecord.cliRecHeight : ""
      );

      setMedicineTaken(
        model.clientRecord?.cliRecMedicineTaken
          ? model.clientRecord.cliRecMedicineTaken
          : ""
      );

      setPenicillinAllergy(
        model.clientRecord?.cliRecPenicilinAlergy
          ? model.clientRecord.cliRecPenicilinAlergy
          : ""
      );

      setIodineAllergy(
        model.clientRecord?.cliRecIodineAlergy
          ? model.clientRecord.cliRecIodineAlergy
          : ""
      );

      setPolenAllergy(
        model.clientRecord?.cliRecPollenAlergy
          ? model.clientRecord.cliRecPollenAlergy
          : ""
      );

      setOtherAllergies(
        model.clientRecord?.cliRecOtherAlergies
          ? model.clientRecord.cliRecOtherAlergies
          : ""
      );

      setSmoker(
        model.clientRecord?.cliRecSmokes ? model.clientRecord.cliRecSmokes : ""
      );

      setDrinker(
        model.clientRecord?.cliRecDrinks ? model.clientRecord.cliRecDrinks : ""
      );

      setHighBloodPressure(
        model.clientRecord?.cliRecHighPresure
          ? model.clientRecord.cliRecHighPresure
          : ""
      );

      setDiabetes(
        model.clientRecord?.cliRecDiabetes
          ? model.clientRecord.cliRecDiabetes
          : ""
      );

      setOtherConditions(
        model.clientRecord?.cliRecOtherConditions
          ? model.clientRecord.cliRecOtherConditions
          : ""
      );

      setSurgicalInterventions(
        model.clientRecord?.cliRecSurgeries
          ? model.clientRecord.cliRecSurgeries
          : ""
      );

      setFamilyDiabetes(
        model.clientRecord?.cliRecFamilyDiabetes
          ? model.clientRecord.cliRecFamilyDiabetes
          : ""
      );

      setFamilyCancer(
        model.clientRecord?.cliRecFamilyCancer
          ? model.clientRecord.cliRecFamilyCancer
          : ""
      );

      setRecordFile(
        model.clientRecord?.cliRecFile ? model.clientRecord.cliRecFile : ""
      );

      setObservations(
        model.clientRecord?.cliRecObservations
          ? model.clientRecord.cliRecObservations
          : ""
      );

      setCaptured(
        model.clientRecord?.cliRecCaptured
          ? model.clientRecord.cliRecCaptured
          : ""
      );
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {renderFormFields()}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default BackgroundModelForm;
