import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";

const ChargesByPaymentType = ({
  translationModel,
  charges,
  payments,
  modelName,
}) => {
  const { t } = useTranslation("common");

  const translationPrefix = () => {
    if (modelName === "charges") {
      return "charges";
    }
    if (modelName === "payments") {
      return "payments";
    }
  };

  const formatMoney = (amount) => {
    return (
      "$" +
      Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
  };

  const renderRows = (data, idKey, typeKey, amountKey) => {
    return data?.map((item) => (
      <TableRow key={"row_" + item[idKey]}>
        <TableCell key={"type_cell_value_" + item[idKey]} align="center">
          {item[typeKey]?.payTypName}
        </TableCell>
        <TableCell key={"amount_cell_value_" + item[idKey]} align="center">
          {formatMoney(item[amountKey])}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography
          id={
            t(
              translationModel +
                `.columns.${translationPrefix()}ByPaymentType.title`
            ) + "_title"
          }
          mt="100px"
          pb="20px"
          variant="h5"
        >
          {t(
            translationModel +
              `.columns.${translationPrefix()}ByPaymentType.title`
          )}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <Fragment>
                <TableRow key="header">
                  <TableCell align="center" key={"type_cell_title"}>
                    {t(
                      translationModel +
                        `.columns.${translationPrefix()}ByPaymentType.paymentType`
                    )}
                  </TableCell>
                  <TableCell key={"amount_cell_title"} align="center">
                    {t(
                      translationModel +
                        `.columns.${translationPrefix()}ByPaymentType.amount`
                    )}
                  </TableCell>
                </TableRow>
                <Fragment>
                  {renderRows(
                    charges?.Charges,
                    "id",
                    "payment_type",
                    "chaAmount"
                  )}
                  {renderRows(payments?.Payments, "id", "type", "payAmount")}
                </Fragment>
              </Fragment>
            </TableBody>
          </Table>
        </TableContainer>
        {charges && (
          <Typography
            id={
              t(
                translationModel +
                  `.columns.${translationPrefix}ByPaymentType.total`
              ) + "_title"
            }
            pt="10px"
            pr="30px"
            variant="h6"
            textAlign="right"
          >
            {t(
              translationModel +
                `.columns.${translationPrefix()}ByPaymentType.total`
            ) +
              " " +
              formatMoney(
                charges?.Charges?.length > 0 &&
                  charges.Charges[0].chaTotalCharges
              )}
          </Typography>
        )}
        {payments && (
          <Typography
            id={
              t(
                translationModel +
                  `.columns.${translationPrefix}ByPaymentType.total`
              ) + "_title"
            }
            pt="10px"
            pr="30px"
            variant="h6"
            textAlign="right"
          >
            {t(
              translationModel +
                `.columns.${translationPrefix()}ByPaymentType.total`
            ) +
              " " +
              formatMoney(
                payments?.Payments?.length > 0 &&
                  payments.Payments[0].payTotalPayments
              )}
          </Typography>
        )}
      </Stack>
    </Fragment>
  );
};
export default ChargesByPaymentType;
