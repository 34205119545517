import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const TicketDetails = ({ modelData, module, translationModel }) => {
  const { t } = useTranslation("common");
  const getModelClient = (model) => {
    return (
      model?.sale?.Client?.salClientName ||
      model?.purchase?.Provider?.proCoCommercialName
    );
  };
  const getModelId = (model) => {
    return model?.sale?.id || model?.purchase?.id;
  };
  const formatDate = (date) => {
    return format(new Date(date), "dd/MM/YYY HH:mm");
  };
  const getModelDate = (model) => {
    return model.sale?.salDate || model.purchase?.purDate;
  };
  const getModelExecutive = (model) => {
    return model.sale?.Executive?.exeInitials || model.purchase?.Executive;
  };
  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };

  const renderDetails = (detail, prefix) => (
    <tr key={detail.id}>
      <td align="center">{detail[`${prefix}Product`]}</td>
      <td align="center">{detail[`${prefix}Quantity`]}</td>
      <td align="center">
        {"$" + parseFloat(detail[`${prefix}Price`], 2).toFixed(2)}
      </td>
      <td align="center">
        {"$" +
          parseFloat(
            detail[`${prefix}Quantity`] * detail[`${prefix}Price`],
            2
          ).toFixed(2)}
      </td>
    </tr>
  );

  return (
    <Fragment>
      <table>
        <thead>
          <tr>
            <td></td>
            <td></td>
            <th align="right">
              {isSalesModule()
                ? t(translationModel + ".columns.saleDetails.client")
                : t(translationModel + ".columns.purchaseDetails.provider")}
              :
            </th>
            <td align="left" pl="5px">
              {getModelClient(modelData)}
            </td>
          </tr>
          <tr sx={{ height: "10px" }}>
            <td sx={{ minWidth: "90px" }}></td>
            <td sx={{ minWidth: "90px" }}></td>
            <th align="right" sx={{ height: "10px" }}>
              {isSalesModule()
                ? t(translationModel + ".columns.saleDetails.saleID")
                : t(translationModel + ".columns.purchaseDetails.purchaseID")}
              :
            </th>
            <td align="left" pl="5px">
              {getModelId(modelData)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <th align="right">
              {t(translationModel + ".columns.saleDetails.date")}:
            </th>
            <td align="left" pl="5px">
              {formatDate(getModelDate(modelData))}
            </td>
          </tr>
          <tr sx={{ height: "10px" }}>
            <td sx={{ minWidth: "90px" }}></td>
            <td sx={{ minWidth: "90px" }}></td>
            <th align="right" sx={{ height: "10px" }}>
              {t(translationModel + ".form.captured")}:
            </th>
            <td align="left" pl="5px">
              {getModelExecutive(modelData)}
            </td>
          </tr>
        </thead>
      </table>
      <table style={{ paddingTop: "20px", paddingBottom: "80px" }}>
        <tbody>
          <tr>
            <th>{t(translationModel + ".columns.saleDetails.name")}</th>
            <th>{t(translationModel + ".columns.saleDetails.quantity")}</th>
            <th>{t(translationModel + ".columns.saleDetails.price")}</th>
            <th>{t(translationModel + ".columns.saleDetails.total")}</th>
          </tr>
          {modelData && (
            <Fragment>
              {modelData.sale?.SaleDetails &&
                modelData.sale.SaleDetails.map((detail) =>
                  renderDetails(detail, "salDet")
                )}
              {modelData.purchase?.PurchaseDetails &&
                modelData.purchase.PurchaseDetails.map((detail) =>
                  renderDetails(detail, "purDet")
                )}
            </Fragment>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};
export default TicketDetails;
