//TODO: Assign Icons and configure routes
import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import {
  HelpOutlineOutlined,
  SettingsOutlined,
  SystemSecurityUpdateWarningOutlined,
  MeetingRoomOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/system";

const HeaderRightMenu = ({ onOpenDialog }) => {
  const [targetMenu, setTargetMenu] = useState(null);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const openMenu = Boolean(targetMenu);
  const navigate = useNavigate();

  const Link = styled(RouterLink)({
    display: "flex",
    width: "100%",
    color: "inherit",
    textDecoration: "none",
  });

  const menuHandler = (event) => {
    setTargetMenu(event.currentTarget);
  };

  const closeHandler = () => {
    setTargetMenu(null);
  };

  const logout = () => {
    dispatch(authActions.logout());
    navigate("/login");
  };

  const reportErrorHandler = () => {
    closeHandler();
    onOpenDialog("reportError");
  };

  return (
    <Fragment>
      <IconButton
        id="basic-button"
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={menuHandler}
        color="inherit"
      >
        <SettingsOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={targetMenu}
        open={openMenu}
        onClose={closeHandler}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Link to="/clients">
            <ListItemIcon>
              <SystemSecurityUpdateWarningOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("header.settings.help")}</ListItemText>
          </Link>
        </MenuItem>
        <MenuItem onClick={reportErrorHandler}>
          <ListItemIcon>
            <HelpOutlineOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("header.settings.reportError")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          <ListItemIcon>
            <MeetingRoomOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("header.settings.logOut")}</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
export default HeaderRightMenu;
