import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { format } from "date-fns";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  model,
  translationModel,
  action,
  clientId,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const [finished, setFinished] = useState(false);
  let loadForm = true;

  const {
    data: taskLists,
    isLoading: taskListsAreLoading,
    sendRequest: fetchTaskLists,
  } = useHttp();

  const {
    data: executives,
    isLoading: executivesAreLoading,
    sendRequest: fetchExecutives,
  } = useHttp();

  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const {
    data: providers,
    isLoading: providersAreLoading,
    sendRequest: fetchProviders,
  } = useHttp();

  const getTaskLists = () => {
    fetchTaskLists({
      url: process.env.REACT_APP_API_SERVER + "/task_lists",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getExecutives = () => {
    fetchExecutives({
      url: process.env.REACT_APP_API_SERVER + "/executives",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getProviders = () => {
    fetchProviders({
      url: process.env.REACT_APP_API_SERVER + "/providers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: taskList,
    setEnteredValue: setTaskList,
    valueIsValid: taskListIsValid,
    valueChangeHandler: taskListChangeHandler,
    hasError: taskListHasError,
    inputBlurHandler: taskListBlurHandler,
    reset: resetTaskList,
  } = useInput();

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueIsValid: descriptionIsValid,
    valueChangeHandler: descriptionChangeHandler,
    hasError: descriptionHasError,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput();

  const {
    enteredValue: dueDate,
    setEnteredValue: setDueDate,
    valueChangeHandler: dueDateChangeHandler,
    reset: resetDueDate,
  } = useInput();

  const {
    enteredValue: client,
    setEnteredValue: setClient,
    valueChangeHandler: clientChangeHandler,
    hasError: clientHasError,
    inputBlurHandler: clientBlurHandler,
    reset: resetClient,
  } = useInput();

  const {
    enteredValue: provider,
    setEnteredValue: setProvider,
    valueChangeHandler: providerChangeHandler,
    hasError: providerHasError,
    inputBlurHandler: providerBlurHandler,
    reset: resetProvider,
  } = useInput();

  const {
    enteredValue: assigned,
    setEnteredValue: setAssigned,
    valueChangeHandler: assignedChangeHandler,
    hasError: assignedHasError,
    inputBlurHandler: assignedBlurHandler,
    reset: resetAssigned,
  } = useInput();

  const finishedChangeHandler = () => {
    setFinished(!finished);
  };

  const resetFinished = () => {
    setFinished("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (taskListIsValid && descriptionIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let finishedValue = null;
    if (finished === true) {
      finishedValue = 1;
    }
    if (finished === false) {
      finishedValue = null;
    }

    if (action === "add") {
      const modelData = {
        tasList: taskList ? taskList : null,
        tasDescription: description ? description : null,
        tasDueDate: dueDate ? dueDate : null,
        tasClientID: client ? client : null,
        tasProviderID: provider ? provider : null,
        tasAssignedTo: assigned ? assigned : null,
        tasFinished: finishedValue ? finishedValue : null,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.task.id,
        tasList: taskList ? taskList : null,
        tasLisDescription: description ? description : null,
        tasDueDate: dueDate ? dueDate : null,
        tasClientID: client ? client : null,
        tasProviderID: provider ? provider : null,
        tasAssignedTo: assigned ? assigned : null,
        tasFinished: finishedValue ? finishedValue : null,
      };
      onUpdate(modelData);
    }
    resetTaskList();
    resetDescription();
    resetDueDate();
    resetClient();
    resetProvider();
    resetFinished();
    resetAssigned();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      getTaskLists();
      getExecutives();
      getClients();
      getProviders();

      if (model && action) {
        let dueDateDate = "";
        if (model.task.tasDueDate) {
          dueDateDate = format(
            new Date(model.task.tasDueDate),
            "yyyy-MM-dd HH:mm"
          );
        }
        setTaskList(model.task.tasList ? model.task.tasList : "");
        setDescription(
          model.task.tasDescription ? model.task.tasDescription : ""
        );
        setDueDate(dueDateDate ? dueDateDate : "");
        setClient(model.task.tasClientID ? model.task.tasClientID : "");
        setProvider(model.task.tasProviderID ? model.task.tasProviderID : "");
        setAssigned(model.task.tasAssignedTo ? model.task.tasAssignedTo : "");
        if (model.task.tasFinished === 1 || model.task.tasFinished === true) {
          setFinished(true);
        }
        if (!model.task.tasFinished) {
          setFinished(false);
        }
        setCaptured(model.task.tasCaptured ? model.task.tasCaptured : "");
      }
      if (clientId && action === "add") {
        setClient(clientId);
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {taskListsAreLoading && <CircularProgress color="inherit" />}
        {!taskListsAreLoading && (
          <FormControl error={taskListHasError ? true : false}>
            <InputLabel id="taskList_label">
              {t(translationModel + ".form.taskList")}*
            </InputLabel>
            <Select
              id="taskList"
              labelId="taskList_label"
              value={taskList}
              label={t(translationModel + ".form.taskList")}
              onChange={taskListChangeHandler}
              onBlur={taskListBlurHandler}
              disabled={action === "view"}
            >
              {taskLists &&
                taskLists.taskLists.map((taskList) => (
                  <MenuItem key={taskList.id} value={taskList.id}>
                    {taskList.tasLisName}
                  </MenuItem>
                ))}
            </Select>
            {taskListHasError && (
              <FormHelperText id="taskList-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl
          error={descriptionHasError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
          {descriptionHasError && (
            <FormHelperText id="description-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <TextField
          id="dueDate"
          label={t(translationModel + ".form.dueDate")}
          type="datetime-local"
          value={dueDate}
          onChange={dueDateChangeHandler}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={action === "view"}
        />
        {clientsAreLoading && <CircularProgress color="inherit" />}
        {!clientsAreLoading && (
          <FormControl error={clientHasError ? true : false}>
            <InputLabel id="client_label">
              {t(translationModel + ".form.client")}
            </InputLabel>
            <Select
              id="client"
              labelId="client_label"
              value={client}
              label={t(translationModel + ".form.client")}
              onChange={clientChangeHandler}
              onBlur={clientBlurHandler}
              disabled={action === "view"}
            >
              {clients &&
                clients.clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.cliTitle && client.cliTitle}{" "}
                    {client.cliFirstName && client.cliFirstName}{" "}
                    {client.cliLastName && client.cliLastName}
                  </MenuItem>
                ))}
            </Select>
            {clientHasError && (
              <FormHelperText id="captured-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {providersAreLoading && <CircularProgress color="inherit" />}
        {!providersAreLoading && (
          <FormControl error={providerHasError ? true : false}>
            <InputLabel id="provider_label">
              {t(translationModel + ".form.provider")}
            </InputLabel>
            <Select
              id="provider"
              labelId="provider_label"
              value={provider}
              label={t(translationModel + ".form.provider")}
              onChange={providerChangeHandler}
              onBlur={providerBlurHandler}
              disabled={action === "view"}
            >
              {providers &&
                providers.Providers.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.proCoCommercialName &&
                      provider.proCoCommercialName}{" "}
                  </MenuItem>
                ))}
            </Select>
            {providerHasError && (
              <FormHelperText id="captured-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControlLabel
          control={
            <Switch
              id="finished"
              checked={finished === 1 || finished === true ? true : false}
              onChange={finishedChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
              disabled={action === "view"}
            />
          }
          label={t(translationModel + ".form.finished")}
        />
        {executivesAreLoading && <CircularProgress color="inherit" />}
        {!executivesAreLoading && (
          <FormControl error={assignedHasError ? true : false}>
            <InputLabel id="assigned_label">
              {t(translationModel + ".form.assigned")}*
            </InputLabel>
            <Select
              id="assigned"
              labelId="assigned_label"
              value={assigned}
              label={t(translationModel + ".form.assigned")}
              onChange={assignedChangeHandler}
              onBlur={assignedBlurHandler}
              disabled={action === "view"}
            >
              {executives &&
                executives.executives.map((assigned) => (
                  <MenuItem key={assigned.id} value={assigned.id}>
                    {assigned.exeInitials}
                  </MenuItem>
                ))}
            </Select>
            {assignedHasError && (
              <FormHelperText id="assigned-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
