import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import ViewHeadline from "@mui/icons-material/ViewHeadline";
import logo from "../../assets/logo.png";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import HeaderMainMenuItems from "./HeaderMainMenuItems";
import HeaderRightMenu from "./HeaderRightMenu";
import HeaderLanguageMenu from "./HeaderLanguageMenu";
import HeatherDialog from "./HeaderDialog";

const Header = ({ username, token }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuItems = useSelector((state) => state.auth.menuItems);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogType, setDialogType] = useState(null);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const fetchMenuItems = async () => {
    setIsLoading(true);
    const response = await fetch(
      process.env.REACT_APP_API_SERVER + "/access_rights",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      }
    );
    if (!response.ok) {
      setError(t("errors.defaultError"));
    }
    const data = await response.json();
    setIsLoading(false);
    return data;
  };

  const handleDrawerToggle = async () => {
    setOpenDrawer(!openDrawer);
    if (!openDrawer && !menuItems) {
      const menuItemsFetchData = await fetchMenuItems();
      dispatch(authActions.setMenuItems(menuItemsFetchData));
    }
  };

  const logout = () => {
    dispatch(authActions.logout());
    navigate("/login");
  };

  const dialogOpenHandler = (type) => {
    setDialogType(type);
  };

  return (
    <AppBar component="nav">
      <Toolbar>
        <Drawer
          open={openDrawer}
          onClose={handleDrawerToggle}
          variant="temporary"
          anchor="left"
        >
          {username && token && (
            <HeaderMainMenuItems
              onSelectMenuItem={handleDrawerToggle}
              error={error}
              loading={isLoading}
            ></HeaderMainMenuItems>
          )}
        </Drawer>
        <Grid container spacing={2}>
          <Grid item xs={6} s={6} md={8} lg={8}>
            {username && (
              <IconButton
                id="mainMenu"
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleDrawerToggle}
              >
                <ViewHeadline />
              </IconButton>
            )}
            <Button
              sx={{ margin: "0 auto" }}
              color="inherit"
              onClick={() => navigate("/")}
            >
              <Avatar
                alt="Logo"
                src={logo}
                sx={{ width: 34, height: 34, paddingRight: "5px" }}
              />
              {smallScreen &&
                `${process.env.REACT_APP_TITLE} ${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_BRANCH}`}
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            s={6}
            md={4}
            lg={4}
            display="flex"
            justifyContent="right"
          >
            <IconButton
              id="user-link"
              aria-haspopup="true"
              color="inherit"
              onClick={() => (username ? logout() : navigate("/login"))}
            >
              <Typography>{username ? username : t("header.login")}</Typography>
            </IconButton>
            <HeaderLanguageMenu />
            {username && <HeaderRightMenu onOpenDialog={dialogOpenHandler} />}
          </Grid>
        </Grid>
      </Toolbar>
      {dialogType && (
        <HeatherDialog
          type={dialogType}
          title={t("errorReporting.title")}
          description={t("errorReporting.description")}
          onDialogHandler={dialogOpenHandler}
        />
      )}
    </AppBar>
  );
};
export default Header;
