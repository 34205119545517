import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const ModelCharges = ({ model, module }) => {
  const { t } = useTranslation("common");
  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };
  const isPurchasesModule = () => {
    return (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    );
  };

  const renderModelRows = (row, type) => (
    <tbody key={row.id}>
      <tr>
        <td style={{ width: "120px" }}></td>
        <th align="right">
          {isSalesModule()
            ? t("charges.columns.date")
            : isPurchasesModule()
            ? t("payments.columns.date")
            : ""}
          :
        </th>
        <td align="left">
          {format(new Date(row[`${type}DateTime`]), "dd/MM/YYY HH:mm")}
        </td>
      </tr>
      <tr>
        <td style={{ width: "120px" }}></td>
        <th align="right">
          {isSalesModule()
            ? t("charges.columns.amount")
            : isPurchasesModule()
            ? t("payments.columns.amount")
            : ""}
          :
        </th>
        <td align="left">
          {"$" + parseFloat(row[`${type}Amount`], 2).toFixed(2)}
        </td>
      </tr>
      <tr>
        <td style={{ width: "120px" }}></td>
        <th align="right">
          {isSalesModule()
            ? t("charges.columns.paymentType")
            : isPurchasesModule()
            ? t("payments.columns.paymentType")
            : ""}
          :
        </th>
        <td align="left">{row[`${type}PaymentTypeName`]}</td>
      </tr>
    </tbody>
  );

  return (
    <table style={{ width: "400px", paddingTop: "20px" }}>
      {model && (
        <Fragment>
          {model.sale?.Charges?.map((charge) => renderModelRows(charge, "cha"))}
          {model.purchase?.Payments?.map((payment) =>
            renderModelRows(payment, "pay")
          )}
        </Fragment>
      )}
    </table>
  );
};
export default ModelCharges;
