import React, { useEffect } from "react";
import { Grid, Button, CircularProgress, Alert } from "@mui/material";
import useHttp from "../../../hooks/use-http";
import { useDispatch } from "react-redux";
import { stockActions } from "../../../store/stock-slice";
import { useTranslation } from "react-i18next";

const Warehouse = ({
  token,
  stockMovementIsCanceled,
  action,
  warehouseType,
  movementData,
  onSelectWarehouseHandler,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const selectedInputWarehouse =
    movementData.stoUpdWarInID !== ""
      ? movementData.stoUpdWarInID
      : localStorage.getItem("default_input_warehouse");
  const selectedOutputWarehouse =
    movementData.stoUpdWarOutID !== ""
      ? movementData.stoUpdWarOutID
      : localStorage.getItem("default_output_warehouse");
  let refreshGrid = true;

  const {
    isLoading: warehouseAreLoading,
    error: fetchWarehouseError,
    data: warehouse,
    sendRequest: warehouseGetRequest,
  } = useHttp();

  const getWarehouse = async () => {
    await warehouseGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/warehouses",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const selectWarehouseHandler = (warehouseId) => {
    const setWarehouseAction =
      warehouseType === "inputWarehouses" ||
      warehouseType === "inputExchangeWarehouses"
        ? stockActions.setInputWarehouse
        : stockActions.setOutputWarehouse;

    dispatch(setWarehouseAction(warehouseId));
    onSelectWarehouseHandler(warehouseId);
  };

  const getDisableStatus = (warehouseId) => {
    const selectedWarehouseId =
      warehouseType === "inputWarehouses" ||
      warehouseType === "inputExchangeWarehouses"
        ? Number(selectedInputWarehouse)
        : Number(selectedOutputWarehouse);

    return (
      action === "view" ||
      stockMovementIsCanceled ||
      selectedWarehouseId === warehouseId
    );
  };

  useEffect(() => {
    if (refreshGrid === true) {
      !warehouse && getWarehouse();
      // eslint-disable-next-line
      refreshGrid = false;
    }
  }, [warehouse]);

  return (
    <Grid sx={{ paddingBottom: "200px", minWidth: "600px" }} container>
      <Grid item sx={{ marginTop: "30px" }} xs={12} s={12} md={12} lg={12}>
        {warehouseAreLoading && <CircularProgress color="inherit" />}
        {fetchWarehouseError && (
          <Alert severity="error">{t("errors.defaultError")}</Alert>
        )}
        {warehouse &&
          warehouse.Warehouses.map(
            (warehouse) =>
              warehouse.warName && (
                <Button
                  id={`warehouse_${warehouse.id}`}
                  sx={{
                    marginLeft: "5px",
                    marginTop: "10px",
                    width: "180px",
                    height: "80px",
                  }}
                  size="large"
                  color="primary"
                  key={warehouse.id}
                  variant="contained"
                  onClick={() => selectWarehouseHandler(warehouse.id)}
                  disabled={getDisableStatus(warehouse.id)}
                >
                  {warehouse.warName && warehouse.warName}
                </Button>
              )
          )}
      </Grid>
    </Grid>
  );
};

export default Warehouse;
