import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import styles from "./styles.module.css";
import ModelGrid from "./ModelGrid";
import ModelForm from "./ModelForm";
import AddModelButton from "../UI/AddModelButton";

const Bookings = (props) => {
  const { t } = useTranslation("common");
  const token = props.token;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [action, setAction] = useState("");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const actionParam = query.get("action");
  const navigate = useNavigate();
  const modelName = "bookings";
  const translationModel = "bookings";
  const bookingHour = props.bookingHour;
  const bookingResource = props.bookingResource;
  const bookingId = props.bookingId;
  const resourceType = props.resourceType;
  const resourceBookings = props.resourceBookings;
  const onSetPageTitle = props.onSetPageTitle;
  const { clientId } = useParams();
  const { type } = useParams();
  let refreshGrid = true;

  const isReport = () => {
    return location.pathname.includes("/reports") ? true : false;
  };

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: model,
    setData: setModel,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const {
    isLoading: createIsLoading,
    error: fetchCreateError,
    sendRequest: sendCreateRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const {
    isLoading: deleteIsLoading,
    error: fetchDeleteError,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const getModelHandler = async (modelId) => {
    await sendModelGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/" + modelId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    let url = `${process.env.REACT_APP_API_SERVER}/${modelName}`;
    if (location.pathname.startsWith("/bookings/conflicts")) {
      url += `/conflicts?page=${reqPage}&pageSize=${reqSize}&search=${
        searchText ?? ""
      }`;
    } else if (clientId) {
      url += `/client/${clientId}?page=${reqPage}&pageSize=${reqSize}&search=${
        searchText ?? ""
      }`;
    } else if (type) {
      url += `?type=${type}&page=${reqPage}&pageSize=${reqSize}&search=${
        searchText ?? ""
      }`;
    } else {
      url += `?page=${reqPage}&pageSize=${reqSize}&search=${searchText ?? ""}`;
    }

    await sendModelRowsGetRequest({
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const createdHandler = async (modelData) => {
    setAction("");
    await sendCreateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/create",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchCreateError) {
      setCreateSuccess(true);
      switch (location.pathname) {
        case "/booking_sheet/personnel":
        case "/booking_sheet/cabin":
          setTimeout(() => {
            props.onCloseDialog();
            setCreateSuccess(false);
          }, process.env.REACT_APP_ALERTS_TIMEOUT);
          break;
        case "/bookings/create":
          setTimeout(() => {
            navigate("/bookings");
            setCreateSuccess(false);
          }, process.env.REACT_APP_ALERTS_TIMEOUT);
          break;
        default:
          break;
      }
    }
    getModelRowsHandler(0);
    setModel("");
  };

  const updateHandler = async (modelData) => {
    await sendUpdateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
        switch (location.pathname) {
          case "/booking_sheet/personnel":
          case "/booking_sheet/cabin":
            props.onCloseDialog();
            break;
          default:
            break;
        }
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
    setModel("");
    setAction("");
  };

  const deleteHandler = async (modelId) => {
    await sendDeleteRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: { id: modelId },
    });

    if (!fetchDeleteError) {
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    getModelRowsHandler(0);
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const viewHandler = (modelId) => {
    setAction("view");
    getModelHandler(modelId);
  };

  const editHandler = (modelId) => {
    setAction("edit");
    getModelHandler(modelId);
  };

  const addHandler = () => {
    setModel("");
    setAction("add");
  };

  const cancelHandler = () => {
    setModel("");
    setAction("");
    switch (location.pathname) {
      case "/booking_sheet/personnel":
      case "/booking_sheet/cabin":
        props.onCloseDialog();
        break;
      case "/bookings/create":
        navigate("/bookings");
        break;
      default:
        break;
    }
  };

  const formTitle = () => {
    let title = isReport()
      ? t("reports.title") +
        t(translationModel + ".title") +
        (action ? " - " + t("actions." + action) : "")
      : t(translationModel + ".title") +
        (action ? " - " + t("actions." + action) : "");

    if (location.pathname.startsWith("/bookings/conflicts")) {
      title =
        t("bookingsInConflict.title") +
        (action ? " - " + t("actions." + action) : "");
    }

    if (location.pathname.startsWith("/bookings/client")) {
      title =
        t(translationModel + ".title") +
        (action ? " - " + t("actions." + action) : "");
    }

    if (
      location.pathname !== "/booking_sheet/personnel" &&
      location.pathname !== "/booking_sheet/cabin"
    ) {
      return title;
    }
  };

  const titleMarginTop = () => {
    if (
      location.pathname !== "/booking_sheet/personnel" &&
      location.pathname !== "/booking_sheet/cabin"
    ) {
      return "100px";
    } else return "0px";
  };

  const setPageTitleHandler = () => {
    if (
      location.pathname === "/bookings" ||
      location.pathname.includes("/reports") ||
      location.pathname.includes("/client") ||
      location.pathname.includes("/bookings/conflicts")
    ) {
      onSetPageTitle(formTitle());
    }
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    setPageTitleHandler();
    if (refreshGrid) {
      actionParam && setAction(actionParam);
      id && getModelHandler(id);
      getModelRowsHandler(0);
    }
    location.pathname === "/bookings/create" && setAction("add");
    props.action && setAction(props.action);
    if (bookingId && action === "view") {
      viewHandler(bookingId);
    }
    if (bookingId && action === "edit") {
      editHandler(bookingId);
    }
    // eslint-disable-next-line
    refreshGrid = false;
  }, [
    sendModelGetRequest,
    sendModelRowsGetRequest,
    sendDeleteRequest,
    refreshGrid,
    action,
    location,
  ]);

  return (
    <Fragment>
      {(modelIsLoading ||
        modelRowsAreLoading ||
        deleteIsLoading ||
        updateIsLoading ||
        createIsLoading) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            modelIsLoading ||
            modelRowsAreLoading ||
            deleteIsLoading ||
            updateIsLoading ||
            createIsLoading
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {formTitle() && (
        <Typography
          id={
            location.pathname === "/bookings/conflicts"
              ? "bookings_in_conflict_title"
              : modelName + "_title"
          }
          mt={titleMarginTop()}
          variant="h4"
        >
          {formTitle()}
          {location.pathname !== "/bookings/conflicts" && !action && (
            <AddModelButton
              modelName={modelName}
              translationModel={translationModel}
              onAdd={addHandler}
            />
          )}
        </Typography>
      )}
      {(fetchModelError ||
        fetchModelRowsError ||
        fetchCreateError ||
        fetchUpdateError ||
        fetchDeleteError) && (
        <Alert severity="error">{`${t("errors.defaultError")} ${
          fetchCreateError || fetchUpdateError || fetchDeleteError
        }`}</Alert>
      )}
      {!fetchCreateError && createSuccess && (
        <Alert id="alert_create_success" severity="success">
          {t("confirmations.createSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {!fetchDeleteError && deleteSuccess && (
        <Alert id="alert_delete_success" severity="success">
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {(!modelRowsAreLoading || deleteIsLoading) && modelRows && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            isLoading={modelRowsAreLoading}
            isReport={isReport()}
            modelRows={modelRows}
            modelName={modelName}
            smallScreen={smallScreen}
            translationModel={translationModel}
            type={type}
            onAddModel={addHandler}
            onDeleteModel={deleteHandler}
            onEditModel={editHandler}
            onRefreshGrid={getModelRowsHandler}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onViewModel={viewHandler}
            onSearch={searchHandler}
          />
        </Paper>
      )}
      {action &&
        !fetchCreateError &&
        !createSuccess &&
        !fetchUpdateError &&
        !updateSuccess && (
          <ModelForm
            action={action}
            hour={bookingHour}
            resource={bookingResource}
            bookingId={bookingId}
            clientId={clientId}
            model={model}
            modelRows={modelRows}
            resourceBookings={resourceBookings}
            resourceType={resourceType}
            translationModel={translationModel}
            token={token}
            onCancel={cancelHandler}
            onCreate={createdHandler}
            onUpdate={updateHandler}
          />
        )}
    </Fragment>
  );
};
export default Bookings;
