import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useHttp from "../../hooks/use-http";
import { Grid, Typography } from "@mui/material";
import ModelForm from "./ModelForm";
import TotalsCash from "./TotalsCash";
import ChargesByPaymentType from "./ChargesByPaymentType";
import CashOperations from "./CashOperations";

const CashCount = ({ token, onSetPageTitle }) => {
  const { t } = useTranslation("common");
  const translationModel = "cashCount";
  const [amountOpen, setAmountOpen] = useState(null);
  const [amountClose, setAmountClose] = useState(null);

  const {
    data: totalCashValues,
    setData: setTotalCashValues,
    isLoading: totalCashValuesAreLoading,
    sendRequest: fetchTotalCashValues,
  } = useHttp();

  const getTotalCashValues = (cashDrawer, dateTimeStart, dateTimeEnd) => {
    fetchTotalCashValues({
      url: `${process.env.REACT_APP_API_SERVER}/cash_operations/cash_count/${cashDrawer}/${dateTimeStart}/${dateTimeEnd}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: chargesByPaymentType,
    setData: setChargesByPaymentType,
    isLoading: chargesByPaymentTypeAreLoading,
    sendRequest: fetchChargesByPaymentType,
  } = useHttp();

  const getChargesByPaymentType = (cashDrawer, dateTimeStart, dateTimeEnd) => {
    fetchChargesByPaymentType({
      url: `${process.env.REACT_APP_API_SERVER}/charges/${cashDrawer}/${dateTimeStart}/${dateTimeEnd}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: paymentsByPaymentType,
    setData: setPaymentsByPaymentType,
    isLoading: paymentsByPaymentTypeAreLoading,
    sendRequest: fetchPaymentsByPaymentType,
  } = useHttp();

  const getPaymentsByPaymentType = (cashDrawer, dateTimeStart, dateTimeEnd) => {
    fetchPaymentsByPaymentType({
      url: `${process.env.REACT_APP_API_SERVER}/payments/${cashDrawer}/${dateTimeStart}/${dateTimeEnd}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    data: operationsByDate,
    setData: setOperationsByDate,
    isLoading: operationsByDateAreLoading,
    sendRequest: fetchOperationsByDate,
  } = useHttp();

  const getOperationsByDate = (cashDrawer, dateTimeStart, dateTimeEnd) => {
    fetchOperationsByDate({
      url: `${process.env.REACT_APP_API_SERVER}/cash_operations/${cashDrawer}/${dateTimeStart}/${dateTimeEnd}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const submitFormHandler = (cashOperationData) => {
    if (cashOperationData) {
      setAmountOpen(cashOperationData.casOpeOpenAmount);
      setAmountClose(cashOperationData.casOpeCloseAmount);
      getTotalCashValues(
        cashOperationData.casOpeDraID,
        cashOperationData.casOpeOpenDate,
        cashOperationData.casOpeCloseDate
      );
      getChargesByPaymentType(
        cashOperationData.casOpeDraID,
        cashOperationData.casOpeOpenDate,
        cashOperationData.casOpeCloseDate
      );
      getPaymentsByPaymentType(
        cashOperationData.casOpeDraID,
        cashOperationData.casOpeOpenDate,
        cashOperationData.casOpeCloseDate
      );
      getOperationsByDate(
        cashOperationData.casOpeDraID,
        cashOperationData.casOpeOpenDate,
        cashOperationData.casOpeCloseDate
      );
    }
  };

  const changeFormHandler = () => {
    setTotalCashValues(null);
    setChargesByPaymentType(null);
    setPaymentsByPaymentType(null);
    setOperationsByDate(null);
  };

  useEffect(() => {
    onSetPageTitle(t(translationModel + ".title"));
  }, [onSetPageTitle, t, translationModel]);

  return (
    <Fragment>
      <Typography id={translationModel + "_title"} mt="100px" variant="h4">
        {t(translationModel + ".title")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} s={12} md={12} lg={12}>
          <ModelForm
            translationModel={translationModel}
            token={token}
            onSubmitForm={submitFormHandler}
            onChangeForm={changeFormHandler}
          />
        </Grid>
        <Grid item xs={4} s={4} md={4} lg={4}>
          {totalCashValues && !totalCashValuesAreLoading && (
            <TotalsCash
              amountClose={amountClose}
              amountOpen={amountOpen}
              token={token}
              totalCashValues={totalCashValues}
              translationModel={translationModel}
            ></TotalsCash>
          )}
        </Grid>
        <Grid item xs={4} s={4} md={4} lg={4}>
          {chargesByPaymentType && !chargesByPaymentTypeAreLoading && (
            <ChargesByPaymentType
              charges={chargesByPaymentType}
              token={token}
              translationModel={translationModel}
              modelName="charges"
            ></ChargesByPaymentType>
          )}
        </Grid>
        <Grid item xs={4} s={4} md={4} lg={4}>
          {paymentsByPaymentType && !paymentsByPaymentTypeAreLoading && (
            <ChargesByPaymentType
              payments={paymentsByPaymentType}
              token={token}
              translationModel={translationModel}
              modelName="payments"
            ></ChargesByPaymentType>
          )}
        </Grid>
        <Grid item xs={4} s={4} md={4} lg={4} sx={{ paddingBottom: "150px" }}>
          {operationsByDate && !operationsByDateAreLoading && (
            <CashOperations
              cashOperations={operationsByDate}
              token={token}
              translationModel={translationModel}
            ></CashOperations>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default CashCount;
