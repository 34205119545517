import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import i18next from "i18next";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import FileUploader from "../UI/FileUploader";

const categories = ["1", "2", "3"];

const ModelForm = ({
  action,
  model,
  translationModel,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const [inactive, setInactive] = useState(false);
  let loadForm = true;

  const categoryChangeHandler = (event) => {
    resetType();
    categoryChangeHandlerEvent(event);
    getTypes(event.target.value);
  };

  const {
    enteredValue: category,
    setEnteredValue: setCategory,
    valueIsValid: categoryIsValid,
    valueChangeHandler: categoryChangeHandlerEvent,
    inputBlurHandler: categoryBlurHandler,
    hasError: categoryHasError,
    reset: resetCategory,
  } = useInput((value) => value.toString().trim() !== "");

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:resources.form.categories.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`resources.form.categories.${key}`])}
        </MenuItem>
      );
    }
  };

  const {
    data: types,
    isLoading: typesAreLoading,
    sendRequest: fetchTypes,
  } = useHttp();

  const getTypes = (resourceCategory) => {
    if (resourceCategory) {
      fetchTypes({
        url:
          process.env.REACT_APP_API_SERVER +
          "/resource_types?category=" +
          resourceCategory,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      });
    } else {
      fetchTypes({
        url: process.env.REACT_APP_API_SERVER + "/resource_types",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      });
    }
  };

  const {
    enteredValue: type,
    setEnteredValue: setType,
    valueIsValid: typeIsValid,
    valueChangeHandler: typeChangeHandler,
    hasError: typeHasError,
    inputBlurHandler: typeBlurHandler,
    reset: resetType,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: name,
    setEnteredValue: setName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: image,
    setEnteredValue: setImage,
    reset: resetImage,
  } = useInput();

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput();

  const inactiveChangeHandler = () => {
    setInactive(!inactive);
  };

  const resetInactive = () => {
    setInactive("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (categoryIsValid && typeIsValid && nameIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let inactiveValue = null;
    if (inactive === true) {
      inactiveValue = 1;
    }
    if (inactive === false) {
      inactiveValue = null;
    }

    if (action === "add") {
      const modelData = {
        resCategory: category,
        resType: type,
        resName: name,
        resImage: image,
        resDescription: description,
        resInactive: inactiveValue,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.resource.id,
        resCategory: category,
        resType: type,
        resName: name,
        resImage: image,
        resDescription: description,
        resInactive: inactiveValue,
      };
      onUpdate(modelData);
    }
    resetCategory();
    resetType();
    resetName();
    resetImage();
    resetDescription();
    resetInactive();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const uploadSuccessHandler = (fileName) => {
    setImage(fileName);
  };

  useEffect(() => {
    if (loadForm) {
      if (model && action) {
        setCategory(
          model.resource.resCategory ? model.resource.resCategory : ""
        );
        getTypes(model.resource.resCategory);
        setType(model.resource.resType ? model.resource.resType : "");
        setName(model.resource.resName ? model.resource.resName : "");
        setImage(model.resource.resImage ? model.resource.resImage : "");
        setDescription(
          model.resource.resDescription ? model.resource.resDescription : ""
        );
        if (
          model.resource.resInactive === 1 ||
          model.resource.resInactive === true
        ) {
          setInactive(true);
        }
        if (!model.resource.resInactive) {
          setInactive(false);
        }
        setCaptured(
          model.resource.resCaptured ? model.resource.resCaptured : ""
        );
      }
      getTypes();
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl error={categoryHasError ? true : false}>
          <InputLabel id="category_label">
            {t(translationModel + ".form.category")}*
          </InputLabel>
          <Select
            id="category"
            labelId="category_label"
            value={category}
            label={t(translationModel + ".form.category")}
            onChange={categoryChangeHandler}
            onBlur={categoryBlurHandler}
            disabled={action === "view"}
          >
            {categories.map((category, index) =>
              findTranslation(category, index)
            )}
          </Select>
          {categoryHasError && (
            <FormHelperText id="type-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {typesAreLoading && <CircularProgress color="inherit" />}
        {!typesAreLoading && (
          <FormControl error={typeHasError ? true : false}>
            <InputLabel id="type_label">
              {t(translationModel + ".form.type")}*
            </InputLabel>
            <Select
              id="type"
              labelId="type_label"
              value={type}
              label={t(translationModel + ".form.type")}
              onChange={typeChangeHandler}
              onBlur={typeBlurHandler}
              disabled={action === "view" || !category}
            >
              {types &&
                types.resourceTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.resTypName}
                  </MenuItem>
                ))}
            </Select>
            {typeHasError && (
              <FormHelperText id="type-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FileUploader
          action={action}
          currentFile={image}
          fileType="resource"
          inputId="image"
          inputLabel={t(translationModel + ".form.image")}
          token={token}
          onUploadSuccess={uploadSuccessHandler}
        ></FileUploader>
        <FormControl variant="outlined">
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              id="inactive"
              checked={inactive === 1 || inactive === true ? true : false}
              onChange={inactiveChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
              disabled={action === "view"}
            />
          }
          label={t(translationModel + ".form.inactive")}
        />
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
