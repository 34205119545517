import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Fab,
  Tooltip,
  Box,
} from "@mui/material";
import { Search, Clear, Close, Error } from "@mui/icons-material";
import useInput from "../../../hooks/use-input";
import styles from "./styles.module.css";

const Header = (props) => {
  const { t } = useTranslation("common");
  const action = props.action;
  const translationModel = props.translationModel;
  const movementType = props.movementType;
  const movementData = props.movementData;
  const stockMovementId = props.stockMovementId;
  const selectedInputWarehouse = props.selectedInputWarehouse;
  const selectedOutputWarehouse = props.selectedOutputWarehouse;
  const stockMovementsOnHold = props.stockMovementsOnHold;
  const disabled = props.disabled;

  const setStockMovementOnHold = () => {
    props.onStockMovementOnHold("onHold");
  };

  const changeStockMovementOnHold = (direction) => {
    props.onChangeStockMovementOnHold("changeStockMovementOnHold" + direction);
  };

  const showWarehousesHandler = (movementType) => {
    props.onShowWarehousesDialog(movementType + "Warehouses");
  };

  const showStockMovementsOnHold = () => {
    props.onShowStockMovementsOnHold("stockMovementsOnHold");
  };

  const isPreviousButtonDisabled = () => {
    if (!stockMovementsOnHold || !stockMovementsOnHold.StockUpdatesOnHold) {
      return true;
    }

    return (
      stockMovementsOnHold.StockUpdatesOnHold.length <= 1 ||
      stockMovementsOnHold.StockUpdatesOnHold[0].id === stockMovementId
    );
  };

  const isNextButtonDisabled = () => {
    const stockMovementsOnHoldArray =
      stockMovementsOnHold?.StockUpdatesOnHold || [];
    const areMoreStockMovements = stockMovementsOnHoldArray.length <= 1;
    const isCurrentStockMovement =
      stockMovementsOnHoldArray.length > 0 &&
      stockMovementsOnHoldArray[stockMovementsOnHoldArray.length - 1].id ===
        stockMovementId;

    return areMoreStockMovements || isCurrentStockMovement;
  };

  const {
    enteredValue: searchCriteria,
    valueChangeHandler: searchCriteriaChangeHandler,
    inputBlurHandler: searchCriteriaBlurHandler,
    reset: resetSearchCriteria,
  } = useInput();

  const searchChangeHandler = (criteria) => {
    props.onSearchProductHandler(criteria);
  };

  useEffect(() => {
    searchChangeHandler(searchCriteria);
    // eslint-disable-next-line
  }, [searchCriteria]);

  return (
    <Grid container spacing={0} className={`${styles["header"]}`}>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Fab
          id="cancel_form"
          className={`${styles["action-buttons"]} ${styles["back-button"]}`}
          size="small"
          aria-label="back"
          onClick={() => setStockMovementOnHold()}
        >
          <Close fontSize="small" />
        </Fab>
      </Grid>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Stack spacing={1} direction="row">
          <Box
            id="stock_movement_title"
            pt={1}
            sx={{ typography: "h6", fontWeight: "bold" }}
          >
            {t(translationModel + ".form.header.title", {
              movementType: t(
                translationModel + ".movementType." + movementType
              ),
            })}
          </Box>
          <Button
            id="previous_stock_movement_on_hold"
            variant="contained"
            onClick={() => changeStockMovementOnHold("Previous")}
            disabled={isPreviousButtonDisabled()}
          >
            {t(translationModel + ".form.buttons.previous")}
          </Button>
          <Box
            id="stock_movement_number"
            pt={1}
            sx={{ typography: "h6", fontWeight: "bold" }}
          >
            {t(translationModel + ".form.header.orderNumber", {
              movementType: t(
                translationModel + ".movementType." + movementType
              ),
            })}
          </Box>
          <Box pt={1} sx={{ typography: "h6" }}>
            {stockMovementId}
          </Box>
          <Button
            id="next_stock_movement_on_hold"
            variant="contained"
            onClick={() => changeStockMovementOnHold("Next")}
            disabled={isNextButtonDisabled()}
          >
            {t(translationModel + ".form.buttons.next")}
          </Button>
          {(movementType === "input" || movementType === "exchange") && (
            <Button
              id="input_warehouse_dialog_button"
              variant="contained"
              onClick={() =>
                showWarehousesHandler(
                  movementType === "input" ? movementType : "inputExchange"
                )
              }
              disabled={
                action === "view" ||
                disabled ||
                movementData?.StockUpdate?.StockUpdateDetails?.length > 0
              }
            >
              {t(translationModel + ".form.buttons.inputWarehouse")}
              {!selectedInputWarehouse &&
                !movementData?.StockUpdate?.stoUpdWarInID && (
                  <Tooltip
                    title={
                      <Typography>
                        {t(
                          translationModel + ".form.buttons.warehouseId.warning"
                        )}
                      </Typography>
                    }
                    placement="top"
                  >
                    <Error fontSize="small" color="warning" />
                  </Tooltip>
                )}
            </Button>
          )}
          {(movementType === "output" || movementType === "exchange") && (
            <Button
              id="output_warehouse_dialog_button"
              variant="contained"
              onClick={() =>
                showWarehousesHandler(
                  movementType === "output" ? movementType : "outputExchange"
                )
              }
              disabled={
                action === "view" ||
                disabled ||
                movementData?.StockUpdate?.StockUpdateDetails?.length > 0
              }
            >
              {t(translationModel + ".form.buttons.outputWarehouse")}
              {!selectedOutputWarehouse && (
                <Tooltip
                  title={
                    <Typography>
                      {t(
                        translationModel + ".form.buttons.warehouseId.warning"
                      )}
                    </Typography>
                  }
                  placement="top"
                >
                  <Error fontSize="small" color="warning" />
                </Tooltip>
              )}
            </Button>
          )}
          <Button
            id="stock_movement_on_hold_button"
            variant="contained"
            onClick={() => showStockMovementsOnHold()}
            disabled={
              stockMovementsOnHold &&
              stockMovementsOnHold.StockUpdatesOnHold &&
              stockMovementsOnHold.StockUpdatesOnHold.length <= 1
            }
          >
            {t(translationModel + ".form.buttons.searchOrder", {
              movementType: t(
                translationModel + ".movementType." + movementType
              ),
            })}
          </Button>
          <FormControl variant="outlined">
            <InputLabel htmlFor="search">
              {t(translationModel + ".form.buttons.searchProduct")}
            </InputLabel>
            <OutlinedInput
              fullWidth={true}
              sx={{ background: "white", minWidth: "250px" }}
              id="search"
              onChange={searchCriteriaChangeHandler}
              onBlur={searchCriteriaBlurHandler}
              value={searchCriteria}
              endAdornment={
                <InputAdornment position="start">
                  {searchCriteria !== "" && action !== "view" && (
                    <Clear onClick={resetSearchCriteria} />
                  )}
                  <Search />
                </InputAdornment>
              }
              type="text"
              label={t(translationModel + ".form.observations")}
              disabled={action === "view" || disabled}
            />
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Header;
