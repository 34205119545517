import React, { Fragment } from "react";
import Header from "./Header";
import Details from "./Details";

const Report = (props) => {
  const stockMovementData = props.stockMovementData;
  const translationModel = props.translationModel;
  const type = props.type;

  return (
    <Fragment>
      <Header
        stockMovementData={stockMovementData}
        translationModel={translationModel}
        type={type}
      />
      <Details
        stockMovementData={stockMovementData}
        translationModel={translationModel}
      />
    </Fragment>
  );
};
export default Report;
