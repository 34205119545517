import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";
import { useLocation } from "react-router-dom";

const useHttp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { t } = useTranslation("common");

  const sendRequest = useCallback(
    async (requestConfig) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers: requestConfig.headers ? requestConfig.headers : {},
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        });

        if (!response.ok) {
          if (response.status === 422) {
            throw new Error(t("errors.overlappingBooking"));
          }
          if (response.status === 423) {
            throw new Error(
              t("errors.deleteError", { model: t("clients.model") })
            );
          }
          if (response.status === 403) {
            throw new Error(t("errors.maxRowsReached"));
          }
          if (response.status === 409) {
            throw new Error(t("errors.uniqueError"));
          }
          if (response.status === 401) {
            dispatch(authActions.logout());
            dispatch(authActions.setRedirect(location.pathname));
            throw new Error(t("errors.unauthorized"));
          } else {
            throw new Error(t("errors.defaultError"));
          }
        }
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error.message);
        setTimeout(() => {
          setError(null);
        }, process.env.REACT_APP_ALERTS_TIMEOUT);
      }
      setIsLoading(false);
    },
    [t, dispatch, location]
  );
  return {
    isLoading,
    sendRequest,
    error,
    data,
    setData,
  };
};

export default useHttp;
