import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Button, Stack } from "@mui/material";
import styles from "./styles.module.css";

const FooterFunctions = ({
  action,
  cashDrawerLastOperation,
  modelData,
  module,
  translationModel,
  onAddNote,
  onPayModel,
  onPrintModel,
}) => {
  const { t } = useTranslation("common");
  const modelIsCanceled =
    modelData.sale?.salCancel || modelData.purchase?.purCanceled;
  const modelAmountDue =
    modelData.sale?.salAmountDue || modelData.purchase?.purAmountDue;
  const [printButton, setPrintButton] = useState(false);
  const [paymentButton, setPaymentButton] = useState(false);
  const [observationsButton, setObservationsButton] = useState(false);
  const editable = useSelector((state) => state.pos.editable);

  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };

  const printSaleHandler = () => {
    onPrintModel();
  };
  const paySaleHandler = () => {
    onPayModel("charges");
  };

  const addNoteSaleHandler = () => {
    onAddNote();
  };

  //TODO: API Add purchase charges
  const printButtonHandler = () => {
    setPrintButton(false);
    (action === "view" ||
      modelIsCanceled ||
      modelData.sale?.Charges.length === 0 ||
      modelData.purchase?.Payments.length === 0) &&
      setPrintButton(true);
  };

  const paymentButtonHandler = () => {
    setPaymentButton(false);
    if (
      action === "view" ||
      modelIsCanceled ||
      !modelAmountDue ||
      modelAmountDue <= 0 ||
      (cashDrawerLastOperation &&
        cashDrawerLastOperation.CashOperation &&
        cashDrawerLastOperation.CashOperation.casOpeType === "2") ||
      editable === false
    ) {
      setPaymentButton(true);
    }
  };

  const observationsButtonHandler = () => {
    setObservationsButton(false);
    (action === "view" || modelIsCanceled || !editable) &&
      setObservationsButton(true);
  };

  useEffect(() => {
    printButtonHandler();
    paymentButtonHandler();
    observationsButtonHandler();
  });

  return (
    <Grid container pt={4}>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Stack spacing={2} direction="row">
          <Button
            id="print_model_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="printButton"
            variant="contained"
            disabled={printButton}
            onClick={printSaleHandler}
          >
            {t(translationModel + ".buttons.print")}
          </Button>
          <Button
            id="pay_model_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="payButton"
            variant="contained"
            disabled={paymentButton}
            onClick={paySaleHandler}
          >
            {isSalesModule()
              ? t(translationModel + ".buttons.charge")
              : t(translationModel + ".buttons.pay")}
          </Button>
          <Button
            id="observations_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="noteButton"
            variant="contained"
            disabled={observationsButton}
            onClick={addNoteSaleHandler}
          >
            {t(translationModel + ".buttons.note")}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default FooterFunctions;
