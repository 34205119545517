import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/auth-slice";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { esES, enUS } from "@mui/x-data-grid";
import { esES as coreEsEs } from "./translations/es/esES";
import { Alert, Container, Grid } from "@mui/material";
import AppRoutes from "./AppRoutes";
import AccessRightsGuard from "./components/UI/AccessRightsGuard";
import Footer from "./components/UI/Footer";
import Header from "./components/UI/Header";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector((state) => state.ui.lang);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.username);
  const loggedOut = useSelector((state) => state.auth.loggedOut);
  const date = JSON.parse(useSelector((state) => state.booking.date));
  const redirect = useSelector((state) => state.auth.redirect);
  const { t } = useTranslation("common");
  const [hasAccessData, setHasAccessData] = useState(null);
  const [hasAccessDataIsLoading, setHasAccessDataIsLoading] = useState(false);
  const [hasAccessDataError, setHasAccessDataError] = useState(false);

  if (!token && !user && !loggedOut) {
    dispatch(authActions.getToken());
    dispatch(authActions.getUsername());
  }

  const theme = (lang) => {
    if (lang === "es") {
      return createTheme(
        {
          palette: {
            primary: { main: "#035fc0" },
          },
        },
        esES,
        coreEsEs
      );
    } else {
      return createTheme({
        palette: {
          primary: { main: "#2e2e35" },
        },
        enUS,
      });
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/login");
    }

    const validateTokenHandler = async (route) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/access_rights`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ route: route }),
        }
      );
      if (!response.ok) {
        return false;
      } else {
        return true;
      }
    };

    const checkAccess = async (route) => {
      setHasAccessDataIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/access_rights`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ route: route }),
        }
      );

      if (!response.ok) {
        dispatch(authActions.setLastPage(location.pathname));
        response.status === 401 && navigate("/login");
        setHasAccessDataError(true);
        setTimeout(() => {
          setHasAccessDataError(false);
          setHasAccessDataIsLoading(false);
        }, 1000);
      } else {
        try {
          const data = await response.json();
          setHasAccessData(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          setHasAccessDataError(true);
        }
      }
      setHasAccessDataIsLoading(false);
    };

    const filterRoutes = (url) => {
      const regex = /\/\d+$/;
      return url.replace(regex, "/");
    };
    const route = filterRoutes(location.pathname);

    if (
      token &&
      route &&
      !route.toLowerCase().includes("login") &&
      validateTokenHandler(route)
    ) {
      checkAccess(route);
      (route === "/login" || route === "/") && navigate("/clients");
    }

    theme(language);
    // eslint-disable-next-line
  }, [token, user, language, location.pathname, redirect, navigate]);

  return (
    <Fragment>
      <ThemeProvider theme={theme(language)}>
        {!hasAccessDataIsLoading &&
          !hasAccessDataError &&
          hasAccessData?.result?.crudActions && (
            <AccessRightsGuard
              accessRights={hasAccessData.result.crudActions}
            />
          )}
        <Header username={user} token={token} />
        <Container maxWidth="xlg">
          <Grid container spacing={2}>
            <Grid item xs={12} s={12} md={12} lg={12}>
              {hasAccessDataError && (
                <Alert
                  id="unauthorized_alert"
                  sx={{ marginTop: "100px" }}
                  severity="warning"
                >
                  {t("errors.unauthorized")}
                </Alert>
              )}
              {!hasAccessDataIsLoading && !hasAccessDataError && (
                <AppRoutes
                  date={date}
                  token={token}
                  hasAccessData={hasAccessData}
                />
              )}
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
