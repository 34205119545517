import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack } from "@mui/material";
import styles from "./styles.module.css";

const FooterFunctions = (props) => {
  const { t } = useTranslation("common");
  const translationModel = props.translationModel;
  const action = props.action;
  const disabled = props.disabled;
  const isClosed = props.isClosed;
  const [movementOnHoldButton, setMovementOnHoldButton] = useState(false);
  const [cancelMovementButton, setCancelMovementButton] = useState(false);
  const [printButton, setPrintButton] = useState(false);
  const [observationsButton, setObservationsButton] = useState(false);
  const [closeStockMovementButton, setCloseStockMovementButton] =
    useState(false);

  const holdMovementHandler = () => {
    props.onSetSaleOnHoldClick("onHold");
  };

  const cancelMovementHandler = () => {
    props.onCancelClick("cancel");
  };

  const closeStockMovementHandler = () => {
    props.onCloseMovementClick("close");
  };

  const observationsHandler = () => {
    props.onObservationsClick("observations");
  };

  const printReportHandler = () => {
    props.onPrintClick("report");
  };

  const movementOnHoldButtonHandler = () => {
    setMovementOnHoldButton(false);
    (action === "view" || disabled || isClosed === true) &&
      setMovementOnHoldButton(true);
  };

  const cancelMovementButtonHandler = () => {
    setCancelMovementButton(false);
    (action === "view" || disabled || isClosed === true) &&
      setCancelMovementButton(true);
  };

  const printButtonHandler = () => {
    (action === "view" || disabled || isClosed === true) &&
      setPrintButton(true);
  };

  const observationsButtonHandler = () => {
    (action === "view" || disabled || isClosed === true) &&
      setObservationsButton(true);
  };

  const closeStockMovementButtonHandler = () => {
    setCloseStockMovementButton(false);
    (action === "view" || disabled || isClosed === true) &&
      setCloseStockMovementButton(true);
  };

  useEffect(() => {
    movementOnHoldButtonHandler();
    cancelMovementButtonHandler();
    printButtonHandler();
    observationsButtonHandler();
    closeStockMovementButtonHandler();
  });

  return (
    <Grid container pt={4}>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Stack spacing={2} direction="row">
          <Button
            id="set_movement_on_hold_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="holdButton"
            variant="contained"
            disabled={movementOnHoldButton}
            onClick={holdMovementHandler}
          >
            {t(translationModel + ".form.buttons.hold")}
          </Button>
          <Button
            id="cancel_movement_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="cancelButton"
            variant="contained"
            disabled={cancelMovementButton}
            onClick={cancelMovementHandler}
          >
            {t(translationModel + ".form.buttons.cancel")}
          </Button>
          <Button
            id="print_movement_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="printButton"
            variant="contained"
            disabled={printButton}
            onClick={printReportHandler}
          >
            {t(translationModel + ".form.buttons.print")}
          </Button>
        </Stack>
        <Stack pt="10px" spacing={2} direction="row">
          <Button
            id="observations_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="noteButton"
            variant="contained"
            onClick={observationsHandler}
            disabled={observationsButton}
          >
            {t(translationModel + ".form.buttons.note")}
          </Button>
          <Button
            id="close_movement_button"
            className={`${styles["footer-action-buttons"]}`}
            size="large"
            color="primary"
            key="closeStockMovementButton"
            variant="contained"
            disabled={closeStockMovementButton}
            onClick={closeStockMovementHandler}
          >
            {t(translationModel + ".form.buttons.closeMovement")}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default FooterFunctions;
