import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import useHttp from "../../hooks/use-http";
import {
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
  Fab,
} from "@mui/material";
import { Refresh } from "@mui/icons-material/";
import styles from "./styles.module.css";
import ModelForm from "./ModelForm";

const Company = ({ token, onSetPageTitle }) => {
  const { t } = useTranslation("common");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [action, setAction] = useState("view");
  const modelName = "companies";
  const translationModel = "company";
  let refreshGrid = true;

  const {
    isLoading: modelIsLoading,
    error: fetchModelError,
    data: model,
    setData: setModel,
    sendRequest: sendModelGetRequest,
  } = useHttp();

  const {
    isLoading: updateIsLoading,
    error: fetchUpdateError,
    sendRequest: sendUpdateRequest,
  } = useHttp();

  const getModelHandler = async (modelId) => {
    await sendModelGetRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/" + modelId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const editHandler = () => {
    setAction("edit");
  };

  const updateHandler = async (modelData) => {
    await sendUpdateRequest({
      url: process.env.REACT_APP_API_SERVER + "/" + modelName + "/",
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: modelData,
    });

    if (!fetchUpdateError) {
      setUpdateSuccess(true);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    }
    setModel("");
    setAction("view");
    getModelHandler(1);
  };

  const cancelHandler = () => {
    setModel("");
    setAction("view");
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(t(`${translationModel}.title`));
  };

  useEffect(() => {
    setPageTitleHandler();
    getModelHandler(1);
    // eslint-disable-next-line
    refreshGrid = false;
  }, [sendModelGetRequest, refreshGrid]);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={modelIsLoading || updateIsLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography id={modelName + "_title"} mt="100px" variant="h4">
        {t(translationModel + ".title")}{" "}
        {action && "-" + t("actions." + action)}
        {!action && (
          <Fab
            className={`${styles["action-buttons"]} ${styles["refresh-button"]}`}
            size="small"
            aria-label="edit"
            onClick={() => getModelHandler(1)}
          >
            <Refresh fontSize="small" />
          </Fab>
        )}
      </Typography>
      {(fetchModelError || fetchUpdateError) && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!fetchUpdateError && updateSuccess && (
        <Alert id="alert_update_success" severity="success">
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".model"),
          })}
        </Alert>
      )}
      {action && (
        <ModelForm
          action={action}
          model={model}
          translationModel={translationModel}
          token={token}
          onCancel={cancelHandler}
          onEdit={editHandler}
          onUpdate={updateHandler}
        />
      )}
    </Fragment>
  );
};
export default Company;
