import { createSlice } from "@reduxjs/toolkit";

const initialBookingState = {
  date: JSON.stringify(new Date()),
};

const bookingSlice = createSlice({
  name: "booking",
  initialState: initialBookingState,
  reducers: {
    setDate(state, action) {
      state.date = action.payload;
    },
  },
});

export const bookingActions = bookingSlice.actions;

export default bookingSlice.reducer;
