import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import POSGrid from "./POSGrid";

const POS = ({ token, createData, action, onCancel, onSetPageTitle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("common");
  const module = location.pathname.replace(/[0-9/\\]/g, "");
  const { clientId } = useParams();
  const setIdHandler = (data) => {
    if (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    ) {
      dispatch(uiActions.setSaleId(data.sale.id));
    }
    if (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    ) {
      dispatch(uiActions.setPurchaseId(data.purchase.id));
    }
  };

  const cancelHandler = () => {
    onCancel();
  };

  const setPageTitleHandler = () => {
    module === "posnew_sale" && onSetPageTitle(t("pos.title.posNewSale"));
    module === "posnew_saleclient" &&
      onSetPageTitle(t("pos.title.posNewSaleClient"));
  };

  useEffect(() => {
    (module === "posnew_sale" || module === "posnew_saleclient") &&
      setPageTitleHandler();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Container sx={{ marginTop: "90px" }} maxWidth="xlg">
        {createData && setIdHandler(createData)}
        {createData &&
          (createData.sale?.id || createData.purchase?.id) &&
          action === "create" && (
            <POSGrid
              token={token}
              action={action}
              clientId={clientId}
              onCancel={cancelHandler}
              module={module}
            />
          )}
        {!createData && action !== "create" && (
          <POSGrid
            token={token}
            action={action}
            clientId={clientId}
            onCancel={cancelHandler}
            module={module}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default POS;
