import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Alert } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";

const ModelGrid = ({
  modelRows,
  isLoading,
  isReport,
  smallScreen,
  modelName,
  translationModel,
  onPageChange,
  onPageSizeChange,
  onSearch,
  onRefreshGrid,
}) => {
  const { t } = useTranslation("common");
  const prefix = modelName === "sale_details" ? "sal" : "pur";
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);
  const [rowCount, setRowCount] = useState(null);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [search, setSearch] = useState(null);

  const searchHandler = (searchText) => {
    onSearch(searchText);
  };

  const refreshGridHandler = () => {
    onRefreshGrid();
  };

  const pageChangeHandler = (newPage, size, search) => {
    onPageChange(newPage, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    onPageSizeChange(size, search);
  };

  const columns = [
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 20,
      maxWidth: 60,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: `${prefix}DetProductID`,
      headerName: t(translationModel + ".columns.productId"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
    {
      field: `${prefix}DetProduct`,
      headerName: t(translationModel + ".columns.product"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
    {
      field: `${prefix}DetQuantity`,
      headerName: t(translationModel + ".columns.quantity"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
    {
      field: `${prefix}DetPrice`,
      headerName: t(translationModel + ".columns.unitPrice"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row?.[`${prefix}DetPrice`]
          ? "$" + parseFloat(params.row[`${prefix}DetPrice`]).toFixed(2)
          : "",
    },
    {
      field: `${prefix}DetTax`,
      headerName: t(translationModel + ".columns.tax"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row?.[`${prefix}DetTax`]
          ? "$" + parseFloat(params.row[`${prefix}DetTax`]).toFixed(2)
          : "",
    },
    {
      field: `${prefix}DetDiscount`,
      headerName: t(translationModel + ".columns.discount"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row?.[`${prefix}DetDiscount`]
          ? "$" + parseFloat(params.row[`${prefix}DetDiscount`]).toFixed(2)
          : "",
    },
    {
      field: `${prefix}DetTotalPrice`,
      headerName: t(translationModel + ".columns.total"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params.row?.[`${prefix}DetTotalPrice`]
          ? "$" + parseFloat(params.row[`${prefix}DetTotalPrice`]).toFixed(2)
          : "",
    },
    {
      field: `${prefix}DetCreationDate`,
      headerName: t(translationModel + ".columns.date"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        format(
          new Date(params.row[`${prefix}DetCreationDate`]),
          "dd/MM/yyyy HH:mm"
        ),
    },
    {
      field: `${prefix}DetCapturedName`,
      headerName: t(translationModel + ".columns.captured"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
  ];

  if (modelName === "sale_details") {
    columns.splice(1, 0, {
      field: "salDetSaleID",
      headerName: t(translationModel + ".columns.saleID"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
    });
  }

  if (modelName === "purchase_details") {
    columns.splice(1, 0, {
      field: "purDetPurchaseID",
      headerName: t(translationModel + ".columns.purchaseID"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      filterable: false,
      sortable: false,
    });
  }

  isReport && columns.shift();

  const CustomToolbar = () => {
    return (
      <GridToolbar
        isReport={isReport}
        isServerPagination={true}
        search={search}
        modelName={modelName}
        smallScreen={smallScreen}
        translationModel={translationModel}
        onSearch={searchHandler}
        onRefreshGrid={refreshGridHandler}
      />
    );
  };

  useEffect(() => {
    modelRows?.totalItems && setRowCount(modelRows?.totalItems);
    if (modelRows?.page) {
      modelRows?.page > 0
        ? setPage(parseInt(modelRows?.page - 1), 10)
        : setPage(parseInt(0, 10));
    }
    modelRows?.pageSize > 0
      ? setPageSize(parseInt(modelRows?.pageSize, 10))
      : setPageSize(5);
    modelRows?.search && setSearch(modelRows.search);
  }, [modelRows]);

  return (
    <Fragment>
      {!modelRows && !isLoading && (
        <Alert severity="warning">{t("errors.noContent")}</Alert>
      )}
      <DataGrid
        paginationMode={rowCount > 0 ? "server" : "client"}
        rows={modelRows?.SaleDetails || modelRows?.PurchaseDetails}
        columns={columns}
        rowCount={rowCount > 0 ? rowCount : 0}
        page={page && page}
        pageSize={pageSize ? pageSize : 5}
        onPageSizeChange={(newPageSize) =>
          pageSizeChangeHandler(newPageSize, search && search)
        }
        onPageChange={(newPage) =>
          pageChangeHandler(
            newPage,
            pageSize > 0 ? pageSize : 5,
            search && search
          )
        }
        rowsPerPageOptions={
          rowsPerPage.length > 0 ? rowsPerPage.map(Number) : [5]
        }
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          sorting: { sortModel: [{ field: "id", sort: "desc" }] },
          columns: {},
        }}
      />
    </Fragment>
  );
};

export default ModelGrid;
