import React, { useEffect, Fragment } from "react";
import useInput from "../../hooks/use-input";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FilledInput,
  InputAdornment,
  FormHelperText,
  Stack,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

const SearchField = ({ search, onSearch }) => {
  const { t } = useTranslation("common");
  const {
    enteredValue: searchText,
    setEnteredValue: setSearchText,
    valueChangeHandler: searchTextChangeHandler,
    hasError: searchTextHasError,
    inputBlurHandler: searchTextBlurHandler,
    reset: resetSearchText,
  } = useInput();

  const submitFormHandler = (event) => {
    if (event.key === "Enter") {
      onSearch(searchText);
    }
  };

  useEffect(() => {
    search && search !== undefined && setSearchText(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <Fragment>
      <Stack justifyContent="center">
        <FormControl
          error={searchTextHasError ? true : false}
          variant="outlined"
        >
          <FilledInput
            sx={{
              height: "2em",
              padding: "4px 0 15px 0",
              margin: "0",
              background: "white",
              width: "200px",
            }}
            id="searchTextField"
            onChange={searchTextChangeHandler}
            onKeyDown={(event) => submitFormHandler(event)}
            onBlur={searchTextBlurHandler}
            value={searchText}
            placeholder={t("actions.search")}
            startAdornment={
              <InputAdornment position="start" sx={{ marginBottom: "-15px" }}>
                <Search onClick={submitFormHandler} />
              </InputAdornment>
            }
            endAdornment={
              searchText !== "" && (
                <InputAdornment position="start" sx={{ marginBottom: "-15px" }}>
                  <Clear onClick={resetSearchText} />
                </InputAdornment>
              )
            }
            type="text"
            label={t("actions.search")}
          />
          {searchTextHasError && (
            <FormHelperText id="searchText-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </Fragment>
  );
};

export default SearchField;
