import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
  TextField,
} from "@mui/material";
import i18next from "i18next";
import { Clear } from "@mui/icons-material";
import { format } from "date-fns";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import FileUploader from "../UI/FileUploader";

const ModelForm = ({
  action,
  model,
  translationModel,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const types = ["product", "service"];
  let loadForm = true;

  const typeChangeHandler = (event) => {
    typeChangeHandlerEvent(event);
    getCategories(event.target.value);
    getProviders(event.target.value);
  };

  const {
    enteredValue: type,
    setEnteredValue: setType,
    valueIsValid: typeIsValid,
    hasError: typeHasError,
    valueChangeHandler: typeChangeHandlerEvent,
    inputBlurHandler: typeBlurHandler,
    reset: resetType,
  } = useInput((value) => value.toString().trim() !== "");

  const findTranslation = (key, index) => {
    if (i18next.exists(`common:products.form.types.${key}`)) {
      return (
        <MenuItem key={index} value={key}>
          {t([`products.form.types.${key}`])}
        </MenuItem>
      );
    }
  };

  const {
    enteredValue: category,
    setEnteredValue: setCategory,
    valueChangeHandler: categoryChangeHandler,
    hasError: categoryHasError,
    inputBlurHandler: categoryBlurHandler,
    reset: resetCategory,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: categories,
    isLoading: categoriesAreLoading,
    sendRequest: fetchCategories,
  } = useHttp();

  const getCategories = (productType) => {
    fetchCategories({
      url:
        process.env.REACT_APP_API_SERVER +
        "/product_categories?type=" +
        productType,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: name,
    setEnteredValue: setName,
    valueIsValid: nameIsValid,
    valueChangeHandler: nameChangeHandler,
    hasError: nameHasError,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: price,
    setEnteredValue: setPrice,
    valueIsValid: priceIsValid,
    valueChangeHandler: priceChangeHandler,
    hasError: priceHasError,
    inputBlurHandler: priceBlurHandler,
    reset: resetPrice,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: tax,
    setEnteredValue: setTax,
    valueIsValid: taxIsValid,
    hasError: taxHasError,
    valueChangeHandler: taxChangeHandler,
    inputBlurHandler: taxBlurHandler,
    reset: resetTax,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: taxes,
    isLoading: taxesAreLoading,
    sendRequest: fetchTaxes,
  } = useHttp();

  const getTaxes = () => {
    fetchTaxes({
      url: process.env.REACT_APP_API_SERVER + "/taxes",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: unit,
    setEnteredValue: setUnit,
    valueChangeHandler: unitChangeHandler,
    inputBlurHandler: unitBlurHandler,
    reset: resetUnit,
  } = useInput();

  const {
    enteredValue: barcode,
    setEnteredValue: setBarcode,
    valueChangeHandler: barcodeChangeHandler,
    inputBlurHandler: barcodeBlurHandler,
    reset: resetBarcode,
  } = useInput();

  const [fractions, setFractions] = useState(false);

  const fractionsChangeHandler = () => {
    setFractions(!fractions);
  };

  const resetFractions = () => {
    setFractions("");
  };

  const {
    enteredValue: description,
    setEnteredValue: setDescription,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput();

  const {
    enteredValue: image,
    setEnteredValue: setImage,
    reset: resetImage,
  } = useInput();

  const {
    enteredValue: provider,
    setEnteredValue: setProvider,
    valueChangeHandler: providerChangeHandler,
    inputBlurHandler: providerBlurHandler,
    reset: resetProvider,
  } = useInput();

  const {
    data: providers,
    isLoading: providersAreLoading,
    sendRequest: fetchProviders,
  } = useHttp();

  const getProviders = (type) => {
    let providerType = "";
    if (type === "product" || type === "service") {
      providerType = "?type=1";
    }
    fetchProviders({
      url: process.env.REACT_APP_API_SERVER + "/providers" + providerType,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: discount,
    setEnteredValue: setDiscount,
    valueChangeHandler: discountChangeHandler,
    inputBlurHandler: discountBlurHandler,
    reset: resetDiscount,
  } = useInput();

  const {
    enteredValue: discountExpiration,
    setEnteredValue: setDiscountExpiration,
    valueChangeHandler: discountExpirationChangeHandler,
    reset: resetDiscountExpiration,
  } = useInput();

  const {
    data: collaborators,
    isLoading: collaboratorsAreLoading,
    sendRequest: fetchCollaborators,
  } = useHttp();

  const getCollaborators = () => {
    fetchCollaborators({
      url: process.env.REACT_APP_API_SERVER + "/resources?category=1",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };
  const {
    enteredValue: defaultCollaborator,
    setEnteredValue: setDefaultCollaborator,
    valueChangeHandler: defaultCollaboratorChangeHandler,
    hasError: defaultCollaboratorHasError,
    inputBlurHandler: defaultCollaboratorBlurHandler,
    reset: resetDefaultCollaborator,
  } = useInput((value) => value.toString().trim() !== "");

  const [allowBooking, setAllowBooking] = useState(false);

  const allowBookingChangeHandler = () => {
    setAllowBooking(!allowBooking);
  };

  const resetAllowBooking = () => {
    setAllowBooking("");
  };

  const {
    enteredValue: duration,
    setEnteredValue: setDuration,
    valueChangeHandler: durationChangeHandler,
    inputBlurHandler: durationBlurHandler,
    reset: resetDuration,
  } = useInput();

  const [inactive, setInactive] = useState(false);

  const inactiveChangeHandler = () => {
    setInactive(!inactive);
  };

  const resetInactive = () => {
    setInactive("");
  };

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (typeIsValid && nameIsValid && priceIsValid && taxIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let fractionsValue = null;
    if (fractions === true) {
      fractionsValue = 1;
    }
    if (fractions === false) {
      fractionsValue = null;
    }

    if (action === "add") {
      const modelData = {
        proType: type ? type : null,
        proCategory: category ? category : null,
        proCommercialName: name ? name : null,
        proSalePrice: price ? price : null,
        proTaxID: tax ? tax : null,
        proUnit: unit ? unit : null,
        proCode: barcode ? barcode : null,
        proFractions: fractionsValue ? fractionsValue : null,
        proCommercialDescription: description ? description : null,
        proImage1: image ? image : null,
        proProviderID: provider ? provider : null,
        proPromoDiscount: discount ? discount : null,
        proPromoExpirationDate: discountExpiration ? discountExpiration : null,
        proDefaultExecutive: defaultCollaborator ? defaultCollaborator : null,
        proReservation: allowBooking ? allowBooking : null,
        proDurationMin: duration ? duration : null,
        proInactive: inactive ? inactive : null,
      };
      onCreate(modelData);
    }

    if (action === "edit") {
      const modelData = {
        id: model.product.id,
        proType: type ? type : null,
        proCategory: category ? category : null,
        proCommercialName: name ? name : null,
        proSalePrice: price ? price : null,
        proTaxID: tax ? tax : null,
        proUnit: unit ? unit : null,
        proCode: barcode ? barcode : null,
        proFractions: fractionsValue ? fractionsValue : null,
        proCommercialDescription: description ? description : null,
        proImage1: image ? image : null,
        proProviderID: provider ? provider : null,
        proPromoDiscount: discount ? discount : null,
        proPromoExpirationDate: discountExpiration ? discountExpiration : null,
        proDefaultExecutive: defaultCollaborator ? defaultCollaborator : null,
        proReservation: allowBooking ? allowBooking : null,
        proDurationMin: duration ? duration : null,
        proInactive: inactive ? inactive : null,
      };
      onUpdate(modelData);
    }
    resetType();
    resetCategory();
    resetName();
    resetPrice();
    resetTax();
    resetUnit();
    resetBarcode();
    resetFractions();
    resetDescription();
    resetImage();
    resetProvider();
    resetDiscount();
    resetDiscountExpiration();
    resetDefaultCollaborator();
    resetAllowBooking();
    resetDuration();
    resetInactive();
    resetCaptured();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const uploadSuccessHandler = (fileName) => {
    setImage(fileName);
  };

  useEffect(() => {
    if (loadForm) {
      getTaxes();
      getCollaborators();

      if (model && action) {
        setType(model.product.proType ? model.product.proType : "");
        getCategories(model.product.proType);
        getProviders(model.product.proType);
        setCategory(model.product.proCategory ? model.product.proCategory : "");
        setName(
          model.product.proCommercialName ? model.product.proCommercialName : ""
        );
        setPrice(model.product.proSalePrice ? model.product.proSalePrice : "");
        setTax(model.product.proTaxID ? model.product.proTaxID : "");
        setUnit(model.product.proUnit ? model.product.proUnit : "");
        setBarcode(model.product.proCode ? model.product.proCode : "");
        if (
          model.product.proFractions === 1 ||
          model.product.proFractions === true
        ) {
          setFractions(true);
        }
        if (!model.product.proFractions) {
          setFractions(false);
        }
        setDescription(
          model.product.proCommercialDescription
            ? model.product.proCommercialDescription
            : ""
        );
        setImage(model.product.proImage1 ? model.product.proImage1 : "");
        setProvider(
          model.product.proProviderID ? model.product.proProviderID : ""
        );
        setDiscount(
          model.product.proPromoDiscount
            ? model.product.proPromoDiscount * 100
            : ""
        );
        let discountExpirationDateTime = "";
        if (model.product.proPromoExpirationDate) {
          discountExpirationDateTime = format(
            new Date(model.product.proPromoExpirationDate),
            "yyyy-MM-dd HH:mm"
          );
        }
        setDiscountExpiration(
          discountExpirationDateTime ? discountExpirationDateTime : ""
        );
        setDefaultCollaborator(
          model.product.proDefaultExecutive
            ? model.product.proDefaultExecutive
            : ""
        );
        if (
          model.product.proReservation === 1 ||
          model.product.proReservation === true
        ) {
          setAllowBooking(true);
        }
        if (!model.product.proReservation) {
          setAllowBooking(false);
        }
        setDuration(
          model.product.proDurationMin ? model.product.proDurationMin : ""
        );
        if (
          model.product.proInactive === 1 ||
          model.product.proInactive === true
        ) {
          setInactive(true);
        }
        if (!model.product.proInactive) {
          setInactive(false);
        }
        setCaptured(model.product.proCaptured ? model.product.proCaptured : "");
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl error={typeHasError ? true : false}>
          <InputLabel id="type_label">
            {t(translationModel + ".form.type")}*
          </InputLabel>
          <Select
            id="type"
            labelId="type_label"
            value={type}
            label={t(translationModel + ".form.type")}
            onChange={typeChangeHandler}
            onBlur={typeBlurHandler}
            disabled={action === "view"}
          >
            {types.map((type, index) => findTranslation(type, index))}
          </Select>
          {typeHasError && (
            <FormHelperText id="description-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {categoriesAreLoading && <CircularProgress color="inherit" />}
        {!categoriesAreLoading && (
          <FormControl error={categoryHasError ? true : false}>
            <InputLabel id="category_label">
              {t(translationModel + ".form.category")}*
            </InputLabel>
            <Select
              id="category"
              labelId="category_label"
              value={category}
              label={t(translationModel + ".form.category")}
              onChange={categoryChangeHandler}
              onBlur={categoryBlurHandler}
              disabled={action === "view" || !type}
            >
              {categories &&
                categories.ProductCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.proCatName}
                  </MenuItem>
                ))}
            </Select>
            {categoryHasError && (
              <FormHelperText id="category-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={priceHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="price">
            {t(translationModel + ".form.price")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="price"
            onChange={priceChangeHandler}
            onBlur={priceBlurHandler}
            value={price}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={
              price !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetPrice} />
                </InputAdornment>
              )
            }
            type="number"
            label={t(translationModel + ".form.price")}
            disabled={action === "view"}
          />
          {priceHasError && (
            <FormHelperText id="price-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {taxesAreLoading && <CircularProgress color="inherit" />}
        {!taxesAreLoading && (
          <FormControl error={taxHasError ? true : false}>
            <InputLabel id="tax_label">
              {t(translationModel + ".form.tax")}*
            </InputLabel>
            <Select
              id="tax"
              labelId="tax_label"
              value={tax}
              label={t(translationModel + ".form.tax")}
              onChange={taxChangeHandler}
              onBlur={taxBlurHandler}
              disabled={action === "view"}
            >
              {taxes &&
                taxes.Taxes.map((tax) => (
                  <MenuItem key={tax.id} value={tax.id}>
                    {tax.taxName}
                  </MenuItem>
                ))}
            </Select>
            {taxHasError && (
              <FormHelperText id="tax-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel htmlFor="unit">
            {t(translationModel + ".form.unit")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="unit"
            onChange={unitChangeHandler}
            onBlur={unitBlurHandler}
            value={unit}
            endAdornment={
              unit !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetUnit} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.unit")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="barcode">
            {t(translationModel + ".form.barcode")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="barcode"
            onChange={barcodeChangeHandler}
            onBlur={barcodeBlurHandler}
            value={barcode}
            endAdornment={
              barcode !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetBarcode} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.barcode")}
            disabled={action === "view"}
          />
        </FormControl>
        {type === "product" && (
          <FormControlLabel
            control={
              <Switch
                id="fractions"
                checked={fractions === 1 || fractions === true ? true : false}
                onChange={fractionsChangeHandler}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t(translationModel + ".form.fractions")}
          />
        )}

        <FormControl variant="outlined">
          <InputLabel htmlFor="description">
            {t(translationModel + ".form.description")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="description"
            onChange={descriptionChangeHandler}
            onBlur={descriptionBlurHandler}
            value={description}
            endAdornment={
              description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDescription} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FileUploader
          action={action}
          currentFile={image}
          fileType="product"
          inputId="image"
          inputLabel={t(translationModel + ".form.image")}
          token={token}
          onUploadSuccess={uploadSuccessHandler}
        ></FileUploader>
        {providersAreLoading && <CircularProgress color="inherit" />}
        {!providersAreLoading && (
          <FormControl>
            <InputLabel id="provider_label">
              {t(translationModel + ".form.provider")}
            </InputLabel>
            <Select
              id="provider"
              labelId="provider_label"
              value={provider}
              label={t(translationModel + ".form.provider")}
              onChange={providerChangeHandler}
              onBlur={providerBlurHandler}
              disabled={action === "view" || !type}
            >
              {providers &&
                providers.Providers.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.proCoCommercialName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel htmlFor="discount">
            {t(translationModel + ".form.discount")}
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="discount"
            onChange={discountChangeHandler}
            onBlur={discountBlurHandler}
            value={discount}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            endAdornment={
              discount !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetDiscount} />
                </InputAdornment>
              )
            }
            type="number"
            label={t(translationModel + ".form.discount")}
            disabled={action === "view"}
          />
        </FormControl>
        <TextField
          id="discountExpiration"
          label={t(translationModel + ".form.discountExpiration")}
          type="datetime-local"
          value={discountExpiration}
          onChange={discountExpirationChangeHandler}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={action === "view" || !discount}
        />
        {collaboratorsAreLoading && <CircularProgress color="inherit" />}
        {!collaboratorsAreLoading && (
          <FormControl>
            <InputLabel id="executive_label">
              {t(translationModel + ".form.defaultCollaborator")}
            </InputLabel>
            <Select
              id="defaultCollaborator"
              labelId="executive_label"
              value={defaultCollaborator}
              label={t(translationModel + ".form.defaultCollaborator")}
              onChange={defaultCollaboratorChangeHandler}
              onBlur={defaultCollaboratorBlurHandler}
              disabled={action === "view"}
            >
              {collaborators &&
                collaborators.resources.map((defaultCollaborator) => (
                  <MenuItem
                    key={defaultCollaborator.id}
                    value={defaultCollaborator.id}
                  >
                    {defaultCollaborator.resName}
                  </MenuItem>
                ))}
            </Select>
            {defaultCollaboratorHasError && (
              <FormHelperText id="defaultCollaborator-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {type === "service" && (
          <FormControlLabel
            control={
              <Switch
                id="allowBooking"
                checked={
                  allowBooking === 1 || allowBooking === true ? true : false
                }
                onChange={allowBookingChangeHandler}
                inputProps={{ "aria-label": "controlled" }}
                disabled={action === "view"}
              />
            }
            label={t(translationModel + ".form.allowBooking")}
          />
        )}
        {type === "service" && allowBooking && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="duration">
              {t(translationModel + ".form.duration")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="duration"
              onChange={durationChangeHandler}
              onBlur={durationBlurHandler}
              value={duration}
              endAdornment={
                duration !== "" &&
                action !== "view" && (
                  <InputAdornment position="start">
                    <Clear onClick={resetDuration} />
                  </InputAdornment>
                )
              }
              type="number"
              label={t(translationModel + ".form.duration")}
              disabled={action === "view"}
            />
          </FormControl>
        )}
        <FormControlLabel
          control={
            <Switch
              id="inactive"
              checked={inactive === 1 || inactive === true ? true : false}
              onChange={inactiveChangeHandler}
              inputProps={{ "aria-label": "controlled" }}
              disabled={action === "view"}
            />
          }
          label={t(translationModel + ".form.inactive")}
        />
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
