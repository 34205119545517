import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import { useSelector } from "react-redux";
import {
  Stack,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
  Typography,
  FilledInput,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const ModelForm = ({
  model,
  translationModel,
  action,
  addPurchaseNote,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const [cancel, setCancel] = useState(false);
  let loadForm = true;
  const { enteredValue: date, reset: resetDate } = useInput();
  const {
    enteredValue: provider,
    setEnteredValue: setProvider,
    valueIsValid: providerIsValid,
    valueChangeHandler: providerChangeHandler,
    inputBlurHandler: providerBlurHandler,
    reset: resetProvider,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: providers,
    isLoading: providersAreLoading,
    sendRequest: fetchProviders,
  } = useHttp();

  const getProviders = () => {
    fetchProviders({
      url: process.env.REACT_APP_API_SERVER + "/providers",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: warehouse,
    setEnteredValue: setWarehouse,
    valueChangeHandler: warehouseChangeHandler,
    valueIsValid: warehouseIsValid,
    hasError: warehouseHasError,
    inputBlurHandler: warehouseBlurHandler,
    reset: resetWarehouse,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: warehouses,
    isLoading: warehousesAreLoading,
    sendRequest: fetchWarehouses,
  } = useHttp();

  const getWarehouses = () => {
    fetchWarehouses({
      url: process.env.REACT_APP_API_SERVER + "/warehouses",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    inputBlurHandler: observationsBlurHandler,
    reset: resetObservations,
  } = useInput();

  const cancelChangeHandler = () => {
    setCancel(!cancel);
  };

  const resetCancel = () => {
    setCancel("");
  };

  let formIsValid = false;

  if (warehouseIsValid && providerIsValid) {
    formIsValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    let cancelValue = null;
    if (cancel === true) {
      cancelValue = 1;
    }
    if (cancel === false) {
      cancelValue = null;
    }

    if (action === "add") {
      const modelData = {
        purProviderID: provider ? provider : null,
        purCanceled: cancelValue,
        purObservations: observations ? observations : null,
        purDate: date ? date : null,
        purWarInput: warehouse,
        purTotalTaxes: 0,
        purTotalItems: 0,
      };
      onCreate(modelData);
    }
    if (action === "edit") {
      const modelData = {
        id: model.purchase.id,
        purProviderID: provider ? provider : null,
        purCanceled: cancelValue,
        purObservations: observations ? observations : null,
        purDate: date ? date : null,
        purWarInput: warehouse,
      };
      onUpdate(modelData);
    }
    resetCancel();
    resetDate();
    resetObservations();
    resetProvider();
    resetWarehouse();
  };

  const cancelHandler = () => {
    onCancel();
  };

  useEffect(() => {
    if (loadForm) {
      getProviders();
      getWarehouses();
      if (model && action) {
        setProvider(
          model.purchase?.purProviderID ? model.purchase?.purProviderID : ""
        );
        setWarehouse(
          model.purchase?.purWarInput ? model.purchase?.purWarInput : ""
        );
        setObservations(
          model.purchase?.purObservations ? model.purchase?.purObservations : ""
        );
        if (
          model.purchase?.purCanceled === 1 ||
          model.purchase?.purCanceled === true
        ) {
          setCancel(true);
        }
        if (!model.purchase?.purCanceled) {
          setCancel(false);
        }
      }
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        {providersAreLoading && <CircularProgress color="inherit" />}
        {!providersAreLoading && !addPurchaseNote && (
          <FormControl>
            <InputLabel id="type_label">
              {t(translationModel + ".form.provider")}
            </InputLabel>
            <Select
              id="provider"
              labelId="provider_label"
              value={provider}
              label={t(translationModel + ".form.provider")}
              onChange={providerChangeHandler}
              onBlur={providerBlurHandler}
              disabled={action === "view"}
            >
              {providers &&
                providers.Providers.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.proCoCommercialName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {warehousesAreLoading && <CircularProgress color="inherit" />}
        {!warehousesAreLoading && !addPurchaseNote && (
          <FormControl error={warehouseHasError ? true : false}>
            <InputLabel id="type_label">
              {t(translationModel + ".form.warehouse")}*
            </InputLabel>
            <Select
              id="warehouse"
              labelId="warehouse_label"
              value={warehouse}
              label={t(translationModel + ".form.warehouse")}
              onChange={warehouseChangeHandler}
              onBlur={warehouseBlurHandler}
              disabled={
                action === "view" ||
                model?.purchase?.PurchaseDetails?.length > 0
              }
            >
              {warehouses &&
                warehouses.Warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.id} {warehouse.warName}
                  </MenuItem>
                ))}
            </Select>
            {warehouseHasError && (
              <FormHelperText id="firstName-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl variant="outlined">
          <InputLabel htmlFor="observations">
            {t(translationModel + ".form.observations")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white", height: "150px" }}
            id="observations"
            onChange={observationsChangeHandler}
            onBlur={observationsBlurHandler}
            value={observations}
            endAdornment={
              observations !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetObservations} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.observations")}
            disabled={action === "view"}
          />
        </FormControl>
        {action === "edit" && !addPurchaseNote && (
          <FormControlLabel
            control={
              <Switch
                id="cancel"
                checked={cancel === 1 || cancel === true ? true : false}
                onChange={cancelChangeHandler}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t(translationModel + ".form.cancel")}
          />
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
