import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";

const TotalsCash = ({
  translationModel,
  amountOpen,
  amountClose,
  totalCashValues,
}) => {
  const { t } = useTranslation("common");

  const formatMoney = (amount) => {
    return (
      "$" +
      Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
  };

  const getTotalCash = (values) => {
    const total =
      Number(amountOpen) +
      Number(values.totalChargesCash) +
      Number(values.totalDepositsCash) +
      Number(amountClose) -
      (Number(values.totalPaymentsCash) + Number(values.totalWithdrawsCash));
    return formatMoney(total);
  };

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography
          id={t(translationModel + ".columns.totalsCash.title") + "_title"}
          mt="100px"
          pb="20px"
          variant="h5"
        >
          {t(`${translationModel}.columns.totalsCash.title`)}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow key="row1">
                <TableCell align="center">
                  {t(translationModel + ".columns.totalsCash.amountCashOpen")}
                </TableCell>
                <TableCell align="center">{formatMoney(amountOpen)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {t(
                    translationModel +
                      ".columns.totalsCash.amountTotalSalesCash"
                  )}
                </TableCell>
                <TableCell align="center">
                  {formatMoney(totalCashValues.totalChargesCash)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {t(
                    translationModel +
                      ".columns.totalsCash.amountTotalPurchasesCash"
                  )}
                </TableCell>
                <TableCell align="center">
                  {formatMoney(totalCashValues.totalPaymentsCash)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {t(
                    translationModel +
                      ".columns.totalsCash.amountTotalCashInput"
                  )}
                </TableCell>
                <TableCell align="center">
                  {formatMoney(totalCashValues.totalDepositsCash)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {t(
                    translationModel +
                      ".columns.totalsCash.amountTotalCashOutput"
                  )}
                </TableCell>
                <TableCell align="center">
                  {formatMoney(totalCashValues.totalWithdrawsCash)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {t(translationModel + ".columns.totalsCash.amountCashClose")}
                </TableCell>
                <TableCell align="center">{formatMoney(amountClose)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          id={t(translationModel + ".columns.totalsCash.total") + "_title"}
          pt="10px"
          pr="30px"
          variant="h6"
          textAlign="right"
        >
          {t(`${translationModel}.columns.totalsCash.total`) +
            " " +
            getTotalCash(totalCashValues)}
        </Typography>
      </Stack>
    </Fragment>
  );
};
export default TotalsCash;
