import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const Header = (props) => {
  const { t } = useTranslation("common");
  const stockMovementData = props.stockMovementData;
  const translationModel = props.translationModel;
  const type = props.type;

  return (
    <Fragment>
      <table>
        <tbody>
          <tr>
            <td align="right" style={{ fontWeight: "bold" }}>
              {t(translationModel + ".form.header.orderNumber", {
                movementType: t(translationModel + ".movementType." + type),
              })}
            </td>
            <td>{stockMovementData.id && stockMovementData.id}</td>
          </tr>
          <tr>
            <td align="right" style={{ fontWeight: "bold" }}>
              {t(translationModel + ".form.columns.StockMovementDetails.date")}:
            </td>
            <td>
              {stockMovementData.stoUpdDate &&
                format(new Date(stockMovementData.stoUpdDate), "dd/mm/yy")}
            </td>
          </tr>
          <tr>
            <td align="right" style={{ fontWeight: "bold" }}>
              {t(
                translationModel + ".form.columns.StockMovementDetails.captured"
              )}
              :
            </td>
            <td>
              {stockMovementData.stoUpdCaptured &&
                stockMovementData.stoUpdCaptured}
            </td>
          </tr>
          <tr>
            <td align="right" style={{ fontWeight: "bold" }}>
              {t(translationModel + ".columns.inputWarehouse")}:
            </td>
            <td>
              {stockMovementData.stoUpdWarInID &&
                stockMovementData.stoUpdWarInID}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};
export default Header;
