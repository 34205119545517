import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbar from "../../UI/ModelGridToolbar";
import GridActions from "../../UI/GridActions";

const ClinicHistoryModelGrid = ({
  modelRows,
  isLoading,
  isReport,
  smallScreen,
  modelName,
  translationModel,
  recordType,
  onDeleteModel,
  onViewModel,
  onRefreshGrid,
  onEditModel,
  onGetModelRowsHandler,
  onPageChange,
  onPageSizeChange,
  onSearch,
}) => {
  const [alertDelete, setAlertDelete] = useState(false);
  const [Id, setId] = useState(null);
  const [rowCount, setRowCount] = useState(null);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [search, setSearch] = useState(null);
  const { t } = useTranslation("common");
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);

  const refreshGridHandler = () => {
    onRefreshGrid();
  };

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (Id) => {
    setAlertDelete(true);
    setId(Id);
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    onDeleteModel(Id);
  };

  const viewHandler = (Id) => {
    onViewModel(Id);
  };

  const editHandler = (Id) => {
    onEditModel(Id);
  };

  const getModelRowsHandler = () => {
    onGetModelRowsHandler();
  };

  const searchHandler = (searchText) => {
    onSearch(searchText);
  };

  const pageChangeHandler = (newPage, size, search) => {
    onPageChange(newPage, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    onPageSizeChange(size, search);
  };

  const renderDataGrid = (modelRows, rowCount, page, pageSize) => {
    return modelRows?.ClientRecords.length && rowCount && page >= 0 && pageSize;
  };

  const getRecordType = (typeId) => {
    switch (typeId) {
      case 1:
        return "background";
      case 2:
        return "consultations";
      case 3:
        return "clinic_history";
      case 4:
        return "prescriptions";
      case 5:
        return "generic";
      default:
        break;
    }
  };

  const columns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.clinicHistory.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={`${modelName}/${getRecordType(recordType)}`}
        />
      ),
    },
    {
      field: "id",
      headerName: t(translationModel + ".columns.clinicHistory.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 100,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "cliRecClientID",
      headerName: t(translationModel + ".columns.clinicHistory.client"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: "10%",
      type: "string",
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row?.cliRecClientName,
    },
    {
      field: "cliRecHeritageConditions",
      headerName: t(
        translationModel +
          ".columns." +
          getRecordType(recordType) +
          ".inheritanceBackground"
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: "30%",
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "cliRecFamiliarConditions",
      headerName: t(
        translationModel +
          ".columns." +
          getRecordType(recordType) +
          ".familyHistory"
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: "30%",
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "cliRecCreationDate",
      headerName: t(translationModel + ".columns.clinicHistory.date"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: "10%",
      type: "string",
      filterable: false,
      sortable: false,
    },
    {
      field: "cliRecCaptured",
      headerName: t(translationModel + ".columns.clinicHistory.captured"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row?.cliRecCapturedName,
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbar
        modelName={modelName}
        isServerPagination={true}
        search={search}
        translationModel={translationModel}
        smallScreen={smallScreen}
        onGetModelRowsHandler={getModelRowsHandler}
        onRefreshGrid={refreshGridHandler}
        onSearch={searchHandler}
      />
    );
  };

  useEffect(() => {
    modelRows?.totalItems && setRowCount(modelRows?.totalItems);
    if (modelRows?.page) {
      modelRows?.page > 0
        ? setPage(parseInt(modelRows?.page - 1), 10)
        : setPage(parseInt(0, 10));
    }
    modelRows?.pageSize > 0
      ? setPageSize(parseInt(modelRows?.pageSize, 10))
      : setPageSize(5);
    modelRows?.search && setSearch(modelRows.search);
  }, [modelRows]);

  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", {
            model: t(translationModel + ".model"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t(translationModel + ".model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {modelRows.ClientRecords !== undefined &&
        modelRows.ClientRecords.length <= 0 &&
        !isLoading && <Alert severity="warning">{t("errors.noContent")}</Alert>}
      {renderDataGrid(modelRows, rowCount, page, pageSize) && (
        <DataGrid
          paginationMode="server"
          rows={modelRows.ClientRecords}
          columns={columns}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) =>
            pageSizeChangeHandler(newPageSize, search && search)
          }
          onPageChange={(newPage) =>
            pageChangeHandler(
              newPage,
              pageSize > 0 ? pageSize : 5,
              search && search
            )
          }
          rowsPerPageOptions={rowsPerPage.map(Number)}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            sorting: { sortModel: [{ field: "id", sort: "desc" }] },
            columns: {},
          }}
        />
      )}
    </Fragment>
  );
};

export default ClinicHistoryModelGrid;
