import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format, add } from "date-fns";
import {
  Chip,
  Tooltip,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid, gridStringOrNumberComparator } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";
import GridActions from "../UI/GridActions";
import styles from "./Clients.module.css";

const ClientsGrid = ({
  clients,
  isLoading,
  isReport,
  smallScreen,
  modelName,
  onDeleteClient,
  onViewClient,
  onEditClient,
  onAddClient,
  onRefreshGrid,
  onPageChange,
  onPageSizeChange,
  onSearch,
}) => {
  const [alertDelete, setAlertDelete] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [rowCount, setRowCount] = useState(null);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [search, setSearch] = useState(null);
  const { t } = useTranslation("common");
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (clientId) => {
    setAlertDelete(true);
    setClientId(clientId);
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    onDeleteClient(clientId);
  };

  const viewHandler = (clientId) => {
    onViewClient(clientId);
  };

  const editHandler = (clientId) => {
    onEditClient(clientId);
  };

  const refreshGridHandler = () => {
    onRefreshGrid();
  };

  const searchHandler = (searchText) => {
    onSearch(searchText);
  };

  const addClientHandler = () => {
    onAddClient();
  };

  const pageChangeHandler = (newPage, size, search) => {
    onPageChange(newPage, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    onPageSizeChange(size, search);
  };

  const renderDataGrid = (clients, rowCount, page, pageSize) => {
    return clients?.clients.length && rowCount && page >= 0 && pageSize;
  };

  const moduleColumns = [
    {
      field: "id as id2",
      headerName: t("clients.columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 250,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          modelName={modelName}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
        />
      ),
    },
    {
      field: "category",
      headerName: t("clients.columns.category"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      editable: false,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) =>
        params?.row?.category?.clieCatName && (
          <Tooltip title={params.row.category.clieCatName} placement="top">
            <Chip
              className={`${styles["category-chip"]}`}
              sx={{
                background: params.row.category.clieCatColor,
              }}
            />
          </Tooltip>
        ),
      sortComparator: (v1, v2) =>
        v1?.clieCatName &&
        v2?.clieCatName &&
        gridStringOrNumberComparator(v1.clieCatName, v2.clieCatName),
      type: "string",
      valueGetter: (value) =>
        value?.row?.category?.clieCatName && value.row.category.clieCatName,
    },
    {
      field: "id",
      headerName: t("clients.columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "contact",
      headerName: t("clients.columns.contact"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${
          params.row.cliTitle
            ? t("clients.form.titles." + params.row.cliTitle)
            : ""
        } ${params.row.cliFirstName || ""} ${params.row.cliLastName || ""} ${
          params.row.cliMothersLastName || ""
        }`,
      renderEditCell: (val) => {
        return `Editing: ${val.value}`;
      },
    },
    {
      field: "cliMediaID",
      headerName: t("clients.columns.media"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        params?.row?.mediaType?.medName
          ? `${params?.row?.mediaType?.medName} `
          : "",
    },
    {
      field: "cliMainPhone",
      headerName: t("clients.columns.mainPhone"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      editable: false,
      filterable: false,
      sortable: false,
    },
    {
      field: "cliMainEmail",
      headerName: t("clients.columns.mainEmail"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      editable: false,
      filterable: false,
      sortable: false,
    },
    {
      field: "cliBirthdate",
      headerName: t("clients.columns.birthday"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        format(add(new Date(params.row.cliBirthdate), { days: 1 }), "dd/MM"),
    },
    {
      field: "executive",
      headerName: t("clients.columns.executive"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.executive.exeInitials,
    },
    {
      field: "cliCreationDate",
      headerName: t("clients.columns.captureDate"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        format(new Date(params.row.cliCreationDate), "dd/MM/yyyy"),
    },
  ];

  const reportsColumns = [
    {
      field: "id",
      headerName: t("clients.columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "number",
    },
    {
      field: "contact",
      headerName: t("clients.columns.contact"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        `${
          params.row.cliTitle
            ? t("clients.form.titles." + params.row.cliTitle)
            : ""
        } ${params.row.cliFirstName || ""} ${params.row.cliLastName || ""} ${
          params.row.cliMothersLastName || ""
        }`,
      renderEditCell: (val) => {
        return `Editing: ${val.value}`;
      },
    },
    {
      field: "clieCatName",
      headerName: t("clients.columns.category"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params?.row?.category?.clieCatName ?? "",
    },
    {
      field: "cliPhones",
      headerName: t("clients.columns.phones"),
      align: "center",
      headerAlign: "center",
      flex: 2,
    },
    {
      field: "cliMails",
      headerName: t("clients.columns.emails"),
      align: "center",
      headerAlign: "center",
      flex: 2,
    },
    {
      field: "cliBirthdate",
      headerName: t("clients.columns.birthday"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        format(new Date(params.row.cliBirthdate), "dd/MM"),
    },
    {
      field: "executive",
      headerName: t("clients.columns.executive"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.executive.exeInitials,
    },
    {
      field: "cliCreationDate",
      headerName: t("clients.columns.captureDate"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (params) =>
        format(new Date(params.row.cliCreationDate), "dd/MM/yyyy"),
    },
  ];

  const columns = isReport ? reportsColumns : moduleColumns;

  const CustomToolbar = () => {
    return (
      <GridToolbar
        isReport={isReport}
        isServerPagination={true}
        smallScreen={smallScreen}
        search={search}
        modelName={"clients"}
        onAddClientHandler={addClientHandler}
        onRefreshGrid={refreshGridHandler}
        onSearch={searchHandler}
      />
    );
  };

  useEffect(() => {
    clients?.totalItems && setRowCount(clients?.totalItems);
    if (clients?.page) {
      clients?.page > 0
        ? setPage(parseInt(clients?.page - 1), 10)
        : setPage(parseInt(0, 10));
    }
    clients?.pageSize > 0
      ? setPageSize(parseInt(clients?.pageSize, 10))
      : setPageSize(5);
    clients?.search && setSearch(clients.search);
  }, [clients]);
  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", { model: t("clients.model") })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t("clients.model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {clients.clients !== undefined &&
        clients.clients.length <= 0 &&
        !isLoading && <Alert severity="warning">{t("errors.noContent")}</Alert>}
      {renderDataGrid(clients, rowCount, page, pageSize) && (
        <DataGrid
          paginationMode="server"
          rows={clients?.clients}
          columns={columns}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) =>
            pageSizeChangeHandler(newPageSize, search && search)
          }
          onPageChange={(newPage) =>
            pageChangeHandler(
              newPage,
              pageSize > 0 ? pageSize : 5,
              search && search
            )
          }
          rowsPerPageOptions={rowsPerPage.map(Number)}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            sorting: { sortModel: [{ field: "id", sort: "desc" }] },
            columns: {},
          }}
        />
      )}
    </Fragment>
  );
};

export default ClientsGrid;
