import React, { Fragment, useEffect, useState } from "react";
import useInput from "../../hooks/use-input";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  FormControl,
  InputLabel,
  Button,
  InputAdornment,
  FilledInput,
  FormHelperText,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import styles from "./UI.module.css";
import { useTranslation } from "react-i18next";

const ModelForm = ({ onCloseDialog }) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.auth.token);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [error, setError] = useState(null);
  const translationModel = "errorReporting";
  let loadForm = true;

  const {
    enteredValue: module,
    setEnteredValue: setModule,
    valueIsValid: moduleIsValid,
    valueChangeHandler: moduleChangeHandler,
    hasError: moduleHasError,
    inputBlurHandler: moduleBlurHandler,
    reset: resetModule,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: description,
    valueIsValid: descriptionIsValid,
    valueChangeHandler: descriptionChangeHandler,
    hasError: descriptionHasError,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    enteredValue: captured,
    setEnteredValue: setCaptured,
    reset: resetCaptured,
  } = useInput();

  let formIsValid = false;

  if (moduleIsValid && descriptionIsValid) {
    formIsValid = true;
  }

  const fetchError = async (modelData) => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER}/mail_notification/api_error_notification?route=${process.env.REACT_APP_TITLE}${modelData.module}&controller=errorReporting&error=${modelData.description}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      }
    );
    const data = await response.json();
    setIsLoading(false);
    return data;
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    const modelData = {
      module,
      description,
      captured,
    };
    const sendErrorNotification = await fetchError(modelData);

    if (!sendErrorNotification.msg) {
      setError("Error");
      setTimeout(() => {
        setError(false);
      }, process.env.REACT_APP_ALERTS_TIMEOUT);
    } else {
      setCreateSuccess(true);
      setTimeout(() => {
        setCreateSuccess(false);
        resetModule();
        resetDescription();
        resetCaptured();
        onCloseDialog();
      }, 2000);
    }
  };

  useEffect(() => {
    if (loadForm) {
      setModule(location.pathname);
      setCaptured("admin");
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm]);

  return (
    <Fragment>
      {!error && createSuccess && (
        <Alert
          id="alert_create_success"
          severity="success"
          sx={{ marginTop: "20px" }}
        >
          {t("confirmations.errorSentSuccessfully")}
        </Alert>
      )}
      {error && (
        <Alert id="alert_error" severity="warning" sx={{ marginTop: "20px" }}>
          {t("errors.noContent")}
        </Alert>
      )}
      {isLoading && !createSuccess && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={"20px"}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {!isLoading && !createSuccess && (
        <Stack
          className={`${styles["form-stack"]}`}
          spacing={1}
          justifyContent="center"
        >
          <FormControl error={moduleHasError ? true : false} variant="outlined">
            <InputLabel htmlFor="module">
              {t(translationModel + ".form.module")}*
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="module"
              onChange={moduleChangeHandler}
              onBlur={moduleBlurHandler}
              value={module}
              type="text"
              readOnly={true}
              label={t(translationModel + ".form.module")}
            />
            {moduleHasError && (
              <FormHelperText id="module-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            error={descriptionHasError ? true : false}
            variant="outlined"
          >
            <InputLabel htmlFor="description">
              {t(translationModel + ".form.description")}
            </InputLabel>
            <FilledInput
              multiline
              maxRows={4}
              sx={{ background: "white" }}
              id="description"
              onChange={descriptionChangeHandler}
              onBlur={descriptionBlurHandler}
              value={description}
              endAdornment={
                description !== "" && (
                  <InputAdornment position="start">
                    <Clear onClick={resetDescription} />
                  </InputAdornment>
                )
              }
              type="text"
              label={t(translationModel + ".form.description")}
            />
            {descriptionHasError && (
              <FormHelperText id="description-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {t("buttons.send", { model: t(translationModel + ".model") })}
          </Button>
        </Stack>
      )}
    </Fragment>
  );
};

export default ModelForm;
