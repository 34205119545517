import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CashDrawers from "./CashDrawers";
import CashOperations from "../CashOperations/CashOperations";
import Charges from "../Charges/Charges";
import EditSale from "../Sales/ModelForm";
import EditPurchase from "../Purchases/ModelForm";
import OnHoldModelRows from "./OnHoldModelRows";
import ProductInfo from "../Products/Products";
import ProductDetails from "./ProductDetails";
import Ticket from "./Ticket/Ticket";

const PosDialog = ({
  action,
  addNote,
  cashDrawerLastOperation,
  cashRegisterMovementType,
  description,
  onHoldModelRows,
  modelData,
  module,
  productId,
  selectedCashDrawerId,
  title,
  token,
  type,
  onClose,
  onSelect,
  onCreateChargeSuccessHandler,
  onCreateCashOperationSuccess,
  onConfirmChangeModelOnHold,
  onConfirmEditModel,
}) => {
  const { t } = useTranslation("common");
  const [itemSuccessfullyAdded, setItemSuccessfullyAdded] = useState(false);
  const closeDialogHandler = () => {
    onClose(type);
  };

  const selectItemHandler = (id, quantity, price) => {
    onSelect(type, id, quantity, price);
  };

  const confirmHandler = (modelData) => {
    if (
      type === "cancelModelConfirmation" ||
      type === "changeModelConfirmation" ||
      type === "exitModelConfirmation" ||
      type === "setModelOnHoldConfirmation"
    ) {
      onConfirmChangeModelOnHold();
    } else {
      modelData && onConfirmEditModel(modelData);
      !modelData && onConfirmEditModel();
    }
  };

  const createSuccessHandler = () => {
    if (type === "charges") {
      setItemSuccessfullyAdded(true);
      onCreateChargeSuccessHandler();
    }
    if (type === "cashMovement") {
      onCreateCashOperationSuccess();
    }
  };

  const isSalesModule = () => {
    return (
      module === "sales" ||
      module === "salesclient" ||
      module === "posnew_sale" ||
      module === "posnew_saleclient" ||
      module === "salespending_charges"
    );
  };

  const isPurchasesModule = () => {
    return (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    );
  };

  const getModelName = () => {
    if (isSalesModule()) {
      return "Charges";
    }
    if (isPurchasesModule()) {
      return "Payments";
    }
  };

  const printTicket = () => {
    let divContents = document.getElementById("printableArea").innerHTML;
    let a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    a.document.write("<body >");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  const showAcceptCancelButton = () => {
    if (type && type === "charges") {
      return true;
    }
    return false;
  };

  const showCancelButton = () => {
    if (
      type &&
      (type === "alertDeleteModelDetail" ||
        type === "cancelModelConfirmation" ||
        type === "cashDrawers" ||
        type === "changeModelConfirmation" ||
        type === "onHoldModelRows" ||
        type === "productInfo" ||
        type === "setModelOnHoldConfirmation" ||
        type === "ticket" ||
        type === "exitModelConfirmation")
    ) {
      return true;
    }
    return false;
  };

  const showConfirmButton = () => {
    if (
      type &&
      (type === "alertDeleteModelDetail" ||
        type === "cancelModelConfirmation" ||
        type === "changeModelConfirmation" ||
        type === "exitModelConfirmation" ||
        type === "setModelOnHoldConfirmation")
    ) {
      return true;
    }
    return false;
  };

  const showDescription = () => {
    if (
      type &&
      (type === "alertDeleteModelDetail" ||
        type === "cancelModelConfirmation" ||
        type === "changeModelConfirmation" ||
        type === "exitModelConfirmation" ||
        type === "setModelOnHoldConfirmation")
    ) {
      return true;
    }
    return false;
  };

  const showProductDetails = () => {
    if (type && type === "productDetails") {
      return true;
    }
    return false;
  };

  const showTicketButton = () => {
    if (type && type === "ticket") {
      return true;
    }
    return false;
  };

  const showTicketComponent = showTicketButton;

  return (
    <Dialog
      id="edit_sale"
      open={type ? true : false}
      onClose={closeDialogHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-title"
      fullWidth={true}
      maxWidth={type === "ticket" ? "xs" : "sm"}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {showDescription() && (
          <Typography variant="body1">{description}</Typography>
        )}
        {type === "editModel" && isSalesModule() && (
          <EditSale
            translationModel={"sales"}
            model={modelData && modelData}
            action={"edit"}
            onCancel={closeDialogHandler}
            onUpdate={confirmHandler}
            addNote={addNote}
          />
        )}
        {type === "editModel" && isPurchasesModule() && (
          <EditPurchase
            translationModel={"purchases"}
            model={modelData && modelData}
            action={"edit"}
            onCancel={closeDialogHandler}
            onUpdate={confirmHandler}
            addNote={addNote}
          />
        )}
        {type === "cashDrawers" && (
          <CashDrawers
            token={token}
            type={type}
            modelData={modelData}
            selectedCashDrawerId={selectedCashDrawerId}
            onSelect={selectItemHandler}
          />
        )}
        {type === "cashMovement" && (
          <CashOperations
            token={token}
            action={"add"}
            cashDrawerLastOperation={cashDrawerLastOperation}
            cashRegisterMovementType={cashRegisterMovementType}
            selectedCashDrawerId={selectedCashDrawerId}
            onCreateCashOperationSuccess={createSuccessHandler}
            onCancel={closeDialogHandler}
          />
        )}
        {type === "charges" && (
          <Charges
            token={token}
            action={action}
            selectedCashDrawerId={selectedCashDrawerId}
            modelData={modelData}
            modelName={getModelName()}
            redirect="pos"
            onCreateSuccess={createSuccessHandler}
            onClose={closeDialogHandler}
          />
        )}

        {type === "onHoldModelRows" && (
          <OnHoldModelRows
            type={type}
            action={action}
            module={module}
            onHoldModelRows={onHoldModelRows}
            onSelect={selectItemHandler}
          />
        )}
        {type === "productInfo" && (
          <ProductInfo
            token={token}
            action="view"
            productId={productId}
            onClose={closeDialogHandler}
          />
        )}
        {showProductDetails() && (
          <ProductDetails
            isSalesModule={isSalesModule()}
            productId={productId}
            token={token}
            resourceCategory={"1"}
            onSelect={selectItemHandler}
            onCancel={closeDialogHandler}
          />
        )}
        {showTicketComponent() && (
          <div id="printableArea">
            <Ticket
              token={token}
              modelData={modelData}
              module={module}
              translationModel="pos"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {showAcceptCancelButton() && (
          <Button id="alert_accept_cancel_button" onClick={closeDialogHandler}>
            {itemSuccessfullyAdded ? t("buttons.accept") : t("buttons.cancel")}
          </Button>
        )}
        {showCancelButton() && (
          <Button id="alert_cancel_button" onClick={closeDialogHandler}>
            {t("buttons.cancel")}
          </Button>
        )}
        {showConfirmButton() && (
          <Button id="alert_confirm_button" onClick={confirmHandler} autoFocus>
            {t("buttons.accept")}
          </Button>
        )}
        {showTicketButton() && (
          <Button id="alert_print_button" onClick={printTicket}>
            {t("pos.buttons.print")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default PosDialog;
