import { createSlice } from "@reduxjs/toolkit";

const initialStockState = {
  stockMovementId: "",
  inputWarehouse: "",
  outputWarehouse: "",
  disableWarehouseButton: false,
  inputWarehouseId: null,
  outputWarehouseId: null,
};

const stockSlice = createSlice({
  name: "stock",
  initialState: initialStockState,
  reducers: {
    setStockMovementId(state, action) {
      state.stockMovementId = action.payload;
    },
    setInputWarehouse(state, action) {
      localStorage.setItem(
        "default_input_warehouse",
        JSON.stringify(action.payload)
      );
      state.inputWarehouse = JSON.parse(action.payload);
    },
    setOutputWarehouse(state, action) {
      localStorage.setItem(
        "default_output_warehouse",
        JSON.stringify(action.payload)
      );
      state.outputWarehouse = JSON.parse(action.payload);
    },
    setDisableWarehouseButton(state, action) {
      state.disableWarehouseButton = action.payload;
    },
    setInputWarehouseId(state, action) {
      state.inputWarehouseId = action.payload;
    },
    setOutputWarehouseId(state, action) {
      state.outputWarehouseId = action.payload;
    },
    getInputWarehouse(state) {
      const default_input_warehouse = localStorage.getItem(
        "default_input_warehouse"
      );
      if (default_input_warehouse) {
        state.inputWarehouse = JSON.parse(default_input_warehouse);
      }
    },
  },
});

export const stockActions = stockSlice.actions;

export default stockSlice.reducer;
