import { createSlice } from "@reduxjs/toolkit";

const initialPosState = {
  selectedCashDrawer: "",
  wareHouse: "",
  editable: false,
};

const posSlice = createSlice({
  name: "pos",
  initialState: initialPosState,
  reducers: {
    setSelectedCashDrawer(state, action) {
      localStorage.setItem(
        "selectedCashDrawer",
        JSON.stringify(action.payload)
      );
      state.selectedCashDrawer = JSON.parse(action.payload);
    },
    setModelIsEditable(state, action) {
      localStorage.setItem("modelIsEditable", JSON.stringify(action.payload));
      state.editable = action.payload;
    },
    setWarehouse(state, action) {
      localStorage.setItem("default_warehouse", JSON.stringify(action.payload));
      state.wareHouse = JSON.parse(action.payload);
    },
    getSelectedCashDrawer(state) {
      const selectedCashDrawer = localStorage.getItem("selectedCashDrawer");
      if (selectedCashDrawer) {
        state.selectedCashDrawer = JSON.parse(selectedCashDrawer);
      }
    },
    getWarehouse(state) {
      const default_warehouse = localStorage.getItem("default_warehouse");
      if (default_warehouse) {
        state.wareHouse = JSON.parse(default_warehouse);
      }
    },
    getModelIsEditable(state) {
      const modelIsEditable = localStorage.getItem("modelIsEditable");
      if (modelIsEditable) {
        state.editable = JSON.parse(modelIsEditable);
      }
    },
  },
});

export const posActions = posSlice.actions;

export default posSlice.reducer;
