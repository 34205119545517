import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Bookings from "../../Bookings/Bookings";

const BookingsSheetDialog = ({
  token,
  dialog,
  dialogTitle,
  dialogDescription,
  action,
  bookingHour,
  bookingResource,
  bookingId,
  resourceType,
  resourceBookings,
  onCloseDialog,
  onEdit,
}) => {
  const { t } = useTranslation("common");
  const closeDialogHandler = () => {
    onCloseDialog();
  };

  const editHandler = () => {
    onEdit();
  };

  return (
    <Dialog
      id="dialog_change_sale_on_hold"
      open={dialog ? true : false}
      onClose={closeDialogHandler}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle id="dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          {dialogDescription && dialogDescription}
        </DialogContentText>
        {dialog && (
          <Bookings
            token={token}
            action={action}
            bookingHour={bookingHour}
            bookingResource={bookingResource}
            bookingId={bookingId}
            resourceType={resourceType}
            resourceBookings={resourceBookings}
            onCloseDialog={closeDialogHandler}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button id="dialog_cancel_button" onClick={closeDialogHandler}>
          {t("buttons.cancel")}
        </Button>
        {action === "view" && (
          <Button id="dialog_edit_button" onClick={editHandler}>
            {t("buttons.edit")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default BookingsSheetDialog;
