import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Bookings from "./components/Bookings/Bookings";
import BookingSheets from "./components/BookingSheets/BookingSheets";
import BookingStatus from "./components/BookingStatus/BookingStatus";
import CashCount from "./components/CashCount/CashCount";
import CashDrawers from "./components/CashDrawers/CashDrawer";
import CashOperations from "./components/CashOperations/CashOperations";
import Charges from "./components/Charges/Charges";
import Clients from "./components/Clients/Clients";
import ClientCategories from "./components/ClientCategories/ClientCategories";
import ClientRecords from "./components/ClientRecords/ClientRecords";
import Company from "./components/Company/Company";
import Dashboard from "./components/Dashboard/Dashboard";
import Executives from "./components/Executives/Executives";
import ErrorPage from "./components/UI/ErrorPage";
import Login from "./components/Login/Login";
import MediaLists from "./components/MediaLists/MediaLists";
import ModelsDetails from "./components/ModelsDetails/ModelsDetails";
import PaymentTypes from "./components/PaymentTypes/PaymentTypes";
import PhotoCategories from "./components/PhotoCategories/PhotoCategories";
import PhotoCatalog from "./components/PhotoCatalog/PhotoCatalog";
import Products from "./components/Products/Products";
import ProductCategories from "./components/ProductCategories/ProductCategories";
import POS from "./components/POS/POS";
import Providers from "./components/Providers/Providers";
import ProviderCategories from "./components/ProviderCategories/ProviderCategories";
import Purchases from "./components/Purchases/Purchases";
import Resources from "./components/Resources/Resources";
import ResourceCategories from "./components/ResourceCategories/ResourceCategories";
import Sales from "./components/Sales/Sales";
import Stock from "./components/Stock/Stock";
import StockMovements from "./components/StockMovements/Datagrid/StockMovements";
import StockMovementDetails from "./components/StockMovementDetails/StockMovementDetails";
import Tasks from "./components/Tasks/Tasks";
import TaskLists from "./components/TaskLists/TaskLists";
import Taxes from "./components/Taxes/Taxes";
import Users from "./components/Users/Users";
import Warehouses from "./components/Warehouses/Warehouses";

const setPageTitleHandler = (title) => {
  if (title.toLowerCase() !== "error" && title.toLowerCase() !== "untitled") {
    document.title = title;
  }
};

const AppRoutes = ({ date, token, hasAccessData }) => {
  return (
    <Routes>
      {!token && (
        <Route
          path="*"
          element={<Login onSetPageTitle={setPageTitleHandler} />}
        />
      )}
      <Route
        path="/"
        element={<Login onSetPageTitle={setPageTitleHandler} />}
      />
      <Route
        path="/login"
        element={<Login onSetPageTitle={setPageTitleHandler} />}
      />
      {token && hasAccessData?.accessRight ? (
        <Fragment>
          <Route
            path="bookings"
            element={
              hasAccessData?.accessRight && (
                <Bookings
                  token={token}
                  date={date}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="bookings/client/:clientId?"
            element={
              hasAccessData?.accessRight && (
                <Bookings
                  token={token}
                  date={date}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="bookings/conflicts"
            element={
              hasAccessData?.accessRight && (
                <Bookings
                  token={token}
                  date={date}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="booking_sheet/:resourceType"
            element={
              hasAccessData?.accessRight && (
                <BookingSheets
                  token={token}
                  date={date}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="booking_status/"
            element={
              hasAccessData?.accessRight && (
                <BookingStatus
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="cash_drawers/"
            element={
              hasAccessData?.accessRight && (
                <CashDrawers
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="cash_operations/"
            element={
              hasAccessData?.accessRight && (
                <CashOperations
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="cash_operations/open"
            element={
              hasAccessData?.accessRight && (
                <CashOperations
                  token={token}
                  action="add"
                  cashRegisterMovementType="1"
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="cash_operations/close"
            element={
              hasAccessData?.accessRight && (
                <CashOperations
                  token={token}
                  action="add"
                  cashRegisterMovementType="2"
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="charges"
            element={
              hasAccessData?.accessRight && (
                <Charges
                  token={token}
                  modelName="Charges"
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="clients"
            element={
              hasAccessData?.accessRight && (
                <Clients token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="client_categories"
            element={
              hasAccessData?.accessRight && (
                <ClientCategories
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="client_records/client/:clientId?"
            element={
              hasAccessData?.accessRight && (
                <ClientRecords
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                  recordType={5}
                />
              )
            }
            exact
          />
          <Route
            path="client_records/background"
            element={
              hasAccessData?.accessRight && (
                <ClientRecords
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                  recordType={1}
                />
              )
            }
            exact
          />
          <Route
            path="client_records/consultations"
            element={
              hasAccessData?.accessRight && (
                <ClientRecords
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                  recordType={2}
                />
              )
            }
            exact
          />
          <Route
            path="client_records/clinic_history"
            element={
              hasAccessData?.accessRight && (
                <ClientRecords
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                  recordType={3}
                />
              )
            }
            exact
          />
          <Route
            path="client_records/prescriptions"
            element={
              hasAccessData?.accessRight && (
                <ClientRecords
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                  recordType={4}
                />
              )
            }
            exact
          />
          <Route
            path="company"
            element={
              hasAccessData?.accessRight && (
                <Company token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="dashboard"
            element={
              hasAccessData?.accessRight && (
                <Dashboard token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="executives"
            element={
              hasAccessData?.accessRight && (
                <Executives
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="media_lists"
            element={
              hasAccessData?.accessRight && (
                <MediaLists
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="payments"
            element={
              hasAccessData?.accessRight && (
                <Charges
                  token={token}
                  modelName="Payments"
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="payment_types"
            element={
              hasAccessData?.accessRight && (
                <PaymentTypes
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="photo_categories"
            element={
              hasAccessData?.accessRight && (
                <PhotoCategories
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="photo_catalog"
            element={
              hasAccessData?.accessRight && (
                <PhotoCatalog
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="photo_catalog/client/:clientId?"
            element={
              hasAccessData?.accessRight && (
                <PhotoCatalog
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="products"
            element={
              hasAccessData?.accessRight && (
                <Products token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="product_categories"
            element={
              hasAccessData?.accessRight && (
                <ProductCategories
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="prices_update"
            element={
              hasAccessData?.accessRight && (
                <Products
                  pricesUpdate="true"
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="pos/new_sale"
            element={
              hasAccessData?.accessRight && (
                <POS
                  token={token}
                  action={"create"}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="pos/new_sale/client/:clientId?"
            element={
              hasAccessData?.accessRight && (
                <POS
                  token={token}
                  action={"create"}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="providers"
            element={
              hasAccessData?.accessRight && (
                <Providers token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="provider_categories"
            element={
              hasAccessData?.accessRight && (
                <ProviderCategories
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="pos/new_purchase"
            element={
              hasAccessData?.accessRight && (
                <POS
                  token={token}
                  action={"create"}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="purchases"
            element={
              hasAccessData?.accessRight && (
                <Purchases token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="purchases/pending_payments"
            element={
              hasAccessData?.accessRight && (
                <Purchases
                  token={token}
                  pendingPayments={true}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="purchases_details"
            element={
              hasAccessData?.accessRight && (
                <ModelsDetails
                  token={token}
                  modelName="purchase_details"
                  translationModel="purchasesDetails"
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="resources"
            element={
              hasAccessData?.accessRight && (
                <Resources token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="resource_categories"
            element={
              hasAccessData?.accessRight && (
                <ResourceCategories
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="sales"
            element={
              hasAccessData?.accessRight && (
                <Sales token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="sales/client/:clientId?"
            element={
              hasAccessData?.accessRight && (
                <Sales token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="sales/pending_charges"
            element={
              hasAccessData?.accessRight && (
                <Sales
                  token={token}
                  pendingCharges={true}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="sales_details"
            element={
              hasAccessData?.accessRight && (
                <ModelsDetails
                  token={token}
                  modelName="sale_details"
                  translationModel="salesDetails"
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="sales_details/client/:clientId?"
            element={
              hasAccessData?.accessRight && (
                <ModelsDetails
                  token={token}
                  modelName="sale_details"
                  translationModel="salesDetails"
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="stock"
            element={
              hasAccessData?.accessRight && (
                <Stock token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="stock_movements/:type"
            element={
              hasAccessData?.accessRight && (
                <StockMovements
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="stock_movement_details/:type"
            element={
              hasAccessData?.accessRight && (
                <StockMovementDetails
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route
            path="tasks"
            element={
              hasAccessData?.accessRight && (
                <Tasks token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="tasks/client/:clientId?"
            element={
              hasAccessData?.accessRight && (
                <Tasks token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="task_lists"
            element={
              hasAccessData?.accessRight && (
                <TaskLists token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="taxes"
            element={
              hasAccessData?.accessRight && (
                <Taxes token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="users"
            element={
              hasAccessData?.accessRight && (
                <Users token={token} onSetPageTitle={setPageTitleHandler} />
              )
            }
            exact
          />
          <Route
            path="warehouses"
            element={
              hasAccessData?.accessRight && (
                <Warehouses
                  token={token}
                  onSetPageTitle={setPageTitleHandler}
                />
              )
            }
            exact
          />
          <Route path="reports">
            <Route
              path="bookings/:type"
              element={
                hasAccessData?.accessRight && (
                  <Bookings
                    token={token}
                    date={date}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="cash_count"
              element={
                hasAccessData?.accessRight && (
                  <CashCount
                    token={token}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="charges"
              element={
                hasAccessData?.accessRight && (
                  <Charges
                    token={token}
                    modelName="Charges"
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="clients"
              element={
                hasAccessData?.accessRight && (
                  <Clients token={token} onSetPageTitle={setPageTitleHandler} />
                )
              }
              exact
            />
            <Route
              path="products"
              element={
                hasAccessData?.accessRight && (
                  <Products
                    token={token}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="purchases"
              element={
                hasAccessData?.accessRight && (
                  <Purchases
                    token={token}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="purchases_details"
              element={
                hasAccessData?.accessRight && (
                  <ModelsDetails
                    token={token}
                    modelName="purchase_details"
                    translationModel="purchasesDetails"
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="purchases/pending_payments"
              element={
                hasAccessData?.accessRight && (
                  <Purchases
                    token={token}
                    pendingPayments={true}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="sales/pending_charges"
              element={
                hasAccessData?.accessRight && (
                  <Sales
                    token={token}
                    pendingCharges={true}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="sales"
              element={
                hasAccessData?.accessRight && (
                  <Sales token={token} onSetPageTitle={setPageTitleHandler} />
                )
              }
              exact
            />
            <Route
              path="sales_details"
              element={
                hasAccessData?.accessRight && (
                  <ModelsDetails
                    token={token}
                    modelName="sale_details"
                    translationModel="salesDetails"
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="stock"
              element={
                hasAccessData?.accessRight && (
                  <Stock token={token} onSetPageTitle={setPageTitleHandler} />
                )
              }
              exact
            />
            <Route
              path="stock/reorder"
              element={
                hasAccessData?.accessRight && (
                  <Stock token={token} onSetPageTitle={setPageTitleHandler} />
                )
              }
              exact
            />
            <Route
              path="stock_movement_details/:type"
              element={
                hasAccessData?.accessRight && (
                  <StockMovementDetails
                    token={token}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
            <Route
              path="services"
              element={
                hasAccessData?.accessRight && (
                  <Products
                    token={token}
                    onSetPageTitle={setPageTitleHandler}
                  />
                )
              }
              exact
            />
          </Route>
        </Fragment>
      ) : (
        <Route
          path="*"
          element={
            <ErrorPage
              hasAccessData={hasAccessData}
              onSetPageTitle={setPageTitleHandler}
            />
          }
        />
      )}
    </Routes>
  );
};
export default AppRoutes;
