import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
} from "@mui/material";
import HeaderMenuRenderIcon from "./HeaderMenuRenderIcon";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
//TODO: implement navLink
const useStyles = makeStyles({
  active: {
    color: "grey",
    background: "#e3e5ea",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  inactive: {
    color: "black",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  iconStyle: {
    marginRight: "8px",
  },
  menu: {
    width: "375px",
  },
});

const HeaderMainMenuItems = ({
  openDrawer: drawerState,
  error,
  loading: isLoading,
  onSelectMenuItem,
}) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = useSelector((state) => state.auth.menuItems);
  //TODO: Needs to be passed to the app
  const [expandedAccordion, setExpandedAccordion] = useState("");
  //TODO: Fix collapse of active menu
  const handleAccordionChange =
    (accordionId) => (event, newExpandedAccordion) => {
      setExpandedAccordion(newExpandedAccordion ? accordionId : false);
    };
  const getActiveAccordion = () => {
    if (menuItems?.AccessRights?.length > 0) {
      menuItems?.AccessRights?.forEach((menu) => {
        if (menu.AccessRights.length > 0) {
          location.pathname &&
            menu.AccessRights.forEach((submenu) => {
              if (submenu.path === location.pathname && !expandedAccordion) {
                setExpandedAccordion(submenu.parent);
              }
            });
        }
      });
    }
  };

  const handleDrawerToggle = (path) => {
    navigate(path);
    onSelectMenuItem(!drawerState);
  };

  useEffect(() => {
    getActiveAccordion();
  });
  return (
    //TODO: Create component
    <List className={classes.menu}>
      {!isLoading && menuItems?.AccessRights?.length === 0 && !error && (
        <Typography variant="body1">{t("errors.noContent")}</Typography>
      )}
      {!isLoading && error && !menuItems && (
        <Typography variant="body1">{t(`${error}`)} x</Typography>
      )}
      {isLoading && <Typography variant="body1">{t("loading")}</Typography>}
      {menuItems?.AccessRights?.map((menu) =>
        menu.AccessRights.length > 0 ? (
          <Accordion
            id={menu.id}
            key={menu.id}
            expanded={expandedAccordion === menu.moduleName}
            onChange={handleAccordionChange(menu.moduleName)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>{HeaderMenuRenderIcon(menu.icon)}</ListItemIcon>
              <Typography id={"menu_item_" + menu.id} variant="body1">
                {menu.type === "mainLink" &&
                  t(`menu.menuItems.${menu.moduleName}.title`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {menu.AccessRights.map(
                (submenu) =>
                  submenu.parentId && (
                    <Link
                      key={submenu.id}
                      to={submenu.path}
                      onClick={() => {
                        handleDrawerToggle();
                      }}
                      className={
                        location.pathname === submenu.path
                          ? classes.active
                          : classes.inactive
                      }
                    >
                      <IconButton
                        aria-label="Link"
                        className={classes.iconStyle}
                      >
                        {HeaderMenuRenderIcon(submenu.icon)}
                      </IconButton>
                      <Typography
                        id={"submenu_item_" + submenu.id}
                        variant="body2"
                      >
                        {submenu.type === "subLink" &&
                          t(
                            `menu.menuItems.${menu.moduleName}.subItems.${submenu.moduleName}`
                          )}
                      </Typography>
                    </Link>
                  )
              )}
            </AccordionDetails>
          </Accordion>
        ) : (
          !menu.parentId &&
          menu.type === "mainLink" && (
            //TODO: create component
            <ListItem
              button
              key={menu.id}
              onClick={() => {
                handleDrawerToggle(menu.path);
              }}
              className={
                location.pathname === menu.path ? classes.active : null
              }
            >
              <ListItemIcon>{HeaderMenuRenderIcon(menu.icon)}</ListItemIcon>
              <ListItemText
                primary={t(`menu.menuItems.${menu.moduleName}.title`)}
              />
            </ListItem>
          )
        )
      )}
    </List>
  );
};
export default HeaderMainMenuItems;
