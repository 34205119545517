import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material/";
import { format, isSameDay } from "date-fns";
import { es, enUS } from "date-fns/locale";

const DateController = ({ date, translationModel, onChangeDate }) => {
  const { t } = useTranslation("common");
  const lang = useSelector((state) => state.ui.lang);
  const changeDate = (direction) => {
    onChangeDate(direction);
  };

  return (
    <Fragment>
      <Button
        color={isSameDay(new Date(date), new Date()) ? "success" : "inherit"}
        id="today_button"
        variant="outlined"
        sx={{ marginRight: "10px" }}
        onClick={() => changeDate("current")}
      >
        {t(translationModel + ".buttons.today")}
      </Button>
      <Button
        color="inherit"
        id="previous_date_button"
        variant="contained"
        onClick={() => changeDate("previous")}
      >
        <ArrowBackIos fontSize="small" />
      </Button>
      <Button
        color="inherit"
        id="next_date_button"
        variant="contained"
        onClick={() => changeDate("next")}
      >
        <ArrowForwardIos fontSize="small" />
      </Button>
      <Button
        color="inherit"
        id="date_button"
        variant="text"
        sx={{ marginLeft: "10px" }}
      >
        <Typography
          id="date_text"
          variant="h5"
          value={format(new Date(date), "yyyyMdd")}
        >
          {format(new Date(date), "MMMM dd yyyy", {
            locale: lang === "es" ? es : enUS,
          })}
        </Typography>
      </Button>
    </Fragment>
  );
};

export default DateController;
