import React from "react";
import { Grid, Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import styles from "./styles.module.css";

const StockMovementsOnHold = (props) => {
  const { t } = useTranslation("common");
  const translationModel = props.translationModel;
  const type = props.type;
  const selectedStockMovementId = props.selectedStockMovementId;
  const stockMovementsOnHold = props.stockMovementsOnHold;

  const selectStockMovementsOnHoldHandler = (
    stockMovementId,
    stockMovementData
  ) => {
    props.onSelectStockMovementsOnHoldHandler(
      "",
      "",
      stockMovementId,
      stockMovementData
    );
  };

  return (
    <Grid sx={{ paddingBottom: "200px", minWidth: "600px" }} container>
      <Grid item sx={{ marginTop: "30px" }} xs={12} s={12} md={12} lg={12}>
        {stockMovementsOnHold &&
          stockMovementsOnHold.StockUpdatesOnHold.map(
            (stockMovement) =>
              stockMovement.id !== selectedStockMovementId && (
                <Card
                  className={`${styles["stockMovementsOnHold"]}`}
                  id={translationModel + "_on_hold_" + stockMovement.id}
                  key={stockMovement.id}
                  onClick={() =>
                    selectStockMovementsOnHoldHandler(
                      stockMovement.id,
                      stockMovement
                    )
                  }
                  style={{ backgroundColor: "#035fc0", color: "white" }}
                >
                  <Typography>
                    {`${t(translationModel + ".columns.id")} ${
                      stockMovement.id
                    } `}
                  </Typography>
                  <div>
                    <Typography>
                      {`${t(translationModel + ".columns.date")} `}
                      {stockMovement.stoUpdDate &&
                        format(
                          new Date(stockMovement.stoUpdDate),
                          "dd/MM/yyyy"
                        )}
                    </Typography>
                  </div>
                  <Typography>
                    {`${t(translationModel + ".columns.detailsCount")} `}
                    {stockMovement.StockUpdateDetails.length &&
                      stockMovement.StockUpdateDetails.length}
                  </Typography>
                  {(type === "input" || type === "exchange") && (
                    <Typography>
                      {`${t(translationModel + ".columns.inputWarehouse")} `}
                      {stockMovement.stoUpdWarInName &&
                        stockMovement.stoUpdWarInName}
                    </Typography>
                  )}
                  {(type === "output" || type === "exchange") && (
                    <Typography>
                      {`${t(translationModel + ".columns.outputWarehouse")} `}
                      {stockMovement.stoUpdWarOutName &&
                        stockMovement.stoUpdWarOutName}
                    </Typography>
                  )}
                  <Typography>
                    {`${t(translationModel + ".columns.captured")} `}
                    {stockMovement.stoUpdCaptured &&
                      stockMovement.stoUpdCaptured}
                  </Typography>
                </Card>
              )
          )}
      </Grid>
    </Grid>
  );
};

export default StockMovementsOnHold;
