import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  token: null,
  username: null,
  loggedOut: false,
  redirect: null,
  accessRights: null,
  lastPage: null,
  menuItems: null,
  defaultCashDrawer: null,
  defaultWarehouse: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setToken(state, action) {
      localStorage.setItem("access_token", JSON.stringify(action.payload));
      state.token = action.payload;
    },
    setUser(state, action) {
      localStorage.setItem("username", JSON.stringify(action.payload));
      state.username = action.payload;
    },
    setRedirect(state, action) {
      state.redirect = action.payload;
    },
    setLastPage(state, action) {
      state.lastPage = action.payload;
    },
    setAccessRights(state, action) {
      state.accessRights = action.payload;
    },
    setMenuItems(state, action) {
      state.menuItems = action.payload;
    },
    setDefaultCashDrawer(state, action) {
      localStorage.setItem("defaultCashDrawer", JSON.stringify(action.payload));
      state.defaultCashDrawer = action.payload;
    },
    setDefaultWarehouse(state, action) {
      localStorage.setItem("defaultWarehouse", JSON.stringify(action.payload));
      state.defaultWarehouse = action.payload;
    },
    getToken(state) {
      const token = localStorage.getItem("access_token");
      state.token = JSON.parse(token);
    },
    getUsername(state) {
      const user = localStorage.getItem("username");
      state.username = JSON.parse(user);
    },
    getDefaultCashDrawer(state) {
      const defaultCashDrawer = localStorage.getItem("defaultCashDrawer");
      state.defaultCashDrawer = JSON.parse(defaultCashDrawer);
    },
    getDefaultWarehouse(state) {
      const defaultWarehouse = localStorage.getItem("defaultWarehouse");
      state.defaultWarehouse = JSON.parse(defaultWarehouse);
    },
    logout(state) {
      state.loggedOut = true;
      state.token = null;
      state.username = null;
      state.redirect = null;
      state.accessRights = null;
      state.menuItems = null;
      state.defaultCashDrawer = null;
      state.defaultWarehouse = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
      localStorage.removeItem("defaultCashDrawer");
      localStorage.removeItem("defaultWarehouse");
      localStorage.removeItem("selectedCashDrawer");
      localStorage.removeItem("modelIsEditable");
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
