import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { format, add } from "date-fns";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";
import GridActions from "../UI/GridActions";

const ModelGrid = (props) => {
  const [pageSize, setPageSize] = useState(
    parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10)
  );
  const [alertDelete, setAlertDelete] = useState(false);
  const [Id, setId] = useState(null);
  const { t } = useTranslation("common");
  const modelRows = props.modelRows;
  const isLoading = props.isLoading;
  const smallScreen = props.smallScreen;
  const modelName = props.modelName;
  const translationModel = props.translationModel;
  const isReport = props.isReport;
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (Id) => {
    setAlertDelete(true);
    setId(Id);
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    props.onDeleteModel(Id);
  };

  const viewHandler = (Id) => {
    props.onViewModel(Id);
  };

  const editHandler = (Id) => {
    props.onEditModel(Id);
  };

  const refreshGridHandler = () => {
    props.onRefreshGrid();
  };

  const addHandler = () => {
    props.onAddModel();
  };

  const columns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      disableExport: true,
      filterable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={modelName}
        />
      ),
    },
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "number",
    },
    {
      field: "exeInitials",
      headerName: t(translationModel + ".columns.initials"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
    },
    {
      field: "contact",
      headerName: t(translationModel + ".columns.name"),
      align: "center",
      headerAlign: "center",
      flex: 2,
      minWidth: 50,
      editable: false,
      valueGetter: (params) =>
        `${
          params.row.exeTitle
            ? t(translationModel + ".form.titles." + params.row.exeTitle)
            : ""
        } ${params.row.exeFirstName || ""} ${params.row.exeLastName || ""} ${
          params.row.exeMotherLastName || ""
        }`,
    },
    {
      field: "emails",
      headerName: t(translationModel + ".columns.emails"),
      align: "center",
      headerAlign: "center",
      flex: 2,
      minWidth: 50,
      editable: false,
      valueGetter: (params) =>
        `${params.row.exeMainEmail || ""}, ${params.row.exeAltEmail || ""} ${
          params.row.exeMotherLastName || ""
        }`,
    },
    {
      field: "exeBirthdate",
      headerName: t(translationModel + ".columns.birthday"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      editable: false,
      valueGetter: (params) =>
        format(add(new Date(params.row.exeBirthdate), { days: 1 }), "dd/MM"),
    },
    {
      field: "medInactive",
      headerName: t(translationModel + ".columns.inactive"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      type: "string",
      renderCell: (params) => (
        <Switch
          id={`inactive_${params.row.id}`}
          checked={
            params.row.exeInactive === 1 || params.row.exeInactive === true
              ? true
              : false
          }
          disabled
        />
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbar
        modelName={modelName}
        smallScreen={smallScreen}
        onAddHandler={addHandler}
        onRefreshGrid={refreshGridHandler}
      />
    );
  };
  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", {
            model: t(translationModel + ".model"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t(translationModel + ".model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {modelRows.executives !== undefined &&
        modelRows.executives.length <= 0 &&
        !isLoading && <Alert severity="warning">{t("errors.noContent")}</Alert>}
      {modelRows.executives.length && (
        <DataGrid
          rows={modelRows.executives}
          columns={columns}
          pageSize={parseInt(pageSize, 10)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={rowsPerPage.map(Number)}
          checkboxSelection={false}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            sorting: { sortModel: [{ field: "id", sort: "desc" }] },
            columns: {
              columnVisibilityModel: {},
            },
          }}
        />
      )}
    </Fragment>
  );
};

export default ModelGrid;
