import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ReportError from "./ReportError";

const HeatherDialog = ({ type, title, description, onDialogHandler }) => {
  const { t } = useTranslation("common");
  const dialogHandler = () => {
    onDialogHandler();
  };

  return (
    <Dialog
      id="edit_sale"
      open={Boolean(type)}
      onClose={dialogHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{description}</Typography>
        {type === "reportError" && (
          <ReportError onCloseDialog={dialogHandler} />
        )}
      </DialogContent>
      <DialogActions>
        <Button id="alert_cancel_button" onClick={dialogHandler}>
          {t("buttons.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default HeatherDialog;
