import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Button,
  CircularProgress,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import ImageAvatar from "./ImageAvatar";
import { CloudUpload, CloudDownload } from "@mui/icons-material";
import styles from "./UI.module.css";

function FileUploader({
  action,
  currentFile,
  fileType,
  inputId,
  inputLabel,
  token,
  onUploadSuccess,
}) {
  const { t } = useTranslation("common");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadDownloadError, setUploadDownloadError] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const uploadHandler = async () => {
    setUploadSuccess(false);
    setUploadDownloadError(false);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      setUploading(true);
      //TODO: Adapt useHttp
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_UPLOAD_DIR}?type=${fileType}`,
          {
            method: "POST",
            body: formData,
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`${errorMessage.error}`);
        }

        const data = await response.json();
        setUploadSuccess(true);
        onUploadSuccess(data.file.newFilename);
        setTimeout(() => {
          setUploading(false);
        }, 1000);
      } catch (error) {
        setUploadDownloadError(true);
        setTimeout(() => {
          setUploading(false);
        }, 1000);
      }
    }
  };

  const getFileHandler = (file) => {
    fetch(
      `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_UPLOAD_DIR}/${process.env.REACT_APP_UPLOAD_GET_FILE_DIR}/${file}?type=${fileType}`,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          console.error("Error fetching file:", response.status);
          return Promise.reject("File fetch failed");
        }
      })
      .then((fileBlob) => {
        const fileUrl = URL.createObjectURL(fileBlob);
        setFileUrl(fileUrl);
      })
      .catch((error) => {
        setUploadDownloadError(error.message);
      });
  };

  const selectFileHandler = () => {
    setUploadSuccess(false);
    setUploadDownloadError(false);
    fileInputRef.current.click();
  };

  const changeFileHandler = (e) => {
    e.target?.files[0].name && setSelectedFile(e.target.files[0]);
    e.target?.files[0].name && setFileName(e.target.files[0].name);
  };

  const showUploadButtonHandler = () => {
    if (
      (currentFile || !currentFile) &&
      selectedFile !== currentFile &&
      selectedFile &&
      !uploadSuccess &&
      !uploadDownloadError
    ) {
      return true;
    }
    return false;
  };

  const documentErrorHandler = () => {
    if (
      fileType === "image" ||
      fileType === "photoCatalog" ||
      fileType === "product" ||
      fileType === "productCategory" ||
      fileType === "resource"
    ) {
      return t("errors.fileImageUploadError");
    }
    return t("errors.fileUploadError");
  };

  useEffect(() => {
    currentFile && setFileName(currentFile);
    currentFile && getFileHandler(currentFile);
    // eslint-disable-next-line
  }, [currentFile]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {uploading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={uploading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {currentFile &&
        (fileType === "image" ||
          fileType === "photoCatalog" ||
          fileType === "clientRecords" ||
          fileType === "product" ||
          fileType === "productCategory" ||
          fileType === "resource") && (
          <ImageAvatar
            currentFile={currentFile}
            inputId={inputId}
            token={token}
            fileType={fileType}
          ></ImageAvatar>
        )}
      <Fragment>
        <FormControl
          error={uploadDownloadError ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor={inputId}>{inputLabel}</InputLabel>
          <FilledInput
            sx={{ background: "white", width: 200 }}
            id={inputId}
            value={fileName ? fileName : ""}
            type="text"
            label={inputLabel}
          />
          {uploadDownloadError && (
            <FormHelperText id={inputId} sx={{ maxWidth: "200px" }}>
              {documentErrorHandler()}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          id={`${inputId}_select_button`}
          variant="contained"
          sx={{ marginTop: "10px", marginLeft: "5px" }}
          color="inherit"
          disabled={action === "view"}
          onClick={selectFileHandler}
        >
          {t(`buttons.choseFile`)}
        </Button>
        <input
          type="file"
          id={`${inputId}_input`}
          ref={fileInputRef}
          disabled={action === "view"}
          className={`${styles["custom-file-input"]}`}
          onChange={changeFileHandler}
        />
        {action === "view" &&
          fileUrl &&
          fileType === "document" &&
          !uploadDownloadError && (
            <Button
              variant="contained"
              color="inherit"
              startIcon={<CloudDownload />}
              sx={{
                marginTop: "20px",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("buttons.download")}
            </Button>
          )}
        {showUploadButtonHandler() && (
          <Button
            component="label"
            variant="contained"
            sx={{ marginTop: "10px", marginLeft: "5px" }}
            startIcon={<CloudUpload />}
            onClick={uploadHandler}
          >
            {t(`buttons.upload`)}
          </Button>
        )}
      </Fragment>
    </div>
  );
}

export default FileUploader;
