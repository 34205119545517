//TODO: Details not showing on new movements
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Details = (props) => {
  const { t } = useTranslation("common");
  const stockMovementData = props.stockMovementData;
  const translationModel = props.translationModel;
  let totalProducts = 0;

  return (
    <Fragment>
      <table style={{ paddingTop: "20px", paddingBottom: "80px" }}>
        <tbody>
          <tr>
            <th>
              {t(translationModel + ".form.columns.StockMovementDetails.name")}
            </th>
            <th>
              {t(
                translationModel + ".form.columns.StockMovementDetails.quantity"
              )}
            </th>
          </tr>
          {stockMovementData &&
            stockMovementData.StockUpdateDetails &&
            stockMovementData.StockUpdateDetails.map((updateDetail) => {
              totalProducts += updateDetail.stoUpdDetQuantity;
              return (
                <tr key={updateDetail.id}>
                  <td align="center">{updateDetail.stoUpdDetProdName}</td>
                  <td align="center">{updateDetail.stoUpdDetQuantity}</td>
                </tr>
              );
            })}
          <tr>
            <th align="right" style={{ paddingTop: "20px" }}>
              {t(translationModel + ".form.columns.StockMovementDetails.total")}
            </th>
            <td align="center" style={{ paddingTop: "20px" }}>
              {totalProducts}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};
export default Details;
