import React, { Fragment, useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";
import useInput from "../../hooks/use-input";
import {
  Button,
  CircularProgress,
  Fab,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Clear, Close } from "@mui/icons-material";

const ModelForm = ({
  token,
  model,
  translationModel,
  action,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  let loadForm = true;
  let formIsValid = false;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    const formFields = {
      username: username,
      profile: profile,
      loginPage: loginPage ? loginPage : null,
      defaultCashDrawer: cashDrawer ? cashDrawer : null,
      defaultWarehouse: warehouse ? warehouse : null,
      executive: executive ? executive : null,
    };

    if (password !== null && password !== undefined && password !== "") {
      formFields.password = password;
    }
    if (
      confirmPassword !== null &&
      confirmPassword !== undefined &&
      confirmPassword !== ""
    ) {
      formFields.password_repeat = confirmPassword;
    }

    if (action === "add") {
      onCreate(formFields);
    }

    if (action === "edit") {
      formFields.id = id;
      onUpdate(formFields);
    }

    resetId();
    resetUsername();
    resetProfile();
    resetPassword();
    resetConfirmPassword();
    resetLoginPage();
    resetWarehouse();
    resetCashDrawer();
    resetRegistered();
    resetLastLogin();
    resetExecutive();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const {
    enteredValue: id,
    setEnteredValue: setId,
    reset: resetId,
  } = useInput();

  const {
    enteredValue: username,
    setEnteredValue: setUsername,
    error: usernameHasError,
    valueChangeHandler: usernameChangeHandler,
    inputBlurHandler: usernameBlurHandler,
    reset: resetUsername,
  } = useInput((value) => value.trim() === "");

  const [password, setPassword] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);
  const resetPassword = () => {
    setPassword("");
  };

  let passwordValue = "";
  const passwordChangeHandler = (event) => {
    passwordValue += event.target.value;
    setPassword(passwordValue);
    if (confirmPassword && confirmPassword !== passwordValue) {
      setPasswordHasError(true);
    }
    if (passwordValue && passwordValue.length < 6) {
      setPasswordHasError(true);
    }
    if (passwordValue && passwordValue.length >= 6) {
      setPasswordHasError(false);
    }
    if (confirmPassword && confirmPassword === passwordValue) {
      setPasswordHasError(false);
    }
  };

  const passwordBlurHandler = () => {
    if (confirmPassword && password !== confirmPassword) {
      setPasswordHasError(true);
    }
    if (confirmPassword && password === confirmPassword) {
      setPasswordHasError(false);
    }
  };

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordHasError, setConfirmPasswordHasError] = useState(false);
  const resetConfirmPassword = () => {
    setConfirmPassword("");
  };

  let confirmPasswordValue = "";
  const confirmPasswordChangeHandler = (event) => {
    confirmPasswordValue += event.target.value;
    setConfirmPassword(confirmPasswordValue);
    if (password !== confirmPasswordValue) {
      setConfirmPasswordHasError(true);
    }
    if (confirmPasswordValue && confirmPasswordValue.length < 6) {
      setConfirmPasswordHasError(true);
    }
    if (confirmPasswordValue && confirmPasswordValue.length >= 6) {
      setConfirmPasswordHasError(false);
    }
    if (password === confirmPasswordValue) {
      setConfirmPasswordHasError(false);
    }
  };

  const confirmPasswordBlurHandler = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordHasError(true);
    }
    if (password === confirmPassword) {
      setConfirmPasswordHasError(false);
    }
  };

  const profiles = [
    "administrator",
    "basicAdministrator",
    "assistant",
    "executive",
    "executive+",
    "cashier",
  ];

  const {
    enteredValue: profile,
    setEnteredValue: setProfile,
    valueChangeHandler: profileChangeHandler,
    hasError: profileHasError,
    inputBlurHandler: profileBlurHandler,
    reset: resetProfile,
  } = useInput((value) => value.toString().trim() !== "" && value !== 0);

  const {
    enteredValue: loginPage,
    setEnteredValue: setLoginPage,
    valueChangeHandler: loginPageChangeHandler,
    inputBlurHandler: loginPageBlurHandler,
    reset: resetLoginPage,
  } = useInput();

  const {
    data: loginPages,
    isLoading: loginPagesAreLoading,
    sendRequest: fetchLoginPages,
  } = useHttp();

  const getLoginPages = () => {
    fetchLoginPages({
      url:
        process.env.REACT_APP_API_SERVER + "/access_rights/start_page_options",
      headers: {
        Accept: "application/json",
        "Content-Profile": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: cashDrawer,
    setEnteredValue: setCashDrawer,
    valueChangeHandler: cashDrawerChangeHandler,
    inputBlurHandler: cashDrawerBlurHandler,
    reset: resetCashDrawer,
  } = useInput();

  const {
    data: cashDrawers,
    isLoading: cashDrawersAreLoading,
    sendRequest: fetchCashDrawers,
  } = useHttp();

  const getCashDrawers = () => {
    fetchCashDrawers({
      url: process.env.REACT_APP_API_SERVER + "/cash_drawers",
      headers: {
        Accept: "application/json",
        "Content-Profile": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: warehouse,
    setEnteredValue: setWarehouse,
    valueChangeHandler: warehouseChangeHandler,
    inputBlurHandler: warehouseBlurHandler,
    reset: resetWarehouse,
  } = useInput();

  const {
    data: warehouses,
    isLoading: warehousesAreLoading,
    sendRequest: fetchWarehouses,
  } = useHttp();

  const getWarehouses = () => {
    fetchWarehouses({
      url: process.env.REACT_APP_API_SERVER + "/warehouses",
      headers: {
        Accept: "application/json",
        "Content-Profile": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };
  const {
    enteredValue: executive,
    setEnteredValue: setExecutive,
    valueChangeHandler: executiveChangeHandler,
    inputBlurHandler: executiveBlurHandler,
    reset: resetExecutive,
  } = useInput();

  const {
    data: executives,
    isLoading: executivesAreLoading,
    sendRequest: fetchExecutives,
  } = useHttp();

  const getExecutives = () => {
    fetchExecutives({
      url: process.env.REACT_APP_API_SERVER + "/executives",
      headers: {
        Accept: "application/json",
        "Content-Profile": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: registered,
    setEnteredValue: setRegistered,
    reset: resetRegistered,
  } = useInput();

  const {
    enteredValue: lastLogin,
    setEnteredValue: setLastLogin,
    reset: resetLastLogin,
  } = useInput();

  if (username && profile && !password && !confirmPassword) {
    formIsValid = true;
  }

  if (
    username &&
    profile &&
    password &&
    confirmPassword &&
    !confirmPasswordHasError
  ) {
    formIsValid = true;
  }

  useEffect(() => {
    if (loadForm) {
      getCashDrawers();
      getWarehouses();
      getLoginPages();
      getExecutives();
    }
    if (model && action) {
      setId(model.user.id ? model.user.id : null);
      setUsername(model.user?.username ? model.user.username : "");
      setProfile(model.user.profile ? model.user.profile : false);
      setLoginPage(model.user?.loginPage ? model.user.loginPage : "");
      setCashDrawer(
        model.user?.defaultCashDrawer ? model.user.defaultCashDrawer : ""
      );
      setWarehouse(
        model.user?.defaultWarehouse ? model.user.defaultWarehouse : ""
      );
      setRegistered(model.user?.registered ? model.user.registered : "");
      setLastLogin(model.user?.last_login ? model.user.last_login : "");
      setExecutive(model.user?.executive ? model.user?.executive : "");
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl error={usernameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="username">
            {t(translationModel + ".form.username")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="username"
            onChange={usernameChangeHandler}
            onBlur={usernameBlurHandler}
            value={username}
            endAdornment={
              username !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetUsername} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.username")}
            disabled={action === "view"}
          />
          {usernameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {action !== "view" && (
          <Fragment>
            <FormControl
              error={passwordHasError ? true : false}
              variant="outlined"
            >
              <InputLabel htmlFor="password">
                {action === "add" && t(translationModel + ".form.password")}
                {action === "edit" && t(translationModel + ".form.newPassword")}
                *
              </InputLabel>
              <FilledInput
                sx={{ background: "white" }}
                id="password"
                onChange={passwordChangeHandler}
                onBlur={passwordBlurHandler}
                value={password}
                endAdornment={
                  password !== "" &&
                  action !== "view" && (
                    <InputAdornment position="start">
                      <Clear onClick={resetPassword} />
                    </InputAdornment>
                  )
                }
                type="password"
                label={
                  action === "add"
                    ? t(translationModel + ".form.password")
                    : action === "edit" &&
                      t(translationModel + ".form.newPassword")
                }
                disabled={action === "view"}
              />
              {passwordHasError && (
                <FormHelperText id="name-helper-text">
                  {t("errors.passwordLength")}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              error={confirmPasswordHasError ? true : false}
              variant="outlined"
            >
              <InputLabel htmlFor="confirmPassword">
                {action === "add" &&
                  t(translationModel + ".form.confirmPassword")}
                {action === "edit" &&
                  t(translationModel + ".form.confirmNewPassword")}
                *
              </InputLabel>
              <FilledInput
                sx={{ background: "white" }}
                id="confirmPassword"
                onChange={confirmPasswordChangeHandler}
                onBlur={confirmPasswordBlurHandler}
                value={confirmPassword}
                endAdornment={
                  confirmPassword !== "" &&
                  action !== "view" && (
                    <InputAdornment position="start">
                      <Clear onClick={resetConfirmPassword} />
                    </InputAdornment>
                  )
                }
                type="password"
                label={
                  action === "add"
                    ? t(translationModel + ".form.confirmPassword")
                    : action === "edit" &&
                      t(translationModel + ".form.confirmNewPassword")
                }
                disabled={action === "view" || !password}
              />
              {confirmPasswordHasError && (
                <FormHelperText id="name-helper-text">
                  {t("errors.passwordsNoMatch")}
                </FormHelperText>
              )}
            </FormControl>
          </Fragment>
        )}
        <FormControl error={profileHasError ? true : false}>
          <InputLabel id="profile_label">
            {t(translationModel + ".form.profile")}*
          </InputLabel>
          <Select
            id="profile"
            labelId="profile_label"
            value={profile}
            label={t(translationModel + ".form.profile")}
            onChange={profileChangeHandler}
            onBlur={profileBlurHandler}
            disabled={action === "view"}
          >
            {profiles.map((profile, index) => (
              <MenuItem key={index} value={profile}>
                {profile}
              </MenuItem>
            ))}
          </Select>
          {profileHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {loginPagesAreLoading && <CircularProgress color="inherit" />}
        {!loginPagesAreLoading && (
          <FormControl>
            <InputLabel id="loginPage_label">
              {t(translationModel + ".form.startPage")}
            </InputLabel>
            <Select
              id="loginPage"
              labelId="loginPage_label"
              value={loginPage}
              label={t(translationModel + ".form.startPage")}
              onChange={loginPageChangeHandler}
              onBlur={loginPageBlurHandler}
              disabled={action === "view"}
            >
              {loginPages?.StartPageOptions?.length > 0 &&
                loginPages?.StartPageOptions.map((page) => (
                  <MenuItem key={page.id} value={page.path}>
                    {page.path}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {executivesAreLoading && <CircularProgress color="inherit" />}
        {!executivesAreLoading && (
          <FormControl>
            <InputLabel id="executive_label">
              {t(translationModel + ".form.executive")}
            </InputLabel>
            <Select
              id="executive"
              labelId="executive_label"
              value={executive}
              label={t(translationModel + ".form.executive")}
              onChange={executiveChangeHandler}
              onBlur={executiveBlurHandler}
              disabled={action === "view"}
            >
              {executives?.executives?.length > 0 &&
                executives.executives.map(
                  (exec) =>
                    (exec.user === null || exec.id === executive) && (
                      <MenuItem key={exec.id} value={exec.id}>
                        {exec.exeInitials}
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
        )}
        {cashDrawersAreLoading && <CircularProgress color="inherit" />}
        {!cashDrawersAreLoading && (
          <FormControl>
            <InputLabel id="profile_label">
              {t(translationModel + ".form.defaultCashDrawer")}
            </InputLabel>
            <Select
              id="cashDrawer"
              labelId="cashDrawer_label"
              value={cashDrawer}
              label={t(translationModel + ".form.defaultCashDrawer")}
              onChange={cashDrawerChangeHandler}
              onBlur={cashDrawerBlurHandler}
              disabled={action === "view"}
            >
              {cashDrawers?.CashDrawers?.length > 0 &&
                cashDrawers?.CashDrawers.map((cashDrawer) => (
                  <MenuItem key={cashDrawer.id} value={cashDrawer.id}>
                    {cashDrawer.casDraName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {warehousesAreLoading && <CircularProgress color="inherit" />}
        {!warehousesAreLoading && (
          <FormControl>
            <InputLabel id="profile_label">
              {t(translationModel + ".form.defaultWarehouse")}
            </InputLabel>
            <Select
              id="warehouse"
              labelId="warehouse_label"
              value={warehouse}
              label={t(translationModel + ".form.defaultWarehouse")}
              onChange={warehouseChangeHandler}
              onBlur={warehouseBlurHandler}
              disabled={action === "view"}
            >
              {warehouses?.Warehouses?.length > 0 &&
                warehouses.Warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.warName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {action === "view" && (
          <TextField
            id="registered"
            label={t(translationModel + ".form.registered")}
            type="dateTime"
            value={registered}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
          />
        )}
        {action === "view" && (
          <TextField
            id="lastLogin"
            label={t(translationModel + ".form.lastLogin")}
            type="dateTime"
            value={lastLogin}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={true}
          />
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
