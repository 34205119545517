import React, { useState, useEffect, Fragment } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useHttp from "../../hooks/use-http";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import styles from "./styles.module.css";
import ModelGrid from "./ModelGrid";

const ModelsDetails = ({
  token,
  action: propAction,
  modelName,
  translationModel,
  onSetPageTitle,
}) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const [action, setAction] = useState("");
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10);
  const { clientId } = useParams();
  let refreshGrid = true;

  const isReport = () => {
    return location.pathname.includes("/reports") ? true : false;
  };

  const {
    isLoading: modelRowsAreLoading,
    error: fetchModelRowsError,
    data: modelRows,
    sendRequest: sendModelRowsGetRequest,
  } = useHttp();

  const getModelRowsHandler = async (page, size, searchText) => {
    const reqPage = page > 0 ? page : 1;
    const reqSize = size > 0 ? size : pageSize;
    const baseUrl = `${process.env.REACT_APP_API_SERVER}/${modelName}`;
    const url = clientId
      ? `${baseUrl}/client/${clientId}?page=${reqPage}&pageSize=${reqSize}&search=${
          searchText ?? ""
        }`
      : `${baseUrl}?page=${reqPage}&pageSize=${reqSize}&search=${
          searchText ?? ""
        }`;
    await sendModelRowsGetRequest({
      url: url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const formTitle = () => {
    let title =
      t(translationModel + ".title") +
      (action ? " - " + t("actions." + action) : "");
    return title;
  };

  const searchHandler = (searchText) => {
    const search = searchText ? searchText : "";
    getModelRowsHandler(1, pageSize, search);
  };

  const setPageTitleHandler = () => {
    onSetPageTitle(formTitle());
  };

  const pageChangeHandler = (page, size, search) => {
    getModelRowsHandler(page + 1, size, search);
  };

  const pageSizeChangeHandler = (size, search) => {
    getModelRowsHandler(1, size, search);
  };

  useEffect(() => {
    if (refreshGrid) {
      setPageTitleHandler();
      propAction && setAction(propAction);
      !modelRows && getModelRowsHandler(0);
      // eslint-disable-next-line
      refreshGrid = false;
    }
  }, [
    sendModelRowsGetRequest,
    refreshGrid,
    propAction,
    modelName,
    translationModel,
    modelRows,
  ]);

  return (
    <Fragment>
      {modelRowsAreLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={modelRowsAreLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {formTitle() && (
        <Typography id={modelName + "_title"} mt="100px" variant="h4">
          {formTitle()}
        </Typography>
      )}
      {fetchModelRowsError && (
        <Alert severity="error">{t("errors.defaultError")}</Alert>
      )}
      {!modelRowsAreLoading && modelRows && !action && (
        <Paper className={styles.datagrid}>
          <ModelGrid
            isLoading={modelRowsAreLoading}
            isReport={isReport()}
            modelName={modelName}
            modelRows={modelRows}
            smallScreen={smallScreen}
            translationModel={translationModel}
            onPageChange={pageChangeHandler}
            onPageSizeChange={pageSizeChangeHandler}
            onSearch={searchHandler}
            onRefreshGrid={getModelRowsHandler}
          />
        </Paper>
      )}
    </Fragment>
  );
};
export default ModelsDetails;
