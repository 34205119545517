import React, { useEffect } from "react";
import useHttp from "../../../hooks/use-http";
import ImageAvatar from "../../UI/ImageAvatar";

const Header = ({ token }) => {
  const {
    isLoading: companyDataIsLoading,
    error: fetchCompanyDataError,
    data: companyData,
    sendRequest: sendCompanyDataGetRequest,
  } = useHttp();

  const getCompanyDataHandler = async () => {
    const baseUrl = `${process.env.REACT_APP_API_SERVER}/companies/1`;
    await sendCompanyDataGetRequest({
      url: baseUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  useEffect(() => {
    getCompanyDataHandler();
    // eslint-disable-next-line
  }, []);

  return (
    !companyDataIsLoading &&
    !fetchCompanyDataError &&
    companyData && (
      <table style={{ width: "100%", paddingBottom: "30px" }}>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <td style={{ verticalAlign: "center" }}>
              {companyData?.company?.comLogo && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ImageAvatar
                    currentFile={companyData?.company?.comLogo}
                    inputId="companyLogo"
                    token={token}
                    fileType="image"
                  ></ImageAvatar>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <th>{companyData.company.comCommercialName}</th>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td>{companyData.company.comCommercialAddress}</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td>{companyData.company.comMainEmail}</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td>{companyData.company.comTels}</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td>{companyData.company.comURL}</td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td>{companyData.company.comTicketLabel}</td>
          </tr>
        </tbody>
      </table>
    )
  );
};

export default Header;
