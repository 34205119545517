import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Grid, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import positive_ssl_certificate from "../../assets/positive_ssl_certificate.png";
import vsoft_logo from "../../assets/vsoft_logo.png";
const Footer = () => {
  const { t } = useTranslation("common");
  const year = new Date().getFullYear();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <footer>
      <Box
        px={{ xs: 3, sm: 10, md: 2, lg: 2 }}
        py={{ xs: 3, sm: 10, md: 2, lg: 2 }}
        bgcolor="primary.main"
        color="white"
        sx={{
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          margin: "0",
          padding: "1rem",
          maxWidth: "100%",
          overflowX: "hidden",
          overflowY: "hidden",
          zIndex: 1,
        }}
      >
        <Container maxWidth="xlg">
          <Grid container spacing={5}>
            {mediumScreen && (
              <Grid
                item
                xs={12}
                s={2}
                md={2}
                lg={2}
                display="flex"
                justifyContent="center"
              >
                <Box spacing={2}>
                  <img
                    alt="Positive SSL Logo"
                    src={positive_ssl_certificate}
                    width="100px"
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={10} s={8} md={8} lg={8}>
              <Box textAlign="center">
                <Link color="inherit" variant="body1" href="/">
                  {t("footer.privatePolicyLink")}
                </Link>
              </Box>
              <Box textAlign="center" pt={1}>
                <Link color="inherit" variant="body1" href="/">
                  {t("footer.companyLink")}
                </Link>
              </Box>
              <Box textAlign="center" pt={{ xs: 2, sm: 4 }}>
                <Typography variant="body1">
                  2013-{year} © {process.env.REACT_APP_TITLE}{" "}
                  {process.env.REACT_APP_VERSION} - {t("footer.createdBy")}
                </Typography>
              </Box>
            </Grid>
            {mediumScreen && (
              <Grid item xs={12} s={2} md={2} lg={2}>
                <Box textAlign="center">
                  <img alt="Vsoft Logo" width="100px" src={vsoft_logo} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};
export default Footer;
