import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Fab, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material/";
import DateController from "./DateController";

const Header = ({
  resourceType,
  modelName,
  translationModel,
  action,
  date,
  onRefresh,
  onChangeDate,
}) => {
  const { t } = useTranslation("common");
  const refreshStyle = {
    marginLeft: "5px",
    marginRight: "5px",
    float: "right",
  };

  const refresh = () => {
    onRefresh();
  };

  const changeDate = (direction) => {
    onChangeDate(direction);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} s={12} md={12} lg={12}>
        <Typography id={translationModel + "_title"} mt="100px" variant="h4">
          {t(translationModel + ".title", {
            resourceType: t(translationModel + ".resourceType." + resourceType),
          })}{" "}
          {action && "- " + t("actions." + action)}
          {!action && (
            <Fab
              sx={refreshStyle}
              size="small"
              aria-label="edit"
              onClick={() => refresh()}
            >
              <Refresh fontSize="small" />
            </Fab>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} s={12} md={12} lg={12} sx={{ paddingTop: "20px" }}>
        <DateController
          translationModel={translationModel}
          date={date}
          onChangeDate={changeDate}
        />
      </Grid>
    </Grid>
  );
};

export default Header;
