import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";

const AccessRightsGuard = ({ accessRights }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessRights) {
      dispatch(authActions.setAccessRights(accessRights));
    }
  }, [accessRights, dispatch]);

  return null;
};

export default AccessRightsGuard;
