import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

const Notifications = (props) => {
  const { t } = useTranslation("common");
  const translationModel = props.translationModel;
  const type = props.type;
  const notificationType = props.notificationType;

  return (
    <Fragment>
      {notificationType === "update" && (
        <Alert
          sx={{ marginTop: "20px" }}
          id="alert_create_success"
          severity="success"
        >
          {t("confirmations.updateSuccess", {
            model: t(translationModel + ".movementType." + type),
          })}
        </Alert>
      )}
      {notificationType === "delete" && (
        <Alert
          sx={{ marginTop: "20px" }}
          id="alert_delete_success"
          severity="success"
        >
          {t("confirmations.deleteSuccess", {
            model: t(translationModel + ".movementType." + type),
          })}
        </Alert>
      )}
    </Fragment>
  );
};

export default Notifications;
