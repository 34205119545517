import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";

function ImageAvatar({ currentFile, fileType, inputId, token }) {
  const [fileUrl, setFileUrl] = useState("");
  const [uploadDownloadError, setUploadDownloadError] = useState(false);

  const getFileHandler = (file, type) => {
    fetch(
      `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_UPLOAD_DIR}/${process.env.REACT_APP_UPLOAD_GET_FILE_DIR}/${file}?type=${type}`,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          console.error("Error fetching file:", response.status);
          return Promise.reject("File fetch failed");
        }
      })
      .then((fileBlob) => {
        const fileUrl = URL.createObjectURL(fileBlob);
        setFileUrl(fileUrl);
      })
      .catch((error) => {
        setUploadDownloadError(error.message);
      });
  };

  useEffect(() => {
    currentFile && getFileHandler(currentFile, fileType);
    // eslint-disable-next-line
  }, [currentFile]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {currentFile && fileUrl && !uploadDownloadError && (
        <Avatar
          sx={{
            width: 50,
            height: 50,
          }}
          id={inputId}
          src={fileUrl}
        />
      )}
    </div>
  );
}

export default ImageAvatar;
