import React, { Fragment, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import useInput from "../../hooks/use-input";
import {
  Button,
  CircularProgress,
  Fab,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Clear, Close } from "@mui/icons-material";
import FileUploader from "../UI/FileUploader";

const ModelForm = ({
  token,
  model,
  translationModel,
  action,
  clientId,
  onCancel,
  onCreate,
  onUpdate,
}) => {
  const { t } = useTranslation("common");
  let loadForm = true;
  let formIsValid = false;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    const formFields = {
      phoClientID: client,
      phoType: category,
      phoStyleName: name,
      phoFrontImage: image1,
      phoFrontImageDesc: image1description,
      phoRightImage: image2,
      phoRightImageDesc: image2description,
      phoLeftImage: image3,
      phoLeftImageDesc: image3description,
      phoBackImage: image4,
      phoBackImageDesc: image4description,
      phoObservations: observations,
    };

    if (action === "add") {
      onCreate(formFields);
    }

    if (action === "edit") {
      formFields.id = id;
      onUpdate(formFields);
    }

    resetId();
    resetName();
    resetClient();
    resetCategory();
    resetImage1();
    resetImage1Description();
    resetImage2();
    resetImage2Description();
    resetImage3();
    resetImage3Description();
    resetImage4();
    resetImage4Description();
    resetObservations();
  };

  const cancelHandler = () => {
    onCancel();
  };

  const {
    data: clients,
    isLoading: clientsAreLoading,
    sendRequest: fetchClients,
  } = useHttp();

  const getClients = () => {
    fetchClients({
      url: process.env.REACT_APP_API_SERVER + "/clients",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: id,
    setEnteredValue: setId,
    reset: resetId,
  } = useInput();

  const {
    enteredValue: client,
    setEnteredValue: setClient,
    valueChangeHandler: clientChangeHandler,
    hasError: clientHasError,
    inputBlurHandler: clientBlurHandler,
    reset: resetClient,
  } = useInput((value) => value.toString().trim() !== "");

  const {
    data: categories,
    isLoading: categoriesAreLoading,
    sendRequest: fetchCategories,
  } = useHttp();

  const getCategories = () => {
    fetchCategories({
      url: process.env.REACT_APP_API_SERVER + "/photo_categories",
      headers: {
        Accept: "application/json",
        "Content-Category": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const {
    enteredValue: category,
    setEnteredValue: setCategory,
    valueChangeHandler: categoryChangeHandler,
    hasError: categoryHasError,
    inputBlurHandler: categoryBlurHandler,
    reset: resetCategory,
  } = useInput((value) => value.toString().trim() !== "" && value !== 0);

  const {
    enteredValue: name,
    setEnteredValue: setName,
    error: nameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.trim() === "");

  const {
    enteredValue: image1,
    setEnteredValue: setImage1,
    reset: resetImage1,
  } = useInput();

  const {
    enteredValue: image1description,
    setEnteredValue: setImage1Description,
    valueChangeHandler: image1descriptionChangeHandler,
    inputBlurHandler: image1descriptionBlurHandler,
    reset: resetImage1Description,
  } = useInput();

  const {
    enteredValue: image2,
    setEnteredValue: setImage2,
    reset: resetImage2,
  } = useInput();

  const {
    enteredValue: image2description,
    setEnteredValue: setImage2Description,
    valueChangeHandler: image2descriptionChangeHandler,
    inputBlurHandler: image2descriptionBlurHandler,
    reset: resetImage2Description,
  } = useInput();

  const {
    enteredValue: image3,
    setEnteredValue: setImage3,
    reset: resetImage3,
  } = useInput();

  const {
    enteredValue: image3description,
    setEnteredValue: setImage3Description,
    valueChangeHandler: image3descriptionChangeHandler,
    inputBlurHandler: image3descriptionBlurHandler,
    reset: resetImage3Description,
  } = useInput();

  const {
    enteredValue: image4,
    setEnteredValue: setImage4,
    reset: resetImage4,
  } = useInput();

  const {
    enteredValue: image4description,
    setEnteredValue: setImage4Description,
    valueChangeHandler: image4descriptionChangeHandler,
    inputBlurHandler: image4descriptionBlurHandler,
    reset: resetImage4Description,
  } = useInput();

  const {
    enteredValue: observations,
    setEnteredValue: setObservations,
    valueChangeHandler: observationsChangeHandler,
    inputBlurHandler: observationsBlurHandler,
    reset: resetObservations,
  } = useInput();

  const { enteredValue: captured, setEnteredValue: setCaptured } = useInput();

  if (client && category) {
    formIsValid = true;
  }

  const uploadSuccessHandler = (fileName, image) => {
    image === 1 && setImage1(fileName);
    image === 2 && setImage2(fileName);
    image === 3 && setImage3(fileName);
    image === 4 && setImage4(fileName);
  };

  useEffect(() => {
    if (loadForm) {
      getClients();
      getCategories();
    }
    if (model && action) {
      setId(model.photo.id ? model.photo.id : null);
      setClient(model.photo.phoClientID ? model.photo.phoClientID : false);
      setCategory(model.photo.phoType ? model.photo.phoType : false);
      setName(model.photo?.phoStyleName ? model.photo.phoStyleName : "");
      setImage1(model.photo?.phoFrontImage ? model.photo.phoFrontImage : "");
      setImage1Description(
        model.photo?.phoFrontImageDesc ? model.photo.phoFrontImageDesc : ""
      );
      setImage2(model.photo?.phoRightImage ? model.photo.phoRightImage : "");
      setImage2Description(
        model.photo?.phoRightImageDesc ? model.photo.phoRightImageDesc : ""
      );
      setImage3(model.photo?.phoLeftImage ? model.photo.phoLeftImage : "");
      setImage3Description(
        model.photo?.phoLeftImageDesc ? model.photo.phoLeftImageDesc : ""
      );
      setImage4(model.photo?.phoBackImage ? model.photo.phoBackImage : "");
      setImage4Description(
        model.photo?.phoBackImageDesc ? model.photo.phoBackImageDesc : ""
      );
      setObservations(
        model.photo?.phoObservations ? model.photo.phoObservations : ""
      );
      setCaptured(model.photo?.phoCaptured ? model.photo.phoCaptured : "");
    }
    if (clientId && action === "add") {
      setClient(clientId);
    }
    // eslint-disable-next-line
    loadForm = false;
  }, [loadForm, model]);

  return (
    <Fragment>
      <Stack
        className={`${styles["form-stack"]}`}
        spacing={1}
        justifyContent="center"
      >
        <Typography variant="h5">
          <Fab
            className={`${styles["action-buttons"]} ${styles["back-button"]}`}
            size="small"
            aria-label="back"
            onClick={() => cancelHandler()}
          >
            <Close fontSize="small" />
          </Fab>
        </Typography>
        <FormControl error={nameHasError ? true : false} variant="outlined">
          <InputLabel htmlFor="name">
            {t(translationModel + ".form.name")}*
          </InputLabel>
          <FilledInput
            sx={{ background: "white" }}
            id="name"
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            value={name}
            endAdornment={
              name !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetName} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.name")}
            disabled={action === "view"}
          />
          {nameHasError && (
            <FormHelperText id="name-helper-text">
              {t("errors.fieldError")}
            </FormHelperText>
          )}
        </FormControl>
        {categoriesAreLoading && <CircularProgress color="inherit" />}
        {!categoriesAreLoading && (
          <FormControl error={categoryHasError ? true : false}>
            <InputLabel id="category_label">
              {t(translationModel + ".form.category")}*
            </InputLabel>
            <Select
              id="category"
              labelId="category_label"
              value={category}
              label={t("categories.form.category")}
              onChange={categoryChangeHandler}
              onBlur={categoryBlurHandler}
              disabled={action === "view"}
            >
              {categories &&
                categories.PhotoCategories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.phoCatName}
                  </MenuItem>
                ))}
            </Select>
            {categoryHasError && (
              <FormHelperText id="category-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {clientsAreLoading && <CircularProgress color="inherit" />}
        {!clientsAreLoading && (
          <FormControl error={clientHasError ? true : false}>
            <InputLabel id="client_label">
              {t(translationModel + ".form.client")}*
            </InputLabel>
            <Select
              id="client"
              labelId="client_label"
              value={client}
              label={t("clients.form.client")}
              onChange={clientChangeHandler}
              onBlur={clientBlurHandler}
              disabled={action === "view"}
            >
              {clients &&
                clients.clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.id} {client.cliFirstName} {client.cliLastName}
                  </MenuItem>
                ))}
            </Select>
            {clientHasError && (
              <FormHelperText id="client-helper-text">
                {t("errors.fieldError")}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <FileUploader
          action={action}
          currentFile={image1}
          fileType="photoCatalog"
          inputId="image1"
          inputLabel={t(translationModel + ".form.image1")}
          token={token}
          onUploadSuccess={(fileName) => uploadSuccessHandler(fileName, 1)}
        ></FileUploader>
        <FormControl variant="outlined">
          <InputLabel htmlFor="image1description">
            {t(translationModel + ".form.description1")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="image1description"
            onChange={image1descriptionChangeHandler}
            onBlur={image1descriptionBlurHandler}
            value={image1description}
            endAdornment={
              image1description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetImage1Description} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.image1description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FileUploader
          action={action}
          currentFile={image2}
          fileType="photoCatalog"
          inputId="image2"
          inputLabel={t(translationModel + ".form.image2")}
          token={token}
          onUploadSuccess={(fileName) => uploadSuccessHandler(fileName, 2)}
        ></FileUploader>
        <FormControl variant="outlined">
          <InputLabel htmlFor="image2description">
            {t(translationModel + ".form.description2")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="image2description"
            onChange={image2descriptionChangeHandler}
            onBlur={image2descriptionBlurHandler}
            value={image2description}
            endAdornment={
              image2description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetImage2Description} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.image2description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FileUploader
          action={action}
          currentFile={image3}
          fileType="photoCatalog"
          inputId="image3"
          inputLabel={t(translationModel + ".form.image3")}
          token={token}
          onUploadSuccess={(fileName) => uploadSuccessHandler(fileName, 3)}
        ></FileUploader>
        <FormControl variant="outlined">
          <InputLabel htmlFor="image3description">
            {t(translationModel + ".form.description3")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="image3description"
            onChange={image3descriptionChangeHandler}
            onBlur={image3descriptionBlurHandler}
            value={image3description}
            endAdornment={
              image3description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetImage3Description} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.image3description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FileUploader
          action={action}
          currentFile={image4}
          fileType="photoCatalog"
          inputId="image4"
          inputLabel={t(translationModel + ".form.image4")}
          token={token}
          onUploadSuccess={(fileName) => uploadSuccessHandler(fileName, 4)}
        ></FileUploader>
        <FormControl variant="outlined">
          <InputLabel htmlFor="image4description">
            {t(translationModel + ".form.description4")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="image4description"
            onChange={image4descriptionChangeHandler}
            onBlur={image4descriptionBlurHandler}
            value={image4description}
            endAdornment={
              image4description !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetImage4Description} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.image4description")}
            disabled={action === "view"}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="observations">
            {t(translationModel + ".form.observations")}
          </InputLabel>
          <FilledInput
            multiline
            maxRows={4}
            sx={{ background: "white" }}
            id="observations"
            onChange={observationsChangeHandler}
            onBlur={observationsBlurHandler}
            value={observations}
            endAdornment={
              observations !== "" &&
              action !== "view" && (
                <InputAdornment position="start">
                  <Clear onClick={resetObservations} />
                </InputAdornment>
              )
            }
            type="text"
            label={t(translationModel + ".form.observations")}
            disabled={action === "view"}
          />
        </FormControl>
        {action === "view" && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="captured">
              {t(translationModel + ".form.captured")}
            </InputLabel>
            <FilledInput
              sx={{ background: "white" }}
              id="captured"
              value={captured}
              type="text"
              label={t(translationModel + ".form.captured")}
              disabled
            />
          </FormControl>
        )}
        {action !== "view" && (
          <Button
            id="submit_form"
            variant="contained"
            onClick={formSubmitHandler}
            disabled={!formIsValid}
          >
            {action === "add" &&
              t("buttons.add", { model: t(translationModel + ".model") })}
            {action !== "add" && t("buttons.save")}
          </Button>
        )}
        <Button
          id="cancel_form"
          variant="contained"
          color={action === "view" ? "inherit" : "warning"}
          onClick={cancelHandler}
        >
          {action === "view" && t("buttons.back")}
          {action !== "view" && t("buttons.cancel")}
        </Button>
      </Stack>
    </Fragment>
  );
};

export default ModelForm;
