import React, { useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  CircularProgress,
  Alert,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";

const Products = ({
  token,
  action,
  editable,
  modelData,
  module,
  searchCriteria,
  productCategory,
  onSelectProductHandler,
}) => {
  const { t } = useTranslation("common");
  const getAmountDue = (model) => {
    if (
      model?.sale?.salAmountDue !== undefined &&
      model?.sale?.Charges?.length > 0
    ) {
      return model.sale.salAmountDue;
    } else if (
      model?.sale?.salAmountDue === 0 &&
      model?.sale?.Charges?.length === 0
    ) {
      return undefined;
    } else if (
      model?.purchase?.purAmountDue !== undefined &&
      model?.purchase?.Payments?.length > 0
    ) {
      return model.purchase.purAmountDue;
    } else if (
      model?.purchase?.purAmountDue === 0 &&
      model?.purchase?.Payments?.length === 0
    ) {
      return undefined;
    } else {
      return undefined;
    }
  };
  const getModelIsCancelled = (model) => {
    return model?.sale?.salCancel || model?.purchase?.purCancel;
  };
  const amountDue = modelData && getAmountDue(modelData);
  const disabled = (modelData && getModelIsCancelled(modelData)) || !editable;

  const isPurchasesModule = () => {
    return (
      module === "purchases" ||
      module === "posnew_purchase" ||
      module === "purchasespending_payments"
    );
  };

  const {
    isLoading: productsAreLoading,
    error: fetchProductsError,
    data: products,
    sendRequest: sendProductsGetRequest,
  } = useHttp();

  const getProductsHandler = async () => {
    const selectedCategory =
      productCategory !== "all" && productCategory !== undefined
        ? productCategory
        : "";

    let requestUrl =
      "/products?orderCol=proCommercialName&orderType=ASC&category=" +
      selectedCategory;

    if (isPurchasesModule()) {
      requestUrl =
        "/products?orderCol=proCommercialName&orderType=ASC&type=product&category=" +
        selectedCategory;
    }

    await sendProductsGetRequest({
      url: process.env.REACT_APP_API_SERVER + requestUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
  };

  const filterProductHandler = (products) => {
    let productsArray = [];

    if (products && products.Products && !searchCriteria) {
      productsArray = products.Products;
    }

    if (products && products.Products && searchCriteria) {
      const filteredProducts = products.Products.filter((product) => {
        if (product.proCommercialName && searchCriteria) {
          return (
            product.proCommercialName
              .toLowerCase()
              .search(searchCriteria.toLowerCase()) >= 0
          );
        }
        return false;
      });
      productsArray.push(...filteredProducts);
    }

    return productsArray.map(
      (product) =>
        product.proCommercialName &&
        product.proCommercialName.length > 0 && (
          <Tooltip
            key={product.id}
            title={
              <Typography variant="h5">{product.proCommercialName}</Typography>
            }
            placement="top"
          >
            <span>
              <Button
                sx={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "180px",
                  height: "80px",
                }}
                size="large"
                color="primary"
                key={product.id}
                id={`product_${product.id}`}
                variant="contained"
                onClick={() => selectProductHandler(product)}
                disabled={
                  action === "view" || disabled || amountDue === 0
                    ? true
                    : false
                }
              >
                {product.proCommercialName &&
                  product.proCommercialName.length > 20 &&
                  product.proCommercialName.slice(0, 20) + "..."}
                {product.proCommercialName &&
                  product.proCommercialName.length <= 20 &&
                  product.proCommercialName}
              </Button>
            </span>
          </Tooltip>
        )
    );
  };

  const selectProductHandler = (product) => {
    onSelectProductHandler("add", product);
  };

  useEffect(() => {
    productCategory && getProductsHandler();
    // eslint-disable-next-line
  }, [productCategory, searchCriteria]);

  return (
    <Grid sx={{ paddingBottom: "80px", minWidth: "600px" }} container>
      <Grid
        className={styles.products}
        item
        sx={{ marginTop: "30px" }}
        xs={12}
        s={12}
        md={12}
        lg={12}
      >
        {productsAreLoading && !products && (
          <CircularProgress color="inherit" />
        )}
        {fetchProductsError && (
          <Alert severity="error">{t("errors.defaultError")}</Alert>
        )}
        {products && filterProductHandler(products)}
      </Grid>
    </Grid>
  );
};

export default Products;
