import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";
import GridActions from "../UI/GridActions";

const ModelGrid = (props) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.ui.page);
  const pageSize = useSelector((state) => state.ui.pageSize);
  const sort = useSelector((state) => state.ui.sort);
  const [alertDelete, setAlertDelete] = useState(false);
  const [Id, setId] = useState(null);
  const { t } = useTranslation("common");
  const action = props.action;
  const modelRows = props.modelRows;
  const isLoading = props.isLoading;
  const smallScreen = props.smallScreen;
  const modelName = props.modelName;
  const translationModel = props.translationModel;
  const isReport = props.isReport;
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (Id) => {
    setAlertDelete(true);
    setId(Id);
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    props.onDeleteModel(Id);
  };

  const changePageHandler = (page) => {
    dispatch(uiActions.setPage(page));
  };

  const changePageSizeHandler = (pageSize) => {
    dispatch(uiActions.setPageSize(pageSize));
  };

  const changeSortHandler = (sortOrder) => {
    dispatch(uiActions.setSort(sortOrder));
  };

  const changeFilterHandler = (searchCriteria) => {
    dispatch(uiActions.setFilter(searchCriteria));
  };

  const viewHandler = (Id) => {
    props.onViewModel(Id);
  };

  const editHandler = (Id) => {
    props.onEditModel(Id);
  };

  const refreshGridHandler = () => {
    props.onRefreshGrid();
  };

  const columns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 2,
      minWidth: 200,
      maxWidth: 200,
      disableExport: true,
      filterable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={modelName}
        />
      ),
    },
    {
      field: "id",
      headerName: t(translationModel + ".columns.id"),
      align: "center",
      headerAlign: "center",
      flex: 2,
      type: "number",
    },
    {
      field: "casDraName",
      headerName: t(translationModel + ".columns.name"),
      align: "center",
      headerAlign: "center",
      flex: 2,
      type: "string",
    },
    {
      field: "casDraObservations",
      headerName: t(translationModel + ".columns.location"),
      align: "center",
      headerAlign: "center",
      flex: 2,
      type: "string",
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbar
        action={action}
        modelName={modelName}
        translationModel={translationModel}
        smallScreen={smallScreen}
        onRefreshGrid={refreshGridHandler}
      />
    );
  };
  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", {
            model: t(translationModel + ".model"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t(translationModel + ".model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {modelRows.CashDrawers !== undefined &&
        modelRows.CashDrawers.length <= 0 &&
        !isLoading && <Alert severity="warning">{t("errors.noContent")}</Alert>}
      {modelRows.CashDrawers.length && (
        <DataGrid
          rows={modelRows.CashDrawers}
          columns={columns}
          pageSize={parseInt(pageSize, 10)}
          onPageChange={(page) => changePageHandler(page)}
          page={page}
          onPageSizeChange={(newPageSize) => changePageSizeHandler(newPageSize)}
          onSortModelChange={(model) =>
            model.length > 0 && changeSortHandler(model[0].field)
          }
          onFilterModelChange={(model) =>
            changeFilterHandler(model.quickFilterValues[0])
          }
          rowsPerPageOptions={rowsPerPage.map(Number)}
          checkboxSelection={false}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: false }}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            sorting: {
              sortModel: [sort],
            },
            columns: {
              columnVisibilityModel: {},
            },
          }}
        />
      )}
    </Fragment>
  );
};

export default ModelGrid;
