import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui-slice";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbar from "../UI/ModelGridToolbar";
import GridActions from "../UI/GridActions";

const ModelGrid = ({
  action,
  modelRows,
  isLoading,
  isReport,
  smallScreen,
  modelName,
  translationModel,
  onDeleteModel,
  onViewModel,
  onRefreshGrid,
  onEditModel,
}) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.ui.page);
  const pageSize = useSelector((state) => state.ui.pageSize);
  const sort = useSelector((state) => state.ui.sort);
  const [alertDelete, setAlertDelete] = useState(false);
  const [Id, setId] = useState(null);
  const { t } = useTranslation("common");
  const rowsPerPage = isReport
    ? JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_REPORTS)
    : JSON.parse(process.env.REACT_APP_ROWS_PER_PAGE_MODULES);

  const refreshGridHandler = () => {
    onRefreshGrid();
  };

  const closeAlertDialog = () => {
    setAlertDelete(false);
  };

  const deleteHandler = (Id) => {
    setAlertDelete(true);
    setId(Id);
  };

  const changePageHandler = (page) => {
    dispatch(uiActions.setPage(page));
  };

  const changePageSizeHandler = (pageSize) => {
    dispatch(uiActions.setPageSize(pageSize));
  };

  const changeSortHandler = (sortOrder) => {
    dispatch(uiActions.setSort(sortOrder));
  };

  const changeFilterHandler = (searchCriteria) => {
    dispatch(uiActions.setFilter(searchCriteria));
  };

  const confirmDelete = () => {
    setAlertDelete(false);
    onDeleteModel(Id);
  };

  const viewHandler = (Id) => {
    onViewModel(Id);
  };

  const editHandler = (Id) => {
    onEditModel(Id);
  };

  const columns = [
    {
      field: "id as id2",
      headerName: t(translationModel + ".columns.actions"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <GridActions
          id={params.row.id}
          onViewModelHandler={viewHandler}
          onEditModelHandler={editHandler}
          onDeleteModelHandler={deleteHandler}
          modelName={modelName}
        />
      ),
    },
    {
      field: "username",
      headerName: t(translationModel + ".columns.username"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row?.username,
    },
    {
      field: "profile",
      headerName: t(translationModel + ".columns.profile"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row?.profile,
    },
    {
      field: "executive",
      headerName: t(translationModel + ".columns.executive"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row?.Executive?.exeInitials,
    },
    {
      field: "cashDrawer",
      headerName: t(translationModel + ".columns.defaultCashDrawer"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row?.CashDrawer?.casDraName,
    },
    {
      field: "warehouse",
      headerName: t(translationModel + ".columns.defaultWarehouse"),
      align: "center",
      headerAlign: "center",
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row?.Warehouse?.warName,
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbar
        action={action}
        modelName={modelName}
        translationModel={translationModel}
        smallScreen={smallScreen}
        onRefreshGrid={refreshGridHandler}
      />
    );
  };

  return (
    <Fragment>
      <Dialog
        id="alert_delete"
        open={alertDelete}
        onClose={closeAlertDialog}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete-title">
          {t("confirmations.deleteConfirmTitle", {
            model: t(translationModel + ".model"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-description">
            {t("confirmations.deleteConfirmDescription", {
              model: t(translationModel + ".model"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert_cancel_button" onClick={closeAlertDialog}>
            {t("buttons.cancel")}
          </Button>
          <Button id="alert_delete_button" onClick={confirmDelete} autoFocus>
            {t("buttons.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      {modelRows.users !== undefined &&
        modelRows.users.length <= 0 &&
        !isLoading && <Alert severity="warning">{t("errors.noContent")}</Alert>}
      {
        <DataGrid
          rows={modelRows.users}
          columns={columns}
          pageSize={parseInt(pageSize, 10)}
          onPageChange={(page) => changePageHandler(page)}
          page={page}
          onPageSizeChange={(newPageSize) => changePageSizeHandler(newPageSize)}
          onSortModelChange={(model) =>
            model.length > 0 && changeSortHandler(model[0].field)
          }
          onFilterModelChange={(model) =>
            changeFilterHandler(model.quickFilterValues[0])
          }
          rowsPerPageOptions={rowsPerPage.map(Number)}
          checkboxSelection={false}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: false }}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            sorting: {
              sortModel: [sort],
            },
            columns: {
              columnVisibilityModel: {},
            },
          }}
        />
      }
    </Fragment>
  );
};

export default ModelGrid;
